import React, { useState } from 'react'
import './ListingCompleteModal.css'
import { Spring, useSpring, config, animated } from 'react-spring'

function ListingCompleteModal({ closeModal }) {
    const props = useSpring({
       from: {height: 0, opacity: 0, transform: 'scale(0.9)'},
       to: {height: window.innerHeight, opacity: 1, transform: 'scale(1)'}
    });

    return (
        <animated.div className='listing-complete-modal' style={{height: props.height, opacity: props.opacity}} onClick={() => closeModal()}>
            <div className="listing-modal-content">

                    <div className='text-container' >
                       <h1>Listing Complete!</h1>
                    </div>

            </div>

        </ animated.div>
        )
}

export default ListingCompleteModal
