import React from 'react'
import './Footer.css'

import { db } from '../firebase'

function Footer() {

    async function upload() {
        alert('doing')
        try {
            await db.collection('users').doc('hZ9sGP7gqPXWABbgGuXenakuF4H3').update({
                sessionTimes: [{
                    startTime: 8,
                    endTime: 12,
                    endDate: '',
                    weekDay: [2,7],
                    monthly: false,
                    monthDays: [],
                }],
            })
            console.log('done')
        } catch (err) {
            console.log(err)
        }

    }

    async function createClass() {
        alert('dooooing')
        try {
            await db.collection('classes').add({

            })
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="footer">
            <p>Forte Tech (Pty) Ltd 2021</p>
            <p onClick={upload} id="reserved-text">All Rights Reserved</p>
        </div>
    )
}

export default Footer
