import React, { useRef, useState } from 'react'
import './InstructorFacilityReviewModal.css'

// MUI
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { Rating } from 'react-simple-star-rating'


import { db } from '../../firebase';

function InstructorFacilityReviewModal({ uid, closeModal, facilityID, bookingID, facilityName }) {

    const [score, setScore] = useState(0);
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [finished, setFinished] = useState(false)

    const textRef = useRef();

    async function saveReview() {
        setLoading(true)

        const textContent = textRef.current.value;

        // Validation
        if (textContent.length < 30) {
            return setErrorMessage('Minimum 30 characters required')
        }

        if (score === 0) {
            return setErrorMessage('Please select a score')
        }

        try {
            await db.collection('facilityReviews').add({
                instructorID: uid,
                facilityID,
                bookingID,
                rating: score,
                textContent,
            })
            setFinished(true)
        } catch (err) {
             setErrorMessage('Could not save your review, please try again')
        }

        setLoading(false)
    }



    return (
        <div onClick={(e) => {if (e.target.className === 'instructor-facility-review-modal') { closeModal() }}} className='instructor-facility-review-modal'>
            {!finished && <div className="instructor-facility-review-modal-content">
                <h1>{facilityName}</h1>
                <p>Let us know about your experience at this facility</p>
                <div className="stars-container">
                <Rating
                ratingValue={score}
                onClick={(e) => setScore(e)}
                fillColor='#D94A4A'
                transition
                allowHover={false}
                // showTooltip
                />
                </div>
                <textarea placeholder='Enter your review here' rows={8} />
            <div className="button-row">
                <button disabled={loading} onClick={saveReview} className="confirm-button">
                    Confirm
                </button>
            </div>
            </div>}

            {finished && <div className="instructor-facility-review-modal-content">
                <h2>Review submitted</h2>
                <p>Thank you for your feedback - we appreciate you helping make Forte a better place for everyone</p>
                </div>}



        </div>
    )
}

export default InstructorFacilityReviewModal
