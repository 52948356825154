import React from 'react'
import './ReviewItem.css'
import { Rating } from 'react-simple-star-rating'


function ReviewItem({ img, name, textContent, date, rating}) {


    return (
        <div className='review-item'>
                <div className="ri-header">
                    <img src={img} alt={name} />
                <h2>{name}</h2>

                </div>
                <span>{date}</span>
                <div className="stars-container">
                <Rating
                    ratingValue={rating}
                    fillColor='#ff003f'
                    transition
                    allowHover={false}
                    readonly
                    size={25}
                // showTooltip
                />
                </div>
                <p>{textContent}</p>
        </div>
    )
}

export default ReviewItem
