import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import './Login.css'
import { AuthProvider, useAuth } from '../../contexts/AuthContext'

// Components
import LoginForm from './LoginForm'
import SignUpForm from './SignUpForm'

import LandingPage from '../../Components/LandingPage/LandingPage'

function Login() {

    const [showLanding, setShowLanding] = useState(true)
    const [formState, setFormState] = useState('login');

    const navigate = useNavigate()

    const { currentUser } = useAuth();

    useEffect(() => {
        if (currentUser) {
            navigate('/')
        }
    }, [])

    function switchForms() {
        if (formState === 'signup') {
            setFormState('login')
        } else {
            setFormState('signup')
        }
    }

    return (
        <AuthProvider>
            <div className="login">
            {showLanding ? <LandingPage handleClick={(e) => {
                setFormState(e)
                setShowLanding(false)
            }} /> : <>
                {formState === 'login' ? <LoginForm switchForm={switchForms} /> : <SignUpForm switchAction={switchForms}/>}
                </>}
            </div>
        </AuthProvider>

    )
}

export default Login
