import React, { useState, useRef, useEffect } from 'react'
import './Steps.css'

// Icons
import { Checkbox } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox';
import PriceIncrease from './PriceIncrease/PriceIncrease';
import BulkDiscount from './BulkDiscount/BulkDiscount'
import InfoIcon from '@mui/icons-material/Info';




function StepSix({  edit, hourlyRate, fwd = true,
                     hourlyValue,
                    priceIncreaseRef, priceDecreaseRef, bulkRef, increaseDefaults, decreaseDefaults, bulkDefaults
}) {

    useEffect(() => {
        // console.log(decreaseDefaults)
        // console.log(increaseDefaults)
setIncreaseDefaultRows()
setDecreaseDefaultRows()
setBulkDefaultRows()
    }, [])



    const options = [
        {
            name: 'Small',
            value: 'small',
        },
        {
            name: 'Medium',
            value: 'medium',
        },
        {
            name: 'Large',
            value: 'large',
        },
    ]
    const [increaseRows, setIncreaseRows] = useState([])
    const [discountRows, setDiscountRows] = useState([])
    const [bulkRows, setBulkRows] = useState([])
    const [bulkTypes, setBulkTypes] = useState([])
    const [update, setUpdate] = useState(0);
    const [bulkOptions, setBulkOptions] = useState(options)
    const [defaultHour, setDefaultHour] = useState(10)

    useEffect(() => {
        setDefaultHour(hourlyValue)
        setUpdate(current => current + 1)
    }, [hourlyValue])

    const bulkTypeRefs = useRef();
    bulkTypeRefs.current = bulkTypes;


    useEffect(() => {
        // console.log(bulkTypeRefs)
        setUpdate(current => current + 1);
    }, [bulkTypeRefs])

    const [increaseArray, setIncreaseArray] = useState([]);
    priceIncreaseRef.current = increaseArray;
    const increaseRef = useRef()
    increaseRef.current = increaseRows

    const [decreaseArray, setDecreaseArray] = useState([])
    priceDecreaseRef.current = decreaseArray;
    const decreaseRef = useRef();
    decreaseRef.current = discountRows;

    const [bulkArray, setBulkArray] = useState([])
    bulkRef.current = bulkArray;
    const bulkTrackRef = useRef();
    bulkTrackRef.current = bulkRows;

    function setIncreaseDefaultRows() {
        if (increaseDefaults === undefined) {
            return
        }
        const rowArray = [];
        setIncreaseArray(increaseDefaults)
        increaseDefaults.forEach((item) => {
            rowArray.push(<PriceIncrease defaultPerc={item.amount} defaultType={item.type} defaultDays={item.days} defaultStart={formatDefaultTime(item.startTime)} defaultEnd={formatDefaultTime(item.endTime)} defaultMonths={item.months} addToIncrease={(item) => storeIncreaseItemChange(item)} changeType={'increase'} removeSelf={removeIncreaseItem}  myKey={item.myKey} key={item.myKey} />)
        })
        setIncreaseRows(rowArray)
        setUpdate(current => current + 1)
    }

    function setDecreaseDefaultRows() {
        if (decreaseDefaults === undefined) {
            return
        }
        const rowArray = [];
        setDecreaseArray(decreaseDefaults)
        decreaseDefaults.forEach((item) => {
            // console.log(item)
            rowArray.push(<PriceIncrease  defaultPerc={item.amount} defaultType={item.type} defaultDays={item.days} defaultStart={formatDefaultTime(item.startTime)} defaultEnd={formatDefaultTime(item.endTime)} defaultMonths={item.months} addToIncrease={(item) => storeDecreaseItemChange(item)} changeType={'decrease'} removeSelf={removeDiscountItem}  myKey={item.myKey} key={item.myKey} />)
        })
        setDiscountRows(rowArray)
        setUpdate(current => current + 1)

    }

    function setBulkDefaultRows() {
        if (bulkDefaults === undefined) {
            return
        }
        const rowArray = [];
        setBulkArray(bulkDefaults)
        bulkDefaults.forEach((item) => {
            rowArray.push(<BulkDiscount
                addToSizeArray={(e, remove) => {
                    setBulkTypes(current => {
                        const filtered = current.filter(item => item !== remove);
                        return [...filtered, e];
                    })}}
                update={() => setUpdate(current => current + 1)} defaultAmount={item.amount} defaultPerc={item.percentage} storeArray={storeBulkItemChange} myKey={item.myKey} key={item.myKey} removeSelf={removeBulkItem} defaultSize={item.size} />)
        })
        setBulkRows(rowArray)
        setUpdate(current => current + 1)

    }

    function storeDecreaseItemChange(item) {
        setDecreaseArray(current => {
            if (current.length === 0) {
                return ([item])
            } else {
               const find = current.find(x => {
                return x.myKey === item.myKey});
               if (find === undefined) {
                return [...current, item]
               } else {
               return current.map(currentItem => {
                    if (currentItem.myKey === item.myKey) {
                        return item
                    } else {
                        return currentItem
                    }
                })
            }
            }

        })
        setUpdate(current => current + 1)
    }

    function storeIncreaseItemChange(item) {
        // console.log('storing item')
        setIncreaseArray(current => {
            if (current.length === 0) {
                return ([item])
            } else {
               const find = current.find(x => {
                return x.myKey === item.myKey});
               if (find === undefined) {
                return [...current, item]
               } else {
               return current.map(currentItem => {
                    if (currentItem.myKey === item.myKey) {
                        return item
                    } else {
                        return currentItem
                    }
                })
            }
            }

        })
        setUpdate(current => current + 1)

    }

    function storeBulkItemChange(item) {
        setBulkArray(current => {
            // console.log(current)
            if (current.length === 0) {
                return ([item])
            } else {
               const find = current.find(x => {
                return x.myKey === item.myKey});
               if (find === undefined) {
                return [...current, item]
               } else {
               return current.map(currentItem => {
                    if (currentItem.myKey === item.myKey) {
                        return item
                    } else {
                        return currentItem
                    }
                })
            }
            }

        })
        setUpdate(current => current + 1)
    }

    function removeIncreaseItem(key) {
        // alert('removing' + key)
        setIncreaseRows(rows => rows.filter(row => {
            // console.log(row)
            return row.key !== key}))
        setIncreaseArray(rows => rows.filter(row => row.myKey !== key))

    }
    function removeDiscountItem(key) {
        setDiscountRows(rows => rows.filter(row => row.key !== key))
        setDecreaseArray(rows => rows.filter(row => row.myKey !== key))

    }

    function removeBulkItem(key, size) {
        console.log(size)
        setBulkRows(rows => rows.filter(row => row.key !== key))
        setBulkTypes(current => current.filter(option => {
            console.log(option)
            return option !== size.current}));
    }

    function formatDefaultTime(time) {
        if (time < 10) {
            return `0${time}:00`
        } else {
            return `${time}:00`
        }
    }

    return (
        <div className={fwd ? "step-six slide-in-right" : "step-six slide-in-left"}>
            {!edit && <h1>Step 6</h1>}
            <h3 className='mb-20'>Set Rates</h3>
            <div className="set-rates-info mb-20">
                         <p className=''>Please start by setting your base hourly rate.</p>
                         <div className="tooltip">
            <InfoIcon />
                            <span className='bubble tooltiptext'>Your base hourly rate forms the
                basis on how you wish to charge
                your clients for utilising your venue.
                Clients can purchase single hour
                sessions or buy in bulk. </span>
            </div>
            </div>
            <div className="rate-container">
                     <div className="rate-row">
                <p>Base Hourly Rate</p>
                <div className="base-input-container">
                    <h3>R</h3>
                <input min={0} defaultValue={hourlyValue} ref={hourlyRate} type="number" step={10} />
                </div>
            </div>
            <h2>Set Rules</h2>
            <div className="discounts-header mt-20">
            <h4>Markdown Rules</h4>
            <div className="tooltip">
            <InfoIcon />
            <span className='bubble tooltiptext'>Markdown rules allow you to discount your
base rate for a set period of time on a once-off
or repeating schedule. We highly recommend
doing this during times of low volume to help
drive your revenue and improve your likelihood
of obtain bookings.
You can also choose to set a discount for
specific hours in a day or month or for the
entire day</span>
            </div>

            </div>
            <div className="discount-container">
                {discountRows}
            </div>
            <div className="discounts-footer">
                <h5>Set new rule</h5>
            <AddBoxIcon onClick={() => setDiscountRows(current => [...current, <PriceIncrease addToIncrease={(item) => storeDecreaseItemChange(item)} changeType={'decrease'}   myKey={`${decreaseRef.current.length }`} key={`${decreaseRef.current.length }`} removeSelf={removeDiscountItem} />])} />

            </div>
            <div className="discounts-header mt-20">
            <div className="discounts-header mt-20">
            <h4>Markup Rules</h4>
            <div className="tooltip">
            <InfoIcon />
            <span className='bubble tooltiptext'>Markup rules allow you to increase your base
for a set period of time on a once-o! or
repeating schedule. We highly recommend
doing this during times of low volume to help
drive your revenue and improve your likelihood
of obtain bookings.</span>
            </div>            </div>
            </div>
            <div className="discount-container">
                {increaseRows}
            </div>
            <div className="discounts-footer">
                <h5>Set new rule</h5>
                <AddBoxIcon onClick={() => {
                setIncreaseRows(current => [...current, <PriceIncrease changeType='increase' addToIncrease={(item) => storeIncreaseItemChange(item)}  removeSelf={removeIncreaseItem} myKey={`${increaseRef.current.length}`} key={`${increaseRef.current.length}`} />])
                }} />
                </div>
            <div className="discounts-header mt-20">
            <div className="discounts-header mt-20">
            <h4>Bulk Discount Rules</h4>
            <div className="tooltip">
            <InfoIcon />
            <span className='bubble tooltiptext'>Discount rules allow you to o!er discounts on
hourly rates when purchased in bulk.<br /><br />
For Example:
R5000.00 = 10% discount. Purchasers of the
bulk package will then only pay R4500.00<br/><br />When creating a bundle, please select the size option most relevant to your offering. For example, A R2000 package might be Small, but a R4000 could be Medium or Large</span>
            </div>             </div>            </div>
            <div className="discount-container">
                {bulkRows}
            </div>
            <div className="discounts-footer">

                <h5>Set new rule</h5>
                <AddBoxIcon onClick={() => {
                    const bulkLength = bulkTrackRef.current.length
                    setBulkRows(current => {
                    if (current.length < 3) {
                        return [...current, <BulkDiscount
                            update={() => setUpdate(current => current + 1)}
                            sizeArrayRef={bulkTypeRefs}
                            addToSizeArray={(e, remove) => {
                            setBulkTypes(current => {
                                const filtered = current.filter(item => item !== remove);
                                return [...filtered, e];
                            })}} storeArray={storeBulkItemChange} myKey={`${bulkLength}`} key={`${bulkLength}`} removeSelf={removeBulkItem} />]
                    } else {
                        return current
                    }
                })
                setUpdate(current => current + 1);
                }} />
                </div>
            </div>

            {/* <div className="rate-container">
                <div className="rate-row">
                    <div className="check-group">
                        <Checkbox defaultChecked={hourlyCheckedValue} inputRef={hourlyChecked} id="hourly-rate" color="default"/>
                        <label for="hourly-rate">Hourly Rate</label>
                    </div>
                    <input min={0} defaultValue={hourlyValue} ref={hourlyRate} type="number" step={10} />
                </div>
                <div className="rate-row">
                    <div className="check-group">
                        <Checkbox defaultChecked={halfDayCheckedValue} inputRef={halfDayChecked} id="half-day-rate" color="default"/>
                        <label for="half-day-rate">Half Day Rate</label>
                    </div>
                    <input min={0} defaultValue={halfDayValue} ref={halfDayRate} type="number" step={20} />
                </div>
                <div className="rate-row">
                    <div className="check-group">
                        <Checkbox defaultChecked={dayCheckedValue} inputRef={fullDayChecked} id="full-day-rate" color="default"/>
                        <label for="full-day-rate">Full Day Rate</label>
                    </div>
                    <input min={0} defaultValue={dayValue} ref={fullDayRate} type="number" step={50} />
                </div>
                <div className="rate-row">
                    <div className="check-group">
                        <Checkbox defaultChecked={weeklyCheckedValue} inputRef={weeklyChecked} id="weekly-rate" color="default"/>
                        <label for="weekly-rate">Weekly Rate</label>
                    </div>
                    <input min={0} defaultValue={weeklyValue} ref={weeklyRate} type="number" step={100} />
                </div>
                <div className="rate-row">
                    <div className="check-group">
                        <Checkbox defaultChecked={monthlyCheckedValue} inputRef={monthlyChecked} id="monthly-rate" color="default"/>
                        <label for="monthly-rate">Monthly Rate</label>
                    </div>
                    <input min={0} defaultValue={monthlyValue} ref={monthlyRate} type="number" step={1000} />
                </div>
            </div> */}
        </div>
    )
}

export default StepSix
