import React, { useState, useEffect, useRef } from 'react'
import './Header.css'
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom'

// Auth
import { useAuth } from '../../contexts/AuthContext'
import { db } from '../../firebase'

// Icons
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ForumIcon from '@material-ui/icons/Forum';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Badge from '@mui/material/Badge';


// Components
import DropdownMenu from '../DropdownMenu'
import NotificationDrop from '../Notifications/NotificationDrop'
import ChatDropdown from '../Chats/ChatDropdown'
import NotificationHandler from '../NotificationHandler/NotificationHandler';
import NotificationSnack from '../NotificationHandler/NotificationSnack';
import LoadingOverlay from 'react-loading-overlay'
import PayBookingModal from '../../Pages/Bookings/PayBookingModal/PayBookingModal'

import logo from '../../assets/images/logowhite.png'


function Header({ hideDrop }) {

    const [showDrop, setShowDrop] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [hideChatDrop, setHideChatDrop] = useState(true);
    const [userName, setUserName] = useState('');
    const [userProfileImage, setUserProfileImage] = useState('');
    const [currentUserRole, setUserRole] = useState();
    const [onEditListingPage, setOnEditListingPage] = useState(false)
    const [showDrawer, setShowDrawer] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const [unreadMessages, setUnreadMessages] = useState(false);
    const [newNotifications, setNewNotifications] = useState(false);

    const [unreadAmount, setUnreadAmount] = useState(0)
    const [unopened, setUnopened] = useState(0)

    const [showPayModal, setShowPayModal] = useState(false)
    const [payModal, setPayModal] = useState(false)

    const [opacity, setOpacity] = useState('0');
    const [logoOpacity, setLogoOpacity] = useState('0')

    const { logOut } = useAuth();
    const navigate = useNavigate();

    const { currentUser, userRole } = useAuth();

    const forumIconRef = useRef()

    const location = useLocation();

    useEffect(() => {
        hideAll();
    },[hideDrop])

    useEffect(() => {
        // console.log(location.pathname)
        if (location.pathname === '/edit-listing') {
            setOnEditListingPage(true)
        } else {
            setOnEditListingPage(false)
        }
    }, [location])

    useEffect(() => {
        if (userRole) {
            // alert(currentUserRole)
        }
    }, [userRole])

    useEffect(() => {
        if (currentUser && userRole) {
            // console.log(userRole)
            listenForMessages(currentUser.uid);
            listenForNotifications(currentUser.uid);
            loadUserName(currentUser.uid);
            setUserRole(userRole);

        }
    }, [currentUser, userRole])

    async function handleLogOut() {
        try {
            await logOut()
            navigate('/login');
        } catch (err) {
            alert('Could not sign you out, please try again')
        }

    }

    async function loadUserName(uid) {
        // console.log(uid)
        const userDoc = await db.collection('users').doc(uid).get();
        if (!userDoc.exists) {
            console.log('1')
            return
        }
        const user = userDoc.data();
        const userName = user.firstName;
        const image = userRole === 'owner' ? user.profileImage : user.imageLink;
        setUserName(userName);
        setUserProfileImage(image);
        setOpacity('1');
    }

    function hideAll() {
        setShowDrop(false);
        setShowNotifications(false);
        setHideChatDrop(true);
    }

    async function listenForMessages(uid) {

        if (userRole === 'owner') {
            db.collection('chat').where('ownerID', '==', uid).orderBy('lastMessage.timeStamp', 'desc').onSnapshot(snapshot => {
                var unreads = false;
                let totalUnreads = 0;

                snapshot.forEach(chatDoc => {
                    const chat = chatDoc.data();
                    const lastMessageRead = chat.lastMessage.read;
                    const lastMessageReceiver = chat.lastMessage.receiver;
                    if (lastMessageRead === false && lastMessageReceiver === uid && chat.lastMessage.sender !== 'admin') {
                        unreads = true;
                        totalUnreads += 1;

                    }
                });

                if (unreads === true) {
                    // setUnreadMessages(true)
                } else {
                    setUnreadMessages(false)
                }
                setUnreadAmount(totalUnreads)
                setUnopened(totalUnreads)

            });
        } else {

            db.collection('chat').where('instructorID', '==', uid).orderBy('lastMessage.timeStamp', 'desc').onSnapshot(snapshot => {
                var unreads = false;
                let totalUnreads = 0;

                for (let i = 0; i < snapshot.docs.length; i++) {
                    const chatDoc = snapshot.docs[i]
                    const chat = chatDoc.data();
                    const lastMessageRead = chat.lastMessage.read;
                    const lastMessageReceiver = chat.lastMessage.receiver;
                    if (lastMessageRead === false && lastMessageReceiver === uid) {
                        unreads = true;
                        totalUnreads += 1;
                    }
                }

                snapshot.forEach(chatDoc => {
                    // const chat = chatDoc.data();
                    // const lastMessageRead = chat.lastMessage.read;
                    // const lastMessageReceiver = chat.lastMessage.receiver;
                    // console.log(lastMessageRead, lastMessageReceiver)
                    // if (lastMessageRead === false && lastMessageReceiver === uid) {
                    //     unreads = true;
                    //     totalUnreads += 1;
                    // }
                });

                if (unreads === true) {
                    // setUnreadMessages(true)
                } else {
                    setUnreadMessages(false)
                }
                setUnopened(totalUnreads)
            });


            // setUnreadAmount(totalUnreads)


        }


    }

    async function listenForNotifications(uid) {
// alert('listening')

if (userRole === 'owner') {
    db.collection('users').doc(uid).collection('notifications').limit(10).orderBy('dateCreated', 'desc').onSnapshot(snapshot => {
        var unreads = false;
        let unreadNots = 0;
        for (let i = 0; i < snapshot.docs.length; i++) {
            const notificationDoc = snapshot.docs[i];
            const notification = notificationDoc.data();
            if (notification.read === false) {
                unreads = true;
                unreadNots += 1;
            }
        }
        setUnreadAmount(unreadNots)
        if (unreads) {
            // setNewNotifications(true);
        } else {
            setNewNotifications(false);
        }
        setLoaded(true)
    })
} else {
    db.collection('users').doc(uid).collection('notifications').limit(10).orderBy('sentAt', 'desc').onSnapshot(snapshot => {
        var unreads = false;
        let unreadNots = 0;

        for (let i = 0; i < snapshot.docs.length; i++) {
            const notificationDoc = snapshot.docs[i];
            const notification = notificationDoc.data();
            if (notification.read === false) {
                unreads = true;
                unreadNots += 1;
            }
        }
        setUnreadAmount(unreadNots)
        if (unreads) {
            // setNewNotifications(true);
        } else {
            setNewNotifications(false);
        }
        setLoaded(true)
    })
}


    }

    async function setNotificationsRead() {
                db.collection('users').doc(currentUser.uid).collection('notifications').onSnapshot((notifications) => {
            const current = notifications.docs.length;
            notifications.docChanges().forEach((change) => {
                if (change.type === 'added') {
                    const notification = change.doc.data();
                    change.doc.ref.update({
                        read: true,
                    });
                }
            });
        });
    }

    async function setRead() {
        const nots = await db.collection('users').doc(currentUser.uid).collection('notifications').where('read', '==', false).get()
        nots.forEach(not => {
            not.ref.update({
                read: true,
            })
        })
    }

    async function loadPayModal(docID) {
    const bookingDoc = await db.collection('bookings').doc(docID).get();
    const booking = bookingDoc.data();
    const { start, end, instructorID, facilityID, total, paymentStatus } = booking;
    const startDate = new Date(start.toDate())
    const endDate = new Date(end.toDate());
    if (paymentStatus === 'paid') {
        return;
    }
    // Facility
    const facilityDoc = await db.collection('facilities').doc(facilityID).get();
    const facility = facilityDoc.data();
    const { listingName } = facility;
     setPayModal(<PayBookingModal closeModal={() => setShowPayModal(false)} start={startDate} end={endDate} bookingID={docID} facilityName={listingName}
        facilityID={facilityID} instructorID={instructorID} total={total}
    />)
    return setShowPayModal(true)
}

    return (
        <div onClick={(evt) => {if (evt.target.className === 'header') { hideAll()}}} className="header">
                                    {showPayModal && payModal}

            {/* Image */}
            <Link to="/">
            <img
            onLoad={() => setLogoOpacity('1')}
            style={{opacity: logoOpacity}}
            className="header-img"
            alt="forte-logo"
            src={logo}
            />
            </Link>
            {/* Menu Items */}
            <div className='header-items' style={{opacity: opacity}}>
                <NavLink onClick={hideAll} exact activeClassName='header-link-active' className='header-link' to='/'>
                    <p>Home</p>
                </NavLink>
                {userRole === 'owner' && <NavLink onClick={hideAll} activeClassName='header-link-active' className={onEditListingPage ? 'header-link header-link-active' : 'header-link'} to='/listings'>
                    <p>Listings</p>
                </NavLink>}
                {userRole === 'instructor' && <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/search'>
                    <p>Facilities</p>
                </NavLink>}
                {userRole === 'instructor' && <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/clients'>
                    <p>Clients</p>
                </NavLink>}
                <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/bookings'>
                    <p>Bookings</p>
                </NavLink>
                <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/calendar'>
                    <p>Calendar</p>
                </NavLink>
                {/* {userRole === 'owner' && <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/crm'>
                <p>CRM</p>
                </NavLink>} */}
                <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/analytics'>
                    <p>Analytics</p>
                </NavLink>
                <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/inbox'>
                    <p>Inbox</p>
                </NavLink>

            </div>
            <div className="header-icons-container">

            <div style={{opacity: opacity}} className="header-icon">
                <div className='header-icon-container' onClick={() => {
                    setShowNotifications(false);
                    setShowDrop(false);
                    setHideChatDrop(current => !current);
                }}>
                                                            <Badge badgeContent={unopened} color='error'>

                                    <ForumIcon className={unreadMessages ? 'person-icon red-icon' : 'person-icon'} />
                                    </Badge>
                                    {/* {unreadMessages &&  <p
                                        className='new-notification-number'>1</p>} */}

                </div>
                <div className="header-icon-container
                " onClick={() => {
                    if (unreadAmount > 0 && showNotifications) {
                        setRead()
                    }
                    setShowNotifications(!showNotifications)
                    setShowDrop(false);
                    setHideChatDrop(true);

                }} >
                                        {/* {newNotifications && <p  className='new-notification-number'></p>} */}
                                        <Badge badgeContent={unreadAmount} color='error'>
                                        <NotificationsIcon className={newNotifications ? 'person-icon red-icon' : 'person-icon'} />
                                        </Badge>
                </div>
                {/* <div className="person-icon-container
                ">
                                    <PersonIcon className="person-icon"/>

                </div> */}
                {/* <p onClick={handleLogOut}>Log Out</p> */}
            </div>
            <MenuIcon onClick={() => setShowDrawer(true)} className='header-menu-icon'/>

            <div style={{opacity: opacity}} className="header-profile">
            <p className='header-profile-selector' onClick={() => {
                setShowDrop(!showDrop)
                                  setShowNotifications(false);
                                  setHideChatDrop(true);

                }}>{userName}</p>
                <img className='header-profile-selector'  onClick={() => {setShowDrop(!showDrop)
                                  setShowNotifications(false);
                                  setHideChatDrop(true);

                }} onLoad={() => setOpacity('1')} src={userProfileImage} alt="user-img" />
            </div>
            </div>


            <DropdownMenu userRole={userRole} show={showDrop} />


            {loaded && <NotificationDrop show={showNotifications} loadPayModal={loadPayModal} hideDrop={() => setShowNotifications(false  )} />}
            {/* {showChatDrop && <ChatDropdown  />} */}
                <ChatDropdown hideDrop={hideChatDrop} />
                <Drawer
                onClick={() => setShowDrawer(false)}
                className='menu-drawer'
                anchor='top'
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                >
<NavLink onClick={hideAll} exact activeClassName='header-link-active' className='header-link' to='/'>
                    <p>Home</p>
                </NavLink>
                {userRole === 'owner' && <NavLink onClick={hideAll} activeClassName='header-link-active' className={onEditListingPage ? 'header-link header-link-active' : 'header-link'} to='/listings'>
                    <p>Listings</p>
                </NavLink>}
                {userRole === 'instructor' && <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/search'>
                    <p>Facilities</p>
                </NavLink>}
                {userRole === 'instructor' && <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/clients'>
                    <p>Clients</p>
                </NavLink>}
                <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/bookings'>
                    <p>Bookings</p>
                </NavLink>
                <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/calendar'>
                    <p>Calendar</p>
                </NavLink>
                {/* {userRole === 'owner' && <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/crm'>
                <p>CRM</p>
                </NavLink>} */}
                <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/analytics'>
                    <p>Analytics</p>
                </NavLink>
                <NavLink onClick={hideAll} activeClassName='header-link-active' className='header-link' to='/inbox'>
                    <p>Inbox</p>
                </NavLink>
                    </Drawer>
                <NotificationHandler />
        </div>
    )
}

export default Header
