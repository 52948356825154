import React, { useState, useEffect, useRef } from 'react'
import './Steps.css'

import { db } from '../../../firebase';

import Multiselect from 'multiselect-react-dropdown';

// Icons
import LocationOnIcon from '@material-ui/icons/LocationOn';

function StepFive({ fwd=true, edit, setCountry, setProvince, setCity, setSuburb, addressOneRef, addressTwoRef, suburbRef, suburbValue, provinceValue, cityRef, postalRef, countryRef, facInfoRef, addressOneValue, addressTwoValue, cityValue, postalValue, countryValue, facInfoValue }) {
    const [countryArray, setCountryArray] = useState([]);
    const [provinceArray, setProvinceArray] = useState([]);
    const [provinceDrop, setProvinceDrop] = useState([])
    const [provinceDisabled, setProvinceDisabled] = useState(true)
    const [cityArray, setCityArray] = useState([]);
    const [cityDrop, setCityDrop] = useState([]);
    const [cityDisabled, setCityDisabled] = useState(true)
    const [suburbArray, setSuburbArray] = useState([]);
    const [suburbDrop, setSuburbDrop] = useState([]);
    const [suburbDisabled, setSuburbDisabled] = useState(true);

    useEffect(() => {
        loadLocations();
        // console.log(cityValue, countryValue, suburbValue)
    }, [])


    function compare( a, b ) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      }

    function checkFilled(countries, provinces, cities, suburbs) {
        if (countryValue !== undefined && countryValue !== '') {
            const array = [];
            for (let i = 0; i < provinces[countryValue].length; i++) {
                array.push({
                    name: provinces[countryValue][i],
                })
            }
            array.sort(compare);
            setProvinceDrop(array);
            setProvinceDisabled(false)

        }

        if (provinceValue !== undefined && provinceValue !== '') {
            const array = [];
            for (let i = 0; i < cities[provinceValue].length; i++) {
                array.push({
                    name: cities[countryValue][i],
                })
            }
            array.sort(compare);
            setCityDrop(array);
            setCityDisabled(false)
        }

        if (cityValue !== undefined && cityValue !== '') {
            const array = [];
            for (var j = 0; j < suburbs[cityValue].length; j++) {
                array.push({
                    name: suburbs[cityValue][j],
                })
            }
            array.sort(compare);
            setSuburbDrop(array);
            setSuburbDisabled(false)
        }
    }

    async function loadLocations() {
        const countryArr = [];

        const locationDoc = await db.collection('eliteData').doc('locations').get();
        const location = locationDoc.data();
        const countries = location.countries;
        const cities = location.cities;
        const suburbs = location.suburbs;
        const provinces = location.provinces;

        for (var i = 0; i < countries.length; i++) {
            countryArr.push({
                name: countries[i],
            })
        }

        setProvinceArray(provinces);
        setCountryArray(countryArr)
        setCityArray(cities)
        // console.log(cities);
        setSuburbArray(suburbs)
        checkFilled(countries, provinces, cities, suburbs)

    }

    function changeProvinceArray(evt, type) {
        if (type === 'add') {
            setCountry(evt[0].name);
            const country = (evt[0].name);
            const array = [];
            for (var i = 0; i < provinceArray[country].length; i++) {
                array.push({
                    name: provinceArray[country][i]
                })
            }
            // console.log(array)
            array.sort(compare);
            setProvinceDrop(array)
            setProvinceDisabled(false)
        }

        if (type === 'remove') {
            setCountry('')
            setProvinceDrop([])
            setProvinceDisabled(true)
        }
    }

    function changeCityArray(evt, type) {
        console.log(evt);
        if (type === 'add') {
            setProvince(evt[0].name);
            const province = (evt[0].name);
            const array = [];
            for (var i = 0; i < cityArray[province].length; i++) {
                array.push({
                    name: cityArray[province][i],
                })
            }
            array.sort(compare);
            setCityDrop(array);
            setCityDisabled(false);
        }

        if (type === 'remove') {
            setProvince('')
            setCityDrop([]);
            setCityDisabled(true);
        }
    }

    function changeSuburbArray(evt, type) {
        if (type === 'add') {
            setCity(evt[0].name);
            const city = evt[0].name;
            const array = [];
            for (var i = 0; i < suburbArray[city].length; i++) {
                array.push({
                    name: suburbArray[city][i],
                })
            }
            array.sort(compare)
            setSuburbDrop(array);
            setSuburbDisabled(false);
        }

        if (type === 'remove') {
            setCity('')
            setSuburbDisabled(true);
            setSuburbDrop([]);
            suburbRef.current.resetSelectedValues();
        }
    }

    return (
        <div className={fwd ? "step-five slide-in-right" : "slide-in-left step-five"}>
            {!edit && <h1>Step 5</h1>}
            <p id="fac-header">Where is your facility?</p>
            {/* <button id="location-button"><LocationOnIcon />Use my current location</button> */}
            <div className="address-input">
                <div className="location-left">
                    <div className="location-input-group">
                        <label for="add-one">Address Line 1</label>
                        <input defaultValue={addressOneValue} ref={addressOneRef} type="text"id="add-one" />
                    </div>
                    <div className="location-input-group">
                        <label for="add-two">Address Line 2</label>
                        <input defaultValue={addressTwoValue} ref={addressTwoRef} type="text"id="add-two" />
                    </div>
                    <div className="location-input-row">
                    <div className="location-input-group-small">
                        <label for="country">Country</label>
                        {/* <input defaultValue={countryValue} ref={countryRef} type="text"id="country" /> */}
                        <Multiselect
                            ref={countryRef}
                            options={countryArray}
                            placeholder=''
                            displayValue='name'
                            onSelect={(evt) => changeProvinceArray(evt, 'add')}
                            onRemove={(evt) => changeProvinceArray(evt, 'remove')}
                            // selectionLimit={1}
                            showArrow
                            singleSelect
                            selectedValues={countryValue !== undefined && [{name: countryValue}]}
                        />
                    </div>
                    <div className="location-input-group-small">
                            <label for="city">Province</label>
                            {/* <input defaultValue={cityValue} ref={cityRef} type="text"id="city" /> */}
                            <Multiselect
                                ref={cityRef}
                                options={provinceDrop}
                                disable={provinceDisabled}
                                // hidePlaceholder={true}
                                placeholder=''
                                displayValue='name'
                                onSelect={(evt) => changeCityArray(evt, 'add')}
                                onRemove={(evt) => changeCityArray(evt, 'remove')}
                                // selectionLimit={1}
                                showArrow
                                singleSelect
                                selectedValues={provinceValue !== undefined && [{name: provinceValue}]}

                        />
                        </div>

                    </div>
                    <div className="location-input-row">
                    <div className="location-input-group-small">
                            <label for="city">City</label>
                            {/* <input defaultValue={cityValue} ref={cityRef} type="text"id="city" /> */}
                            <Multiselect
                                ref={cityRef}
                                options={cityDrop}
                                disable={cityDisabled}
                                // hidePlaceholder={true}
                                placeholder=''
                                displayValue='name'
                                onSelect={(evt) => changeSuburbArray(evt, 'add')}
                                onRemove={(evt) => changeSuburbArray(evt, 'remove')}
                                // selectionLimit={1}
                                showArrow
                                singleSelect
                                selectedValues={cityValue !== undefined && [{name: cityValue}]}

                        />
                        </div>
                        <div className="location-input-group-small">
                            <label for="city">Suburb</label>
                            {/* <input defaultValue={suburbValue} ref={suburbRef} type="text"id="city" /> */}
                            <Multiselect
                            ref={suburbRef}
                            options={suburbDrop}
                            disable={suburbDisabled}
                            placeholder=''
                            displayValue='name'
                            onSelect={(evt) => setSuburb(evt[0].name)}
                            onRemove={(evt) => setSuburb('')}
                            showArrow
                            singleSelect
                            selectedValues={suburbValue !== undefined && [{name: suburbValue}]}

                        />
                        </div>
                        {/* <div className="location-input-group-small">
                            <label  for="zip-code">Zip Code</label>
                            <input className='zip-input' defaultValue={postalValue} ref={postalRef} type="text"id="zip-code" />
                        </div> */}
                    </div>

                </div>
                <div className="location-right">
                    <div className="location-text-group">
                        <label for="facility-info">Please provide any other relevant information (Access info, etc)</label>
                        <textarea defaultValue={facInfoValue} ref={facInfoRef} rows="9" cols="60" id="facility-info" />
                    </div>
                </div>



            </div>
        </div>
    )
}

export default StepFive
