import React, { useRef } from 'react'
import './ImageDescription.css'
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import uuid from 'react-uuid'

// Icons
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// Firebase
import { db, storage, admin } from '../../../firebase';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
  });

function ImageDescription({img, file, edit, facilityID, disableButtons, setError, description, removeItem, id}) {

    const classes = useStyles();
    const [progress, setProgress] = React.useState(0);
    const [uploadedLink, setUploadedLink] = React.useState();
    const [saveDisabled, setSaveDisabled] = React.useState(true)
    const [finished, setFinished] = React.useState(false);

    const descriptionRef = useRef();

    // React.useEffect(() => {
    //   disableButtons(true);

    //   const timer = setInterval(() => {
    //     setProgress((oldProgress) => {
    //       if (oldProgress === 100) {
    //         return 0;
    //       }
    //       const diff = Math.random() * 10;
    //       return Math.min(oldProgress + diff, 100);
    //     });
    //   }, 500);

    //   return () => {
    //     clearInterval(timer);
    //   };
    // }, []);

    function reUpload() {
      uploadImage();
    }

    async function uploadImage() {
      disableButtons(true);
    const storageRef = storage.ref().child(`facility-images/${uuid()}`);
     const task = storageRef.put(file);
     task.on('state changed',
     (snapshot) => {
      var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(percentage);
      console.log(percentage);
     },
     (err) => {
      setError('Could not upload, trying again');
      reUpload();
     },
     () => {
      task.snapshot.ref.getDownloadURL().then(async (url) => {
        console.log(url);
        setUploadedLink(url);
        setSaveDisabled(false);
      });

     },
     );
    }

    React.useEffect(() => {
      if (edit !== true) {
      console.log('uploading')
      uploadImage()
      }
    },[]);

    async function saveLink() {
      descriptionRef.current.focus();
      const url = uploadedLink;
      const imageMap = {
        link: url,
        description: descriptionRef.current.value,
      }
      await db.collection('facilities').doc(facilityID).update({
        imageLinks: admin.firestore.FieldValue.arrayUnion(imageMap)
      });
      disableButtons(false)
      setFinished(true)
    }


    return (
        <div className="image-description">
            {/* <ImageIcon className="image-icon" /> */}
            <img src={img} alt="img" />
            <div className="loading-container">
                {/* <TextField id="text-field" placeholder="Describe the image" variant="outlined"/> */}
                {finished ? <p>Saved!</p> : <input defaultValue={description} ref={descriptionRef} type="text" placeholder="Describe the image" />}
                <div className={classes.root}>
                    <LinearProgress variant="determinate" value={progress} />
                </div>
            </div>
            <button disabled={saveDisabled} onClick={saveLink}><SaveIcon /></button>
            <button onClick={() => removeItem(id)}><HighlightOffIcon /></button>

        </div>
    )
}

export default ImageDescription
