import React, { useState, useRef, useEffect } from 'react'
import './BulkDiscount.css'

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';



function BulkDiscount({ myKey, removeSelf, storeArray, defaultAmount = 0, defaultPerc = 5, defaultSize = '', sizeArrayRef = '', addToSizeArray, optionArray, update }) {

    const [updater, setUpdater] = useState(0)
    const [selectedSize, setSelectedSize] = useState(defaultSize)
    const sizeRef = useRef()
    sizeRef.current = selectedSize;
    const options = [
        {
            name: 'Small',
            value: 'small',
        },
        {
            name: 'Medium',
            value: 'medium',
        },
        {
            name: 'Large',
            value: 'large',
        },
    ]

    useEffect(() => {

        setUpdater(current => current + 1);
    }, [sizeArrayRef])

    useEffect(() => {
        // console.log(myKey)
    }, [])

    const amountRef = useRef();
    const percRef = useRef();

    function storeChanges() {
        storeArray({
            amount: parseInt(amountRef.current.value),
            percentage: parseInt(percRef.current.value),
            myKey,
            size: sizeRef.current,
        })
        update()
        setUpdater(current => current + 1);

    }

    function storeSelectChanges(evt) {
        storeArray({
            amount: parseInt(amountRef.current.value),
            percentage: parseInt(percRef.current.value),
            myKey,
            size: evt,
        })
        update()
        setUpdater(current => current + 1);
    }

    return (
        <div className='bulk-discount'>
            <h3>R</h3>
            <input onChange={storeChanges} ref={amountRef} defaultValue={defaultAmount} className='amount-input' placeholder='Amount' type='number' step={500}/>
            <input onChange={storeChanges} ref={percRef} defaultValue={defaultPerc} className='perc-input' placeholder='Discount' type='number' min={5} max={100} step={5} />
            <p>%</p>
            <SelectSearch
                    search={false}
                    closeOnSelect={true}
                    placeholder={'Size'}
                    options={options.filter(option => {
                        // console.log(`filtering ${myKey} array is ${sizeArrayRef.current}`)
                        // console.log(option)
                        // console.log(sizeArrayRef.current)
                        // console.log(!sizeArrayRef.current.includes(option.value))
                        if (sizeArrayRef !== '') {
                            return !sizeArrayRef.current.includes(option.value)
                        } else {
                            return true
                        }})}
                    onChange={(evt) => {
                        // console.log(`filtering ${sizeRef.current}`)
                        // console.log(evt);
                        setSelectedSize(evt);
                        addToSizeArray(evt, sizeRef.current)
                        storeSelectChanges(evt)
                        // update()
                        setUpdater(current => current + 1);
                    }}
                    value={defaultSize}
                    />
            <RemoveCircleOutlineIcon onClick={() => removeSelf(myKey, sizeRef)} />
        </div>
    )
}

export default BulkDiscount
