import React, { useState, useEffect } from 'react'
import './InstructorViewSessionModal.css'

import { db } from '../../../firebase'

function InstructorViewSessionModal({ closeModal, available, date, startTime, endTime, athleteName, sessionID, calendarID, location, uid, startHour }) {
    // console.log(available, date, startTime, endTime, sessionID, athleteName)

    const now = new Date();

    useEffect(() => {
        console.log(calendarID)
    }, [])

    const [cancelStarted, setCancelStarted] = useState(false)
    const [rescheduleStarted, setRescheduleStarted] = useState(false)


    async function cancelSlot() {
        if (available) {
            const calendarDoc = await db.collection('users').doc(uid).collection('calendar').doc(calendarID).get();
            const calendarData = calendarDoc.data();
            const sessionMap = calendarData.Sessions;
            delete sessionMap[startHour];
        } else {
            const calendarDoc = await db.collection('users').doc(uid).collection('calendar').doc(calendarID).get();
            const calendarData = calendarDoc.data();
            const sessionMap = calendarData.Sessions;
            delete sessionMap[startHour];

            await db.collection('sessions').doc(sessionID).update({
                status: 'cancelledByInstructor',
            });
        }

    }

    async function rescheduleSlot() {

    }

    return (
        <div onClick={(e) => { if (e.target.className === 'instructor-view-session-modal') { closeModal() }} } className='instructor-view-session-modal'>
            <div className="instructor-view-session-modal-content">
                <h1>{available ? 'Available Session' : athleteName}</h1>
                {!cancelStarted && <div className="session-info-container">
                    <div className="session-info-row">
                        <p className="bold-text">Date:</p>
                        <p>{date}</p>
                    </div>
                    <div className="session-info-row">
                        <p className="bold-text">Time:</p>
                        <p>{startTime} - {endTime}</p>
                    </div>
                    {!available && <div className="session-info-row">
                        <p className="bold-text">Location:</p>
                        <p>{location}</p>
                    </div>}
                </div>}
                {cancelStarted && <div className='session-info-container'>
                    <p>Are you sure you want to cancel this session?</p>
                    </div>}
                {!cancelStarted && <div className="ivm-button-row button-row">
                    {date > now && <button onClick={() => setCancelStarted(true)} className="cancel-button">Cancel</button>}
                    {(!available && date > now) && <button onClick={() => setRescheduleStarted(false)} className="confirm-button">Reschedule</button>}
                </div>}
                {cancelStarted && <div className="ivm-button-row button-row">
                    <button onClick={() => setCancelStarted(false)} className="cancel-button">Back</button>
                    <button onClick={cancelSlot} className="confirm-button">Confirm</button>
                </div>}
                {rescheduleStarted && <div className="ivm-button-row button-row">
                <button onClick={() => setRescheduleStarted(false)} className="cancel-button">Back</button>
                    {!available && <button onClick={rescheduleSlot} className="confirm-button">Confirm</button>}
                </div>}
            </div>
        </div>
    )
}

export default InstructorViewSessionModal
