import React from 'react'
import './CRM.css'

function CRM() {
    return (
        <div className='crm'>
            <h1>Client Relationship Management</h1>
        </div>
    )
}

export default CRM
