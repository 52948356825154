import React from 'react'
import { useNavigate, useLocation } from 'react-router';
import './ChatItem.css';

function ChatItem({ image, message, name, hideChat, dateString, chatID, unread, menu = false, clickAction, sent = false, }) {

    const navigate = useNavigate();
    const location = useLocation();

    function goToChat() {
        console.log(chatID)
        if (!menu) {
            hideChat();

            navigate(
                '/inbox',
                {
                    state: {
                        chatID,
                    }
                })
        } else {
            clickAction()
        }

    }

    return (
        <div onClick={goToChat} className={unread === true ? 'unread-chat-item chat-item' : 'chat-item'}>
            <div className="chat-image">
                <img alt='profile' src={image} />
            </div>
            <div className={unread === true ? 'unread-chat-content chat-content' :  "chat-content"}>
                <h3>{name}</h3>
                <p>{sent ? 'You: ' : ''}{message}</p>
                <span>{dateString}</span>
            </div>
        </div>
    )
}

export default ChatItem
