import React, { useState, useRef } from 'react'
import './CertUploadModal.css'
import {useDropzone} from 'react-dropzone';
import CloudUpload from '@mui/icons-material/CloudUpload';
import uuid from 'react-uuid'


import { admin, db, storage } from '../../../firebase'

function CertUploadModal({ uid, closeModal }) {

    const [image, setImage] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const nameRef = useRef()

    const {getRootProps, getInputProps} = useDropzone({
        // accept: 'image/*',
        onDrop: acceptedFiles => {
            const newFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
              }));
              console.log(newFiles[0])
            setImage(newFiles[0]);

        }
      });


    async function saveData() {

        setError('')

        if (nameRef.current.value === '') {
            return setError('Please enter a name for your certificate');
        }

        const storageRef = storage.ref().child(`facility-images/${uuid()}`);
        const task = storageRef.put(image);
        task.on('state changed',
        (snapshot) => {
        },
        (err) => {
         return setError('Could not upload, trying again');
        },
        () => {
         task.snapshot.ref.getDownloadURL().then(async (url) => {
           console.log(url);
            const newCert = {
                name: nameRef.current.value,
                accepted: false,
                imageURL: url,
            }
            db.collection('users').doc(uid).collection('confidentialInfo').doc('personalDetails').update({
                certificates: admin.firestore.FieldValue.arrayUnion(newCert),
            })
           setLoading(false);
         });

        },
        );
    }

    return (
        <div className='cert-upload-modal' onClick={(e) => {if (e.target.className === 'cert-upload-modal') {closeModal()}}}>
            <div className="cert-upload-modal-content">

            <h2>Upload Certification</h2>

            <div {...getRootProps({className: 'dropzone mt-20'})}>
                                <input {...getInputProps()} />
                                <div className='dz-upload-container'>
                                    {/* No image */}
                                    {(image === '') && <>
                                    <CloudUpload />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                    </> }
                                    {/* {imageLink !== '' && image === '' && <img alt={classDetails.className} src={imageLink} />} */}

                                    {image !== '' && <>
                                        <img alt={image.name} src={image.preview} />
                                        <p>{image.name}</p>

                                    </> }

                                </div>
                    </div>
                    {error !== '' && <div className="error-message-row">
                        <p>{error}</p>
                    </div>}
                    <input type='text' ref={nameRef} placeholder='Enter certificate name' />
                    <div className="button-row cu-button-row">
                        <button onClick={saveData} disabled={loading}>
                            Confirm
                        </button>
                    </div>
            </div>
        </div>
    )
}

export default CertUploadModal
