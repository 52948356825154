import React from 'react'
import './HomeSummary.css'

function HomeSummary({ yesterday, today, total}) {
    return (
        <div className="home-summary">
            <div className="bookings-item">
                <h3>{yesterday}</h3>
                <p>Yesterday</p>
            </div>
            <div className="bookings-item">
            <h3>{today}</h3>
                <p>Today</p>
            </div>
            <div className="vr"></div>
            <div className="total-bookings">
                <h3>{total}</h3>
                <p>Bookings</p>
            </div>
        </div>
    )
}

export default HomeSummary
