import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './SearchItem.css'

// Components
import ImageCarousel from '../Listings/ImageCarousel'
import Tooltip from '@mui/material/Tooltip';
import ReviewsBox from './ReviewsBox/ReviewsBox';


// Material UI
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StarIcon from '@mui/icons-material/Star';
import PeopleIcon from '@mui/icons-material/People';
import PaymentIcon from '@mui/icons-material/Payment';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import GridViewIcon from '@mui/icons-material/GridView';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import SpaIcon from '@mui/icons-material/Spa';
import WcIcon from '@mui/icons-material/Wc';
import ShareIcon from '@mui/icons-material/Share';
import ShowerIcon from '@mui/icons-material/Shower';
import HotTubIcon from '@mui/icons-material/HotTub';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';

import { db, admin } from '../../firebase';
import Payment from '@material-ui/icons/Payment';

function SearchItem({ country, city, suburb, postalCode, addressOne, addressTwo, ownerIDs, facilityID,
    listingName, venueInfo, hourlyRate, halfDayRate, dayRate, weekRate, monthRate, totalReviews, reviewAverage,
    venueAmenities, venueFacilities, totalBookings, maxCapacity, distance, imageLinks, favorited, userID, venueType, showProfile
}) {


    function returnIcon(type) {
        switch (type) {
            case 'Weight Section': return <Tooltip title={type}><FitnessCenterIcon /></Tooltip>;
            case 'Functional Floor': return <Tooltip title={type}><GridViewIcon /></Tooltip>;
            case 'Yoga Studio': return <Tooltip title={type}><SelfImprovementIcon /></Tooltip>;
            case 'Dance Studio': return <Tooltip title={type}><LibraryMusicIcon /></Tooltip>;
            case 'Massage Rooms': return <Tooltip title={type}><SpaIcon /></Tooltip>;
            case 'Parking': return <Tooltip title={type}><LocalParkingIcon /></Tooltip>;
            case 'Gender Specific Bathrooms': return <Tooltip title={type}><WcIcon /></Tooltip>;
            case 'Unisex Bathrooms': return <Tooltip title={type}><ShareIcon /></Tooltip>;
            case 'Showers': return <Tooltip title={type}><ShowerIcon /></Tooltip>;
            case 'Sauna': return <Tooltip title={type}><WhatshotIcon /></Tooltip>;
            case 'Steam Room': return <Tooltip title={type}><HotTubIcon /></Tooltip>;
            case 'Food Cafe': return <Tooltip title={type}><RestaurantIcon /></Tooltip>;
            case 'Smoothie Bar': return <Tooltip title={type}><LocalDrinkIcon /></Tooltip>;
            default: return type;
        }
    }

    const facilities = [
        {
            name: 'Weight Section',
            id: 'weight-section',
        },
        {
            name: 'Functional Floor',
            id: 'function-floor',
        },
        {
            name: 'Yoga Studio',
            id: 'yoga-studio',
        },
        {
            name: 'Dance Studio',
            id: 'dance-studio',
        },
        {
            name: 'Massage Rooms',
            id: 'massage-room',
        },
        {
            name: 'Parking',
            id: 'parking',
        }

    ];
    const amenities = [
        {
            name: 'Gender Specific Bathrooms',
            id: 'both-bathrooms',
        },
        {
            name: 'Unisex Bathrooms',
            id: 'uni-bathrooms',
        },
        {
            name: 'Showers',
            id: 'showers',
        },
        {
            name: 'Sauna',
            id: 'sauna',
        },
        {
            name: 'Steam Room',
            id: 'steam-room',
        },
        {
            name: 'Food Cafe',
            id: 'food-cafe',
        },
        {
            name: 'Smoothie Bar',
            id: 'smoothie-bar',
        },
    ];

    const navigate = useNavigate();

    const [facilitiesArray, setFacilitiesArray] = useState([]);
    const [amenitiesArray, setAmenitiesArray] = useState([]);
    const [favorite, setFavorite] = useState(false);

    useEffect(() => {
        // console.log(venueAmenities)
        setFavorite(favorited)
        formatFacilities()
        formatAmenities()
    }, [])

    function formatFacilities() {
        const facArray = [];
        for (var i = 0; i < venueFacilities.length; i++) {
            for (var j = 0; j < facilities.length; j++) {
                if (venueFacilities[i] === facilities[j].id) {
                    facArray.push(facilities[j].name);
                }
            }
        }
        setFacilitiesArray(facArray)
    }

    function formatAmenities() {
        const facArray = [];
        for (var i = 0; i < venueAmenities.length; i++) {
            for (var j = 0; j < amenities.length; j++) {
                if (venueAmenities[i] === amenities[j].id) {
                    facArray.push(amenities[j].name);
                }
            }
        }
        setAmenitiesArray(facArray);
    }

    async function toggleFavorites() {
        if (favorite) {
            setFavorite(false);
            await db.collection('users').doc(userID).update({
                favoriteFacilities: admin.firestore.FieldValue.arrayRemove(facilityID)
            });
        } else {
            setFavorite(true);
            await db.collection('users').doc(userID).update({
                favoriteFacilities: admin.firestore.FieldValue.arrayUnion(facilityID)
            });
        }
    }

    async function goToCalendar() {
        if (!favorite) {
            await db.collection('users').doc(userID).update({
                favoriteFacilities: admin.firestore.FieldValue.arrayUnion(facilityID)
            });
            navigate(
                '/calendar',
                {
                    state: {facilityID},
            })
        } else {
            navigate(
                '/calendar',
                {
                    state: {facilityID},
            })
        }
    }



    return (
        <div className='search-item scale-in-ver-top'>
            <div className="search-item-left">
                <ImageCarousel
                imageArray={imageLinks}
                showFullScreen={false}
                showThumb={false}
                />
            </div>
            <div className="search-item-right">
                <div onClick={toggleFavorites} className="favorites-button-row">
                    {/* <p>{favorite ? 'Remove from' : 'Add to' } favorites</p> */}
                    {favorite ? <FavoriteIcon style={{ fill: 'darkred' }} /> : <FavoriteBorderIcon />}
                </div>
                <div className="search-header">
                    <div className="distance-display">
                        <PersonPinCircleIcon />
                    <h5>{distance} km from you</h5>

                    </div>
                    <div className="si-header-container">
                        <h1>{listingName}</h1>
                        <div className="si-header-review">
                            <Tooltip title={'Review Average'}><StarIcon /></Tooltip>
                            <p>{reviewAverage}</p>
                        </div>

                    </div>
                    <p className="location-pee">{suburb}, {city}</p>
                    <p>{venueType}</p>
                    {/* <p>{addressOne}, {addressTwo}, {postalCode} {suburb}</p>
                    <p>{city}, {country}</p> */}
                </div>
                <hr />
                <div className="search-content">
                    <div className="search-content-left">
                        {/* <div className="search-content-review">
                            <div className="search-content-review-item">
                                <Tooltip title={'Review Average'}><StarIcon /></Tooltip>
                                <p>{reviewAverage} / 5 ({totalReviews})</p>
                            </div>
                            <div className="search-content-review-item">
                                <Tooltip title={'Capacity'}><PeopleIcon /></Tooltip>
                                <p>{maxCapacity} max</p>
                            </div>
                            <div className="search-content-review-item">
                                <Tooltip title='Base Rate'><PaymentIcon /></Tooltip>
                                <p>R {hourlyRate} / hour</p>
                            </div>
                        </div> */}
                        {/* <h4>Facilities</h4> */}
                        <div className="facilities-container">
                            {/* <ul> */}
                                {facilitiesArray.map((facility) => {
                                    // return <li>{facility}</li>
                                    return <div className='info-icon'>{returnIcon(facility)}</div>
                                })}
                                                                {amenitiesArray.map((amenity) => {
                                    return <div className='info-icon'>{returnIcon(amenity)}</div>
                                })}
                            {/* </ul> */}
                        </div>



                    </div>
                    <div className="search-content-right">

                    </div>
                </div>
                <div className="search-item-button-row">
                    <div className="sibr-left">
                    <div className="search-content-review-item">
                                {/* <Tooltip title='Base Rate'><PaymentIcon /></Tooltip> */}
                                <h4>Base Rate:</h4>
                                <p>R {hourlyRate} / hour</p>
                            </div>
                    </div>
                    <div className="sibr-right button-row">
                                            <button onClick={showProfile} className="confirm-button regal-button">View</button>
                                            <button onClick={goToCalendar} className='confirm-button saffron-button'>Book</button>
                    </div>
                    {/* <button className='search-button'>Contact</button> */}
                </div>
            </div>
        </div>
    )
}

export default SearchItem
