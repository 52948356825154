import React, { useState, useRef, useEffect } from 'react'
import './SettingsPage.css'

import SettingsItem from './SettingsItem'

import { db } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext'

function SettingsPage() {

    const { currentUser, userRole } = useAuth();

    // Instructor State
    const [newSessions, setNewSessions] = useState(false)
    const [newRequests, setNewRequests] = useState(false)
    const [sessionChanges, setSessionChanges] = useState(false)
    const [cancellations, setCancellations] = useState(false)

    const [acceptedBookings, setAcceptedBookings] = useState(false)
    const [cancelledBookings, setCancelledBookings] = useState(false)
    const [newDiscounts, setNewDiscounts] = useState(false)
    const [newFacilities, setNewFacilities] = useState(false)

    const newSessionsRef = useRef();
    const newRequestsRef = useRef();
    const sessionChangesRef = useRef();
    const cancellationsRef = useRef();
    const acceptedBookingsRef = useRef();
    const cancelledBookingsRef = useRef();
    const newDiscountsRef = useRef();
    const newFacilitiesRef = useRef();

    useEffect(() => {
        if (currentUser !== null && currentUser !== undefined) {
            loadInstructorSettings(currentUser.uid)
        }
    }, [currentUser])

    async function saveInstructorSettings() {
        await db.collection('users').doc(currentUser.uid).collection('accountInfo').doc('notificationSettings').update({
            newSessions: newSessionsRef.current,
            newRequests: newRequestsRef.current,
            sessionChanges: sessionChangesRef.current,
            cancelledSessions: cancellationsRef.current,
            acceptedBookings: acceptedBookingsRef.current,
            cancelledBookings: cancelledBookingsRef.current,
            newDiscounts: newDiscountsRef.current,
            newFacilities: newFacilitiesRef.current,
        });
    }

    async function loadInstructorSettings(uid) {
       const prefsDoc = await db.collection('users').doc(uid).collection('accountInfo').doc('notificationSettings').get();
       if (prefsDoc.exists) {
           const prefs = prefsDoc.data();
           setNewSessions(prefs.newSessions)
           setNewRequests(prefs.newRequests)
           setSessionChanges(prefs.sessionChanges)
           setCancellations(prefs.cancelledSessions)
           setAcceptedBookings(prefs.acceptedBookings)
           setCancelledBookings(prefs.setCancelledBookings)
           setNewDiscounts(prefs.newDiscounts)
           setNewFacilities(prefs.newFacilities)
       }
    }



    return (
        <div className='settings-page'>
            <div className="settings-header">
                <h1>Edit Settings</h1>
            </div>
                <div className="settings-block">
                    <div className="settings-block-header">
                        <h2>Notifications</h2>
                        </div>
                        {userRole === 'instructor' && <>
                        <h3>Clients</h3>
                        <div className="settings-block-row">
                            <SettingsItem setting={newSessions} text='New Sessions' selectRef={newSessionsRef} />
                            <SettingsItem setting={newRequests} text='New Requests' selectRef={newRequestsRef} />
                        </div>
                        <div className="settings-block-row">
                            <SettingsItem setting={sessionChanges} text='Session Changes' selectRef={sessionChangesRef} />
                            <SettingsItem setting={cancellations} text='Cancellations' selectRef={cancellationsRef} />
                        </div>
                        <h3 className='mt-20'>Facilities</h3>
                        <div className="settings-block-row">
                            <SettingsItem setting={acceptedBookings} text='Accepted Bookings' selectRef={acceptedBookingsRef} />
                            <SettingsItem setting={cancelledBookings} text='Cancelled Bookings' selectRef={cancelledBookingsRef} />
                        </div>
                        <div className="settings-block-row">
                            <SettingsItem setting={newDiscounts} text='New Discounts' selectRef={newDiscountsRef} />
                            <SettingsItem setting={newFacilities} text='New Facilities' selectRef={newFacilitiesRef} />
                        </div>
                        </>}
                </div>
                <div className="settings-block">
                    <div className="settings-block-header">
                        <h2>Emails</h2>
                        </div>
                        {/* <div className="settings-block-row">
                            <SettingsItem setting='on' text='Email Notifications' />
                            <SettingsItem setting='on' text='Email Notifications' />
                        </div>
                        <div className="settings-block-row">
                            <SettingsItem setting='on' text='Email Notifications' />
                            <SettingsItem setting='on' text='Email Notifications' />
                        </div> */}
                </div>
                    <div className="settings-button-row">
                        <button className='picton-button' onClick={saveInstructorSettings}>Save Changes</button>
                    </div>
            </div>
                )
}

export default SettingsPage
