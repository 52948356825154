import React , {useEffect, useState, useRef} from 'react'
import './SearchPage.css'
import '../Analytics/Clients/SearchDropModal.css'

// Components
import Multiselect from 'multiselect-react-dropdown';
import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';
import LoadingOverlay from 'react-loading-overlay';
import SearchItem from './SearchItem';
import FacilityProfile from './FacilityProfile/FacilityProfile';
import GoogleMapReact from 'google-map-react';
import SimpleMap from './SimpleMap'

import GeohashDistance from 'geohash-distance'
import Geohash from 'latlon-geohash';



// Firebase
import { useAuth } from '../../contexts/AuthContext'
import { db } from '../../firebase'
import { Slider } from '@material-ui/core';

function SearchPage() {

    function compare( a, b ) {
        if ( a.distance < b.distance ){
            // console.log('a')
          return -1;
        }
        if ( a.distance > b.distance ){
            // console.log('b')
          return 1;
        }
        return 0;
      }


    const [nameArray, setNameArray] = useState([]);
    const [countryArray, setCountryArray] = useState([]);
    const [cityArray, setCityArray] = useState([]);
    const [cityDrop, setCityDrop] = useState([]);
    const [cityDisabled, setCityDisabled] = useState(true)
    const [suburbArray, setSuburbArray] = useState([]);
    const [suburbDrop, setSuburbDrop] = useState([]);
    const [suburbDisabled, setSuburbDisabled] = useState([]);
    const [loadedFacilities, setLoadedFacilities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [noResults, setNoResults] = useState(false);
    const [selectedFacility, setSelectedFacility] = useState('');
    const [selectedRating,setSelectedRating] = useState(0);
    const [favorites, setFavorites] = useState([]);
    const [updater, setUpdater] = useState(0)
    const [geoPoint, setGeoPoint] = useState(null)
    const [userHash, setUserHash] = useState('')

    const [search, setSearch] = useState('')

    const [cov, setCOV] = useState('')
    const [cv, setCV] = useState('')
    const [sv, setSV] = useState('')


    const [showProfile, setShowProfile] = useState(false)
    const [profile, setProfile] = useState()

    const facilityRef = useRef();
    const amenityRef = useRef();
    const countryRef = useRef();
    const cityRef = useRef();
    const suburbRef = useRef();
    const searchRef = useRef();
    const sliderRef = useRef();

    const { currentUser } = useAuth();

    // Search Options
    const facilities = [
        {
            name: 'Weight Section',
            id: 'weight-section',
        },
        {
            name: 'Functional Floor',
            id: 'function-floor',
        },
        {
            name: 'Yoga Studio',
            id: 'yoga-studio',
        },
        {
            name: 'Dance Studio',
            id: 'dance-studio',
        },
        {
            name: 'Massage Rooms',
            id: 'massage-room',
        },
        {
            name: 'Parking',
            id: 'parking',
        }

    ];
    const amenities = [
        {
            name: 'Both Bathrooms',
            id: 'both-bathrooms',
        },
        {
            name: 'Unisex Bathrooms',
            id: 'uni-bathrooms',
        },
        {
            name: 'Showers',
            id: 'showers',
        },
        {
            name: 'Sauna',
            id: 'sauna',
        },
        {
            name: 'Steam Room',
            id: 'steam-room',
        },
        {
            name: 'Food Cafe',
            id: 'food-cafe',
        },
        {
            name: 'Smoothie Bar',
            id: 'smoothie-bar',
        },
    ];

    useEffect(() => {
        if (currentUser) {
            getUserCoords()
            loadFacilityNames()
            loadFacilityLocations()
            loadUserFavorites(currentUser.uid);
        }
    }, [currentUser])

    useEffect(() => {
    }, [loadedFacilities])

    useEffect(() => {
        console.log(suburbRef.current)
    }, [sv])

    async function getUserCoords() {
        navigator.geolocation.getCurrentPosition((pos) => {
            console.log(pos)
            const newHash = Geohash.encode(pos.coords.latitude, pos.coords.longitude);
            console.log(newHash)
            setUserHash(newHash)
            setGeoPoint(pos);
            searchFacilities(newHash)

        })
    }

    async function loadUserFavorites(uid) {
        const userDoc = await db.collection('users').doc(uid).get();
        const user = userDoc.data();
        const favoriteFacs = user.favoriteFacilities ?? [];
        setFavorites(favoriteFacs)
    }

    // Clear all filters
    async function clearFilters() {
        facilityRef.current.resetSelectedValues();
        amenityRef.current.resetSelectedValues();
        countryRef.current.resetSelectedValues();
        cityRef.current.resetSelectedValues();
        suburbRef.current.resetSelectedValues();
        setUpdater(current => current + 1)
    }

    // Search for facilities
    async function searchFacilities(userHash) {
        setNoResults(false);
        setLoading(true);
        let query;

        // Region Dropdowns
        const country = countryRef.current.state.selectedValues;
        const city = cityRef.current.state.selectedValues;
        const suburbs = suburbRef.current.state.selectedValues;

        // Option dropdowns
        const facSelected = facilityRef.current.state.selectedValues;
        const amenSelected = amenityRef.current.state.selectedValues;


        // If a country has been selected
        if (country.length > 0) {
            // If a city has been selected
            if (city.length > 0) {

                try {
                    query = await db.collection('facilities').where('reviewAverage', '>=', selectedRating).where('country', '==', country[0].name).where('city', '==', city[0].name).get();
                } catch(err) {
                    console.log(err);
                }
            } else {
                console.log(selectedRating);
                try {
                    query = await db.collection('facilities').where('reviewAverage', '>=', selectedRating).where('country', '==', country[0].name).get();
                } catch(err) {
                    console.log(err);
                }
            }
        } else {
            try {
                query = await db.collection('facilities').where('reviewAverage', '>=', selectedRating).where('country', '==', 'South Africa').get();
            } catch(err) {
                console.log(err);
            }
        }
        const facilityArray = [];

        for (var i = 0; i < query.docs.length; i++) {
            const fac = query.docs[i].data();
            const facilityID = query.docs[i].id;

            const { country, city, suburb } = fac;
            const { addressOne, addressTwo , postalCode } = fac;
            const { listingName, description, ownerIDs, imageLinks } = fac;
            const { hourlyRate } = fac;
            const { totalReviews, reviewAverage } = fac;
            const { venueAmenities, venueFacilities, maxCapacity, totalBookings, venueType } = fac;
            const locationHash = fac.locationHash ?? '';

            let distance = 10;
            // console.log(locationHash, userHash)
            if (locationHash !== '' && userHash !== '') {
                distance = Math.round((GeohashDistance.inKm(locationHash, userHash) * 10)) / 10;
                // console.log(distance)
            }



            let venueTypeStore = '';

            switch (venueType) {
                case 'full-gym': venueTypeStore = 'Full Gym'
                    break;
                case 'private-space': venueTypeStore = 'Private Space'
                    break;
                case 'yoga-studio': venueTypeStore = 'Yoga Studio'
                    break;
                case 'pilates-studio': venueTypeStore = 'Pilates Studio'
                    break;
                case 'boxing-gym': venueTypeStore = 'Boxing Gym'
                    break;
                case 'treatment-facility': venueTypeStore = 'Treatment Facility'
                    break;
                default: break;
            }

            // Owner Name
            const ownerID = ownerIDs[0];
            const ownerDoc = await db.collection('users').doc(ownerID).get();
            const owner = ownerDoc.data();
            const ownerName = owner.firstName;

            let search = true;
            let favorite = false;

            if (favorites.includes(facilityID)) {
                favorite = true;
            }

            // Venue Facilities
            for (var j = 0; j < facSelected.length; j++) {
                if (!venueFacilities.includes(facSelected[j].id)) {
                    search = false;
                }
            }
            // Venue Amenities
            for (var k = 0; k < amenSelected.length; k++) {
                if (!venueAmenities.includes(amenSelected[k].id)) {
                    search = false;
                }
            }

            let suburbFound = false;
            // Suburbs
            for (var h = 0; h < suburbs.length; h++) {
                if (suburbs.name === suburb) {
                    suburbFound = true;
                }
            }

            if (suburbs.length === 0) {
                suburbFound = true;
            }
            // console.log(search, suburbFound)
            if (search && suburbFound) {
                facilityArray.push({
                    facilityID, ownerIDs,
                    country, city, suburb,
                    addressOne, addressTwo, postalCode,
                    listingName, description, favorite,
                    hourlyRate, ownerName, distance,
                    totalReviews, reviewAverage, maxCapacity, totalBookings,
                    venueAmenities, venueFacilities, imageLinks, venueTypeStore,
                })
                // facilityArray.push({
                //     facilityID, ownerIDs,
                //     country, city, suburb,
                //     addressOne, addressTwo, postalCode,
                //     listingName, description, favorite,
                //     hourlyRate, halfDayRate, dayRate, weekRate, monthRate,
                //     totalReviews, reviewAverage, maxCapacity, totalBookings,
                //     venueAmenities, venueFacilities, imageLinks,
                // })
            }
        }

        if (facilityArray.length === 0) {
            setNoResults(true);
        }
        facilityArray.sort(compare)
        setLoadedFacilities(facilityArray);
        setLoading(false)
        // window.scrollTo(0, document.body.scrollHeight / 5)

    }

    // Load particular facility
    async function loadFacility() {
        if (selectedFacility === '') {
            alert('none selected')
            return null;
        }
        setLoading(true)

        const facilityDoc = await db.collection('facilities').doc(selectedFacility).get();
        const fac = facilityDoc.data();
        const facilityID = facilityDoc.id;
        const { country, city, suburb } = fac;
        const { addressOne, addressTwo , postalCode } = fac;
        const { listingName, description, ownerIDs, imageLinks } = fac;
        const { hourlyRate, halfDayRate, dayRate, weekRate, monthRate } = fac;
        const { totalReviews, reviewAverage } = fac;
        const { venueAmenities, venueFacilities, maxCapacity, totalBookings } = fac;

        let favorite = false;

        if (favorites.includes(facilityID)) {
            favorite = true;
        }

        setLoadedFacilities([])
        setLoadedFacilities([{
            facilityID, ownerIDs,
            country, city, suburb,
            addressOne, addressTwo, postalCode,
            listingName, description, favorites,
            hourlyRate, halfDayRate, dayRate, weekRate, monthRate,
            totalReviews, reviewAverage, maxCapacity, totalBookings,
            venueAmenities, venueFacilities, imageLinks,
        }]);
        setUpdater(current => current + 1);
        setLoading(false)
    }

    // Load all locations
    async function loadFacilityLocations() {

        const countryArr = [];

        const locationDoc = await db.collection('eliteData').doc('locations').get();
        const location = locationDoc.data();
        const countries = location.countries;
        const cities = location.cities;
        const suburbs = location.suburbs;

        for (var i = 0; i < countries.length; i++) {
            countryArr.push({
                name: countries[i],
            })
        }

        setCountryArray(countryArr)
        setCityArray(cities)
        setSuburbArray(suburbs)

    }

    function changeCityArray(evt, type) {
        if (type === 'add') {
            const country = (evt[0].name);
            setCOV(country)
            const array = [];
            for (var i = 0; i < cityArray[country].length; i++) {
                array.push({
                    name: cityArray[country][i],
                })
            }
            setCityDrop(array);
            setCityDisabled(false);
        }

        if (type === 'remove') {
            setCOV('')
            cityRef.current.resetSelectedValues()
            setCityDrop([]);
            setCityDisabled(true);
        }
    }

    function changeSuburbArray(evt, type) {
        if (type === 'add') {
            const city = evt[0].name;
            const array = [];
            for (var i = 0; i < suburbArray[city].length; i++) {
                array.push({
                    name: suburbArray[city][i],
                })
            }
            setSV('')
            suburbRef.current.resetSelectedValues();
            setCV(city)
            setSuburbDrop(array);
            setSuburbDisabled(false);
        }

        if (type === 'remove') {
            setSuburbDisabled(true);
            setSuburbDrop([]);
            setCV('')
            suburbRef.current.resetSelectedValues();
        }

        setUpdater(current => current + 1)
    }

    // Load all facility names
    async function loadFacilityNames() {
        const facNames = [];

        const facilities = await db.collection('facilities').get();
        for (var i = 0; i < facilities.docs.length; i++) {
            const facility = facilities.docs[i].data();
            const facilityID = facilities.docs[i].id;
            const facilityName = facility.listingName ?? 'null';
            if (facilityName === 'null') {
                continue;
            }
            facNames.push({
                name: facilityName,
                value: facilityID,
            })
        }

        setNameArray(facNames);
        // setLoading(false);
    }

    function showFacilityProfile(facility) {

        setProfile(<FacilityProfile favorites={favorites} userID={currentUser.uid} listing={facility} goBack={() => setShowProfile(false)} />)
        setShowProfile(true)
    }

    const AnyReactComponent = ({ text }) => <div>{text}</div>;


    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading Facilities...'
        >
        <div className='search-page'>
            {!showProfile && <h1>Find your facility</h1>}
            {!showProfile && <div className="search-filters">
                {/* <h2>Filters</h2> */}
                <div className="filter-row">
                    <div className="filter-group">
                        <p>Facility</p>
                        <Multiselect options={facilities}
                            displayValue='name'
                            ref={facilityRef}
                            showCheckbox={true}
                            hidePlaceholder
                            showArrow
                            controlShouldRenderValue={false}
                            disablePreSelectedValues={true}
                            placeholder=''

                        />
                    </div>
                    <div className="filter-group slider-group">
                    <p>Rating</p>

                        <Slider onChange={(evt, val) => setSelectedRating(val)} ref={sliderRef} step={1} min={0} max={5} valueLabelDisplay='on' defaultValue={0} marks />
                    </div>
                    <div onClick={(evt) => {
                        // alert(evt.target.classList[0])
                        if (evt.target.classList[0] === ('search-wrapper') || evt.target.classList[0] === ('icon_cancel')) {
                            // alert('showing')
                            amenityRef.current.searchBox.current.classList.add('show')
                            amenityRef.current.searchBox.current.classList.remove('hide')

                        }
                    }} className="filter-group">
                        <p>Amenities</p>
                        <Multiselect options={amenities}
                        ref={amenityRef}
                        hidePlaceholder
                            displayValue='name'
                            onSelect={(evt) => {
                                // alert('hi')
                                const length = evt.length;
                                if (length > 0) {
                                    // alert('hiding')
                                    amenityRef.current.searchBox.current.classList.add('hide')
                                    amenityRef.current.searchBox.current.classList.remove('remove')

                                }

                            }}
                            onRemove={(evt) => console.log(evt)}
                            showArrow
                            showCheckbox
                            placeholder=''

                        />
                    </div>
                </div>
                <div className="filter-row">
                <div className="filter-group">
                        <p>Country</p>
                        <Multiselect
                        ref={countryRef}
                        options={countryArray}
                        hidePlaceholder
                            displayValue='name'
                            onSelect={(evt) => changeCityArray(evt, 'add')}
                            onRemove={(evt) => changeCityArray(evt, 'remove')}
                            // selectionLimit={1}
                            showArrow
                            singleSelect
                            placeholder=''
                        />
                    </div>
                    <div className="filter-group">
                    <p>City</p>
                    <Multiselect
                    ref={cityRef}
                    options={cityDrop}
                            disable={cityDisabled}
                            hidePlaceholder
                            displayValue='name'
                            onSelect={(evt) => changeSuburbArray(evt, 'add')}
                            onRemove={(evt) => changeSuburbArray(evt, 'remove')}
                            showArrow
                            singleSelect
                            placeholder=''
                        />
                    </div>
                    <div className="filter-group">
                    <p>Suburb</p>
                    <Multiselect
                            ref={suburbRef}
                            options={suburbDrop}
                            disable={suburbDisabled}
                            hidePlaceholder
                            placeholder=''
                            displayValue='name'
                            onSelect={(evt) => setSV(evt[0].name)}
                            onRemove={(evt) => setSV('')}
                            showArrow
                            singleSelect
                            // selectedValues={[{name: sv}]}
                        />
                    </div>
                </div>
                <div className="filter-button-row">
                    <button onClick={clearFilters} className='clear-button'>Clear</button>
                    {/* <button onClick={searchFacilities} className='search-button'>Search</button> */}
                </div>
                <div className="search-row">
                    <p>Search for facility</p>
                    {/* <SelectSearch
            autoFocus={false}
            ref={searchRef}
            autoComplete='on'
            search={true}
            closeOnSelect={true}
            filterOptions={ fuzzySearch }
            onChange={(evt) => setSelectedFacility(evt)}
            options={nameArray} placeholder="" /> */}
            <input type='text' className='fac-search' defaultValue={search} onChange={(e) => setSearch(e.target.value)} ref={searchRef} />
                            {/* <button onClick={loadFacility} className='search-row-button'>Go</button> */}

                </div>
            </div>}
            {geoPoint !== null && <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBNVt036Vwj3EMeNMVyTEh9JPqCuZ6xsi8'}}
          defaultCenter={{
              lat: geoPoint.coords.latitude,
              lng: geoPoint.coords.longitude,
          }}
          defaultZoom={15}
        //   options={{}}
        >
          {/* <AnyReactComponent
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
          /> */}
          <div className="simple-map">
                 <SimpleMap />

          </div>
        </GoogleMapReact>}
            <div className="search-results">
                {showProfile && profile}
                {!showProfile && loadedFacilities.map((facility, index) => {

                // Option dropdowns
                let facSelected = '' 
                let amenSelected = ''
                if (facilityRef.current !== null) {
                    facSelected = facilityRef.current.state.selectedValues;
                    amenSelected = amenityRef.current.state.selectedValues;

                }

                if (search !== '') {
                    if (!facility.listingName.toLowerCase().includes(search.toLowerCase())) {
                        return null
                    }
                }

                    // Selected facs
                    if (facSelected.length > 0) {
                        for (let i = 0; i < facSelected.length; i++) {
                            if (!facility.venueFacilities.includes(facSelected[i].id)) {
                                return null
                            }
                        }
                    }

                    // Rating
                    if (facility.reviewAverage < selectedRating) {
                        return null
                    }

                    // Selected alems
                    if (amenSelected.length > 0) {
                        for (let i = 0; i < amenSelected.length; i++) {
                            if (!facility.venueAmenities.includes(amenSelected[i].id)) {
                                return null
                            }
                        }
                    }

                    // Country
                    if (cov !== facility.country && cov !== '') {
                        return null
                    }

                    // City
                    if (cv !== facility.city && cv !== '') {
                        return null
                    }

                    // Suburb
                    if (sv !== facility.suburb && sv !== '') {
                        return null
                    }


                    return <SearchItem
                    venueAmenities={facility.venueAmenities} venueFacilities={facility.venueFacilities} listingName={facility.listingName}
                    venueInfo={facility.description} addressOne={facility.addressOne} addressTwo={facility.addressTwo} city={facility.city}
                    country={facility.country} suburb={facility.suburb} postalCode={facility.postalCode} reviewAverage={facility.reviewAverage}
                    totalReviews={facility.totalReviews} totalBookings={facility.totalBookings} maxCapacity={facility.maxCapacity} userID={currentUser.uid} showProfile={() => showFacilityProfile(facility)}
                    hourlyRate={facility.hourlyRate} halfDayRate={facility.halfDayRate} dayRate={facility.dayRate} weekRate={facility.weekRate} monthRate={facility.monthRate}
                    facilityID={facility.facilityID} distance={facility.distance} ownerIDs={facility.ownerIDs} imageLinks={facility.imageLinks} favorited={facility.favorite} venueType={facility.venueTypeStore}
                    />
                })}
                {noResults && <div className='no-results-container'>
                    <h1>Sorry! We couldn't find any places matching your query</h1>
                    </div>}
            </div>
        </div>
        </LoadingOverlay>
    )
}

export default SearchPage
