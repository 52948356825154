import React, { useState, useEffect, useRef } from 'react'
import './Clients.css'

import { db } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext'

// Components
import ClientRelationships from '../Analytics/Clients/ClientRelationships'
import LoadingOverlay from 'react-loading-overlay'

function Clients() {

    // State
    const [userID, setUserID] = useState('')
    const [role, setRole] = useState('')
    const [loading, setLoading] = useState(true)

    const { currentUser, userRole } = useAuth();

    useEffect(() => {
        if (currentUser !== null && currentUser !== undefined && userRole !== null && currentUser !== userRole) {
            setUserID(currentUser.uid)
            setRole(userRole)
        }
    }, [currentUser, userRole])

    return (
        <LoadingOverlay
        spinner
        active={loading}
        >
        <div className='clients'>
            {(userID !== '' && role !== '') && <ClientRelationships uid={userID} setLoading={setLoading} userRole={role} />}
        </div>
        </LoadingOverlay>

    )
}

export default Clients
