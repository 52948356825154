import React, { useContext, useEffect, useState } from 'react'
import { auth, db } from '../firebase'

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState()
    const [userRole, setUserRole] = useState()

    // Sign Up
    function signUp(email, password) {
        return auth.createUserWithEmailAndPassword(email, password);
    }

    async function returnUser(email, password) {
       const newUser = await auth.createUserWithEmailAndPassword(email, password);
       return newUser
    }

    // Log In
    function logIn(email, password) {
        return auth.signInWithEmailAndPassword(email, password);
    }

    // Log Out
    function logOut() {
        return auth.signOut();
    }

    // User change
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
            if (user) {
                            console.log(user.email);
                            user.getIdTokenResult().then((result) => {
                                console.log(result.claims.role);

                                if (result.claims.role === undefined) {
                                    db.collection('users').doc(user.uid).get().then((userDoc) => {
                                        if (userDoc.exists) {
                                                setUserRole(userDoc.data().role)

                                        }
                                    })
                                } else {
                                     setUserRole(result.claims.role);
                                }
                            })

            }
        });

        return unsubscribe
    }, []);


    const value = {
        userRole,
        currentUser,
        logIn,
        signUp,
        logOut,
        returnUser
     }
    return (
    <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
    )
}

