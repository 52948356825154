import React, { useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext'

import './LoginForm.css'

import logo from '../../assets/images/logowhite.png'

import ForgotPasswordModal from './ForgotPasswordModal/ForgotPasswordModal'

function LoginForm({ switchForm }) {

    const navigate = useNavigate();

    // Login function
    const { logIn } = useAuth();

    const { logOut } = useAuth();


    // States
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showForgotten, setShowForgotten] = useState(false)

    // Ref
    const emailRef = useRef();
    const passwordRef = useRef();

    async function handleClick() {

        try {
            setError('Logging you in...');
            setLoading(true)
            await logIn(emailRef.current.value, passwordRef.current.value);
                  navigate("/");
        } catch(err) {
            setError(err.message);
            console.log(err)
        }
        setLoading(false);

    }

    function handleEnter(evt) {
        if (evt.key === 'Enter') {
            handleClick();
        }
    }

    // function validate

    return (
        <div className="login-form">

            {showForgotten && <ForgotPasswordModal closeModal={() => setShowForgotten(false)} />}

            <img
            alt="logo"
            src={logo} />
            <div className="login-input-container">
                <div className="input-group">
                    <p>Email / Username</p>
                    <input type="email" autoComplete="on" placeholder="email" ref={emailRef} onKeyUp={(evt) => {handleEnter(evt)}} />
                </div>
                <div className="input-group">
                    <p>Password</p>
                    <input type="password" autoComplete="current-password" placeholder="password" ref={passwordRef} onKeyUp={(evt) => {handleEnter(evt)}} />
                </div>
            </div>
            <div className="signup-error-container">
                {error !== '' && <p className="fade-in">{error}</p>}
            </div>
            <div className="login-button-row">
                <button disabled={loading} onClick={handleClick} className='valencia-button'>Sign In</button>
            </div>
            <div className="forgot-password">
                <p onClick={() => setShowForgotten(true)}>Forgot my password</p>
            </div>
            <div className="register-action-container">
                <p>Dont have an account?</p>
                <span onClick={switchForm}>Register</span>
            </div>
        </div>
    )
}

export default LoginForm
