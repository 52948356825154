import React, { useEffect, useState, useRef } from 'react'
import './ChatDropdown.css'

// Firestore
import { db } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext'

// Components
import ChatItem from './ChatItem'

function ChatDropdown({ hideDrop, show }) {

    const { currentUser, userRole } = useAuth();

    const hideRef = useRef();

    const [currentUserID, setCurrentUserID] = useState();
    const [chatContent, setChatContent] = useState([]);

    useEffect(() => {
        if (currentUser !== undefined && currentUser !== null && userRole !== undefined && userRole !== null) {
            if (userRole === 'owner') {
                setCurrentUserID(currentUser.uid)
                loadChats(currentUser.uid);
            } else {
                setCurrentUserID(currentUser.uid)
                loadChatsForInstructor(currentUser.uid);
            }
        }
    }, [currentUser, userRole])

    function compare( a, b ) {
        if ( a.messageDate > b.messageDate ){
            // console.log('a')
          return -1;
        }
        if ( a.messageDate < b.messageDate ){
            // console.log('b')
          return 1;
        }
        return 0;
      }


    async function loadChatsForInstructor(uid) {
        setChatContent([])
        let chatArray = [];
        db.collection('chat').where('instructorID', '==', uid).orderBy('lastMessage.timeStamp', 'desc').onSnapshot(async chats => {
            chatArray = [];
            for (var i = 0; i < chats.docs.length; i++) {
                const chat = chats.docs[i].data();
                const chatID = chats.docs[i].id;
                if ((chat.ownerID === null || chat.ownerID === undefined) && chat.adminChat !== true) {
                    const athleteID = chat.athleteID;
                    const athleteDoc = await db.collection('users').doc(athleteID).get();
                    const athlete = athleteDoc.data();
                    const athleteName = `${athlete.firstName} ${athlete.lastName}`;

                    const lastChat = chat.lastMessage
                    const lastMessage = lastChat.content;
                    const profileImage = athlete.imageLink;
                    const messageDate = new Date (lastChat.timeStamp.toDate());
                    const messageDateString = messageDate.toDateString();
                    let unread = false;
                    if (lastChat.receiver === uid && lastChat.read === false) {
                        unread = true;
                    }
                    chatArray.push({
                        instructorName: athleteName,
                        lastMessage,
                        profileImage,
                        messageDate,
                        messageDateString,
                        chatID,
                        unread
                    });
                } else if ((chat.athleteID === null || chat.athleteID === undefined) && chat.adminChat !== true) {
                    const ownerID = chat.ownerID;
                    const ownerDoc = await db.collection('users').doc(ownerID).get();
                    const owner = ownerDoc.data();
                    const ownerName = `${owner.firstName} ${owner.lastName}`;

                    const lastChat = chat.lastMessage
                    const lastMessage = lastChat.textContent;
                    const profileImage = owner.profileImage;
                    const messageDate = new Date (lastChat.timeStamp.toDate());
                    const messageDateString = messageDate.toDateString();
                    let unread = false;
                    if (lastChat.receiver === uid && lastChat.read === false) {
                        unread = true;
                    }
                    chatArray.push({
                        instructorName: ownerName,
                        lastMessage,
                        profileImage,
                        messageDate,
                        messageDateString,
                        chatID,
                        unread,
                        sent: lastChat.receiver !== uid,
                    });
                } else if (chat.adminChat === true) {

                    const lastChat = chat.lastMessage
                    const lastMessage = lastChat.content;
                    const profileImage = 'https://firebasestorage.googleapis.com/v0/b/forte-v2.appspot.com/o/images%2Flogowhite.png?alt=media&token=db8abc1e-b79e-4d51-bcdc-734fe52fdd48';
                    const messageDate = new Date (lastChat.timeStamp.toDate());
                    const messageDateString = messageDate.toDateString();
                    let unread = false;
                    if (lastChat.receiver === uid && lastChat.read === false) {
                        unread = true;
                    }
                    chatArray.push({
                        instructorName: 'Admin',
                        lastMessage,
                        profileImage,
                        messageDate,
                        messageDateString,
                        chatID,
                        unread,
                        sent: lastChat.receiver !== uid,
                    });
                }

            }
                    chatArray.sort(compare);

        setChatContent(chatArray);

        });
    }

    async function loadChats(uid) {
        setChatContent([]);
        // const chats = await db.collection('chat').where('ownerID', '==', uid).get();
        let chatArray = [];
        db.collection('chat').where('ownerID', '==', uid).orderBy('lastMessage.timeStamp', 'desc').onSnapshot(async chats => {
            chatArray = [];
        for (var i = 0; i < chats.docs.length; i++) {
            const chat = chats.docs[i].data();
            // console.log(chat)
            if (chat.adminChat) {
                const chatID = chats.docs[i].id;
                const instructorName = 'Forte Admin';
                const imageLink = 'https://firebasestorage.googleapis.com/v0/b/forte-v2.appspot.com/o/Logo_Mark_Black.jpg?alt=media&token=65572089-f2af-4d8e-bab3-d53216fc831b';
        
                const lastChat = chat.lastMessage
                const lastMessage = lastChat.textContent;
                const messageDate = new Date (lastChat.timeStamp.toDate());
                const messageDateString = messageDate.toDateString();
                let unread = false;
                if (lastChat.receiver === uid && lastChat.read === false) {
                    unread = true;
                }
                chatArray.push({
                    instructorName,
                    lastMessage,
                    profileImage: imageLink,
                    messageDate,
                    messageDateString,
                    chatID,
                    unread,
                    sent: lastChat.receiver !== uid,
                });

                continue
            }
            const chatID = chats.docs[i].id;

            const instructorID = chat.instructorID;
            const instructorProfile = await db.collection('users').doc(instructorID).get();
            const instructor = instructorProfile.data();
            const instructorName = `${instructor.firstName} ${instructor.lastName}`;

            const lastChat = chat.lastMessage
            const lastMessage = lastChat.textContent;
            const profileImage = instructor.imageLink;
            const messageDate = new Date (lastChat.timeStamp.toDate());
            const messageDateString = messageDate.toDateString();
            let unread = false;
            if (lastChat.receiver === uid && lastChat.read === false) {
                unread = true;
            }
            // console.log(messageDateString)
            // const profileImage = 'https://www.biography.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTY2NzAyMDMwMjQzNTA1NTA0/donald_trump_photo_michael_stewartwireimage_gettyimages-169093538jpg.jpg';
            // console.log(lastMessage)
            chatArray.push({
                instructorName,
                lastMessage,
                profileImage,
                messageDate,
                messageDateString,
                chatID,
                unread,
                sent: lastChat.receiver !== uid,
            });

        }
        chatArray.sort(compare);


        setChatContent(chatArray);
    });

    }


    return (
        <div ref={hideRef} className={!hideDrop ? 'chat-dropdown scale-in-ver-top' : 'chat-dropdown scale-out-ver-top'}>
            {chatContent.map((content, index) => {
                return <ChatItem sent={content.sent} unread={content.unread} dateString={content.messageDateString} chatID={content.chatID} hideChat={() => {}} name={content.instructorName} message={content.lastMessage} image={content.profileImage}  />
            })}
        </div>
    )
}

export default ChatDropdown
