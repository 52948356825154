import React, { useEffect, useState } from 'react'
import './DropdownMenu.css'

import { useNavigate } from 'react-router-dom'


// Auth
import { useAuth } from '../contexts/AuthContext'

import { auth } from '../firebase'

function DropdownMenu({ userRole, show = false}) {

    const { logOut } = useAuth();
    const navigate = useNavigate();



    function goToProfile() {
        if (userRole === 'owner') {
            navigate('/profile-edit');
        } else {
            navigate('/profile')
        }
    }

    function goToSettings() {
        navigate('/settings')
    }

    function goToCredits() {
        if (userRole === 'owner') {
            navigate('owner-credits')
        } else {
            navigate('instructor-credits')
        }
    }

    async function handleLogOut() {
        try {
            await auth.signOut()
            // alert('bout to nav')
            navigate('/login')

        } catch (err) {
            // alert('Could not sign you out, please try again')
            navigate('/login')

        }

    }

    return (
        <div className={show ? 'dropdown-menu scale-in-ver-top' : 'dropdown-menu scale-out-ver-top'}>
            <p onClick={() => goToProfile()} >Profile</p>
            <p onClick={() => goToCredits()} >Credits</p>
            <p onClick={goToSettings} >Settings</p>
            <p onClick={() => window.open('https://www.forte-app.com/support', 'blank')}>Help</p>
            <p onClick={handleLogOut}>Log Out</p>
        </div>
    )
}

export default DropdownMenu
