import React from 'react'
import './ViewConfirmedBookings.css'
import { useNavigate } from 'react-router';

function ViewConfirmedBooking({ type, athleteID = [], chatID, imageLink, name, bookingPeriod, startTime, endTime, about, info, revenue, forteFee, netRevenue, goBack }) {

    const navigate = useNavigate();

    function goToChat() {
        navigate(
            '/inbox',
            {
                state: {chatID},
        })
    }
    return (
        <div className='view-confirmed-booking'>
            <h3>Confirmed Booking Information</h3>
            <div className="booking-details-div scale-in-ver-center">
            <div className="vb-left-col">
                <img alt="user-img" src={imageLink} />
            </div>
            <div className="vb-right-col">
                <p className='mb-20'><span className='bold-text'>Name:</span> {name}</p>
                <p><span className='bold-text'>Booking Date:</span> {bookingPeriod}</p>
                <p><span className='bold-text'>Booking Start Time:</span> {startTime}</p>
                <p className='mb-20'><span className='bold-text'>Booking End Time:</span> {endTime}</p>
                <div className="about-instructor-div mb-20">
                    <p className='bold-text'>About {name}</p>
                    {type === 'group' && <p>Total Attendees: {athleteID.length}</p>}
                    <p>{about}</p>
                </div>
                <div className="additional-info-div mb-20">
                    <p className='bold-text'>Additional Information:</p>
                    <p>{info}</p>
                </div>
                <div className="revenue-div mb-20">
                    <div className="revenue-left">
                        <p>Booking Revenue</p>
                        <p>(-) Forte Fees</p>
                        <p>Net Revenue</p>
                    </div>
                    <div className="revenue-right">
                        {type !== 'group' ? <p>R{revenue}</p> : <p>R{revenue * athleteID.length}</p>}
                        {type !== 'group' ? <p className='forte-fee'>(R{forteFee})</p> : <p>R{forteFee * athleteID.length}</p>}
                        {type !== 'group' ? <p className='net-revenue'>R{netRevenue}</p> : <p>R{netRevenue * athleteID.length}</p>}
                    </div>
                </div>

            </div>
            </div>
            <div className="bc-button-row">
                    {/* <div className="button-margin">

                    </div> */}
                <div className="button">
                <button onClick={goBack} className="bc-view-button">Back</button>

                </div>
                <div className="button-right">
                {/* <button className="bc-deny-button">Deny</button> */}
                <button onClick={goToChat} className="bc-confirm-button">Chat</button>
                </div>

                </div>

        </div>
    )
}

export default ViewConfirmedBooking
