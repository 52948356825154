import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router';
import './Inbox.css'
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';

import moment from 'moment'

// Components
import Contact from './Contact'
import Message from './Message'
import LoadingOverlay from 'react-loading-overlay';
import BroadcastMessageModal from './BroadcastMessageModal';

import ChatItem from '../../Components/Chats/ChatItem';

// Material UI
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MenuIcon from '@material-ui/icons/Menu'
import { Drawer } from '@material-ui/core';

function Inbox() {

    const { currentUser, userRole } = useAuth();
    const location = useLocation();

    const activeRef = useRef();
    const bottomRef = useRef();

    const chatInputRef = useRef();

    const searchRef = useRef();

    const [showDrawer, setShowDrawer] = useState(false)
    const [loading, setLoading] = useState(true);
    const [activeChat, setActiveChat] = useState('1');
    const [loadedContacts, setLoadedContacts] = useState([]);
    const [currentChatMessages, setCurrentChatMessages] = useState([]);
    const [currentUserID, setCurrentUserID] = useState();
    const [currentReceiverID, setCurrentReceiverID] = useState();
    const [currentReceiverName, setCurrentReceiverName] = useState('')
    const [searching, setSearching] = useState(false);
    const [showBroadcastModal, setShowBroadcastModal] = useState();
    const [currentSearchValue, setCurrentSearchValue] = useState();
    const [currentUserRole, setCurrentUserRole] = useState('')
    const [chatType, setChatType] = useState('')

    const chatTypeRef = useRef();
    chatTypeRef.current = chatType;

    activeRef.current = activeChat;

    useEffect(() => {
        if (userRole) {
            setCurrentUserRole(userRole);
        }
    }, [userRole])

    useEffect(() => {
        if (currentUser && userRole) {
            setCurrentUserID(currentUser.uid);
            if (userRole === 'owner') {
                loadChats(currentUser.uid);

            }
            if (userRole === 'instructor') {
                loadInstructorChats(currentUser.uid);

            }
        }
    }, [currentUser, userRole]);

    useEffect(() => {
        if (activeChat !== '1') {
        loadMessagesForChat(currentUser.uid);
        }
        // console.log(activeChat);
    }, [activeChat])

    useEffect(() => {
        if (currentChatMessages.length > 0) {
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [currentChatMessages])

    // Search contacts

    async function loadChats(uid) {


        db.collection('chat').where('ownerID', '==', uid).orderBy('lastMessage.timeStamp','desc').onSnapshot(async chats => {
            const contactArray = [];

            for (var i = 0; i < chats.docs.length; i ++) {
                const chatID = chats.docs[i].id;
                const chat = chats.docs[i]
                if (chat.data().adminChat) {
                    const lastMessageDate = new Date(chat.data().lastMessage.timeStamp.toDate())
                    const dateString = lastMessageDate.toDateString();
                    const instructorName = 'Forte Support';
                    const imageLink = 'https://firebasestorage.googleapis.com/v0/b/forte-v2.appspot.com/o/facility-images%2FUntitled%20design.jpg?alt=media&token=f72104a9-2ec1-497a-9b5d-ccc2c6057100';
                    if (location.state !== undefined && location.state !== null && location.state.chatID === chatID) {
                        // console.log(location.state.chatID);
                        setActiveChat(location.state.chatID);
                        setCurrentReceiverName(instructorName)
                        setCurrentReceiverID('admin');
                        setChatType('instructor')
    
                    } else if (i === 0) {
                    setActiveChat(chatID);
                    setCurrentReceiverName(instructorName)
                    setCurrentReceiverID('admin');
                    setChatType('instructor')
                    }
                    contactArray.push({sent: chat.data().lastMessage.receiver !== uid,'name': instructorName, 'chatID': chatID, 'instructorID': 'admin', 'type': 'instructor', 'lastMessage': chat.data().lastMessage.textContent, dateString, imageLink });

                    continue
                } else {

                
                const lastMessageDate = new Date(chat.data().lastMessage.timeStamp.toDate())
                const dateString = lastMessageDate.toDateString();
                const instructorID = chat.data().instructorID;
                const instructorDoc = await db.collection('users').doc(instructorID).get();
                const instructor = instructorDoc.data();
                const instructorName = `${instructor.firstName} ${instructor.lastName}`;
                const imageLink = instructor.imageLink;
                if (location.state !== undefined && location.state !== null && location.state.chatID === chatID) {
                    // console.log(location.state.chatID);
                    setActiveChat(location.state.chatID);
                    setCurrentReceiverName(instructorName)
                    setCurrentReceiverID(instructorID);
                    setChatType('instructor')

                } else if (i === 0) {
                setActiveChat(chatID);
                setCurrentReceiverName(instructorName)
                setCurrentReceiverID(instructorID);
                setChatType('instructor')
                }
                contactArray.push({sent: chat.data().lastMessage.receiver !== uid,'name': instructorName, 'chatID': chatID, 'instructorID': instructorID, 'type': 'instructor', 'lastMessage': chat.data().lastMessage.textContent, dateString, imageLink });
                // contactArray.push(<Contact onclick={() => {setActiveChat(chatID);}} name={instructorName} active={activeChat === chatID} />)
            }
            // });
        }
            console.log(contactArray)
            setLoadedContacts(contactArray)
            setLoading(false);
        });
        // await idArray.forEach(async (chatID) => {


    }

    async function loadInstructorChats(uid) {

        db.collection('chat').where('instructorID', '==', uid).orderBy('lastMessage.timeStamp', 'desc').onSnapshot(async instructorChats => {

            const contactArray = [];
            let setChat = false;
        let stateID = '';
        if (location.state !== undefined && location.state !== null) {
            // setActiveChat(location.state.chatID);
            stateID = location.state.chatID;
        }
        for (var i = 0; i < instructorChats.docs.length; i++) {
            const chat = instructorChats.docs[i].data();
            const chatID = instructorChats.docs[i].id;
            const lastMessageDate = new Date(chat.lastMessage.timeStamp.toDate())
            const dateString = lastMessageDate.toDateString();

            if ((chat.ownerID === null || chat.ownerID === undefined) && chat.adminChat !== true) {
                const athleteID = chat.athleteID;
                const athleteDoc = await db.collection('users').doc(athleteID).get();
                const athlete = athleteDoc.data();
                const athleteName = `${athlete.firstName} ${athlete.lastName}`;
                if (setChat === false || chatID === stateID) {
                    setActiveChat(chatID);
                    setCurrentReceiverID(athleteID);
                    setCurrentReceiverName(athleteName)
                    setChatType('athlete')
                    setChat = true;
                }
                contactArray.push({sent: chat.lastMessage.receiver !== uid, 'name': athleteName, 'chatID': chatID, 'ownerID': athleteID, 'type': 'athlete', 'lastMessage': chat.lastMessage.content, dateString, imageLink: athlete.imageLink });

            } else if ((chat.athleteID === null || chat.athleteID === undefined) && chat.adminChat !== true) {
                const ownerID = chat.ownerID;
                const ownerDoc = await db.collection('users').doc(ownerID).get();
                const owner = ownerDoc.data();
                const ownerName = `${owner.firstName} ${owner.lastName}`;
                if (setChat === false || chatID === stateID) {
                    setActiveChat(chatID);
                    setCurrentReceiverID(ownerID);
                    setCurrentReceiverName(ownerName)
                    setChatType('owner')
                    setChat = true;
                }
                contactArray.push({'name': ownerName, 'chatID': chatID, 'ownerID': ownerID, 'type': 'owner', 'lastMessage': chat.lastMessage.textContent, dateString, imageLink: owner.profileImage });
            } else if (chat.adminChat === true) {
                if (setChat === false || chatID === stateID) {
                    setActiveChat(chatID);
                    setCurrentReceiverID('admin');
                    setCurrentReceiverName('Forte')
                    setChatType('admin')
                    setChat = true;
                }

                contactArray.push({sent: chat.lastMessage.receiver !== uid,'name': 'Forte Support', 'chatID': chatID, 'ownerID': 'admin', 'type': 'admin', 'lastMessage': chat.lastMessage.content, dateString, imageLink: 'https://firebasestorage.googleapis.com/v0/b/forte-v2.appspot.com/o/facility-images%2FUntitled%20design.jpg?alt=media&token=f72104a9-2ec1-497a-9b5d-ccc2c6057100' });
            }

        }
        setLoadedContacts(contactArray)
        setLoading(false);

    });
    }

    async function sendMessage() {
        chatInputRef.current.focus();
        const textContent = chatInputRef.current.value;
        // console.log(currentReceiverID);
        if (chatType === 'owner') {
            try {
                await db.collection('chat').doc(activeChat).collection('Messages').add({
                    textContent,
                    sender: currentUserID,
                    receiver: currentReceiverID,
                    timeStamp: new Date(),
                    read: false,
                });
                await db.collection('chat').doc(activeChat).update({
                    lastMessage: {
                        textContent,
                        sender: currentUserID,
                        receiver: currentReceiverID,
                        timeStamp: new Date(),
                        read: false,
                    }
                })
                chatInputRef.current.value = '';
                loadMessagesForChat();
            } catch (err) {
                console.log(err);
            }
        } else {
            try {
                await db.collection('chat').doc(activeChat).collection('Messages').add({
                    content: textContent,
                    sender: currentUserID,
                    receiver: currentReceiverID,
                    timeStamp: new Date(),
                    read: false,
                });
                await db.collection('chat').doc(activeChat).update({
                    lastMessage: {
                        content: textContent,
                        sender: currentUserID,
                        receiver: currentReceiverID,
                        timeStamp: new Date(),
                        read: false,
                    }
                })
                chatInputRef.current.value = '';
                loadMessagesForChat();
            } catch (err) {
                console.log(err);
            }
        }

    }

    async function loadMessagesForChat(uid) {
        setLoading(true)
        const chatDoc = await db.collection('chat').doc(activeChat).get();
        const chat = chatDoc.data();
        if (chat.lastMessage.receiver === uid && chat.lastMessage.read === false) {
            chatDoc.ref.update({
                'lastMessage.read': true,
            });
        }

        db.collection('chat').doc(activeChat).collection('Messages').orderBy('timeStamp', 'asc').onSnapshot((messageCollection) => {
            const messageArray = [];
            messageCollection.forEach((messageDoc) => {
                const message = messageDoc.data();
                const timeStamp = new Date(message.timeStamp.toDate());
                const messageMoment = moment(timeStamp);
                // const nowDate = new Date()
                const now = moment();
                let displayDate = '';
                if (messageMoment.isSame(now, 'day')) {
                    displayDate = messageMoment.format('HH:mm');
                } else {
                    displayDate = messageMoment.format('D/M/YYYY');
                }
                if (message.read === false && message.receiver === currentUserID) {
                    messageDoc.ref.update({
                        read: true,
                    })
                }
                const messageClass = message.sender === currentUserID ? 'sent' : 'received';
                if (chatType === 'owner') {
                    messageArray.push({'text': message.textContent, 'msgClass': messageClass, 'timeStamp': displayDate, read: message.read })

                } else {
                messageArray.push({'text': message.textContent ?? message.content, 'msgClass': messageClass, 'timeStamp': displayDate, read: message.read })
                }
            });
            setCurrentChatMessages(messageArray);
        })

        setLoading(false)
        // const messageCollection = await db.collection('chat').doc(activeChat).collection('Messages').orderBy('timeStamp', 'asc').get();
        // const messageArray = [];
        // messageCollection.forEach((messageDoc) => {
        //     const message = messageDoc.data();
        //     // console.log(message.textContent);
        //     const messageClass = message.sender === currentUserID ? 'sent' : 'received';
        //     messageArray.push({'text': message.textContent, 'msgClass': messageClass })
        // });
        // setCurrentChatMessages(messageArray);
        // bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }

    function searchContacts() {
        const searchInput = searchRef.current.value;
        setCurrentSearchValue(searchInput);
        if (searchInput.length > 0) {
            setSearching(true);
        } else {
            setSearching(false);
        }
    }

    const img = 'https://i.pinimg.com/736x/3f/94/70/3f9470b34a8e3f526dbdb022f9f19cf7.jpg'

    return (
        <LoadingOverlay active={loading} spinner  >
        <div className="inbox">
            <Drawer
                            onClick={() => setShowDrawer(false)}
                            className='chat-menu-drawer'
                            anchor='left'
                            open={showDrawer}
                            onClose={() => setShowDrawer(false)}
            >
<div className="contact-list-container-side">
            <div onClick={() => setShowBroadcastModal(true)} className="broadcast-message-container">
                <AddCircleIcon />
                <p>Send a broadcast message</p>
            </div>
                <input type="text" placeholder="search" ref={searchRef} onKeyUp={() => searchContacts()}/>
                {loadedContacts.map((chat, index) => {
                if (searching) {
                    const chatName = chat.name.toLowerCase();
                    if (chatName.includes(currentSearchValue.toLowerCase())) {
                        // return <Contact onclick={() => {setActiveChat(chat.chatID); setCurrentReceiverID(chat.instructorID); setChatType(chat.type)}} name={chat.name} active={activeChat === chat.chatID} />
                        return <ChatItem sent={chat.sent} chatID={chat.chatID} dateString={chat.dateString} image={chat.imageLink} message={chat.lastMessage} name={chat.name} menu={true} clickAction={() => {setActiveChat(chat.chatID); setCurrentReceiverName(chat.name); setCurrentReceiverID(chat.instructorID); setChatType(chat.type)}} />

                    } else {
                        return null;
                    }
                } else {
                    // return <Contact onclick={() => {setActiveChat(chat.chatID); setCurrentReceiverName(chat.name); setCurrentReceiverID(chat.instructorID); setChatType(chat.type)}} name={chat.name} active={activeChat === chat.chatID} />
                    return <ChatItem sent={chat.sent} chatID={chat.chatID} dateString={chat.dateString} image={chat.imageLink} message={chat.lastMessage} name={chat.name} menu={true} clickAction={() => {setActiveChat(chat.chatID); setCurrentReceiverName(chat.name); setCurrentReceiverID(chat.instructorID); setChatType(chat.type)}} />

                }
                })}
            </div>
            </Drawer>
            {showBroadcastModal && <BroadcastMessageModal role={userRole} senderID={currentUserID} contacts={loadedContacts} closeModal={() => setShowBroadcastModal(false)} />}
            <div className="contact-list-container">
            <div onClick={() => setShowBroadcastModal(true)} className="broadcast-message-container">
                <AddCircleIcon />
                <p>Send a broadcast message</p>
            </div>
                <input type="text" placeholder="search" ref={searchRef} onKeyUp={() => searchContacts()}/>
                {loadedContacts.map((chat, index) => {
                if (searching) {
                    const chatName = chat.name.toLowerCase();
                    if (chatName.includes(currentSearchValue.toLowerCase())) {
                        return <ChatItem sent={chat.sent} chatID={chat.chatID} dateString={chat.dateString} image={chat.imageLink} message={chat.lastMessage} name={chat.name} menu={true} clickAction={() => {setActiveChat(chat.chatID); setCurrentReceiverName(chat.name); setCurrentReceiverID(chat.instructorID); setChatType(chat.type)}} />

                        // return <Contact onclick={() => {setActiveChat(chat.chatID); setCurrentReceiverID(chat.instructorID); setChatType(chat.type)}} name={chat.name} active={activeChat === chat.chatID} />
                    } else {
                        return null;
                    }
                } else {
                    // return <Contact onclick={() => {setActiveChat(chat.chatID); setCurrentReceiverName(chat.name); setCurrentReceiverID(chat.instructorID); setChatType(chat.type);}} name={chat.name} active={activeChat === chat.chatID} />
                    return <ChatItem sent={chat.sent} chatID={chat.chatID} dateString={chat.dateString} image={chat.imageLink} message={chat.lastMessage} name={chat.name} menu={true} clickAction={() => {setActiveChat(chat.chatID); setCurrentReceiverName(chat.name); setCurrentReceiverID(chat.instructorID); setChatType(chat.type)}} />
                }
                })}
            </div>
            <div className="chat-container">
                <div className="chat-name-container">
                    <div className="empty-container">

                    </div>
                    <h2>{currentReceiverName}</h2>
                    <MenuIcon className='chat-menu-icon' onClick={() => setShowDrawer(true)} />
                </div>
                <div className="chat-type">
                <p>{chatType}</p>
                </div>
                <div className="messages-container">
                    {currentChatMessages.map((message, index) => {
                        return <Message read={message.read} text={message.text} msgClass={message.msgClass} sentAt={message.timeStamp} userImage={img}/>
                    })}
                    <div ref={bottomRef} className="bottom-chat-div">

                    </div>
                </div>
                <div className="chat-input">
                    {/* <form> */}
                        <input onKeyUp={(evt) => {if (evt.key === 'Enter') {
                            sendMessage();
                        }}} type="text" ref={chatInputRef} name="name" autoComplete="off"/>
                        <Button onClick={() => sendMessage()}
                            variant="contained"
                            >
                            <SendIcon />
                        </Button>
                    {/* </form> */}
                </div>
            </div>
        </div>
        </LoadingOverlay>
    )
}

export default Inbox
