import React from 'react'
import './FinancialStatements.css'

// Components
import StatementRow from './StatementRow'
import ProfileSummary from '../Bookings/ProfileSummary'

function FinancialStatements({venueName, facilityID, venueDetails}) {

    return (
        <div className="financial-statements">
            <div className="financial-statements-left">
                <div className="business-performance-header">
                    <h2>{venueName}</h2>
                </div>
                <div className="statement-container">
                    <StatementRow startDate='1 September' endDate='7 September' link='' year='2021'/>
                    <StatementRow startDate='1 September' endDate='7 September' link='' year='2021'/>
                    <StatementRow startDate='1 September' endDate='7 September' link='' year='2021'/>
                    <StatementRow startDate='1 September' endDate='7 September' link='' year='2021'/>
                </div>
            </div>
            <div className="financial-statements-right">
                <ProfileSummary />
            </div>

        </div>
    )
}

export default FinancialStatements
