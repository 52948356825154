import React, { useState, useEffect} from 'react'
import { useNavigate } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import './NewsfeedItem.css'

// Material UI
import AddCommentIcon from '@material-ui/icons/AddComment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import GroupsIcon from '@mui/icons-material/Groups';
import PaidIcon from '@mui/icons-material/Paid';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import PayBookingModal from '../Bookings/PayBookingModal/PayBookingModal';
import Tooltip from '@material-ui/core/Tooltip';
import { db } from '../../firebase';


const useStyles = makeStyles(() => ({
    tooltip: {
      fontSize: '18px',
    },
  }));


function NewsfeedItem({title, description, type, docID, facilityID, userRole}) {
    const navigate = useNavigate();
    const classes = useStyles();

    const [chosenImage, setChosenImage] = useState();
    const [showPayModal, setShowPayModal] = useState(false)
    const [payModal, setPayModal] = useState(false)

    useEffect(() => {
        // console.log(docID)
        switch (type) {
            case 'booking-paid': setChosenImage(<CreditScoreIcon />)
                break;
            case 'booking-payment': setChosenImage(<PaidIcon />)
                break;
            case 'group': setChosenImage(<GroupsIcon />)
                break;
            case 'session': setChosenImage(<EventAvailableIcon />)
                break;
            case 'booking': setChosenImage(<EventAvailableIcon />)
                break;
            case 'review': setChosenImage(<AddCommentIcon />)
                break;
            case 'chat': setChosenImage(<MailIcon />)
                break;
            case 'credits': setChosenImage(<AddShoppingCartIcon />)
                break;
            default: setChosenImage(<NotificationsIcon />)
                break;
        }
    }, []);

    async function handleClick(e) {
        console.log(e)
        if (e.target.classList[0] === ('mb-10')) {
            return
        }
        if (e.target.className === 'pay-booking-modal') {
            return
        }
        // return console.log(type)

        console.log(userRole)
        if (userRole === 'instructor') {

            if (type === 'booking-payment') {
                return navigate('/bookings', {
                    state: {
                        type,
                        bookingID: docID,
                    }
                })
                // const bookingDoc = await db.collection('bookings').doc(docID).get();
                // const booking = bookingDoc.data();
                // const { start, end, instructorID, facilityID, total, paymentStatus } = booking;

                // const startDate = new Date(start.toDate())
                // const endDate = new Date(end.toDate());

                // // Facility
                // const facilityDoc = await db.collection('facilities').doc(facilityID).get();
                // const facility = facilityDoc.data();
                // const { listingName } = facility;
                // console.log('setting')
                // if (paymentStatus === 'paid') {
                //     return;
                // }
                // setPayModal(<PayBookingModal hp={true} closeModal={() => setShowPayModal(false)} start={startDate} end={endDate} bookingID={docID} facilityName={listingName}
                //     facilityID={facilityID} instructorID={instructorID} total={total}
                // />)
                // return setShowPayModal(true)
            }
            if (type === 'group') {
               return navigate(
                    '/bookings',
                    {
                        state: {
                            bookingID: docID,
                            facilityID,
                            type,
                        }
                })
            }
            if (type === 'chat') {
                console.log(docID)
                return navigate(
                    '/inbox',
                    {
                        state: {
                            chatID: docID,
                    }
                })
            }
            if (type === 'session') {
                console.log(docID)
                return navigate(
                    '/bookings',
                    {
                        state: {
                            bookingID: docID,
                            type,
                    }
                })
            }
        }
        console.log(docID)

        if (type === 'booking') {
            navigate(
                '/bookings',
                {
                    state: {
                        bookingID: docID,
                        facilityID,
                        type,
                    }
            })
        }
        if (type === 'review') {
            navigate(
                '/analytics',
                {
                    state: {
                        reviewID: docID,
                        facilityID,
                        type,
                }
            })
        }
    }

    return (
        <div onClick={handleClick} className='newsfeed-item'>
                        {showPayModal && payModal}

            <div className="newsfeed-item-image">
                {chosenImage}
            </div>
            <div className="newsfeed-item-content">
                <h3>{title}</h3>
                <Tooltip classes={{tooltip: classes.tooltip}} title={description}><p>{description}</p></Tooltip>
            </div>
        </div>
    )
}

export default NewsfeedItem
