import React from 'react'
import './CreditPurchase.css'

function CreditPurchase({ date = new Date(), amount, }) {
  return (
    <div className='credit-purchase'>
        <h3>{date.toLocaleDateString('en-us', { dateStyle: 'medium'})}</h3>
        <p>R {amount}</p>
    </div>
  )
}

export default CreditPurchase