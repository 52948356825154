import React, { useState, useEffect, useRef} from 'react'
import './RequestedSessionModal.css'

import { db } from '../../../firebase'

// MUI
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeIcon from '@mui/icons-material/Home';
import PaymentIcon from '@mui/icons-material/Payment';

function RequestedSessionModal({ closeModal, uid, athleteName, date, start, end, total, reloadCalendar, venue, sessionID }) {

    console.log(start)

    const [errorMessage, setErrorMessage] = useState('')

    async function cancelRequest() {

        try {
            await db.collection('sessions').doc(sessionID).update({
                requestStatus: 'cancelledByInstructor',
                status: 'requestCancelled',
            })
        } catch (err) {
            console.log('Could not process your cancellation, please try again')
        }

    }

    async function confirmRequest() {
        // return console.log(sessionID)
        try {
            await db.collection('sessions').doc(sessionID).update({
                requestStatus: 'paymentDue',
            })
            reloadCalendar()
            closeModal()
        } catch (err) {
            console.log('Could not process your confirmation, please try again')
        }
    }

    return (
        <div onClick={(e) => {if (e.target.className === 'requested-session-modal') { closeModal() }}} className='requested-session-modal'>
            <div className="requested-session-modal-content scale-in-center">
            <h1>{start.toLocaleDateString('en-us', { dateStyle: 'long' })}</h1>
            <div className="rsm-group">
                <AccessTimeIcon />
                <p>{start.toLocaleTimeString('en-us', { timeStyle: 'short' })} - {end.toLocaleTimeString('en-us', { timeStyle: 'short' })}</p>
            </div>
            <div className="rsm-group">
                <PersonIcon />
                <p>{athleteName}</p>
            </div>
            <div className="rsm-group">
                <HomeIcon />
                <p>{venue}</p>
            </div>
            <div className="rsm-group">
                <PaymentIcon />
                <p>R {total}</p>
            </div>
            {errorMessage !== '' && <div className="error-message-row">
                <p>{errorMessage}</p>
            </div>}
            <div className="button-row rsm-button-row">
                <button onClick={cancelRequest} className="cancel-button">Cancel</button>
                <button onClick={confirmRequest} className="confirm-button">Confirm</button>
            </div>

            </div>
        </div>
    )
}

export default RequestedSessionModal
