import React, { useRef, useState } from 'react'
import './ReportReviewModal.css'

// Material UI
import Checkbox from '@material-ui/core/Checkbox';

function ReportReviewModal({ closeModal, reviewID }) {

    console.log(reviewID)

    const [otherSelected, setOtherSelected] = useState(false);

    const abuseRef = useRef();
    const otherRef = useRef();
    const wrongRef = useRef();
    const spamRef = useRef();
    const falseRef = useRef();

    return (
        <div onClick={(evt) => {
            if (evt.target.className === 'report-review-modal') {
                closeModal();
            }
        }} className='report-review-modal'>
            <div className="report-review-modal-content">
                <div className="report-review-header-row">
                    <h1>Report Review</h1>
                </div>
                <p>I want to report this review because:</p>
                <div className="report-review-options">
                    <div className="check-group">
                            <Checkbox inputRef={abuseRef} color="default"/>
                            <label for="weight-section">The language used is abusive</label>
                    </div>
                    <div className="check-group">
                            <Checkbox inputRef={wrongRef} color="default"/>
                            <label for="weight-section">This review is not meant for me</label>
                    </div>
                    <div className="check-group">
                            <Checkbox inputRef={spamRef} color="default"/>
                            <label for="weight-section">This review is spam</label>
                    </div>
                    <div className="check-group">
                            <Checkbox inputRef={falseRef} color="default"/>
                            <label for="weight-section">This review contains things I find untrue</label>
                    </div>
                    <div className="check-group">
                            <Checkbox onChange={() => setOtherSelected(!otherSelected)} inputRef={otherRef} color="default"/>
                            <label for="weight-section">Other</label>
                    </div>
                    <div className="report-textarea">
                    {otherSelected && <textarea rows={5} placeholder='Other Reason' />}
                    </div>
                    <div className="report-button-row">
                        <button className='review-report-button'>Report</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ReportReviewModal
