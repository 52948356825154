import React, { useEffect, useState } from 'react'
import './Newsfeed.css'

// Icons
import NotificationsIcon from '@material-ui/icons/Notifications';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

// Components
import FeedItem from './FeedItem';
import NotificationItem from '../../Components/Notifications/NotificationItem';
import NewsfeedItem from './NewsfeedItem';

import { db } from '../../firebase';

function Newsfeed({ uid, userRole }) {

    const [nots, setNots] = useState([])

    React.useEffect(() => {
        if (userRole === 'owner') {
            loadNotifications()
        } else {
            loadInstructorNotifications()
        }
    }, []);
    async function createReview() {
        const dateTimeStamp = new Date();
        const textContent = 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni fugiat cupiditate aliquid neque explicabo tenetur ipsam, earum tempore atque odit nulla mollitia autem necessitatibus inventore totam. Labore voluptatum aspernatur nam.';
        const rating = 5;
        const instructorID = 'atxPm2ShebOh0Z9uW7OxRfgz1oE3';
        const facilityID = '5uvsarnkBka9MxZnoyc5';
        alert('adding');
        try {
            await db.collection('facilityReviews').add({
                dateTimeStamp,
                textContent,
                rating,
                instructorID,
                facilityID,
            });
        } catch (err) {
            console.log(err);
        }

        alert('added')
    }

    async function createBooking() {
        console.log('creating')
        const start = new Date(2021, 9, 1, 12, 0, 0);
        const end = new Date(2021, 9, 1, 17, 0, 0);
        const instructorID = 'hZ9sGP7gqPXWABbgGuXenakuF4H3';
        const facilityID = '5uvsarnkBka9MxZnoyc5';
        const title = 'John Wei';
        const type = 'pending';
        const rate = 100;
        const rateType = 'hourly';

        try {
            await db.collection('facilities').doc('5uvsarnkBka9MxZnoyc5').collection('calendarEvents').add({
                start,
                end,
                instructorID,
                facilityID,
                title,
                type,
                rate,
                rateType,
            });
        } catch (err) {
            console.log(err);
        }


        console.log('created');
    }

    async function loadNotifications() {
        const notificationsCollection = await db.collection('users').doc(uid).collection('notifications')
        .orderBy('dateCreated', 'desc')
        .get()
        const array = [];
        for (let i = 0; i < notificationsCollection.docs.length; i++) {
            const notification = notificationsCollection.docs[i].data();
            const {facilityID, title, type, documentID} = notification;
            if (title === 'Pending Bookings') {
                continue;
            }
            const message = notification.message ?? notification.textContent;
            array.push({
                userRole,
                facilityID,
                message,
                title,
                type,
                documentID,
            })
        }

        setNots(array)
    }

    async function loadInstructorNotifications() {
        const notificationsCollection = await db.collection('users').doc(uid).collection('notifications')
        .orderBy('sentAt', 'desc')
        .limit(10)
        .get()
        const array = [];
        for (let i = 0; i < notificationsCollection.docs.length; i++) {
            const notification = notificationsCollection.docs[i].data();
            const {title} = notification;
            const message = notification.message ?? notification.textContent;
            let type = notification.type ?? '';
            if (type === '') {
                if (title.toLowerCase() === 'new chat message') {
                    type = 'chat';
                }
                if (title.toLowerCase().includes('booking')) {
                    type = 'booking';
                }
                if (message.toLowerCase().includes('group')) {
                    type ='group'
                }
                if (title.toLowerCase().includes('refund')) {
                    type = 'refund'
                }
            }

            array.push({
                facilityID: '',
                documentID: notification.documentID,
                message,
                title,
                userRole,
                type,
            })
        }

        setNots(array)
    }

    return (
        <div className="news-feed">
            <h2 onClick={() => {}}>News Feed</h2>
            {/* <hr id="news-hr"/> */}
            <div className="feed-container">
                {
                    nots.map(notification => <NewsfeedItem userRole={userRole} facilityID={notification.facilityID} docID={notification.documentID} type={notification.type} description={notification.message} title={notification.title}  />)
                }
                {nots.length === 0 && <h3 style={{textAlign:'center'}}>No feed items to display - open up your calendar to get going</h3>}
            </div>
        </div>
    )
}

export default Newsfeed
