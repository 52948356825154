import React, { useEffect } from 'react'
import './Contact.css'

function Contact({name, active, onclick}) {

    useEffect(() => {
        // console.log(name, active)
    }, [active]);

    return (
        <div className="contact">
            <p onClick={onclick} className={active ? 'active' : null}>{name}</p>
            <hr className="ruler"/>
        </div>
    )
}

export default Contact
