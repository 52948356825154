import React from 'react'
import './CalendarInfo.css'

function CalendarInfo({ closeModal }) {
    return (
        <div onClick={(e) => {
            if (e.target.className === 'calendar-info') {
                closeModal()
            }
        }} className='calendar-info'>
            <div className="calendar-info-content">

                    <div className="calendar-legend-group">
                      <div className="cal-leg-item">
                        <span className="blue"></span>
                        <p>Bookings</p>
                      </div>
                      <div className="cal-leg-item">
                        <span className="black"></span>
                        <p>Sessions</p>
                      </div>
                      <div className="cal-leg-item">
                        <span className="red"></span>
                        <p>Pending</p>
                      </div>
                      <div className="cal-leg-item">
                        <span className="yellow"></span>
                        <p>Payment Pending</p>
                      </div>
                      <div className="cal-leg-item">
                        <span className="green"></span>
                        <p>Available</p>
                      </div>
                    </div>
                    </div>

        </div>
    )
}

export default CalendarInfo
