import React, { useState, useEffect, useRef} from 'react'

import './CreditsModal.css'

import { db, admin } from '../../firebase';

import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';
import LoadingOverlay from 'react-loading-overlay';


function CreditsModal({ closeModal, facilityID, instructorID }) {

    const requestPayment = admin.functions().httpsCallable('requestYocoPayment');
    const testPayment = admin.functions().httpsCallable('yocoTestPayment');

    const [discounts, setDiscounts] = useState([])
    const [facilityName, setFacilityName] = useState('')
    const [chosenDiscount, setChosenDiscount] = useState('')
    const [purchaseStarted, setPurchaseStarted] = useState(false)
    const [startPayment, setStartPayment] = useState(false)
    const [loading, setLoading] = useState(false)

    const [discountMap, setDiscountMap] = useState({})

    const [paymentAmount, setPaymentAmount] = useState()

    const formRef = useRef();
    const totalRef = useRef()
    totalRef.current = paymentAmount;
    const payButtonRef = useRef();
    const yocoRef = useRef();

    const [storeInline, setStoreInline] = useState()
    const [errorMessage, setErrorMessage] = useState('');
    const [paymentCompleted, setPaymentCompleted] = useState(false)

    const discRef = useRef();
    discRef.current = discounts;

    useEffect(() => {
        loadFacilityDiscounts()
    }, [])

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://js.yoco.com/sdk/v1/yoco-sdk-web.js";
        script.async = true;

        document.body.appendChild(script);
        // alert('loaded script')
        // return () => {
        //   document.body.removeChild(script);
        // }
      }, []);

      function cap(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    async function loadFacilityDiscounts() {
        const facilityDoc = await db.collection('facilities').doc(facilityID).get();
        const facility = facilityDoc.data();
        const {bulkDiscounts, listingName} = facility;
        const bulkArray = []

        const discMap = {};

        for (let i = 0; i < bulkDiscounts.length; i++) {
            const discount = bulkDiscounts[i];
            const {amount,percentage,size} = discount;
            bulkArray.push({
                name: `${cap(size)}: R${amount} - ${percentage}% discount`,
                value: size,
                amount,
                percentage,
            })
            discMap[size] = {amount, percentage}
        }
        setDiscounts(bulkArray)
        setFacilityName(listingName)
        setDiscountMap(discMap);
    }

    async function storeCredits() {
        console.log(chosenDiscount)

        const now = new Date();

        let chosen;

        for (let i = 0; i < discRef.current.length; i++) {
            const disc = discRef.current[i];
            const {value} = disc;
            if (chosenDiscount === value) {
                chosen = disc;
            }
        }

        console.log(instructorID)

        try {

        // Instructor Credit Balance
        const instructorDoc = await db.collection('users').doc(instructorID).get();
        const instructor = instructorDoc.data();
        const facilityCredits = instructor.facilityCredits ?? {};

        if (facilityCredits[facilityID] === null || facilityCredits[facilityID] === null) {
            facilityCredits[facilityID] = chosen.amount;
        } else {
            facilityCredits[facilityID] += chosen.amount;
        }

        await instructorDoc.ref.update({
            facilityCredits,
        });

        // Facility Credit Balance
        const facilityDoc = await db.collection('facilities').doc(facilityID).get();
        const facility = facilityDoc.data();
        const instructorCredits = facility.instructorCredits ?? {};

        if (instructorCredits[instructorID] === null || instructorCredits[facilityID] === null) {
            instructorCredits[instructorID] = chosen.amount;
        } else {
            instructorCredits[instructorID] += chosen.amount;
        }

        await facilityDoc.ref.update({
            instructorCredits,
        })

        // Instructor transaction
        await instructorDoc.ref.collection('bulkPurchases').add({
            date: now,
            facilityID,
            amount: chosen.amount,
            percentage: chosen.percentage,
            size: chosen.value,
        })

        // Facility transaction
        await facilityDoc.ref.collection('bulkPurchases').add({
            date: now,
            instructorID,
            amount: chosen.amount,
            percentage: chosen.percentage,
            size: chosen.value,
        })
    } catch(err) {
        console.log(err)
    }
        setLoading(false)
        setPaymentCompleted(true)
    }

    function mountYoco() {
        setStartPayment(true)

        var sdk = new window.YocoSDK({
            publicKey: 'pk_test_71402d41jB90nKE024a4'
          });
          console.log(1);
          const discProps = discountMap[chosenDiscount];
          const discount = (discProps.percentage / 100) * discProps.amount;
          const amountInCents = (discProps.amount - discount) * 100;
          setPaymentAmount(amountInCents / 100)
          console.log(amountInCents)
          // Create a new dropin form instance
          var inline = sdk.inline({
            layout: 'plain',
            amountInCents,
            currency: 'ZAR'
          });
          setStoreInline(inline)
          // this ID matches the id of the element we created earlier.
          inline.mount(yocoRef.current);
        // setPaymentForm(inline)
        console.log(3);
    }

    async function onYocoSubmit() {
        setLoading(true)
        var form = formRef.current;
        var submitButton = payButtonRef.current;
        form.addEventListener('submit', function (event) {
          event.preventDefault()
          // Disable the button to prevent multiple clicks while processing
          submitButton.disabled = true;
          // This is the inline object we created earlier with the sdk
          storeInline.createToken().then(function (result) {
            // Re-enable button now that request is complete
            // (i.e. on success, on error and when auth is cancelled)
            submitButton.disabled = false;
            if (result.error) {
              const error = result.error.message;
              setErrorMessage(error)
            //   errorMessage && alert("error occured: " + errorMessage);
            } else {
              const token = result;
              console.log(token);
              const discProps = discountMap[chosenDiscount];
              const discount = discProps.percentage * discProps.amount
              console.log(discount);
            //   alert("card successfully tokenised: " + token.id);
              testPayment({token: token.id, amountInCents: ((discProps.amount * 100) - discount)}).then((res) => {
                console.log(res)
                if (res.data === 'yoco error') {
                    return setErrorMessage('An error occurred trying to process your payment, please try again')
                } else {
                    storeCredits()
                    setStartPayment(false);
                }

              })
            }
          }).catch(function (error) {
              setLoading(false)
            // Re-enable button now that request is complete
            submitButton.disabled = false;
            setErrorMessage('An error occurred trying to process your payment, please try again')
            // alert("error occured: " + error);
          });
        });
    }

    return (
        <div className='credits-modal' onClick={(e) => {
            if (e.target.className === 'credits-modal') {
                closeModal()
            }
        }}>
            <LoadingOverlay active={loading} spinner text={'Purchasing your credits...'}>
            <div className="credits-modal-content">
                {(!startPayment && !paymentCompleted && !loading) && <>
                <h1>Purchase Credits</h1>
                <h3>{facilityName}</h3>
                <h2>Available Discounts</h2>

                <div className="discounts-container">
                    {/* {discounts.map(discount => {
                        return <div>
                            <h4>{discount.type} discount</h4>
                            <p>R {discount.amount} - {discount.percentage}% off</p>
                        </div>
                    })} */}
                <SelectSearch
                  closeOnSelect={true}
                  search={false}
                  placeholder={'Select Option'}
                  options={discounts}
                  onChange={(value) => {
                      console.log(value)
                    setChosenDiscount(value)
                  }}
                  />
                </div>
                <div className="button-row">
                <button onClick={closeModal} className='cancel-button'>Cancel</button>
                <button disabled={chosenDiscount === ''} className='confirm-button' onClick={mountYoco}>Purchase</button>
            </div>
            </>}
            {paymentCompleted && <div className='payment-completed'>
                <h2>Purchase Completed</h2>
                <p>Successfully purchased R{paymentAmount} from {facilityName}</p>
                <div className="button-row">
                   <button onClick={closeModal} className="general-button">
                       Close
                   </button>
                </div>
                </div>}
            <div className='yoco-container'>
            <form ref={formRef} hidden={!startPayment} style={{fontFamily:'LibreFranklin'}} id="payment-form" method="POST">
                    <div class="one-liner">
                        <div ref={yocoRef} id="card-frame">
                        </div>
                        {/* {paymentForm} */}
                        {errorMessage !== '' && <div className="error-message-row">
                            <p>{errorMessage}</p>
                        </div>}
                        <div style={{justifyContent: 'center'}}  className="button-row">
                        <button hidden={!startPayment} onClick={onYocoSubmit} ref={payButtonRef} id="pay-button">
                        PAY ZAR {totalRef.current}.00
                        </button>
                        </div>

                    </div>
                    <p class="success-payment-message" />
                </form>
            </div>
            {purchaseStarted && <>
                <div className="credits-purchase-item">
                    <h4>Total Credits</h4>
                    <p></p>
                </div>
                <div className="credits-purchase-item">
                    <h4>Total Discount</h4>
                    <p></p>
                </div>
                <div className="credits-purchase-item">
                    <h4>Purchase Amount</h4>
                    <p></p>
                </div>
            </>}
            </div>
                </LoadingOverlay>
        </div>
    )
}

export default CreditsModal
