import React, { useState, useEffect } from 'react'
import './InfoCard.css'

// Material UI
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PaymentIcon from '@material-ui/icons/Payment';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import LocationCityIcon from '@mui/icons-material/LocationCity';


function InfoCard({ value, info, today, change, dir, type, flex, icon, hideChange, fixedHeight, daily = true, lastMonth }) {

    const [cardStyle, setCardStyle] = useState();
    const [chosenicon, setChosenIcon] = useState();

    useEffect(() => {
        const height = fixedHeight ? '18vh' : 'max-content' ;

        // Color
        switch (type) {
            case 'general':
                const generalStyleMap = {
                    backgroundColor: '#dddddd',
                    flex: flex,
                    height,
                };
                setCardStyle(generalStyleMap);
                break;
            case 'red':
                const redStyleMap = {
                    backgroundColor: '#D94A4A',
                    color: 'white',
                    flex: flex,
                    height,
                };
                setCardStyle(redStyleMap);
                break
            case 'blue':
                const blueStyleMap = {
                    color: 'white',
                    backgroundColor: '#023e73',
                    flex: flex,
                    height,
                };
                setCardStyle(blueStyleMap);
                break
            default: break;
        }

        // Icon
        switch (icon) {
            case 'money':
                setChosenIcon(<PaymentIcon />)
                break;
            case 'gym':
                setChosenIcon(<FitnessCenterIcon />)
                break;
            case 'person':
                setChosenIcon(<PersonIcon />)
                break;
            case 'session':
                setChosenIcon(<FitnessCenterIcon />)
                break;
            case 'people':
                setChosenIcon(<PeopleIcon />)
                break;
            case 'booking':
                setChosenIcon(<LocationCityIcon />)
                break;
            case 'group':
                setChosenIcon(<GroupsIcon />)
                break;
            default:
                break;
        }

    }, [])

    return (
        <div style={cardStyle} className='info-card scale-in-fade'>
            <div className="info-card-container">
                <div className="info-card-content-container">
                    <h2>{value}</h2>
                    <p>{info}</p>
                </div>
                <div className="icon-container">
                {chosenicon}

                </div>
            </div>

            {hideChange !== true && <div className="info-card-bottom-row">
                {daily && <p>Today: {today} ({change}%{dir === 'up' ? <TrendingUpIcon /> : <TrendingDownIcon />})</p>}
                {!daily && <p>Last Month: {lastMonth} ({change}%{dir === 'up' ? <TrendingUpIcon /> : <TrendingDownIcon />})</p>}

            </div>}
        </div>
    )
}

export default InfoCard
