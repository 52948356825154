import React, { useState, useRef } from 'react'
import './ReplyReviewModal.css'

import Checkbox from '@material-ui/core/Checkbox';

import { db } from '../../../firebase';


function ReplyReviewModal({ closeModal, userName, reviewID }) {
    const [responseType, setResponseType] = useState('public')
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const replyRef = useRef();

    async function sendReply() {
        setLoading(true);
        setErrorMessage('');

        const replyContent = replyRef.current.value;
        console.log(replyContent);
        if (replyContent.length < 30) {
            setErrorMessage('Minimum 30 characters required');
        }

        try {
            await db.collection('facilityReviews').doc(reviewID).update({
                replyContent,
            })
        } catch (err) {
            setErrorMessage('Sorry, we were unable to store your reply, please try again')
        }

        setLoading(false);
    }

    return (
        <div onClick={(evt) => {
            if (evt.target.className === 'reply-review-modal') {
                closeModal();
            }
        }} className='reply-review-modal'>
            <div className="reply-review-modal-content">
                <div className="reply-review-header">
                    <h1>Reply to {userName}</h1>
                </div>
                <div className="response-type-group">
                    <button
                    onClick={() => setResponseType('private')}
                    className={responseType === 'private' ? 'selected-response-button response-button-left' : 'response-button response-button-left' }>Private</button>
                    <button
                    onClick={() => setResponseType('public')}
                    className={responseType === 'public' ? 'selected-response-button response-button-right' : 'response-button response-button-right' }>Public</button>
                </div>
            <div className="reply-review-content">
                <textarea ref={replyRef} rows={10} cols={50} placeholder='Enter your reply...'></textarea>
            </div>
            <div className="reply-review-error">
                <p>{errorMessage}</p>
            </div>
            <div className="reply-review-button-row">
                <button onClick={sendReply} disabled={loading} className='review-reply-button'>Reply</button>
            </div>
            </div>
        </div>
    )
}

export default ReplyReviewModal
