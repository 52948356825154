import React, { useState, useEffect } from 'react'
import './Bookings.css'

import { db } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext'
import { useLocation } from 'react-router'

// Components
import ProfileSummary from './ProfileSummary'
import PendingBookingItem from './PendingBookingItem'
import ConfirmedBookingItem from './ConfirmedBookingItem'
import BookingConfirmation from './BookingConfirmation'
import ViewConfirmedBooking from './ViewConfirmedBooking'
import AddClassModal from './Classes/AddClassModal'
import ClassItem from './Classes/ClassItem'
import RescheduleSessionModal from './RescheduleSessionModal/RescheduleSessionModal'

import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';

import AddBoxIcon from '@material-ui/icons/AddBox';

import LoadingOverlay from 'react-loading-overlay'

function Bookings() {

    const now = new Date()

    const { currentUser, userRole } = useAuth();
    const location = useLocation();

    const options = [
        {
            name: 'Client Sessions',
            value: 'sessions'
        },
        {
            name: 'Facility Bookings',
            value: 'bookings'
        },
        {
            name: 'Group Classes',
            value: 'classes'
        },
    ]

    function compare( a, b ) {
        if ( a.start > b.start ){
            // console.log('a')
          return 1;
        }
        if ( a.start < b.start ){
            // console.log('b')
          return -1;
        }
        return 0;
      }

    // State
    const [showBookingDetail, setShowBookingDetail] = useState(false);
    const [currentBooking, setCurrentBooking] = useState();
    const [pendingBookings, setPendingBookings] = useState([]);
    const [confirmedBookings, setConfirmedBookings] = useState([]);
    const [sessions, setSessions] = useState([])
    const [requestedSessions, setRequestedSessions] = useState([])
    const [groupSessions, setGroupSessions] = useState([])
    const [currentUserID, setCurrentUserID] = useState('');
    const [loading, setLoading] = useState(true);
    const [reloadPage, setReloadPage] = useState(0);
    const [selectedMenu, setSelectedMenu] = useState('pending')
    const [upcoming, setUpcoming] = useState(false)
    const [past, setPast] = useState(false)
    const [sessionsUpcoming, setSessionsUpcoming] = useState(false)
    const [sessionsPast, setSessionsPast] = useState(false)
    const [type, setType] = useState('bookings');
    const [classes, setClasses] = useState([])
    const [classesPast, setClassesPast] = useState(false)
    const [classesUpcoming, setClassesUpcoming] = useState(false)
    const [showAddClass, setShowAddClass] = useState(false)
    const [showReschedule, setShowReschedule] = useState(false)
    const [rescheduleModal, setRescheduleModal] = useState()
    
    const [years, setYears] = useState([])
    const [months, setMonths] = useState([])

    const [selYear, setSelYear] = useState('all')
    const [selMonth, setSelMonth] = useState('all')

    useEffect(() => {
        if (location.state !== undefined && location.state !== null
            && (currentUser !== null && currentUser !== undefined && userRole !== undefined)) {

                if (userRole === 'owner') {
                    showClickedBooking(location.state.bookingID, location.state.facilityID)
                } else {
                    if (location.state.type === 'booking-payment') {
                        return setType('bookings')
                    }

                    setType('sessions')
                    console.log(location.state.type)
                    if (location.state.type === 'booking') {
                    showInstructorClickedBooking(location.state.bookingID, currentUser.uid)
                    }
                    if (location.state.type === 'group') {
                        showInstructorClickedGroupSession(location.state.bookingID, currentUser.uid)
                    }
                    if (location.state.type === 'session') {
                        showInstructorClickedSession(location.state.bookingID, currentUser.uid)
                    }
                }
        }
    }, [location.state, userRole, currentUser])

    async function setTimeDrops(uid) {
        const userDoc = await db.collection('users').doc(uid).get();
        const user = userDoc.data()

        const yarr = []
        const marr = []

        const now = new Date()
        const dateJoined = new Date(user.dateJoinedStamp.toDate());
        const yearJoined = dateJoined.getFullYear();
        const currentYear = now.getFullYear()
        const diff = currentYear - yearJoined;

        yarr.push({
            name: 'All',
            value: 'all'
        })

        yarr.push({
            name: yearJoined,
            value: yearJoined,
        })
    
        for (let i = 0; i < diff; i++) {
            yarr.push({
                name: yearJoined + i + 1,
                value: yearJoined + i + 1,
            })
        }


        marr.push({
            name: 'All',
            value: 'all'
        })

        const monthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        for (let i = 0; i < 12; i++) {
            marr.push({
                name: monthArray[i],
                value: i,
            })
        }

        setMonths(marr)
        setYears(yarr)


    }

    async function showClickedBooking(bookingID, facilityID) {
        // return console.log(userRole)
        console.log(bookingID, facilityID)
        const bookingDoc = await db.collection('facilities').doc(facilityID).collection('calendarEvents').doc(bookingID).get();
        
        if (!bookingDoc.exists) {
            return
        }
        const booking = bookingDoc.data();

        // const facilityDoc = await db.collection('facilities').doc(facilityID).get();
        // const facility = facilityDoc.data();
        // const facilityName = facility.listingName;

        console.log(booking)

        const bookingStart = new Date(booking.start.toDate());
        const bookingEnd = new Date(booking.end.toDate());
        const startTime = bookingStart.toLocaleTimeString();
        const endTime = bookingEnd.toLocaleTimeString();
        // const calendarID = bookingDoc.id;
        const instructorID = booking.instructorID;
        const instructorProfile = await db.collection('users').doc(instructorID).get();
        const instructor = instructorProfile.data();
        const instructorImage = instructor.imageLink;
        const total = booking.total;

        const chatColl = await db.collection('chat').where('ownerID', '==', currentUser.uid).where('instructorID', '==', instructorID).get();
        const chatID = chatColl.docs[0].id;

        setCurrentBooking(<ViewConfirmedBooking
            chatID={chatID}
            bookingID={booking.bookingID}
            name={booking.title}
            startTime={startTime}
            endTime={endTime}
            imageLink={instructorImage}
            end={bookingStart}
            start={bookingEnd}
            bookingPeriod={bookingStart.toDateString()}
            revenue={total}
            forteFee={total * 0.1}
            netRevenue={total * 0.9}
            goBack={() => setShowBookingDetail(false)}
            />)

        setShowBookingDetail(true)
    }

    async function showInstructorClickedSession(sessionID, uid) {

        const sessionDoc = await db.collection('sessions').doc(sessionID).get();
        const session = sessionDoc.data();

        const bookingStart = new Date(session.dateTimeStamp.toDate());
        const bookingEnd = new Date(bookingStart);
        bookingEnd.setHours(bookingEnd.getHours() + 1);

        const startTime = bookingStart.toLocaleTimeString('en-us', { timeStyle: 'short'})
        const endTime = bookingEnd.toLocaleTimeString('en-us', { timeStyle: 'short'})
        const dateString = bookingStart.toLocaleDateString('en-us', { dateStyle: 'medium'})

        const { cost, athleteID } = session;

        const athleteDoc = await db.collection('users').doc(athleteID).get();
        const athlete = athleteDoc.data();
        const athleteName = `${athlete.firstName} ${athlete.lastName}`;
        const { imageLink } = athlete;

        const bookingToShow = (<div className='bookings-col-left'><ViewConfirmedBooking
        chatID={''}
        bookingID={sessionID}
        name={athleteName}
        startTime={startTime}
        endTime={endTime}
        imageLink={imageLink}
        end={bookingStart}
        start={bookingEnd}
        bookingPeriod={dateString}
        revenue={cost}
        forteFee={cost * 0.1}
        netRevenue={cost * 0.9}
        goBack={() => setShowBookingDetail(false)}
        type={location.state.type}
        ahleteID={athleteID}

/></div>)
setCurrentBooking(bookingToShow);
setShowBookingDetail(true);
    }

    async function showInstructorClickedGroupSession(sessionID, uid) {
        const sessionDoc = await db.collection('sessions').doc(sessionID).get();
        if (!sessionDoc.exists) {
            return setType('classes')
        }
        const session = sessionDoc.data();


        const bookingStart = new Date(session.dateTimeStamp.toDate());
        const bookingEnd = new Date(bookingStart);
        bookingEnd.setHours(bookingEnd.getHours() + 1);

        const startTime = bookingStart.toLocaleTimeString('en-us', { timeStyle: 'short'})
        const endTime = bookingEnd.toLocaleTimeString('en-us', { timeStyle: 'short'})
        const dateString = bookingStart.toLocaleDateString('en-us', { dateStyle: 'medium'})

        const { cost, classID, athleteID } = session;

        const classDoc = await db.collection('classes').doc(classID).get();
        const classData = classDoc.data();
        console.log(classData)
        const { className, imageLinks } = classData;

        const bookingToShow = (<div className='bookings-col-left'><ViewConfirmedBooking
        chatID={''}
        bookingID={sessionID}
        name={className}
        startTime={startTime}
        endTime={endTime}
        imageLink={imageLinks}
        end={bookingStart}
        start={bookingEnd}
        bookingPeriod={dateString}
        revenue={cost}
        forteFee={cost * 0.1}
        netRevenue={cost * 0.9}
        goBack={() => setShowBookingDetail(false)}
        type={'classes'}
        ahleteID={athleteID}

/></div>)
setCurrentBooking(bookingToShow);
setShowBookingDetail(true);


    }

    async function showInstructorClickedBooking(bookingID, uid) {
        const bookingDoc = await db.collection('users').doc(uid).collection('calendarEvents').doc(bookingID).get();
        const booking = bookingDoc.data();
        console.log(booking)
        const facilityID = booking.facilityID;
        const bookingStart = new Date(booking.start.toDate());
        const bookingEnd = new Date(booking.end.toDate());
        const startTime = bookingStart.toLocaleTimeString();
        const endTime = bookingEnd.toLocaleTimeString();
        const calendarID = bookingDoc.id;
        const total = booking.total;

        const facilityDoc = await db.collection('facilities').doc(facilityID).get();
        const facility = facilityDoc.data();
        const facilityName = facility.listingName;
        const imageLink = facility.imageLinks[0].link;
        const facOwner = facility.ownerIDs[0];

        const chatColl = await db.collection('chat').where('ownerID', '==', facOwner).where('instructorID', '==', uid).get();
        const chatID = chatColl.docs[0].id;

        setCurrentBooking(<ViewConfirmedBooking
            chatID={chatID}
            bookingID={booking.bookingID}
            name={booking.title}
            startTime={startTime}
            endTime={endTime}
            imageLink={imageLink}
            end={bookingStart}
            start={bookingEnd}
            bookingPeriod={bookingStart.toDateString()}
            revenue={total}
            forteFee={total * 0.1}
            netRevenue={total * 0.9}
            goBack={() => setShowBookingDetail(false)}
            />)

        setShowBookingDetail(true)
    }

    useEffect(() => {
        if (currentUser !== null && currentUser !== undefined && userRole !== undefined) {

            if (userRole === 'owner') {
                setTimeDrops(currentUser.uid)
                showPendingBookings(currentUser.uid)
                showConfirmedbookings(currentUser.uid)
            } else if (userRole === 'instructor') {
                if (location.state === null) {
                setType('sessions')

                }
                showInstructorPendingBookings(currentUser.uid);
                showInstructorConfirmedBookings(currentUser.uid);
                loadInstructorSessions(currentUser.uid)
                loadInstructorClasses(currentUser.uid)
            }

        }
    }, [currentUser, reloadPage, userRole])

    function compare( a, b ) {
        if ( a.start < b.start ){
          return 1;
        }
        if ( a.start > b.start ){
          return -1;
        }
        return 0;
      }

    async function showInstructorPendingBookings(uid) {
        setLoading(true)
        const userDoc = await db.collection('users').doc(uid).get();
        const bookingArray = [];

        const userEvents = await db.collection('users').doc(uid).collection('calendarEvents').where('type', '==', 'pending')
        .orderBy('start', 'asc')
        .get();
        const pendingPaymentEvents = await db.collection('users').doc(uid).collection('calendarEvents').where('paymentStatus', '==', 'paymentPending').get();
        console.log(userEvents.docs.length)

        for (let i = 0; i < userEvents.docs.length; i++) {
            const calendarID = userEvents.docs[i].id;
            const booking = userEvents.docs[i].data();
            const bookingID = booking.bookingID;
            const facID = booking.facilityID;
            const facilityName = booking.title;
            const total = booking.total;
            const facilityDoc = await db.collection('facilities').doc(facID).get();
            const facility = facilityDoc.data();
            const facilityImages = facility.imageLinks;
            const availableID = booking.availableID ?? '';
            const paymentStatus = booking.paymentStatus ?? ''
            const bookingMap = {
                availableID,
                facilityID: facID,
                bookingID,
                calendarID,
                start: new Date(booking.start.toDate()),
                end: new Date(booking.end.toDate()),
                startDate: (new Date(booking.start.toDate())).toLocaleString(),
                endDate: (new Date(booking.end.toDate())).toLocaleString(),
                name: booking.title,
                imageLink: facilityImages[0].link,
                rentalType: 'Short Term Rental',
                venue: facilityName,
                paymentStatus,
                total,
            }
            bookingArray.push(bookingMap);
        }

        for (let i = 0; i < pendingPaymentEvents.docs.length; i++) {
            const calendarID = pendingPaymentEvents.docs[i].id;
            const booking = pendingPaymentEvents.docs[i].data();
            const bookingID = booking.bookingID;
            const facID = booking.facilityID;
            const facilityName = booking.title;
            const total = booking.total;
            const facilityDoc = await db.collection('facilities').doc(facID).get();
            const facility = facilityDoc.data();
            const facilityImages = facility.imageLinks;
            const availableID = booking.availableID ?? '';
            const paymentStatus = booking.paymentStatus ?? ''
            const bookingMap = {
                availableID,
                facilityID: facID,
                bookingID,
                calendarID,
                start: new Date(booking.start.toDate()),
                end: new Date(booking.end.toDate()),
                startDate: (new Date(booking.start.toDate())).toLocaleString(),
                endDate: (new Date(booking.end.toDate())).toLocaleString(),
                name: booking.title,
                imageLink: facilityImages[0].link,
                rentalType: 'Short Term Rental',
                venue: facilityName,
                paymentStatus,
                total,
            }
            bookingArray.push(bookingMap);
        }

        bookingArray.sort(compare)

        setPendingBookings(bookingArray);
        setLoading(false)
    }

    async function showInstructorConfirmedBookings(uid) {
        setLoading(true)
        const userDoc = await db.collection('users').doc(uid).get();
        const bookingArray = [];

        const userEvents = await db.collection('users').doc(uid).collection('calendarEvents').where('type', '==', 'booked')
        .orderBy('start', 'desc')
        .get();

        for (var i = 0; i < userEvents.docs.length; i++) {
            const calendarID = userEvents.docs[i].id;
            const booking = userEvents.docs[i].data();
            if (booking.paymentStatus === 'paymentPending') {
                continue;
            }
            const bookingID = booking.bookingID;
            const facID = booking.facilityID;
            const facilityName = booking.title;
            const facilityDoc = await db.collection('facilities').doc(facID).get();
            const facility = facilityDoc.data();
            const facilityImages = facility.imageLinks;
            const total = booking.total;
            const availableID = booking.availableID;
            const bookingStart = new Date(booking.start.toDate());
            if (bookingStart > now) {
                setUpcoming(true)
            } else {
                setPast(true)
            }

            let reviewID = ''
            const reviews = await db.collection('facilityReviews').where('bookingID', '==', bookingID).get();
            if (reviews.docs.length > 0) {
                reviewID = reviews.docs[0].id;
            }

            console.log(reviewID)

            const bookingMap = {
                availableID,
                facilityID: facID,
                bookingID,
                calendarID,
                start: new Date(booking.start.toDate()),
                end: new Date(booking.end.toDate()),
                startDate: (new Date(booking.start.toDate())).toLocaleString(),
                endDate: (new Date(booking.end.toDate())).toLocaleString(),
                name: booking.title,
                imageLink: facilityImages[0].link,
                rentalType: 'Short Term Rental',
                venue: facilityName,
                total,
                reviewID,
            }
            bookingArray.push(bookingMap);
        }
        setConfirmedBookings(bookingArray);
        setLoading(false)
    }


    async function showPendingBookings(uid) {
        const now = new Date()
        const userProfile = await db.collection('users').doc(uid).get();
        const facilityIDs = userProfile.data().facilityIDs ?? [];
        const bookingArray = [];
        for (var i = 0; i < facilityIDs.length; i++) {
            const facID = facilityIDs[i];
            const facilityDoc = await db.collection('facilities').doc(facID).get();
            const facility = facilityDoc.data();
            const facilityName = facility.listingName;
            const facilityPendingBookings = await facilityDoc.ref.collection('calendarEvents')
            .where('start', '>', now)
            .where('type', '==', 'pending').orderBy('start').get();
            // facilityDoc.ref.collection('calendarEvents').where('type', '==', 'pending').onSnapshot(async (facilityPendingBookings) => {
                for (var j = 0; j < facilityPendingBookings.docs.length; j++) {
                    const booking = facilityPendingBookings.docs[j].data();
                    const calendarID = facilityPendingBookings.docs[j].id;
                    const bookingID = booking.bookingID;
                    const instructorID = booking.instructorID;
                    const instructorProfile = await db.collection('users').doc(instructorID).get();
                    const instructor = instructorProfile.data();
                    const instructorImage = instructor.imageLink;
                    const total = booking.total;
                    // console.log(calendarID)
                    const bookingMap = {
                        facilityID: facID,
                        bookingID,
                        calendarID,
                        start: new Date(booking.start.toDate()),
                        end: new Date(booking.end.toDate()),
                        startDate: (new Date(booking.start.toDate())).toLocaleString(),
                        endDate: (new Date(booking.end.toDate())).toLocaleString(),
                        name: booking.title,
                        imageLink: instructorImage,
                        rentalType: 'Short Term Rental',
                        venue: facilityName,
                        total,
                        rate: booking.rate,
                    }
                    bookingArray.push(bookingMap);
                }
            // });


        }

        setPendingBookings(bookingArray);
        setLoading(false)
    }

    async function showConfirmedbookings(uid) {
        const userProfile = await db.collection('users').doc(uid).get();
        const facilityIDs = userProfile.data().facilityIDs ?? [];
        const bookingArray = [];
        const now = new Date();
        for (var i = 0; i < facilityIDs.length; i++) {
            const facilityID = facilityIDs[i];
            const facilityDoc = await db.collection('facilities').doc(facilityID).get();
            const facility = facilityDoc.data();
            const facilityName = facility.listingName;

            const confirmedBookings = await db.collection('facilities').doc(facilityID).collection('calendarEvents')
                .where('type', '==', 'booked')
                .orderBy('start', 'desc')
                .get();
            for (var j = 0; j < confirmedBookings.docs.length; j++) {
                const bookingDoc = confirmedBookings.docs[j];
                const booking = bookingDoc.data();
                const bookingStart = new Date(booking.start.toDate());
                if (bookingStart > now) {
                    setUpcoming(true)
                } else {
                    setPast(true)
                }

                const calendarID = bookingDoc.id;
                const bookingID = booking.bookingID;
                const instructorID = booking.instructorID;
                const instructorProfile = await db.collection('users').doc(instructorID).get();
                const instructor = instructorProfile.data();
                const instructorImage = instructor.imageLink;
                const total = booking.total;

                const chatColl = await db.collection('chat').where('ownerID', '==', currentUser.uid).where('instructorID', '==', instructorID).get();
                let chatID = '';
                if (chatColl.docs.length > 0) {
                    chatID = chatColl.docs[0].id;
                }

                const bookingMap = {
                    rate: booking.rate,
                    chatID,
                    calendarID,
                    instructorID,
                    facilityID,
                    bookingID,
                    start: new Date(booking.start.toDate()),
                    end: new Date(booking.end.toDate()),
                    startDate: (new Date(booking.start.toDate())).toLocaleDateString('en-us', { dateStyle: 'full'}),
                    endDate: (new Date(booking.end.toDate())).toLocaleString(),
                    name: booking.title,
                    imageLink: instructorImage,
                    rentalType: 'Short Term Rental',
                    venue: facilityName,
                    total,
                }
                bookingArray.push(bookingMap);
            }
        }

        bookingArray.sort(compare)

        setConfirmedBookings(bookingArray)
    }

    // Show booking when clicked on
    async function showPendingBookingInfo(booking) {
        setShowBookingDetail(true);
        const startDate = booking.start;
        const endDate = booking.end;
        let bookingPeriod;
        if (startDate.getDate() === endDate.getDate()) {
            bookingPeriod = startDate.toDateString();
        } else {
            bookingPeriod = `${startDate.toDateString()} ${endDate.toDateString()}`
        }
        const startTime = startDate.toLocaleTimeString('en-us', { timeStyle: 'short' });
        const endTime = endDate.toLocaleTimeString('en-us', { timeStyle: 'short' });
        const bookingToShow = (<div className='bookings-col-left'><BookingConfirmation
                             bookingID={booking.bookingID}
                             name={booking.name}
                             startTime={startTime}
                             endTime={endTime}
                             imageLink={booking.imageLink}
                             end={booking.end}
                             start={booking.start}
                             bookingPeriod={bookingPeriod}
                             revenue={booking.total}
                             forteFee={booking.total * 0.1}
                             netRevenue={booking.total * 0.9}
                             goBack={() => setShowBookingDetail(false)}
                             role={userRole}

        /></div>)
        setCurrentBooking(bookingToShow);
    }

    // Show confirmed booking when clicked on
    async function showConfirmedBookingInfo(booking) {
        setShowBookingDetail(true);
        const startDate = booking.start;
        const endDate = booking.end;
        const total = booking.total;
        const facilityID = booking.facilityID;
        let bookingPeriod;
        if (startDate.getDate() === endDate.getDate()) {
            bookingPeriod = startDate.toLocaleDateString('en-us', { dateStyle: 'full' });
        } else {
            bookingPeriod = `${startDate.toDateString()} ${endDate.toDateString()}`
        }
        const instructorID = booking.instructorID;
        let chatColl;
        if (userRole === 'owner') {
            chatColl = await db.collection('chat').where('ownerID', '==', currentUser.uid).where('instructorID', '==', instructorID).get();
        }
        if (userRole === 'instructor') {
            const facility = await db.collection('facilities').doc(facilityID).get();
            const userIDs = facility.data().ownerIDs;
            chatColl = await db.collection('chat').where('instructorID', '==', currentUser.uid).where('ownerID', '==', userIDs[0]).get();
        }
        // new Date().toLocaleTimeString('en-gb', { hour: '2-digit', minute: '2-digit' })
        const chatID = chatColl.docs[0].id;
        const startTime = startDate.toLocaleTimeString('en-gb', { timeStyle: 'short' });
        const endTime = endDate.toLocaleTimeString('en-gb', { timeStyle: 'short' });
        const bookingToShow = (<div className='bookings-col-left'><ViewConfirmedBooking

                             chatID={chatID}
                             bookingID={booking.bookingID}
                             name={booking.name}
                             startTime={startTime}
                             endTime={endTime}
                             imageLink={booking.imageLink}
                             end={booking.start}
                             start={booking.end}
                             bookingPeriod={bookingPeriod}
                             revenue={total}
                             forteFee={total * 0.1}
                             netRevenue={total * 0.9}
                             goBack={() => setShowBookingDetail(false)}

        /></div>)
        setCurrentBooking(bookingToShow);
    }

    // Show confirmed session when clicked on
    async function showComfimedSessionInfo(session) {
        const {total, athleteID, sessionID, name, imageLink, start, end, venue, dateString, type } = session;
        const startTime = start.toLocaleTimeString('en-gb', { hour: '2-digit', minute: '2-digit' });
        const endTime = end.toLocaleTimeString('en-gb', { hour: '2-digit', minute: '2-digit' });

        // ChatID
        let chatID = '';
        if (type === 'session') {
            const chatColl = await db.collection('chat')
            .where('instructorID', '==', currentUser.uid)
            .where('athleteID', '==', athleteID)
            .get();
         chatID = chatColl.docs[0].id;
        }


        const bookingToShow = (<div className='bookings-col-left'><ViewConfirmedBooking
                            chatID={chatID}
                            bookingID={sessionID}
                            name={name}
                            startTime={startTime}
                            endTime={endTime}
                            imageLink={imageLink}
                            end={start}
                            start={end}
                            bookingPeriod={dateString}
                            revenue={total}
                            forteFee={total * 0.1}
                            netRevenue={total * 0.9}
                            goBack={() => setShowBookingDetail(false)}
                            type={type}
                            ahleteID={athleteID}

        /></div>)
        setCurrentBooking(bookingToShow);
        setShowBookingDetail(true);

    }

    // Load instructor classes
    async function loadInstructorClasses(uid) {
        const classesColl = await db.collection('classes').where('instructorID', '==', uid).get();
        const classArray = [];

        for (let i = 0; i < classesColl.docs.length; i++) {
            const classData = classesColl.docs[i].data();
            const classID = classesColl.docs[i].id;
            const {className, cost, description, difficulty, duration, imageLinks, limit,
                onlineURL, position, repeat, reviewAmount, reviewAverage, sessionArray, sessionType,
                locationString, locationSuburb, subCategories, locationName

            } = classData;
            const dateCreated = new Date(classData.dateCreated.toDate())


            classArray.push({
                className, cost, description, difficulty, duration, imageLinks, limit, classID,
                onlineURL, position, repeat, reviewAmount, reviewAverage, sessionArray, sessionType,
                locationString, locationSuburb, dateCreated, subCategories, locationName,

            })
        }
        setClasses(classArray);
    }

    // Load instructor sessions
    async function loadInstructorSessions(uid) {
        const sessionDocs = await db.collection('sessions')
            .where('instructorID', '==', uid)
            .get();

        const sessions = sessionDocs.docs;
        const sessionArray = [];
        const requestArray = [];
        const groupArray = [];

        for (let i = 0; i < sessions.length; i++) {
            const session = sessions[i].data();
            const sessionID = sessions[i].id;
            const {athleteID, location, duration, cost, isRequestSession, requestStatus, status, isGroupSession, classID} = session;
            const sessionLength = duration / 60;
            if (status === 'cancelledByAthlete' || status === 'cancelledByInstructor') {
                continue;
            }
            const sessionDate = new Date(session.dateTimeStamp.toDate());
            const start = new Date(sessionDate);
            const end  = new Date(sessionDate);
            end.setHours(end.getHours() + sessionLength);
            const dateString = sessionDate.toLocaleDateString('en-us', { dateStyle: 'long' });

            if (sessionDate > now) {
                if (!isGroupSession) {
                    setSessionsUpcoming(true)
                } else {
                    setClassesUpcoming(true)
                }
            } else {
                if (!isGroupSession) {
                    setSessionsPast(true)
                } else {
                    setClassesPast(true)
                }
            }

            let athleteName = '';
            let imageLink = ''

            let chatID = '';


            // Athlete
            if (!isGroupSession) {
                const athleteDoc = await db.collection('users').doc(athleteID).get();
                const athlete = athleteDoc.data();
                imageLink = athlete.imageLink;
                athleteName = `${athlete.firstName} ${athlete.lastName}`;

                // Chat
                const chatColl = await db.collection('chat')
                    .where('athleteID', '==', athleteID)
                    .where('instructorID', '==', uid)
                    .get();
                chatID = chatColl.docs[0].id;

            }

            let className = '';
            if (isGroupSession) {
                const classDoc = await db.collection('classes').doc(classID).get();
                const classData = classDoc.data();
                className = classData.className;
                imageLink = classData.imageLinks;
            }


            if (isRequestSession) {

                if (requestStatus === 'requestedByAthlete') {

                requestArray.push({
                    sessionID,
                    athleteID,
                    dateString,
                    imageLink,
                    name: athleteName,
                    type: 'request',
                    start,
                    end,
                    total: cost,
                    venue: location,
                    requestStatus,
                    chatID
                })
            } else {
                sessionArray.push({
                    sessionID,
                    athleteID,
                    dateString,
                    imageLink,
                    name: athleteName,
                    type: 'session',
                    start,
                    end,
                    total: cost,
                    venue: location,
                    chatID,
                })
            }
            } else if (isGroupSession) {

                groupArray.push({
                    sessionID,
                    athleteID,
                    dateString,
                    imageLink,
                    name: className,
                    type: 'group',
                    start,
                    end,
                    total: cost,
                    venue: location,
                })
            }
            else {
                sessionArray.push({
                    sessionID,
                    athleteID,
                    dateString,
                    imageLink,
                    name: athleteName,
                    type: 'session',
                    start,
                    end,
                    total: cost,
                    venue: location,
                    chatID,
                })
            }

        }

        setSessions(sessionArray)
        setRequestedSessions(requestArray)
        setGroupSessions(groupArray)
    }

    // Display session reschedule modal for instrucor
    function showRescheduleModal(session) {
        setRescheduleModal(<RescheduleSessionModal uid={currentUser.uid} sessionID={session.sessionID} athleteName={session.name} closeModal={() => setShowReschedule(false)} sessionDate={session.start} />)
        setShowReschedule(true)
    }

    return (
        <LoadingOverlay
        active={loading}
        spinner
        >
        <div className="bookings">
            {showAddClass && <AddClassModal closeModal={() => setShowAddClass(false)} uid={currentUser.uid} edit={false} classDetails={{}} />}
            {showReschedule && rescheduleModal}
            {showBookingDetail ?
            currentBooking
            :
            <>
            <div className="bookings-col-left">
            {/* {userRole === 'owner' && <h3>Pending Bookings (awaiting your confirmation)</h3>} */}
            {/* {userRole === 'owner' && */}
            <div className="bookings-header-row">
            {userRole === 'instructor' && <SelectSearch
                  
                  value={type}
                  closeOnSelect={true}
                  search={false}
                  placeholder={'Client Sessions'}
                  options={options}
                  onChange={(value) => {
                    setType(value)
                  }}
                  />}
            <div className="selected-client-menu">
                        {type === 'classes' && <button onClick={() => setSelectedMenu('pending')} className={selectedMenu === 'pending' ? 'active-client-menu-button left-button' : 'left-button'}>Overview</button>}
                        {type !== 'classes' && <button onClick={() => setSelectedMenu('pending')} className={selectedMenu === 'pending' ? 'active-client-menu-button left-button' : 'left-button'}>Pending</button>}
                        <button onClick={() => setSelectedMenu('upcoming')} className={selectedMenu === 'upcoming' && 'active-client-menu-button'} >Upcoming</button>
                        <button onClick={() => setSelectedMenu('past')} className={selectedMenu === 'past' ? 'active-client-menu-button right-button' : 'right-button'}>Past</button>
                    </div>

                {userRole === 'owner' && <>
                  <div className="bookings-months">
                  <SelectSearch
                  
                  value={selYear}
                  closeOnSelect={true}
                  search={false}
                  placeholder={'Select Year'}
                  options={years}
                  onChange={(value) => {
                      console.log(value)
                    setSelYear(value)
                  }}
                  />
                <div className="right-sel-search">
                <SelectSearch
                //   className='ml-20'
                  disabled={selYear === 'all'}
                  value={selMonth}
                  closeOnSelect={true}
                  search={false}
                  placeholder={'Select Month'}
                  options={months}
                  onChange={(value) => {
                    setSelMonth(value)
                  }}
                  />                    
                </div>

                  </div>
                </>}

            </div>

            {/* } */}

                  {/* Classes */}
                  {(selectedMenu === 'pending' && type === 'classes') &&
                      <div className="classes-overview mt-20 mb-20">
                          <div className="add-classes-container">
                          <div onClick={() => setShowAddClass(true)} className="new-client new-class">
                <AddBoxIcon className='add-client-icon' />
                <p>Add</p>
            </div>
                          </div>
                        {classes.map(item => <ClassItem classDetails={item} />)}
                          {classes.length === 0 && <h1>No Classes Added Yet</h1>}
                      </div>
                  }

                  {/* Pending */}
                    {selectedMenu === 'pending' && <div className="pending-bookings">
                    {/* Bookings */}
                    {(pendingBookings.length > 0 && type === 'bookings') && pendingBookings.map((booking, index) => {
                        return                     <PendingBookingItem
                        paymentStatus={booking.paymentStatus ?? ''}
                        reloadPage={() => setReloadPage(current => current + 1)}
                        calendarID={booking.calendarID}
                        bookingID={booking.bookingID}
                        facilityID={booking.facilityID}
                        end={booking.end}
                        start={booking.start}
                        instructorID={currentUser.uid}
                        endDate={booking.endDate}
                        startDate={booking.startDate}
                        name={booking.name}
                        imageLink={booking.imageLink}
                        totalPrice={booking.total}
                        type={'Pending Confirmation'}
                        venue={booking.venue}
                        viewBooking={() => showPendingBookingInfo(booking)}
                        role={userRole}
                        />
                    }) }
                    {(requestedSessions.length === 0 && type === 'sessions' ) && <h1>No pending bookings</h1>}
                    {/* Sessions */}
                    {(requestedSessions.length > 0 && type === 'sessions') && requestedSessions.map((booking, index) => {

                        if (selYear !== 'all') {
                            const sessionYear = booking.start.getFullYear()

                            if (selYear !== sessionYear) {
                                return null
                            }

                            if (selMonth !== 'all') {
                                const sessionMonth = booking.start.getMonth()
                                if (sessionMonth !== selMonth) {
                                    return null
                                }
                            }
                        }

                        // console.log(booking)
                        return                     <PendingBookingItem
                        
                        reloadPage={() => setReloadPage(current => current + 1)}
                        calendarID={booking.calendarID}
                        bookingID={booking.sessionID}
                        facilityID={booking.facilityID}
                        end={booking.end}
                        start={booking.start}
                        instructorID={booking.instructorID}
                        endDate={booking.endDate}
                        startDate={booking.startDate}
                        name={booking.name}
                        imageLink={booking.imageLink}
                        totalPrice={booking.total}
                        type={booking.rentalType}
                        venue={booking.venue}
                        viewBooking={() => showPendingBookingInfo(booking)}
                        role={userRole}
                        requestStatus={booking.requestStatus}
                        chatID={booking.chatID}
                        />
                    }) }
                    {(pendingBookings.length === 0 && type === 'bookings' ) && <h1>No pending bookings</h1>}

                </div>}
                {/* <h3 className='confirmed-bookings-header'>Confirmed Bookings</h3> */}


                {/* Upcoming */}
                {selectedMenu === 'upcoming' && <div className="confirmed-bookings mt-20 mb-20">
                    {/* Bookings */}
                    {(upcoming && type === 'bookings') && confirmedBookings.map((booking, index) => {

                        if (booking.start > now) {

                            if (selYear !== 'all') {
                                const sessionYear = booking.start.getFullYear()
    
                                if (selYear !== sessionYear) {
                                    return null
                                }
    
                                if (selMonth !== 'all') {
                                    const sessionMonth = booking.start.getMonth()
                                    if (sessionMonth !== selMonth) {
                                        return null
                                    }
                                }
                            }
                            
                            // console.log(booking.end)
                        return <ConfirmedBookingItem
                        rate={booking.rate}
                        endObj={booking.end}
                        startObj={booking.start}
                        calendarID={booking.calendarID}
                        chatID={booking.chatID}
                        bookingID={booking.bookingID}
                        facilityID={booking.facilityID}
                        instructorID={booking.instructorID}
                        endDate={booking.endDate}
                        startDate={booking.startDate}
                        name={booking.name}
                        imageLink={booking.imageLink}
                        totalPrice={booking.total}
                        type="Short Term Rental"
                        venue={booking.venue}
                        showBooking={() => showConfirmedBookingInfo(booking)}
                        role={userRole}
                        />} else {
                            return null
                        }

                    })}
                    {(!upcoming && type === 'bookings') && <h1>No upcoming bookings</h1>}
                    {/* Sessions */}
                    {(sessionsUpcoming && type === 'sessions') && sessions.map((booking, index) => {

                        if (booking.start > now) {
                        return <ConfirmedBookingItem
                        rate={booking.rate}
                        endObj={booking.end}
                        startObj={booking.start}
                        calendarID={booking.calendarID}
                        chatID={booking.chatID}
                        bookingID={booking.sessionID}
                        facilityID={booking.facilityID}
                        instructorID={booking.instructorID}
                        endDate={booking.endDate}
                        startDate={booking.startDate}
                        name={booking.name}
                        imageLink={booking.imageLink}
                        totalPrice={booking.total}
                        type="session"
                        venue={booking.venue}
                        showBooking={() => showComfimedSessionInfo(booking)}
                        openReschedule={() => showRescheduleModal(booking)}
                        role={userRole}
                        />} else {
                            return null
                        }

                    })}
                    {(!sessionsUpcoming && type === 'sessions') && <h1>No upcoming sessions</h1>}
                    {/* Classes */}
                    {(classesUpcoming && type === 'classes') && groupSessions.map((booking, index) => {
                        if (booking.start > now) {
                        return <ConfirmedBookingItem
                        rate={booking.rate}
                        endObj={booking.end}
                        startObj={booking.start}
                        calendarID={booking.calendarID}
                        chatID={booking.chatID}
                        bookingID={booking.sessionID}
                        facilityID={booking.facilityID}
                        instructorID={booking.instructorID}
                        endDate={booking.endDate}
                        startDate={booking.startDate}
                        name={booking.name}
                        imageLink={booking.imageLink}
                        totalPrice={booking.total}
                        type="class"
                        venue={booking.venue}
                        showBooking={() => showComfimedSessionInfo(booking)}
                        role={userRole}
                        uid={currentUser.uid}
                        />} else {
                            return null
                        }

                    })}
                    {(!classesUpcoming && type === 'classes') && <h1>No upcoming group sessions</h1>}

                </div>}

                {/* Past bookings */}
                {selectedMenu === 'past' && <div className="confirmed-bookings mt-20 mb-20">
                    {/* Bookings */}
                    {(past && type === 'bookings') && confirmedBookings.map((booking, index) => {
                        console.log('here')
                        if (booking.start < now) {

                        if (selYear !== 'all') {
                            const sessionYear = booking.start.getFullYear()

                            if (selYear !== sessionYear) {
                                return null
                            }

                            if (selMonth !== 'all') {
                                const sessionMonth = booking.start.getMonth()
                                if (sessionMonth !== selMonth) {
                                    return null
                                }
                            }
                        }
                    

                        return <ConfirmedBookingItem
                        rate={booking.rate}
                        endObj={booking.end}
                        reviewID={booking.reviewID}
                        startObj={booking.start}
                        calendarID={booking.calendarID}
                        chatID={booking.chatID}
                        bookingID={booking.bookingID}
                        facilityID={booking.facilityID}
                        instructorID={booking.instructorID}
                        endDate={booking.endDate}
                        startDate={booking.startDate}
                        name={booking.name}
                        imageLink={booking.imageLink}
                        totalPrice={booking.total}
                        type="Short Term Rental"
                        venue={booking.venue}
                        showBooking={() => showConfirmedBookingInfo(booking)}
                        role={userRole}
                        />} else {
                            return null
                        }

                    })}
                    {(!past && type === 'bookings') && <h1>No past bookings</h1>}
                    {/* Sessions */}
                    {(sessionsPast && type === 'sessions') && sessions.map((booking, index) => {
                        if (booking.start < now) {

                        return <ConfirmedBookingItem
                        rate={booking.rate}
                        endObj={booking.end}
                        startObj={booking.start}
                        calendarID={booking.calendarID}
                        chatID={booking.chatID}
                        bookingID={booking.bookingID}
                        facilityID={booking.facilityID}
                        instructorID={booking.instructorID}
                        endDate={booking.endDate}
                        startDate={booking.startDate}
                        name={booking.name}
                        imageLink={booking.imageLink}
                        totalPrice={booking.total}
                        type="Short Term Rental"
                        venue={booking.venue}
                        showBooking={() => showComfimedSessionInfo(booking)}
                        role={userRole}
                        />} else {
                            return null
                        }

                    })}
                    {(!sessionsPast && type === 'sessions') && <h1>No past sessions</h1>}

                    {/* Classes */}
                    {(classesPast && type === 'classes') && groupSessions.map((booking, index) => {

                        if (booking.start < now) {

                        return <ConfirmedBookingItem
                        rate={booking.rate}
                        endObj={booking.end}
                        startObj={booking.start}
                        calendarID={booking.calendarID}
                        chatID={booking.chatID}
                        bookingID={booking.bookingID}
                        facilityID={booking.facilityID}
                        instructorID={booking.instructorID}
                        endDate={booking.endDate}
                        startDate={booking.startDate}
                        name={booking.name}
                        imageLink={booking.imageLink}
                        totalPrice={booking.total}
                        type="Short Term Rental"
                        venue={booking.venue}
                        showBooking={() => showComfimedSessionInfo(booking)}
                        role={userRole}
                        />} else {
                            return null
                        }

                    })}
                    {(!classesPast && type === 'classes') && <h1>No past classes</h1>}
                </div>}

            </div>
            <div className="bookings-col-right">
                <ProfileSummary  />
            </div>
            </>
            }
            {/* <div className="bookings-col-right">
                <ProfileSummary />
            </div> */}
        </div>
        </LoadingOverlay>

    )
}

export default Bookings
