import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import './AnalyticsGraphs.css'

import { db } from '../../../firebase'
import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';


function AnalyticsGraphs({ uid, userRole }) {

    const options = {
        scales: {
            x: {
              grid: {
                display: false
              }
            },
            y: {
              grid: {
                display: false
              }
            }
          },
    }

    const ownerOptions = [
        {
            name: 'Revenue',
            value: 'revenue',
        },
        {
            name: 'Bookings',
            value: 'bookings',
        },
        {
            name: 'Clients',
            value: 'clients',
        },
        {
            name: 'Reviews',
            value: 'reviews',
        },
        {
            name: 'Occupancy',
            value: 'occupancy',
        },

    ]

    const instructorOptions = [
        {
            name: 'Revenue',
            value: 'revenue',
        },
        {
            name: 'Bookings',
            value: 'bookings',
        },
        {
            name: 'Clients',
            value: 'clients',
        },
        {
            name: 'Reviews',
            value: 'reviews',
        },
    ];

    const [revenueData, setRevenueData] = useState({});
    const [bookingData, setBookingData] = useState({})
    const [clientData, setClientData] = useState({})
    const [reviewData, setReviewData] = useState({})
    const [occupancyData, setOccupancyData] = useState({})
    const [selectedGraph, setSelectedGraph] = useState('clients')

    useEffect(() => {
        if (userRole === 'owner') {
            loadData()
        }
        if (userRole === 'instructor') {
            loadInstructorData()
        }
    }, [])

    const data = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        datasets: [
          {
            label: "First dataset",
            data: [33, 53, 85, 41, 44, 65],
            fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)"
          },
          {
            label: "Second dataset",
            data: [33, 25, 35, 51, 54, 76],
            fill: false,
            borderColor: "#742774"
          }
        ]
      };

    async function loadData() {
        console.log('loading')
        const days = [];
        const thisMonthRevenueArray = [];
        const lastMonthRevenueArray = [];
        const thisMonthBookingArray = [];
        const lastMonthBookingArray = [];
        const thisMonthClientsArray = [];
        const lastMonthClientsArray = [];

        const thisMonthClientIDsArray = []
        const lastMonthClientIDsArray = []

        const thisMonthReviewsArray = []
        const lastMonthReviewsArray = []

        const thisMonthHoursAvailableArray = [];
        const lastMonthHoursAvailableArray = [];
        const thisMonthHoursBookedArray = [];
        const lastMonthHoursBookedArray = [];

        const thisMonthOccupancyArray = [];
        const lastMonthOccupancyArray = [];


        // Create data arrays
        for (let i = 0; i < 30; i++) {
            days.push(`${i + 1}`)
            thisMonthRevenueArray.push(0)
            lastMonthRevenueArray.push(0)
            thisMonthBookingArray.push(0)
            lastMonthBookingArray.push(0)
            thisMonthClientsArray.push(0)
            lastMonthClientsArray.push(0)

            thisMonthClientIDsArray.push([])
            lastMonthClientIDsArray.push([])
            thisMonthReviewsArray.push(0)
            lastMonthReviewsArray.push(0)

            thisMonthHoursAvailableArray.push(0)
            lastMonthHoursAvailableArray.push(0)
            thisMonthHoursBookedArray.push(0)
            lastMonthHoursBookedArray.push(0)

            thisMonthOccupancyArray.push(0)
            lastMonthOccupancyArray.push(0)
        }

        const firstOfThisMonth = new Date();
        firstOfThisMonth.setDate(1);
        firstOfThisMonth.setHours(0);

        const firstOfLastMonth = new Date();
        console.log(firstOfLastMonth.getMonth())
        firstOfLastMonth.setDate(1);

        const toSet = firstOfLastMonth.getMonth() - 1;
        firstOfLastMonth.setMonth(toSet);
        firstOfLastMonth.setHours(0);
        console.log(firstOfLastMonth)
        const userDoc = await db.collection('users').doc(uid).get();
        const user = userDoc.data();
        const {facilityIDs} = user;


        for (let i = 0; i < facilityIDs.length; i++) {
            const facilityID = facilityIDs[0]
            const thisMonthFacilityBookings = await db.collection('facilities').doc(facilityID).collection('calendarEvents')
            .where('type', '==', 'booked')
            .where('start', '>=', firstOfThisMonth)
            .get();
            const lastMonthFacilityBookings = await db.collection('facilities').doc(facilityID).collection('calendarEvents')
            .where('type', '==', 'booked')
            .where('start', '>=', firstOfLastMonth)
            .where('start', '<=', firstOfThisMonth)
            .get();
            const thisMonthFacilityReviews = await db.collection('facilityReviews')
            .where('facilityID', '==', facilityID)
            .where('dateTimeStamp', '>=', firstOfThisMonth)
            .get()
            .catch(err => console.log(err));
            const lastMonthFacilityReviews = await db.collection('facilityReviews')
            .where('facilityID', '==', facilityID)
            .where('dateTimeStamp', '<=', firstOfThisMonth)
            .where('dateTimeStamp', '>=', firstOfLastMonth)
            .get()
            .catch(err => console.log(err));
            const thisMonthAvailableSessions = await db.collection('facilities').doc(facilityID).collection('calendarEvents')
            .where('type', '==', 'available')
            .where('start', '>=', firstOfThisMonth)
            .get();
            const lastMonthAvailableSessions = await db.collection('facilities').doc(facilityID).collection('calendarEvents')
            .where('type', '==', 'available')
            .where('start', '>=', firstOfLastMonth)
            .where('start', '<=', firstOfThisMonth)
            .get();

            for (let j = 0; j < thisMonthAvailableSessions.docs.length; j++) {
                const booking = thisMonthAvailableSessions.docs[j].data();
                const start = new Date(booking.start.toDate());
                const end = new Date(booking.end.toDate());
                const duration = end.getHours() - start.getHours();
                const dom = start.getDate()
                thisMonthHoursAvailableArray[dom - 1] += duration;
            }
            for (let j = 0; j < lastMonthAvailableSessions.docs.length; j++) {
                const booking = lastMonthAvailableSessions.docs[j].data();
                const start = new Date(booking.start.toDate());
                const end = new Date(booking.end.toDate());
                const duration = end.getHours() - start.getHours();
                const dom = start.getDate()
                lastMonthHoursAvailableArray[dom - 1] += duration;
            }


            for (let j = 0; j < thisMonthFacilityBookings.docs.length; j++) {
                const booking = thisMonthFacilityBookings.docs[j].data();
                const date = new Date(booking.start.toDate());
                const dom = date.getDate();
                const {total, instructorID, rate} = booking;
                const duration = total / rate;

                // Bookings & Revenue
                thisMonthRevenueArray[dom - 1] += total;
                thisMonthBookingArray[dom - 1] += 1;

                // Clients
                if (!thisMonthClientIDsArray[dom - 1].includes(instructorID)) {
                    thisMonthClientIDsArray[dom - 1].push(instructorID);
                    thisMonthClientsArray[dom - 1] += 1;
                }

                // Occupancy
                thisMonthHoursBookedArray[dom - 1] += duration;
            }
            for (let j = 0; j < lastMonthFacilityBookings.docs.length; j++) {
                const booking = lastMonthFacilityBookings.docs[j].data();
                const date = new Date(booking.start.toDate());
                const dom = date.getDate();
                const {total, instructorID, rate} = booking;
                const duration = total / rate;

                // Bookings & Revenue
                lastMonthRevenueArray[dom - 1] += total;
                lastMonthBookingArray[dom - 1] += 1;

                // Clients
                if (!lastMonthClientIDsArray[dom - 1].includes(instructorID)) {
                    lastMonthClientIDsArray[dom - 1].push(instructorID);
                    lastMonthClientsArray[dom - 1] += 1;
                }

                // Occupancy
                lastMonthHoursBookedArray[dom - 1] += duration;

            }
            // console.log(thisMonthHoursAvailableArray)
            // console.log(thisMonthHoursBookedArray)
            // console.log(lastMonthHoursAvailableArray)
            // console.log(lastMonthHoursBookedArray)
            for (let j = 0; j < thisMonthFacilityReviews.docs.length; j++) {
                const review = thisMonthFacilityReviews.docs[i].data();
                const date = new Date(review.dateTimeStamp.toDate());
                const dom = date.getDate();

                thisMonthReviewsArray[dom - 1] += 1;
            }
            for (let j = 0; j < lastMonthFacilityReviews.docs.length; j++) {
                const review = lastMonthFacilityReviews.docs[i].data();
                const date = new Date(review.dateTimeStamp.toDate());
                const dom = date.getDate();

                lastMonthReviewsArray[dom - 1] += 1;
            }
        }
        // Occupancy
        for (let i = 0; i < thisMonthOccupancyArray.length; i++) {
            if (thisMonthHoursAvailableArray[i] !== 0) {
                thisMonthOccupancyArray[i] = (thisMonthHoursBookedArray[i] / thisMonthHoursAvailableArray[i]) * 100;
            }
            if (lastMonthHoursAvailableArray[i] !== 0) {
                lastMonthOccupancyArray[i] = (lastMonthHoursBookedArray[i] / lastMonthHoursAvailableArray[i]) * 100;
            }
        }


        // Revenue Graph
        const revData = {

            labels: days,
            datasets: [{
                label: `Revenue (${firstOfThisMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: thisMonthRevenueArray,
                fill: false,
                borderColor: '#D94A4A',
                pointRadius: 0,

            },
             {
                label: `Revenue (${firstOfLastMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: lastMonthRevenueArray,
                fill: false,
                borderColor: '#033d73',
                pointRadius: 0,
            }
            ],
        }
        setRevenueData(revData)

        // Bookings Graph
        const bookData = {
            labels: days,
            datasets: [{
                label: `Bookings (${firstOfThisMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: thisMonthBookingArray,
                fill: false,
                borderColor: '#D94A4A',
                pointRadius: 0,

            },
             {
                label: `Bookings (${firstOfLastMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: lastMonthBookingArray,
                fill: false,
                borderColor: '#033d73',
                pointRadius: 0,

            }
            ],
        }

        setBookingData(bookData);

        const data = {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
            datasets: [
              {
                label: "First dataset",
                data: [33, 53, 85, 41, 44, 65],
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
              },
              {
                label: "Second dataset",
                data: [33, 25, 35, 51, 54, 76],
                fill: false,
                borderColor: "#742774"
              }
            ]
          };
          console.log(days)
        // Clients Graph
        const clientData = {
            labels: days,
            datasets: [
                {
                label: `Clients (${firstOfThisMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: thisMonthClientsArray,
                fill: false,
                borderColor: '#D94A4A',
                pointRadius: 0,
            },
             {
                label: `Clients (${firstOfLastMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: lastMonthClientsArray,
                fill: false,
                borderColor: '#033d73',
                pointRadius: 0,

            }
            ],
        }

        setClientData(clientData);

        // Reviews Graph
        const reviewsData = {
            labels: days,
            datasets: [{
                label: `Reviews (${firstOfThisMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: thisMonthReviewsArray,
                fill: false,
                borderColor: '#D94A4A',
                pointRadius: 0,

            },
             {
                label: `Reviews (${firstOfLastMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: lastMonthReviewsArray,
                fill: false,
                borderColor: '#033d73',
                pointRadius: 0,

            }
            ],
        }

        setReviewData(reviewsData);

        // Occupancy Graph
        const occData = {
            labels: days,
            datasets: [{
                label: `Occupancy (${firstOfThisMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: thisMonthOccupancyArray,
                fill: false,
                borderColor: '#D94A4A',
                pointRadius: 0,

            },
             {
                label: `Occupancy (${firstOfLastMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: lastMonthOccupancyArray,
                fill: false,
                borderColor: '#033d73',
                pointRadius: 0,

            }
            ],
        }
        console.log(clientData)

        setOccupancyData(occData);




    }

    async function loadInstructorData() {
        console.log('hiiiiii')
        const days = [];
        const thisMonthRevenueArray = [];
        const lastMonthRevenueArray = [];
        const thisMonthBookingArray = [];
        const lastMonthBookingArray = [];
        const thisMonthClientsArray = [];
        const lastMonthClientsArray = [];

        const thisMonthClientIDsArray = []
        const lastMonthClientIDsArray = []

        const thisMonthReviewsArray = []
        const lastMonthReviewsArray = []

                // Create data arrays
        for (let i = 0; i < 31; i++) {
            days.push(`${i + 1}`)
            thisMonthRevenueArray.push(0)
            lastMonthRevenueArray.push(0)
            thisMonthBookingArray.push(0)
            lastMonthBookingArray.push(0)
            thisMonthClientsArray.push(0)
            lastMonthClientsArray.push(0)

            thisMonthClientIDsArray.push([])
            lastMonthClientIDsArray.push([])
            thisMonthReviewsArray.push(0)
            lastMonthReviewsArray.push(0)
        }

        const firstOfThisMonth = new Date();
        firstOfThisMonth.setDate(1);
        firstOfThisMonth.setHours(0);

        const firstOfLastMonth = new Date();
        firstOfLastMonth.setDate(1);
        firstOfLastMonth.setMonth(firstOfLastMonth.getMonth() - 1);
        firstOfLastMonth.setHours(0);

        const sessionsLastMonth = await db.collection('sessions')
            .where('instructorID', '==', uid)
            .where('status', '==', 'completed')
            .where('dateTimeStamp', '>=', firstOfLastMonth)
            .where('dateTimeStamp', '<=', firstOfThisMonth)
            .get()
            .catch(err => console.log(err));
        const sessionsThisMonth = await db.collection('sessions')
            .where('instructorID', '==', uid)
            .where('status', '==', 'completed')
            .where('dateTimeStamp', '>=', firstOfThisMonth)
            .get()
            .catch(err => console.log(err));

        for (let j = 0; j < sessionsThisMonth.docs.length; j++) {
            const booking = sessionsThisMonth.docs[j].data();
            const date = new Date(booking.dateTimeStamp.toDate());
            const dom = date.getDate();
            const {cost, athleteID} = booking;

            // Bookings & Revenue
            thisMonthRevenueArray[dom - 1] += cost;
            thisMonthBookingArray[dom - 1] += 1;

            // Clients
            if (!thisMonthClientIDsArray[dom - 1].includes(athleteID)) {
                thisMonthClientIDsArray[dom - 1].push(athleteID);
                thisMonthClientsArray[dom - 1] += 1;
            }
        }
        for (let j = 0; j < sessionsLastMonth.docs.length; j++) {
            const booking = sessionsLastMonth.docs[j].data();
            const date = new Date(booking.dateTimeStamp.toDate());
            const dom = date.getDate();
            const {cost, athleteID} = booking;

            // Bookings & Revenue
            lastMonthRevenueArray[dom - 1] += cost;
            lastMonthBookingArray[dom - 1] += 1;

            // Clients
            if (!lastMonthClientIDsArray[dom - 1].includes(athleteID)) {
                lastMonthClientIDsArray[dom - 1].push(athleteID);
                lastMonthClientsArray[dom - 1] += 1;
            }
        }

        // Revenue Graph
        const revData = {
            labels: days,
            datasets: [{
                label: `Revenue (${firstOfThisMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: thisMonthRevenueArray,
                fill: false,
                borderColor: '#D94A4A',
                pointRadius: 0,

            },
                {
                label: `Revenue (${firstOfLastMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: lastMonthRevenueArray,
                fill: false,
                borderColor: '#033d73',
                pointRadius: 0,
                tension: 0.5,
            }
            ],
        }
        setRevenueData(revData)

        // Bookings Graph
        const bookData = {
            labels: days,
            datasets: [{
                label: `Bookings (${firstOfThisMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: thisMonthBookingArray,
                fill: false,
                borderColor: '#D94A4A',
                pointRadius: 0,

            },
                {
                label: `Bookings (${firstOfLastMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: lastMonthBookingArray,
                fill: false,
                borderColor: '#033d73',
                pointRadius: 0,
                tension: 0.5,
            }
            ],
        }

        setBookingData(bookData);

        // Clients Graph
        const clientData = {
            labels: days,
            datasets: [{
                label: `Clients (${firstOfThisMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: thisMonthClientsArray,
                fill: false,
                borderColor: '#D94A4A',
                pointRadius: 0,

            },
             {
                label: `Clients (${firstOfLastMonth.toLocaleDateString('en-gb', {month: 'long'})})`,
                data: lastMonthClientsArray,
                fill: false,
                borderColor: '#033d73',
                pointRadius: 0,

            }
            ],
        }

        setClientData(clientData);

    }
    




    return (
        <div className='analytics-graphs'>
            <div className="ag-header">
            <h1>Performance Graphs</h1>
            <SelectSearch
                    search={false}
                    closeOnSelect={true}
                    placeholder={`${selectedGraph}`}
                    options={userRole === 'owner' ? ownerOptions : instructorOptions}
                    onChange={(evt) => {
                        // console.log()
                        setSelectedGraph(evt);
                    }}
                    />
            </div>

            <div className="graph-container">
            {/* <Line title='Poop' data={data} /> */}

                {selectedGraph === 'revenue' && <Line options={options} title={'Monthly Revenue'} data={revenueData} />}
                {selectedGraph === 'bookings' && <Line options={options} title={'Monthly Bookings'} data={bookingData} />}
                {selectedGraph === 'clients' && <Line options={options} title={'Monthly Clients'} data={clientData} />}
                {selectedGraph === 'reviews' && <Line options={options} title={'Monthly Reviews'} data={reviewData} />}
                {selectedGraph === 'occupancy' && <Line options={options} title={'Monthly Occupancy'} data={occupancyData} />}
            </div>
        </div>
    )
}

export default AnalyticsGraphs
