import React, { useState, useRef } from 'react'
import './AddClassModal.css'

import {useDropzone} from 'react-dropzone';
import uuid from 'react-uuid'

import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import Geocode from "react-geocode";
import Geohash from 'latlon-geohash';
import { admin } from '../../../firebase';

import CloudUpload from '@mui/icons-material/CloudUpload';

import { db, storage } from '../../../firebase';

function AddClassModal({ uid, closeModal, classDetails, edit = false }) {

    const date = new Date().toISOString();

    Geocode.setApiKey("AIzaSyBNVt036Vwj3EMeNMVyTEh9JPqCuZ6xsi8");

    const [discipline, setDiscipline] = useState(classDetails.sessionType ?? '')
    const [difficulty, setDifficulty] = useState(classDetails.difficulty ?? '')
    const [location, setLocation] = useState(classDetails.locationString ?? '')
    const [locValue, setLocValue] = useState(classDetails.locationSuburb ?? '')
    const [geoHash, setGeoHash] = useState()
    const [geoPoint, setGeoPoint] = useState()
    const [image, setImage] = useState('');
    const [error, setError] = useState('')
    const [loading, setLoading] = useState();
    const [deleting, setDeleting] = useState(false)
    const [imageLink, setImageLink] = useState(classDetails.imageLinks ?? '')
    const [deleteDisabled, setDeleteDisabled] = useState(false)
    const [type, setType] = useState('')


    const placesRef = useRef();
    const titleRef = useRef();
    const workoutRef = useRef();
    const descriptionRef = useRef();
    const rateRef = useRef();
    const capRef = useRef()
    const linkRef = useRef()
    const deleteRef = useRef();

    const dateRef = useRef();
    const timeRef = useRef();

    const {getRootProps, getInputProps} = useDropzone({
        // accept: 'image/*',
        onDrop: acceptedFiles => {
            const newFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
              }));
              console.log(newFiles[0])
            setImage(newFiles[0]);

        }
      });


    const disciplines = [
        {
            name: 'Personal Training',
            value: 'Personal Training',
        },
        {
            name: 'Pilates',
            value: 'Pilates',
        },
        {
            name: 'Yoga',
            value: 'Yoga',
        },
        {
            name: 'Other',
            value: 'Other',
        },
    ]

    const difficulties = [
        {
            name: 'Beginner',
            value: 'Beginner',
        },
        {
            name: 'Intermediate',
            value: 'Intermediate',
        },
        {
            name: 'Difficult',
            value: 'Difficult',
        },
        {
            name: 'Professional',
            value: 'Professional',
        },
    ]

    const locations = [
        {
            name: 'At Facility',
            value: 'facility',
        },
        {
            name: 'Online',
            value: 'online',
        },
        {
            name: 'Outdoors',
            value: 'outdoors',
        },
    ]

    const options = [
        {
            name: 'Paid',
            value: 'paid',
        },
        {
            name: 'Free',
            value: 'free'
        }
    ]

    async function getPlaceCoords(address) {
        try {
            const response = await Geocode.fromAddress(address);
            const { lat, lng } = response.results[0].geometry.location;
            const newHash = Geohash.encode(lat, lng);
            const newPoint = new admin.firestore.GeoPoint(lat, lng);
            setGeoHash(newHash)
            setGeoPoint(newPoint);
            console.log(lat, lng);

        } catch (err) {
            console.log(err)
        }
    }

    function validateData() {

        setError('');

        // Name
        if (titleRef.current.value === '') {
            setError('Please enter a name for your class')
            return false
        }

        // Workout
        if (workoutRef.current.value === '') {
            setError('Please enter a workout type for your class')
            return false
        }

        // Discipline
        if (discipline === '') {
            setError('Please select a discipline for your class');
            return false
        }

        // Difficulty
        if (difficulty === '') {
            setError('Please select a discipline for your class');
            return false
        }

        // Description
        if (descriptionRef.current.value === '') {
            setError('Please enter a decsription for your class')
            return false
        }

        // Location
        if (location === '') {
            setError('Please select a location type for your facility')
            return false
        }

        if (location === 'online' && linkRef.current.value === '') {
            setError('Please enter a link for your class')
            return false;
        }

        if ((location === 'outdoors' || location === 'facility') && (locValue === null || locValue === undefined)) {
            setError('Please enter a location for your location')
            return false;
        }

        // Rate
        if (rateRef.current.value === '') {
            setError('Plase enter a rate for your class');
            return false;
        }

        // Capacity
        if (capRef.current.value === '') {
            setError('Please enter a capacity for your class');
            return false;
        }

        // Image
        if (image === '') {
            setError('Please upload an image for your class')
        }


    }

    async function saveClass() {

        if (validateData() === false) {
            return
        }

        setLoading(true)

        let classDoc;

        const cost = type === 'paid' ? rateRef.current.value : 0;

        if (location === 'online') {
            classDoc = await db.collection('classes').add({
                className: titleRef.current.value,
                cost,
                dateCreated: new Date(),
                description: descriptionRef.current.value,
                difficulty,
                duration: 60,
                imageLinks: '',
                instructorID: uid,
                limit: capRef.current.value,
                repeat: false,
                reviewAmount: 0,
                reviewAverage: 0,
                sessionArray: [],
                sessionType: discipline,
                subCategories: workoutRef.current.value,
                locationString: location,
                onlineURL: linkRef.current.value,
            })
        } else {
            classDoc = await db.collection('classes').add({
                className: titleRef.current.value,
                cost,
                dateCreated: new Date(),
                description: descriptionRef.current.value,
                difficulty,
                duration: 60,
                imageLinks: '',
                instructorID: uid,
                limit: capRef.current.value,
                position: {
                    geoHash,
                    geopoint: geoPoint,
                },
                repeat: false,
                reviewAmount: 0,
                reviewAverage: 0,
                sessionArray: [],
                sessionType: discipline,
                subCategories: workoutRef.current.value,                
                locationString: location,
                locationSuburb: locValue.label,
                locationName: locValue.value.terms[0].value,
            })
        }
    
    // If session is scheduled
    const sessionDate = dateRef.current.value;
    if (sessionDate !== '') {
        const sdObj = new Date(sessionDate);
        const startHours = parseInt(timeRef.current.value.slice(0,2));
        const startMinutes = parseInt(timeRef.current.value.slice(3,5));
        sdObj.setHours(startHours);
        sdObj.setMinutes(startMinutes)

        if (location === 'online') {
            await db.collection('sessions').add({
                athleteID: [],
                classID: classDoc.id,
                cost,
                dateCreated: new Date(),
                dateTimeStamp: sdObj,
                duration: 60,
                instructorID: uid,
                location: linkRef.current.value,
                isGroupSession: true,
                isOnlineSession: true,
                limit: capRef.current.value,
                onlineURL: linkRef.current.value,
                paidWithCredits: [],
                sessionSpeciality: workoutRef.current.value,
                sessionType: discipline,
                status: 'booked',
            });
        } else {
            await db.collection('sessions').add({
                athleteID: [],
                classID: classDoc.id,
                cost,
                dateCreated: new Date(),
                dateTimeStamp: sdObj,
                duration: 60,
                instructorID: uid,
                location: locValue.label,
                isGroupSession: true,
                isOnlineSession: false,
                limit: classDetails.limit,
                paidWithCredits: [],
                position: {
                    geoHash,
                    geopoint: geoPoint,
                },
                sessionSpeciality: workoutRef.current.value,
                sessionType: discipline,
                status: 'booked',
            });
        }


    }

    const storageRef = storage.ref().child(`facility-images/${uuid()}`);
     const task = storageRef.put(image);
     task.on('state changed',
     (snapshot) => {
     },
     (err) => {
      return setError('Could not upload, trying again');
     },
     () => {
      task.snapshot.ref.getDownloadURL().then(async (url) => {
        console.log(url);
        classDoc.ref.update({
            imageLinks: url,
        });
        setLoading(false);
      });

     },
     );



    }

    async function deleteClass() {
        try {
            await db.collection('classes').doc(classDetails.classID).delete();
            return closeModal();
        } catch(err) {
            return setError('Could not delete your class, please try again')
        }
    }

    return (
        <div className='add-class-modal' onClick={(e) => {if (e.target.className === 'add-class-modal') {closeModal()}}}>
            <div className="add-class-modal-content scale-in-center">

                {edit ? <h2>Edit Class</h2> : <h2>Add Class</h2>}
                <div className="add-class-container">
                    <h5>General</h5>
                    <div className="add-class-row bs-input">
                        <div className="add-class-item">
                            <input placeholder='Class Title' type='text' defaultValue={classDetails.className ?? ''} />
                        </div>
                        <div className="add-class-item item-right">
                        <input placeholder='Workout Type eg. HIIT' type='text' defaultValue={classDetails.subCategories} />

                        </div>
                    </div>
                    <div className="add-class-row bs-input">
                        <div className="add-class-item">
                            <SelectSearch
                                value={discipline}
                                closeOnSelect={true}
                                search={false}
                                placeholder={'Select Discipline'}
                                options={disciplines}
                                onChange={(value) => {
                                    setDiscipline(value)
                                }}
                            />
                        </div>
                    {/* </div> */}
                    {/* <div className="add-class-row"> */}
                        <div className="add-class-item item-right">
                            <SelectSearch
                            value={difficulty}
                                closeOnSelect={true}
                                search={false}
                                placeholder={'Select Difficulty'}
                                options={difficulties}
                                onChange={(value) => {
                                    setDifficulty(value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="add-class-row">
                        <textarea defaultValue={classDetails.description} rows={5} placeholder='Description' />
                    </div>
                    <h5>Details</h5>
                    <div className="add-class-row">
                        <div className="add-class-item bs-input">
                            <SelectSearch
                                value={location}
                                closeOnSelect={true}
                                search={false}
                                placeholder={'Location Type'}
                                options={locations}
                                onChange={(value) => {
                                    setLocation(value)
                                }}
                            />
                        </div>
                        <div className="add-class-item item-right">
                            {location === 'online' ? <input ref={linkRef} placeholder='Enter link here...' /> :
                                                    <GooglePlacesAutocomplete

                                                    apiKey="AIzaSyBNVt036Vwj3EMeNMVyTEh9JPqCuZ6xsi8"
                                                    selectProps={{
                                                        placeholder: 'Search address...',
                                                        value: locValue,
                                                        onChange: (e) => {setLocValue(e); console.log(e); getPlaceCoords(e.label)},
                                                    }
                                                    }
                                                    ref={placesRef}
                                                    // placeholder='Location'
                                                    onLoadFailed={
                                                        (e) => console.log(e)
                                                    }

                        />
                            }
                        </div>
                    </div>
                    <h5>Event Type</h5>
                    <div className="add-class-row bs-input">
                        <div className="add-class-item">
                        <SelectSearch
                                value={type}
                                closeOnSelect={true}
                                search={false}
                                placeholder={'Event type'}
                                options={options}
                                onChange={(value) => {
                                    setType(value)
                                }}
                            />
                        </div>
                        <div className="add-class-item item-right">

                        </div>
                    </div>
                    <h5>Capacity & Price</h5>
                    <div className="add-class-row bs-input">
                        <div className="add-class-item">
                        <input defaultValue={classDetails.limit} placeholder='Capacity' ref={capRef} type='number' min={0} step={1} max={15} />                                    
                        </div>
                                <div className="add-class-item item-right">
                                {type === 'paid' && <input defaultValue={classDetails.cost} name='Price' placeholder='Price' ref={rateRef} type='number' min={0} step={20} /> }                           

                                </div>
                    </div>
                    {!edit && <>
                    <h5>Schedule the first session</h5>
                    <div className="add-class-row bs-input">
                        <div className="add-class-item">
                        <input ref={dateRef} type='date' min={date.slice(0, date.indexOf('T'))} />
                        </div>
                        <div className="add-class-item item-right">
                        <input ref={timeRef}  type='time' />
                        </div>
                    </div>
                    </>}
                    <h5>Image</h5>
                    <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <div className='dz-upload-container'>
                                    {/* No image */}
                                    {(image === '' && imageLink === '') && <>
                                    <CloudUpload />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                    </> }
                                    {imageLink !== '' && image === '' && <img alt={classDetails.className} src={imageLink} />}

                                    {image !== '' && <>
                                        <img alt={image.name} src={image.preview} />
                                        <p>{image.name}</p>

                                    </> }

                                </div>
                    </div>
                    {error !== '' && <div className='error-message-row'><p>{error}</p></div>}
                    <div className="button-row add-class-buttons">
                        {edit && <button onClick={() => setDeleting(true)} className="cancel-button">Delete</button>}
                        <button disabled={loading} onClick={saveClass} className="picton-button confirm-button">Save</button>
                    </div>
                    {deleting && <div className="delete-container button-row">
                        <input onKeyUp={(e) => {
                            if (e.target.value === 'DELETE') {
                                setDeleteDisabled(false)
                            } else {
                                setDeleteDisabled(true)
                            }
                        }} type='text' placeholder='Type DELETE to confirm' ref={deleteRef} />
                        <button disabled={deleteDisabled} onClick={deleteClass} className="confirm-delete-button">Confirm</button>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default AddClassModal
