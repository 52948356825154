import React, { useEffect, useState, useRef } from 'react'
import './PayBookingModal.css'
import '../../Calendar/InstructorBookingRequestModal.css'

import { db, admin } from '../../../firebase'

import Switch from 'react-ios-switch';
import LoadingOverlay from 'react-loading-overlay';

function PayBookingModal({ hp = false, closeModal, bookingID, start, end, facilityID, facilityName, total, instructorID, reloadPage = () => {}, }) {

    const [loading, setLoading] = useState(true)
    const [startPayment, setStartPayment] = useState(false)
    const [paymentType, setPaymenType] = useState('card')
    const [errorMessage, setErrorMessage] = useState('')

    const [finished, setFinished] = useState(false)

    const requestPayment = admin.functions().httpsCallable('requestYocoPayment');
    const testPayment = admin.functions().httpsCallable('yocoTestPayment');

    const yocoRef = useRef();
    const formRef = useRef();
    const payButtonRef = useRef();

    const [storeInline, setStoreInline] = useState()
    const [credits, setCredits] = useState(0)
    const [selectedCredits, setSelectedCredits] = useState(false)



    useEffect(() => {
        loadInstructorCredits()

        const script = document.createElement('script');

        script.src = "https://js.yoco.com/sdk/v1/yoco-sdk-web.js";
        script.async = true;

        document.body.appendChild(script);
        // alert('loaded script')
        // return () => {
        //   document.body.removeChild(script);
        // }
      }, []);

      async function loadInstructorCredits() {
        const instructorDoc = await db.collection('users').doc(instructorID).get();
        const instructor = instructorDoc.data();
        const facilityCredits = instructor.facilityCredits ?? {};

        const remaining = facilityCredits[facilityID] ?? 0

        setCredits(remaining)
        setLoading(false)

    }

    async function saveCreditPayment() {
        setLoading(true)
        const userDoc = await db.collection('users').doc(instructorID).get();
        const user = userDoc.data();
        const { facilityCredits } = user;
        const newCredits = credits - total;
        facilityCredits[facilityID] = newCredits;

        const bookingDoc = await db.collection('bookings').doc(bookingID).get();
        const booking = bookingDoc.data();
        const { calendarID, instructorCalendarID } = booking;

        await db.collection('users').doc(instructorID).collection('calendarEvents').doc(instructorCalendarID).update({
            paymentStatus: 'paid',
        });
        await db.collection('facilities').doc(facilityID).collection('calendarEvents').doc(calendarID).update({
            paymentStatus: 'paid',
        });

        try {
            await db.collection('users').doc(instructorID).update({
                facilityCredits,
            })

            await db.collection('bookings').doc(bookingID).update({
                paymentStatus: 'paid',
                dateCreated: new Date(),
            })
            setFinished(true)
            setLoading(false)
        } catch (err) {
            setErrorMessage('Could not process your payment, please try again')
        }
    }

    async function saveCardPayment(chargeID) {

        const bookingDoc = await db.collection('bookings').doc(bookingID).get();
        const booking = bookingDoc.data();
        const { calendarID, instructorCalendarID } = booking;

        try {
            await db.collection('bookings').doc(bookingID).update({
                paymentStatus: 'paid',
                chargeID,
            })
            await db.collection('users').doc(instructorID).collection('calendarEvents').doc(instructorCalendarID).update({
                paymentStatus: 'paid',
            });
            await db.collection('facilities').doc(facilityID).collection('calendarEvents').doc(calendarID).update({
                paymentStatus: 'paid',
            });
        } catch (err) {
            setErrorMessage('Could not process your payment, please try again')
        }


    }


      function mountYoco() {
          setLoading(true)
        setStartPayment(true)
        var sdk = new window.YocoSDK({
            publicKey: 'pk_test_71402d41jB90nKE024a4'
          });
          console.log(1);
          const amountInCents = total * 100;
          console.log(amountInCents)
          // Create a new dropin form instance
          var inline = sdk.inline({
            layout: 'plain',
            amountInCents,
            currency: 'ZAR'
          });
          setStoreInline(inline)
          // this ID matches the id of the element we created earlier.
          inline.mount(yocoRef.current);
        // setPaymentForm(inline)
        console.log(3);
        setLoading(false)
    }

    async function onYocoSubmit() {
        setLoading(true)
        var form = formRef.current;
        var submitButton = payButtonRef.current;
        form.addEventListener('submit', function (event) {
          event.preventDefault()
          // Disable the button to prevent multiple clicks while processing
          submitButton.disabled = true;
          // This is the inline object we created earlier with the sdk
          storeInline.createToken().then(function (result) {
            // Re-enable button now that request is complete
            // (i.e. on success, on error and when auth is cancelled)
            submitButton.disabled = false;
            if (result.error) {
              const error = result.error.message;
              setErrorMessage(error)
            //   errorMessage && alert("error occured: " + errorMessage);
            } else {
              const token = result;
              console.log(token);
            //   alert("card successfully tokenised: " + token.id);
              testPayment({token: token.id, amountInCents: (total * 100)}).then((res) => {
                console.log(res)
                if (res.data === 'yoco error') {
                    return setErrorMessage('An error occurred trying to process your payment, please try again')
                } else {
                    // Store booking
                    saveCardPayment(res.data)
                    setStartPayment(false)
                    setFinished(true);
                    setLoading(false)
                    reloadPage()
                }

              })
            }
          }).catch(function (error) {
              setLoading(false)
            // Re-enable button now that request is complete
            submitButton.disabled = false;
            setErrorMessage('An error occurred trying to process your payment, please try again')
            // alert("error occured: " + error);
          });
        });
    }

    return (
        <div onClick={(e) => {
            if (e.target.className === 'pay-booking-modal') {
                closeModal()
            }
        }} className='pay-booking-modal'>
            <LoadingOverlay className='scale-in-center' spinner active={loading} text='Processing payment...'>
            <div className="pay-booking-modal-content">

{(!startPayment && !finished) && <>
    {hp && <p onClick={closeModal} className='mb-10'>Back</p>}
    <h1>Booking Request: {facilityName}</h1>
<div className="booking-request-row mt-10">
    <p className="fat-pee">
        Date:
    </p>
    <p className='middle-pee'>{start.toLocaleDateString('en-us', { dateStyle: 'long' })}</p>
</div>
                 <div className="booking-request-row">
            <p className='fat-pee'>Start:</p>
            <p className='middle-pee'>{start.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})}</p>
        </div>
        <div className="booking-request-row">
            <p className='fat-pee'>End:</p>
            <p className='middle-pee'>{end.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})}</p>
        </div>
        <div className="booking-request-row">
    <p className='fat-pee'>Total:</p>
    <p className='middle-pee'>R {total}</p>
    </div>
    {credits !== 0 && <div className="credits-info-container">
                    <div className="remaining-credits-row">
                        <p className="fat-pee">
                            Credits Remaining
                        </p>
                        <p>{credits}</p>
                    </div>
                    {(credits > total) && <div className="remaining-credits-row">
                        <p className="fat-pee">
                            Pay with credits
                        </p>
                        <Switch checked={paymentType === 'credits'} onChange={((e) => {
                            if (e) {
                                setPaymenType('credits')
                            } else {
                                setPaymenType('card')
                                setSelectedCredits(false)
                            }
                        })} />
                    </div>}
                </div>}
    <div className="button-row">
        <button onClick={() => {
            if (selectedCredits) {
                saveCreditPayment()
            } else {
                if (paymentType === 'card') {
                    mountYoco()
                } else {
                    setSelectedCredits(true)
                }
            }

        }} className="general-button">
            {selectedCredits ? 'Confirm Credit Payment' : paymentType === 'credits' ? 'Pay with credits ' : 'Pay with card'}
        </button>
    </div>
    </>}

        {finished && <div className='booking-paid-success'>
            <h3>Success!</h3>
            <p>Your booking at {facilityName} is confirmed</p>
            <div className="booking-request-row mt-10">
    <p className="fat-pee">
        Date:
    </p>
    <p className='middle-pee'>{start.toLocaleDateString('en-us', { dateStyle: 'long' })}</p>
</div>
                 <div className="booking-request-row">
            <p className='fat-pee'>Start:</p>
            <p className='middle-pee'>{start.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})}</p>
        </div>
        <div className="booking-request-row">
            <p className='fat-pee'>End:</p>
            <p className='middle-pee'>{end.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})}</p>
        </div>
        <div className="booking-request-row">
    <p className='fat-pee'>Total:</p>
    <p className='middle-pee'>R {total}</p>
    </div>
            </div>}
    <div className='yoco-container mt-20'>


                {/* Yoco Form */}
            <form ref={formRef} hidden={!startPayment} style={{fontFamily:'LibreFranklin'}} id="payment-form" method="POST">
                    <div class="one-liner">
                        <div ref={yocoRef} id="card-frame">
                        </div>
                        {/* {paymentForm} */}
                        {errorMessage !== '' && <div className="error-message-row">
                            <p>{errorMessage}</p>
                        </div>}
                        <div style={{justifyContent: 'center'}}  className="button-row">
                        <button hidden={!startPayment} onClick={onYocoSubmit} ref={payButtonRef} id="pay-button">
                        PAY ZAR {total}.00
                        </button>
                        </div>

                    </div>
                    <p class="success-payment-message" />
                </form>
            </div>
            </div>
            </LoadingOverlay>
        </div>
    )
}

export default PayBookingModal
