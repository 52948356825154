import React, { useState } from 'react';
import './App.css';
import './Animations.css'
import { HashRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';

// Compents
import Header from './Components/Header/Header';
import BlackHeader from './Components/Header/BlackHeader';
import Home from './Pages/Home/Home';
import Footer from './Components/Footer';
import Inbox from './Pages/Inbox/Inbox';
import Listings from './Pages/Listings/Listings';
import Bookings from './Pages/Bookings/Bookings';
import CalendarPage from './Pages/Calendar/CalendarPage';
import Analytics from './Pages/Analytics/Analytics';
import CRM from './Pages/CRM/CRM';
import SearchPage from './Pages/Search/SearchPage';
import Login from './Pages/Login & SignUp/Login';
import ProfileCreation from './Pages/Onboarding/ProfileCreation';
import InstructorCreation from './Pages/Onboarding/InstructorCreation';
import EditListing from './Pages/Listings/EditListing';
import ViewListing from './Pages/Listings/ViewListing';
import ProfileEditPage from './Pages/Profile/ProfileEditPage';
import SettingsPage from './Pages/Settings/SettingsPage';
import Clients from './Pages/Clients/Clients';
import InstructorProfileEditPage from './Pages/Profile/IntructorProfileEditPage'
import InstructorCredits from './Pages/Credits/InstructorCredits';

import FacilityProfile from './Pages/Search/FacilityProfile/FacilityProfile';

import { useAuth, AuthProvider } from './contexts/AuthContext';
import { db } from './firebase';
import OwnerCredits from './Pages/Credits/OwnerCredits';

function App() {
  const location = useLocation();

  // Auth
  const { currentUser, userRole } = useAuth();

  const [authState, setAuthState] = useState(true)
  const [hideDrop, setHideDrop] = useState(0);
  const [role, setRole] = useState('');

  const navigate = useNavigate()


  React.useEffect(() => {
    // if (currentUser) {
    //   fetchUserProfile(currentUser.uid);
    // }

    if (currentUser !== null && location.pathname !== '/profile-creation' && location.pathname !== '/instructor-creation' && location.pathname !== '/login') {
      // console.log('auth true')
      setAuthState(true);
    }

    if (location.pathname === '/profile-creation' || location.pathname === '/instructor-creation') {
      setAuthState(false)
    }

    if (currentUser === null) {
      setAuthState(false);
    }
    console.log(location.pathname)
    if (currentUser === null && location.pathname !== '/login' && !location.pathname.includes('listing')) {
      navigate('/login')
      setAuthState(false);
      // console.log(location.pathname)
    }
  }, [currentUser, location.pathname,]);

  React.useEffect(() => {
    // alert('changed')
  }, [currentUser])


  async function fetchUserProfile(uid) {
    const profile = await db.collection('users').doc(currentUser.uid).get();
    const role = profile.data().role;
    setRole(role);
  }

  React.useEffect(() => {
    if (userRole) {
    setRole(userRole)
    }
  }, [userRole])


  return (
    <AuthProvider>
    <div onClick={(evt) => {
      // console.log(typeof evt.target.className);
      if (typeof evt.target.className == 'object' || evt.target.className === 'header-profile-selector') {
      } else {
        // console.log(evt)
        setHideDrop(current => current + 1);
      }
      }} className="app">
      {/* <Router> */}
        {authState ? <Header hideDrop={hideDrop} /> : <BlackHeader />}
      {/* <Header /> */}
          <Routes>
            <Route path='/owner-credits' element={<OwnerCredits />} />
            <Route path='/instructor-credits' element={<InstructorCredits />} />
            <Route path='/instructor-creation' element={<InstructorCreation />} />
            <Route path='/settings' element={<SettingsPage />} />
            <Route path='/search' element={<SearchPage />} />
            <Route path='/profile-edit' element={<ProfileEditPage />} />
            <Route path='/profile-creation' element={<ProfileCreation />} />
            <Route path='/crm' element={<CRM />} />
            <Route path='/login' element={<Login />} />
            <Route path='/analytics' element={<Analytics />} />
            <Route path='/calendar' element={<CalendarPage />} />
            <Route path='/bookings' element={<Bookings />} />
            <Route path='/listings' element={<Listings />} />
            <Route path='/edit-listing' element={<EditListing />} />
            <Route path='/view-listing' element={ <ViewListing />} />
            <Route path='/inbox' element={<Inbox />} />
            <Route path='/clients' element={<Clients />} />
            <Route path='/profile' element={<InstructorProfileEditPage />} />
            <Route path='/' element={<Home />} />
            <Route path='/listing/:id' element={<FacilityProfile />}/>
          </Routes>
      {/* </Router> */}
      {/* Footer */}
      <Footer />
    </div>
    </AuthProvider>
  );
}

export default App;
