import React, { useEffect, useState, useRef } from 'react'
import './Home.css'
import { useNavigate } from 'react-router'

// Components
import Newsfeed from './Newsfeed'
import StatsBox from './StatsBox/StatsBox'
import BookingsContainer from './BookingsContainer/BookingsContainer'

import { useAuth } from '../../contexts/AuthContext'

function Home() {

    const roleRef = useRef();

    const { currentUser, userRole } = useAuth();
    const navigate = useNavigate();
    const [userID, setUserID] = useState('')
    const [userRoleStore, setUserRoleStore] = useState('')
    roleRef.current = userRoleStore

    useEffect(() => {
        // console.log(currentUser)
        if (currentUser === null) {
            navigate('/login')
        }
        if (currentUser !== null && currentUser !== undefined && userRole !== undefined) {
            setUserID(currentUser.uid)
            setUserRoleStore(userRole)
        }
    }, [currentUser, userRole])

    return (

        <div className='home'>
                        {/* {userRole === 'instructor' && <h1>Instructor Home</h1>} */}
                        <div className="home-top">
                            <div className="home-top-left">
                            <div className="news-feed-container scale-in-ver-center">
                {userID !== '' && roleRef.current !== '' &&  <Newsfeed uid={userID} userRole={userRole} />}
            </div>
                            </div>
                            <div className="home-top-right">
                            <div className="home-bookings-container scale-in-ver-center">
                    {userID !== '' && roleRef.current !== '' && <BookingsContainer userID={userID} userRole={roleRef.current}/>}
                </div>
                            </div>
                        </div>
                        <div className="home-bottom">
                        {/* <div className="home-right"> */}
                {userID !== '' && roleRef.current !== '' && <StatsBox uid={userID} userRole={userRole} />}
            {/* </div> */}
                        </div>

            {/* <div className="home-left">
                <div className="home-bookings-container">
                    {userID !== '' && roleRef.current !== '' && <BookingsContainer userID={userID} userRole={roleRef.current}/>}
                </div>
            <div className="news-feed-container">
                {userID !== '' && roleRef.current !== '' &&  <Newsfeed uid={userID} userRole={userRole} />}
            </div>
            </div>
            <div className="home-right">
                {userID !== '' && roleRef.current !== '' && <StatsBox uid={userID} userRole={userRole} />}
            </div> */}
        </div>
    )
}

export default Home
