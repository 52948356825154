import React, { useState, useRef, useEffect } from 'react'
import './InstructorAvailableModal.css'
import '../../Analytics/Clients/ClientRelationships.css'
import Multiselect from 'multiselect-react-dropdown';
import Switch from 'react-ios-switch';
import DatePicker from "react-multi-date-picker"

import { admin, db } from '../../../firebase';


const weekArray = [
    {
        name: 'Monday'
    },
    {
        name: 'Tuesday'
    },
    {
        name: 'Wednesday'
    },
    {
        name: 'Thursday'
    },
    {
        name: 'Friday'
    },
    {
        name: 'Saturday'
    },
    {
        name: 'Sunday'
    },
    // {
    //     name: 'Weekdays'
    // },
    // {
    //     name: 'Weekends'
    // }
]

const defStart = new Date();
defStart.setMinutes(0)
defStart.toLocaleTimeString('en-gb', {
    hour: '2-digit',
    minute: '2-digit'
})

const defEnd = new Date();
defEnd.setMinutes(0)
defEnd.setHours(defEnd.getHours() + 1)
defEnd.toLocaleTimeString('en-gb', {
    hour: '2-digit',
    minute: '2-digit'
})

function InstructorAvailableModal({ uid, selectedDate, start = defStart, end = defEnd, closeModal, setEvents, reloadCalendar }) {
    const [date, setDate] = useState(formatDate(selectedDate))
    const [endingTime, setEndingTime] = useState((formatTime(end)));
    const [startingTime, setStartingTime] = useState((formatTime(start)))
    const [selectedMenu, setSelectedMenu] = useState('once')
    const [selectedDays, setSelectedDays] = useState([])
    const [scheduleType, setScheduleType] = useState('daily')
    const [values, setValues] = useState([])
    const [error, setError] = useState('')


    const startTimeRef = useRef();
    startTimeRef.current = startingTime;
    const endTimeRef = useRef();
    endTimeRef.current = endingTime;
    const dateRef = useRef();
    dateRef.current = date;

    const dateInputRef = useRef();
    const startTimeInputRef = useRef();
    const endTimeInputRef = useRef();
    const daysRef = useRef();
    const endDateRef = useRef();




    function formatTime(date) {
        const d = new Date(date);
        return (d.toLocaleTimeString('en-gb', {
            hour: '2-digit',
            minute: '2-digit'
        }))


    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    function validateData() {
        setError('')
        // const start = startTimeInputRef.current.value;
        // const end = endTimeInputRef.current.value;
        // if (start === '') {
        //     setError('Please select a starting time')
        //     return false
        // }
        // if (end === '') {
        //     setError('Please select an ending time')
        //     return false
        // }

        if (selectedMenu === 'once') {


        } else {
            if (scheduleType === 'daily') {
                console.log(daysRef.current)
                if (daysRef.current.state.selectedValues.length === 0) {
                    setError('Please select at least one day')
                    return false
                }

            } else {
                if (values.length === 0) {
                    setError('Please select at least one day')
                    return false
                }
            }
        }
    }

    async function saveCalendarSlot() {

        if (validateData() === false) {
            return
        }

        const startHours = parseInt(startTimeInputRef.current.value.slice(0,2));
        const endHours = parseInt(endTimeInputRef.current.value.slice(0,2));
        const sessionsMap = {}
        const sessionArr= [];


        if (selectedMenu === 'once') {
            const selDate = new Date(dateRef.current);
            selDate.setHours(0);
            selDate.setMinutes(0);
            selDate.setSeconds(0);
            selDate.setMilliseconds(0)

       // Check if calendar slot already exists for day
       const calDocs = await db.collection('users').doc(uid).collection('calendar')
       .where('date', '==', selDate)
       .get()
        // If it exists
        if (calDocs.docs.length > 0) {
            console.log('do exist')
            const calDoc = calDocs.docs[0].data();
            const sessionsMap = calDoc.Sessions;
            for (let i = startHours; i < endHours; i++) {
                const startTime = new Date(selDate)
                const endTime = new Date(selDate)
                startTime.setHours(startHours + i)
                endTime.setHours(startHours + 1 + i)
                sessionsMap[`${i}`] = 'Available'
                sessionArr.push({
                    athleteID: '',
                    sessionID: '',
                    sessionName: 'Available',
                    startTime,
                    endTime,
                })
                sessionsMap[`${i}`] = 'Available'
            }
            console.log(sessionsMap)
            await db.collection('users').doc(uid).collection('calendar').doc(calDocs.docs[0].id).update({
                Sessions: sessionsMap,
            })
            // await db.collection('users').doc(uid).collection('calendar').doc(calDocs.docs[0].id).update({
            //     Sessions: sessionArr,
            // })
            reloadCalendar()


        } else {
            console.log('dont exist')
            for (let i = startHours; i < endHours; i++) {
                const startTime = new Date(selDate)
                const endTime = new Date(selDate)
                startTime.setHours(startHours + i)
                endTime.setHours(startHours + 1 + i)
                sessionsMap[`${i}`] = 'Available'
                sessionArr.push({
                    athleteID: '',
                    sessionID: '',
                    sessionName: 'Available',
                    startTime,
                    endTime,
                })
            }
            console.log(sessionsMap)
            await db.collection('users').doc(uid).collection('calendar').add({
                date: selDate,
                Sessions: sessionsMap,
            })
            // await db.collection('users').doc(uid).collection('calendar').add({
            //     date: selDate,
            //     Sessions: sessionArr,
            //     dateCreated: new Date(),
            // })
            reloadCalendar()
        }

        } else {
            if (scheduleType === 'daily') {
                const selDays = daysRef.current.state.selectedValues;
                const weekDays = [];
                let endDate = endDateRef.current.value;
                if (endDate !== '') {
                    endDate = new Date(endDate);
                }
                for (let i = 0; i < selDays.length; i++) {
                    const day = selDays[i].name;
                    switch (day) {
                        case 'Monday': weekDays.push(1); break;
                        case 'Tuesday': weekDays.push(2); break;
                        case 'Wednesday': weekDays.push(3); break;
                        case 'Thursday': weekDays.push(4); break;
                        case 'Friday': weekDays.push(5); break;
                        case 'Saturday': weekDays.push(6); break;
                        case 'Sunday': weekDays.push(7); break;
                        default: break;
                    }
                }

                const timeMap = {
                    endDate,
                    endTime: endHours,
                    monthDays: [],
                    monthly: false,
                    startTime: startHours,
                    weekDay: weekDays,
                }

                try {
                    await db.collection('users').doc(uid).update({
                        sessionTimes: admin.firestore.FieldValue.arrayUnion(timeMap)
                    })
                    reloadCalendar()
                } catch (err) {
                    return setError('Could not save, please try again')
                }

            }
            if (scheduleType === 'monthly') {
                const monthDays = [];
                let endDate = endDateRef.current.value;
                if (endDate !== '') {
                    endDate = new Date(endDate);
                }
                for (let i = 0; i < values.length; i++) {
                    const day = values[i].day;
                    monthDays.push(day)
                }

                const timeMap = {
                    endDate,
                    endTime: endHours,
                    monthDays,
                    weekDay: [],
                    monthly: true,
                    startTime: startHours,
                }
                try {
                    await db.collection('users').doc(uid).update({
                        sessionTimes: admin.firestore.FieldValue.arrayUnion(timeMap)
                    })
                    reloadCalendar()
                } catch (err) {
                    return setError('Could not save, please try again')
                }
            }


        }




        closeModal();
    }

    return (
        <div onClick={(evt) =>  {if (evt.target.className === 'instructor-available-modal') { closeModal()}}} className='instructor-available-modal'>
            <div className="instructor-available-modal-content scale-in-center">
             <h1>Set Availability</h1>
             <div className="selector-container selected-client-menu">
                <button onClick={() => setSelectedMenu('once')} className={selectedMenu === 'once' ? 'active-client-menu-button left-button' : 'left-button'} >Once</button>
                <button onClick={() => setSelectedMenu('scheduled')} className={selectedMenu === 'scheduled' ? 'active-client-menu-button right-button' : 'right-button'}>Schedule</button>
             </div>
             {/* Once off */}
              {selectedMenu === 'once' && <>
             <div className="instructor-available-modal-row date-row">
                    <p>Date:</p>
                    <input type='date' ref={dateInputRef} onChange={(e) => console.log(e)} min={formatDate(new Date())} defaultValue={dateRef.current} />
                    {/* <p className='bold'>Start Date:</p>
                    <p className='middle-pee'>{startingDate.toLocaleDateString("en-US", options)}</p>
                    <DateTimePicker minDate={minDate} disableClock={true}  className='available-dt-picker' onChange={setStartingDate} defaultValue={start} value={startingDate} /> */}
                </div>
                <div className="instructor-available-modal-row date-row">
                    <p className='bold'>Time:</p>
                    {/* <TimePicker disableClock={true} /> */}
                    <input type='time' ref={startTimeInputRef} defaultValue={startTimeRef.current} step={1200} onChange={(e) => console.log(e)} />
                    <h1>-</h1>
                    <input type='time' ref={endTimeInputRef}  defaultValue={endTimeRef.current}/>
                    {/* <p className='middle-pee'>{endingDate.toLocaleDateString("en-US", options)}</p> */}
                    {/* <DateTimePicker minDate={minDate} className='available-dt-picker' onChange={setEndingDate} value={endingDate} defaultValue={end} /> */}
                </div>
                {/* <div className="instructor-available-modal-row">
                    <p className="bold">
                        Location:
                    </p>
                </div> */}
             </>}
             {/* Scheduled */}
             {selectedMenu === 'scheduled' && <>
             <div className="sched-msg">
                 <p>* Scheduled sessions may take a minute to reflect in the calendar</p>
             </div>
             <div className="instructor-available-modal-row">
                 <p>Daily</p>
                 <div className="switch-container">
                 <Switch className='switch' checked={scheduleType === 'monthly'} onChange={(e) => {
                if (e) {
                    setScheduleType('monthly')
                } else {
                    setScheduleType('daily')
                }
            }} />                    <p>Monthly</p>
                 </div>
             </div>
             {scheduleType === 'daily' && <>
             <div className="instructor-available-modal-row">

                    <p>Days:</p>
                    <Multiselect options={weekArray}
                    onSelect={(e) => setSelectedDays(e)}
                    displayValue='name'
                    selectedValues={selectedDays}
                    showCheckbox={true}
                    closeOnSelect={false}
                    placeholder='Select Weekday(s)'
                    ref={daysRef}

                />
                </div>
                <div className="instructor-available-modal-row date-row">
                <p>End:</p>
                    <input type='date' ref={endDateRef} min={formatDate(new Date())}/>
                </div>

                 </>}
                {scheduleType === 'monthly' &&
                <>
                   <div className="instructor-available-modal-row sd-pick">
                       <p>Days:</p>
                 <DatePicker
                    disableMonthPicker={true}
                    disableYearPicker={true}
                    multiple
                    value={values}
                    placeholder={'Select day(s)'}
                    onChange={(e) => setValues(e)}
                    hideWeekDays
                    format='D'
                    ref={daysRef}
                    // weekDays={['', '', '', '', '', '','' ]}
                    />
                 </div>
                 <div className="instructor-available-modal-row date-row">
                <p>End:</p>
                    <input type='date' ref={endDateRef} onChange={(e) => console.log(e)} min={formatDate(new Date())}/>
                </div>
                 </>
                }


                <div className="instructor-available-modal-row date-row">
                    <p className='bold'>Time:</p>
                    {/* <TimePicker disableClock={true} /> */}
                    <input type='time' ref={startTimeInputRef} defaultValue={startTimeRef.current} step={1200} onChange={(e) => console.log(e)} />
                    <h1>-</h1>
                    <input type='time' ref={endTimeInputRef}  defaultValue={endTimeRef.current}/>
                    {/* <p className='middle-pee'>{endingDate.toLocaleDateString("en-US", options)}</p> */}
                    {/* <DateTimePicker minDate={minDate} className='available-dt-picker' onChange={setEndingDate} value={endingDate} defaultValue={end} /> */}
                </div>
                {/* <div className="instructor-available-modal-row">
                    <p className="bold">
                        Location:
                    </p>
                </div> */}
             </>}

                {error !== '' &&               <div className="error-message-row">
                 <p>{error}</p>
                 </div>}
                <div className="button-row">
                    <button onClick={saveCalendarSlot} id='confirm-button' className='confirm-button'>Confirm</button>
                    <button onClick={closeModal} id="" className='valencia-button cancel-button'>Close</button>

                </div>
            </div>
        </div>
    )
}

export default InstructorAvailableModal
