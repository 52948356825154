import React, { useState } from 'react'
import './SettingsItem.css'

import Switch from 'react-ios-switch';

function SettingsItem({ text, setting, selectRef }) {

    const [chosen, setChosen] = useState(setting);
    selectRef.current = chosen;

    return (
        <div className='settings-item'>
            <p>{text}</p>
            <div className="setings-buttons">
                <Switch checked={chosen} onChange={setChosen} />
                {/* <button onClick={() => setSelectedButton('on')} className={selectedButton === 'on' ? 'settings-button active-settings-button left-button' : 'settings-button left-button'}>On</button>
                <button onClick={() => setSelectedButton('off')} className={selectedButton === 'off' ? 'settings-button active-settings-button right-button' : 'settings-button right-button'}>Off</button> */}
            </div>
        </div>
    )
}

export default SettingsItem
