import React, { useState, useRef, useEffect} from 'react'
import './AddClientModal.css'

import niceware from 'niceware'

import { db, admin, auth } from '../../../firebase'

import LoadingOverlay from 'react-loading-overlay'

function AddClientModal({ closeModal, uid, reload }) {

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    // Refs
    const emailRef = useRef()
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const phonenumberRef = useRef();

    const aboutRef = useRef();
    const heightRef = useRef();
    const weightRef = useRef();

    const createUser = admin.functions().httpsCallable('createOfflineUser');

    // Validate data
    function validateData() {

        setError('')

        const emailAddress = emailRef.current.value;
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        const phonenumber = phonenumberRef.current.value;

        if (emailAddress === '') {
            setError('Please enter an email address')
            return false
        }
        if (phonenumber === '') {
            setError('Please enter a phone number')
            return false
        }
        if (firstName === '') {
            setError('Please enter an email address')
            return false
        }
        if (lastName === '') {
            setError('Please enter an email address')
            return false
        }


    }

    // Save data
    async function saveUser() {

        setLoading(true)

        if (validateData() === false) {
            return setLoading(false)
        }



        const emailAddress = emailRef.current.value;
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        const store = phonenumberRef.current.value;
        const store2 = store.substring(1);
        const phonenumber = `27${store2}`;
        console.log(phonenumber)
        const passwords = niceware.generatePassphrase(8)
        // console.log(password)
        let password = ''
        for (let i = 0; i < passwords.length; i++) {
            if (passwords[i].length > 5) {
                password = passwords[i];
            }
        }

        // let cancel = false;

        // const user = await auth.createUserWithEmailAndPassword(emailAddress, password).catch(e => {
        //     console.log(e)
        //     setError(e.message)
        //     cancel = true;
        //     return setLoading(false)
        // })
        // const userID = user.data;
        // console.log(userID);
        // if (userID === undefined) {
        //     return setLoading(false)
        // }
        // console.log(email)
        try {

            const user = await createUser({email: emailAddress, password: password}).catch(e => {
                console.log(e)
                
            });

            
        const userID = user.data;
        console.log(userID)
        // Create document
        await db.collection('users').doc(userID).set({
            emailAddress,
            firstName,
            lastName,
            phonenumber,
            hasCompletedProfile: false,
            isOfflineUser: true,
            role: 'athlete',
            sessionTimes: [],
            password: password[0],
            instructorID: uid,
            dateCreated: new Date(),
        });

        await db.collection('users').doc(uid).update({
            clients: admin.firestore.FieldValue.arrayUnion(userID),
        })
        } catch (err) {
            // if (err.includes('read')) {
            //     setError('User already exists, please try again.')
            //     return setLoading(false)
            // }

            setError('User already exists, please try again.')
            return setLoading(false)
        }



        setLoading(false)
        reload()
        closeModal();

    }

    return (
        <div onClick={(e) => {if (e.target.className === 'add-client-modal') { closeModal() }}} className='add-client-modal'>
            <LoadingOverlay active={loading} spinner text='Saving user...'>
            <div className="add-client-modal-content scale-in-center">
                <h1>Add Client</h1>
                <h4>Personal Details</h4>
                <div className="acm-input-group">
                    <input ref={firstNameRef} type='text' placeholder='First Name' />
                    <input ref={lastNameRef} type='text' placeholder='Last Name' />
                </div>
                <div className="acm-input-group">
                    <input ref={emailRef} type='text' placeholder='Email' />
                    <input ref={phonenumberRef} type='text' placeholder='Phone Number' />
                </div>
                <h4>Personal Details (optional)</h4>
                <textarea ref={aboutRef} placeholder='Notes' rows={5}/>
                <div className="acm-details-group">
                    <p>Height:</p>
                    <input ref={heightRef} placeholder='cm' type='number' />
                </div>
                <div className="acm-details-group">
                    <p>Weight:</p>
                    <input ref={weightRef} placeholder='kg' type='number'/>
                </div>
                {error !== '' && <div className="error-message-row">
                    <p>{error}</p>
                </div>}
                <div className="acm-button-row button-row">
                    <button disabled={loading} onClick={saveUser} className="picton-button confirm-button">Save</button>
                </div>
            </div>
            </LoadingOverlay>
        </div>
    )
}

export default AddClientModal
