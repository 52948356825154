import React from 'react'
import './BusinessPerformance.css'

// Components
import AnalyticsBox from './AnalyticsBox'
import HomeSummary from '../../Components/HomeSummary'
import InfoCard from '../../Components/Cards/InfoCard'
import StatsBox from '../Home/StatsBox/StatsBox'

// Charts
import Chart from "react-google-charts";
import BookingsLineGraph from '../Home/BookingsLineGraph';
import AnalyticsGraphs from './AnalyticsGraphs/AnalyticsGraphs';


function BusinessPerformance({ venueName, facilityID, uid, userRole }) {
    return (
        <div className="business-performance">
            <div className="business-performance-header">
                <h2>{venueName}</h2>
            </div>
            <div className="analytics-home-info-card-row">
                <StatsBox uid={uid} analytics={true} userRole={userRole} />
                {/* <InfoCard value={100} info={'Total Bookings'} type='general' flex={0.3} today={15} dir='up' change={7} icon='money' />
                <InfoCard value={100} info={'Total Income'} type='red' flex={0.3} today={15} dir='up' change={7} icon={'gym'}/>
                <InfoCard value={100} info={'Statistic'} type='blue' flex={0.3} today={15} dir='down' change={7} icon={'person'} /> */}
                </div>
            <div className="analytics-graphs-container">
               {userRole !== undefined && <AnalyticsGraphs uid={uid} userRole={userRole} />}
                </div>
            {/* <div className="business-performance-boxes">
                    <AnalyticsBox boxTitle={'Bookings this Month'} />
                    <AnalyticsBox boxTitle={'Revenue this Month'} />
                    <AnalyticsBox boxTitle={'Instructors Booked'} />
                    <HomeSummary today={15} yesterday={25} total={1300} />
            </div>
            <div className="business-performance-chart-row">
                <div className="chart-container">


            <Chart
                width={'500px'}
                height={'300px'}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={[
                    ['Type', 'Measurement'],
                    ['Option 1', 11],
                    ['Option 2', 2],
                ]}
                options={{
                    title: 'Pie Title',
                    slices: {
                        0: { color: 'black' },
                        1: { color: 'red' },
                      },
                }}
                rootProps={{ 'data-testid': '1' }}
                />
                </div>
                <div className="line-container">
                <BookingsLineGraph lineColor='black' />

                </div>
            </div> */}
        </div>
    )
}

export default BusinessPerformance
