import React, { useRef, useState, useEffect } from 'react'
import './RatesBox.css'
import Checkbox from '@material-ui/core/Checkbox';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Geocode from "react-geocode";
import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';

import { admin } from '../../firebase';

function RatesBox({ discipline, facRef, homeRef, outdoorRef, onlineRef, facCheckRef, homeCheckRef,outdoorCheckRef, onlineCheckRef, onlineLinkRef, facLocationRef, facMembersOnlyRef, outdoorLocationRef,
    facRateDefault, facCheckDefault, facLocationDefault, facMemOnlyDefault, homeRateDefault, homeCheckDefault, odCheckedDefault, odRateDefault, odLocationDefault, onCheckedDefault, onRateDefault, onLinkDefault,
    facCoordsRef, odCoordsRef, setYogaDrop, setRRDrop, yogaDropVal, rrDropVal
}) {

    Geocode.setApiKey("AIzaSyBNVt036Vwj3EMeNMVyTEh9JPqCuZ6xsi8");

    useEffect(() => {
        if (discipline === 'Yoga') {
           console.log(`yogadrop val is ${yogaDropVal}`)

        }

        console.log(facLocationDefault)

    }, [])

    const [facChecked, setFacChecked] = useState(facCheckDefault);
    const [homeChecked, setHomeChecked] = useState(homeCheckDefault);
    const [outdoorChecked, setOutdoorChecked] = useState(odCheckedDefault);
    const [onlineChecked, setOnlineChecked] = useState(onCheckedDefault);

    const [facLocationValue, setFacLocationValue] = useState(facLocationDefault);
    const [facLocationCoords, setFacLocationCoords] = useState()
    const [odLocationValue, setODLocationValue] = useState(odLocationDefault);
    const [odLocationCoords, setODLocationCoords] = useState()

    const [facMemOnlyChecked, setFacMemOnlyChecked] = useState(false)

    facCheckRef.current = facChecked;
    homeCheckRef.current = homeChecked;
    outdoorCheckRef.current = outdoorChecked;
    onlineCheckRef.current = onlineChecked;
    facMembersOnlyRef.current = facMemOnlyChecked
    facCoordsRef.current = facLocationCoords;
    odCoordsRef.current = odLocationCoords
    facLocationRef.current = facLocationValue
    outdoorLocationRef.current = odLocationValue;


    async function getPlaceCoords(address, type) {
        try {
            const response = await Geocode.fromAddress(address);
            const { lat, lng } = response.results[0].geometry.location;
            const newPoint = new admin.firestore.GeoPoint(lat, lng);
            if (type === 'fac') {
                setFacLocationCoords(newPoint)
            }
            if (type === 'outdoor') {
                setODLocationCoords(newPoint)
            }

        } catch (err) {
            console.log(err)
        }
    }


    return (
        <div className='rates-box'>
            <p>{discipline} Coaching Hourly Rates</p>
            <div className="rates-input-container">
                {discipline === 'Yoga' && <div className="yoga-speciality-drop">
                <SelectSearch
                            onChange={(e) => setYogaDrop(e)}
                            //  ref={yogaDropRef}
                             search={false}
                             closeOnSelect={true}
                             filterOptions={ fuzzySearch }
                             placeholder={'Select Primary Speciality'}
                             options={[
                                 {
                                 name: 'Yoga',
                                 value: 'yogaOnly',
                                },
                                 {
                                 name: 'Pilates',
                                 value: 'yoga',
                                },
                                 {
                                 name: 'Yoga & Pilates',
                                 value: 'yoga&pilates',
                                },
                            ]}
                            value={yogaDropVal}
                             />
                </div>}
                {discipline === 'Recovery' && <div className="rr-speciality-drop">
                <SelectSearch
                             onChange={(e) => setRRDrop(e)}
                             search={false}
                             closeOnSelect={true}
                             filterOptions={ fuzzySearch }
                             placeholder={'Select Primary Speciality'}
                             options={[
                                 {
                                 name: 'Physiotherapist',
                                 value: 'physiotherapist',
                                },
                                 {
                                 name: 'Biokineticist',
                                 value: 'biokineticist',
                                },
                            ]}
                            value={rrDropVal}
                             />
                </div>}

                <div className="rates-input-row mt-20">
                    <Checkbox defaultChecked={facCheckDefault} onChange={() => setFacChecked(checked => !checked)} />
                    <div className="rates-input-box">
                        <div className="rib-left">
                            <p>FACILITY</p>
                        </div>
                        <div className="rib-right">
                            <p>R</p>
                            <input min={0} step={50} defaultValue={facRateDefault} disabled={!facChecked} type='number' ref={facRef} />
                        </div>
                    </div>
                </div>
                <div hidden={!facChecked} className="facility-details">
                <GooglePlacesAutocomplete
                                apiKey="AIzaSyBNVt036Vwj3EMeNMVyTEh9JPqCuZ6xsi8"
                                selectProps={{
                                    value: facLocationValue,
                                    onChange: (e) => {setFacLocationValue(e); getPlaceCoords(e.label, 'fac')},
                                    placeholder: 'Enter your location here',
                                }
                                }
                                ref={facLocationRef}
                                placeholder={'Enter your location here...'}
                                onLoadFailed={
                                    (e) => console.log(e)
                                }

    />
                    {/* <input defaultValue={facLocationDefault} ref={facLocationRef} type='text' placeholder='Name of facility e.g. Virgin Active - Old Eds' /> */}
                    <div className="fac-location-check">
                        <Checkbox defaultChecked={facMemOnlyDefault} onChange={() => setFacMemOnlyChecked(checked => !checked)} />
                        <p>This facility is restricted to members only</p>
                    </div>
                </div>
                <div className="rates-input-row">
                    <Checkbox defaultChecked={homeCheckDefault} onChange={() => setHomeChecked(checked => !checked)} />
                    <div className="rates-input-box">
                        <div className="rib-left">
                            <p>HOME</p>
                        </div>
                        <div className="rib-right">
                            <p>R</p>
                            <input min={0} step={50} defaultValue={homeRateDefault} disabled={!homeChecked} type='number' ref={homeRef} />
                        </div>
                    </div>
                </div>
                <div className="rates-input-row">
                    <Checkbox defaultChecked={odCheckedDefault} onChange={() => setOutdoorChecked(checked => !checked)} />
                    <div className="rates-input-box">
                        <div className="rib-left">
                            <p>OUTDOORS</p>
                        </div>
                        <div className="rib-right">
                            <p>R</p>
                            <input min={0} step={50} defaultValue={odRateDefault} disabled={!outdoorChecked} type='number' ref={outdoorRef} />
                        </div>
                    </div>
                </div>
                 <div style={{ display: outdoorChecked ? 'block' : 'none', width: '100%',}} className='outdoor-details facility-details'>
                 <GooglePlacesAutocomplete
                                apiKey="AIzaSyBNVt036Vwj3EMeNMVyTEh9JPqCuZ6xsi8"
                                selectProps={{
                                    value: odLocationValue,
                                    onChange: (e) => {setODLocationValue(e); getPlaceCoords(e.label, 'outdoor')},
                                }
                                }
                                ref={outdoorLocationRef}
                                placeholder={'Enter your location here...'}
                                onLoadFailed={
                                    (e) => console.log(e)
                                }

    />
                    {/* <input defaultValue={odLocationDefault} type='text' placeholder='Name of outdoor location e.g. Camps Bay Park' ref={outdoorLocationRef} /> */}
                    </div>
                <div className="rates-input-row">
                    <Checkbox defaultChecked={onCheckedDefault} onChange={() => setOnlineChecked(checked => !checked)}  />
                    <div className="rates-input-box">
                        <div className="rib-left">
                            <p>ONLINE</p>
                        </div>
                        <div className="rib-right">
                            <p>R</p>
                            <input min={0} step={50} defaultValue={onRateDefault} disabled={!onlineChecked} type='number' ref={onlineRef} />
                        </div>
                    </div>
                </div>
                <div style={{ display: onlineChecked ? 'flex' : 'none'}} className="rates-online-link">
                    <p>Please provide a link for your online classes</p>
                    <input defaultValue={onLinkDefault} ref={onlineLinkRef} type='text' />
                </div>
            </div>
        </div>
    )
}

export default RatesBox
