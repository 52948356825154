import React, {useState} from 'react'
import './DiscountModal.css'

function DiscountModal({clickAction, closeModal}) {

const date = new Date();

const [allState, setAllState] = useState(false)
const [currentDiscount, setCurrentDiscount] = useState(0);
const [startDate, setStartDate] = useState('')
const [endDate, setEndDate] = useState('')


    return (
        <div className="discount-modal" onClick={(event) => clickAction(event)}>
            <div className="modal-content">
                <h2>Yoga Studio - Discount Management</h2>
                <div className="discount-row">
                    <h3>Start Date</h3>
                    <input type="date" onChange={(evt) => {const format = (new Date(evt.target.value)).toDateString(); setStartDate(format); console.log(startDate);}} />
                </div>
                <div className="discount-row">
                    <h3>End Date</h3>
                    <input type="date" onChange={(evt) => {const format = (new Date(evt.target.value)).toDateString(); setEndDate(format); console.log(endDate);}}/>
                </div>
                <div id="input-div" className="discount-row">
                    <div className="amount-left">
                                            <h3>Discount Amount</h3>

                    </div>
                    <div className="amount-right">
                                            <input id="amount-input" type="number" max={0.95} min={0.05} step={0.05} onChange={(evt) => {setCurrentDiscount(evt.target.value);}} />

                    </div>


                </div>
                <div className="discount-check-row">
                    <h3>Applied to</h3>
                    <div className="check-col">
                                          <div className="check-group">
                        <input disabled={allState} type="checkbox" id="hourly" />
                        <label for="hourly">Hourly Rate</label>
                    </div>
                    <div className="check-group">
                        <input disabled={allState} type="checkbox" id="half-day" />
                        <label for="half-day">Half Day Rate</label>
                    </div>
                    <div className="check-group">
                        <input disabled={allState} type="checkbox" id="full-day" />
                        <label for="full-day">Full Day Rate</label>
                    </div>
                    <div className="check-group">
                        <input type="checkbox" id="all" onClick={() => {setAllState(!allState)}} />
                        <label for="all">All of the above</label>
                    </div>
                    </div>


                </div>
                <div className="confirmation-message">
                    <p>Please note: <br /> By confirming these dates, you are applying a discount of {currentDiscount * 100}% to all the listing rates for the period {startDate} - {endDate}</p>
                </div>
                <div className="button-row">
                    <button id="cancel-button" onClick={closeModal}>Cancel</button>
                    <button id="confirm-button">Confirm</button>
                </div>
            </div>
        </div>
    )
}

export default DiscountModal
