import React, { useState, useRef, useEffect } from 'react'
import './PriceIncrease.css'

import Multiselect from 'multiselect-react-dropdown';
import TimePicker from 'react-time-picker';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Checkbox } from '@material-ui/core'

const weekArray = [
    {
        name: 'Monday'
    },
    {
        name: 'Tuesday'
    },
    {
        name: 'Wednesday'
    },
    {
        name: 'Thursday'
    },
    {
        name: 'Friday'
    },
    {
        name: 'Saturday'
    },
    {
        name: 'Sunday'
    },
]

const monthArray = [
    {
        name: 'January'
    },
    {
        name: 'February'
    },
    {
        name: 'March'
    },
    {
        name: 'April'
    },
    {
        name: 'May'
    },
    {
        name: 'June'
    },
    {
        name: 'July'
    },
    {
        name: 'August'
    },
    {
        name: 'September'
    },
    {
        name: 'October'
    },
    {
        name: 'November'
    },
    {
        name: 'December'
    },
]

function PriceIncrease({ removeSelf, myKey, changeType, addToIncrease,
                    defaultType = 'daily', defaultDays = [], defaultMonths = [], defaultStart = '00:00', defaultEnd = '00:00', defaultPerc = 0,
}) {
    const [selectedDays, setSelectedDays] = useState(defaultDays)
    const [selectedMonths, setSelectedMonths] = useState(defaultMonths)
    const [startTime, setStartTime] = useState(defaultStart)
    const [endTime, setEndTime] = useState(defaultEnd,)
    const [type, setType] = useState(defaultType);
    const [endDate, setEndDate] = useState()
    const [endDateSelected, setEndDateSelected] = useState(false)
    const [update, setUpdate] = useState(0)


    useEffect(() => {
        // console.log(changeType)
        // console.log(defaultStart, defaultEnd)
    }, [])

    const daysRef = useRef();
    const monthsRef = useRef()
    const startRef = useRef();
    // startRef.current = startTime;
    const endRef = useRef();
    // endRef.current = endTime;
    const amountRef = useRef();
    const endDateRef = useRef();

    function addToIncreaseArray() {
        setUpdate(current => current + 1)
        if (type === 'daily') {
        const itemMap = {
            changeType,
            days: daysRef.current.state.selectedValues,
            endTime: parseInt(endRef.current.value),
            startTime: parseInt(startRef.current.value),
            amount: parseInt(amountRef.current.value),
            myKey,
            type,
        }
        addToIncrease(itemMap);
        setUpdate(current => current + 1)

    }
        if (type === 'monthly') {
            const itemMap = {
                changeType,
                type,
                months: monthsRef.current.state.selectedValues,
                amount: parseInt(amountRef.current.value),
                myKey,
            }
            addToIncrease(itemMap);

        }
        // console.log(itemMap)

    }


    return (
        <div className="price-increase-container">

        <div className='price-increase'>

            <div className="top-row">

            <div className="selected-client-menu">
                        <button onClick={() => setType('daily')} className={type === 'daily' ? 'active-client-menu-button left-button' : 'left-button'}>Daily</button>
                        <button onClick={() => setType('monthly')} className={type === 'monthly' ? 'active-client-menu-button right-button' : 'right-button'} >Monthly</button>
                    </div>
            <div className="pi-weekday">
            {type === 'daily' && <Multiselect options={weekArray}
                    onSelect={addToIncreaseArray}
                    displayValue='name'
                    selectedValues={selectedDays}
                    showCheckbox={true}
                    closeOnSelect={false}
                    placeholder='Select Weekday'
                    ref={daysRef}
                    onRemove={addToIncreaseArray}

                />}
            {type === 'monthly' && <Multiselect options={monthArray}
                    onSelect={addToIncreaseArray}
                    displayValue='name'
                    selectedValues={selectedMonths}
                    showCheckbox={true}
                    closeOnSelect={false}
                    placeholder='Select Month'
                    ref={monthsRef}
                    onRemove={addToIncreaseArray}
                />}
            </div>
            </div>

            <div className="middle-row">

            {type === 'daily' && <><div className="pi-start">
                <input defaultValue={defaultStart} ref={startRef} type='time' onChange={(e) => {
                    setStartTime(e.target.value)
                    setUpdate(current => current + 1)
                    addToIncreaseArray()}
                    } />
            {/* <TimePicker
                onChange={(e) => {
                    setStartTime(e)
                    setUpdate(current => current + 1)
                    addToIncreaseArray()
                }
                }
                format='HH:mm'
                renderHourMarks={false}
                value={startTime}/> */}
                            </div>
                            <h3>-</h3>
            <div className="pi-end">
            <input type='time' defaultValue={defaultEnd} ref={endRef} onChange={(e) => {
                    setEndTime(e.target.value)
                    setUpdate(current => current + 1)
                    addToIncreaseArray()}
                    } />
            {/* <TimePicker
                format='HH:mm'

                onChange={(e) => {
                    // console.log(e)
                    setEndTime(e)
                    setUpdate(current => current + 1)
                    addToIncreaseArray()
                }
                }                value={endTime}/> */}
            </div></>}
            </div>
            <div className="bottom-row">

            <div className="pi-percentage">
                <input onChange={addToIncreaseArray} ref={amountRef} defaultValue={defaultPerc} min={1} max={100} step={1} type='number' /><p>%</p>
            </div>
            <RemoveCircleOutlineIcon onClick={() => removeSelf(myKey)} />
            </div>

        </div>
        <div className="end-date-container">
        <Checkbox />
            <h4>Set End Date</h4>
            <input ref={endDateRef} type='date' />
            </div>
           </div>

    )
}

export default PriceIncrease
