import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router';
import './ProfileCreation.css'

// Auth
import { useAuth } from '../../contexts/AuthContext';
import { db, storage } from '../../firebase';

// Icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PublishIcon from '@material-ui/icons/Publish';
import CloudUpload from '@mui/icons-material/CloudUpload';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

import ClipLoader from "react-spinners/ClipLoader";
import LoadingOverlay from 'react-loading-overlay';
import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';

import {useDropzone} from 'react-dropzone';

 
const bankNameArray = [
    {
        name: 'Absa Group Limited',
        value: 'Absa Group Limited',
    },
    {
        name: 'African Bank Limited',
        value: 'African Bank Limited',
    },
    {
        name: 'Bidvest Bank Limited',
        value: 'Bidvest Bank Limited',
    },
    {
        name: 'Capitec Bank Limited',
        value: 'Capitec Bank Limited',
    },
    {
        name: 'Discovery Limited',
        value: 'Discovery Limited',
    },
    {
        name: 'First National Bank',
        value: 'First National Bank',
    },
    {
        name: 'FirstRand Bank',
        value: 'FirstRand Bank',
    },
    {
        name: 'Grindrod Bank Limited',
        value: 'Grindrod Bank Limited',
    },
    {
        name: 'Imperial Bank South Africa',
        value: 'Imperial Bank South Africa',
    },
    {
        name: 'Investec Bank Limited',
        value: 'Investec Bank Limited',
    },
    {
        name: 'Nedbank Limited',
        value: 'Nedbank Limited',
    },
    {
        name: 'Sasfin Bank Limited',
        value: 'Sasfin Bank Limited',
    },
    {
        name: 'Standard Bank of South Africa',
        value: 'Standard Bank of South Africa',
    },
    {
        name: 'Ubank Limited',
        value: 'Ubank Limited',
    },
    {
        name: 'TymeBank',
        value: 'TymeBank',
    },

    ]

    const bankTypeArray = [
        {
            name: 'Cheque',
            value: 'Cheque',
        },
        {
            name: 'Savings',
            value: 'Savings,'
        }
    ]

function ProfileCreation() {



    // Auth
    const {currentUser} = useAuth();

    // Refs
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const cellRef = useRef();
    const dobRef = useRef();
    const idRef = useRef();
    const ppRef = useRef()
    const accHolderRef = useRef();
    const bankBranchNameRef = useRef();
    const bankAccRef = useRef();
    const bankBranchCodeRef = useRef();
    const aboutRef = useRef();
    const imageNameRef = useRef();
    const dragRef = useRef();
    const vatRef = useRef();
    const bankNameRef = useRef();
    const bankTypeRef = useRef();

    // State
    const [errorMessage, setErrorMessage] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [ppImage, setPPImage] = useState('')
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Setting up your account...')
    const [bankSelected, setBankSelected] = useState('');
    const [bankTypeSelected, setBankTypeSelected] = useState('');

    // History
    const navigate = useNavigate();

    // FUNCTIONS

    // Upload data to firestore
    async function uploadData() {


        setLoading(true);
        // Refs
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        // var emailAddress = emailRef.current.value;
        const cellNumber = cellRef.current.value;
        var dob = dobRef.current.value;
        dob = new Date(dob);
        const idNumber = idRef.current.value;
        const passportNumber = ppRef.current.value;
        const accountHolder = accHolderRef.current.value;
        const bankBranchName = bankBranchNameRef.current.value;
        const bankAccNum = bankAccRef.current.value;
        const bankBranchCode = bankBranchCodeRef.current.value;
        const about = aboutRef.current.value;


        const validated = validateInput();
        if (validated === false) {
            setLoading(false);
            return
            // return setErrorMessage('Please ensure all fields are filled in correctly')
        }
        // return;
        // If email is blank, use account address

        const selectedMetrics = [
            'Capacity Taken',
            'Occupancy (Month)',
            'Unused Credits',
            'Credits Sold',
            'Revenue / Booking',
            'Bookings (Month)'
        ];

        // Upload Image
        let passportImageLink = '';
        if (ppImage !== '') {
            const ppStorageRef = storage.ref().child(`passport-images/${currentUser.uid}`);
            const task = ppStorageRef.put(profileImage);

            task.on('state changed',
                (snapshot) => {
                    setLoadingMessage('Uploading your image...')
                    var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log(percentage);
                },
                (err) => {
                    setLoading(false);
                    setErrorMessage('Error uploading images, please refresh the page and try again')
                },
                () => {
                    task.snapshot.ref.getDownloadURL((link) => {
                        passportImageLink = link
                    })
                }
            )
        }

        const storageRef = storage.ref().child(`profile-images/${currentUser.uid}`);
            const task = storageRef.put(profileImage);

            task.on('state changed',
            (snapshot) => {
                setLoadingMessage('Uploading your image...')
                var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(percentage);
            },
            (err) => {
                setLoading(false);
                setErrorMessage('Error uploading images, please refresh the page and try again')
            },
            () => {
                task.snapshot.ref.getDownloadURL().then((profileImage) => {
                console.log(profileImage);
                setLoadingMessage('Storing your details...')
                // Upload Data
                db.collection('users').doc(currentUser.uid).set({
                    role: 'owner',
                    firstName,
                    lastName,
                    dateJoinedStamp: new Date(),
                    emailAddress: currentUser.email,
                    cellNumber,
                    dob,
                    idNumber,
                    passportNumber,
                    passportImageLink,
                    bankName: bankSelected,
                    bankAccType: bankTypeSelected,
                    bankBranchName,
                    bankAccNum,
                    bankBranchCode,
                    accountHolder,
                    about,
                    profileImage,
                    facilityIDs: [],
                    selectedMetrics,
                    chatIDs: [],
                }).then(() => {
                    db.collection('users').doc(currentUser.uid).collection('notifications').add({
                        dateCreated: new Date(),
                        title: 'Welcome to Forte',
                        documentID: '',
                        message: 'Add listings to get started!',
                        read: false,
                        type: 'welcome',
                    });
                    }).then(() => {
                    navigate('/');
                });
            });
            },
            );

    }

    const {getRootProps, getInputProps} = useDropzone({
        // accept: 'image/*',
        onDrop: acceptedFiles => {
            const newFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
              }));
              console.log(newFiles[0])
            setProfileImage(newFiles[0]);

        }
      });

    const {getInputProps: getPPInputProps, getRootProps: getPPRootProps} = useDropzone({
        // accept: 'image/*',
        onDrop: acceptedFiles => {
            const newFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
              }));
              console.log(newFiles[0])
            setPPImage(newFiles[0]);

        }
      });

    // Validate input
    function validateInput() {
        // Reset borders
        firstNameRef.current.className = '';
        lastNameRef.current.className = '';
        cellRef.current.className = '';
        dobRef.current.className = '';
        idRef.current.className = '';
        ppRef.current.className = '';
        accHolderRef.current.className = '';
        bankBranchNameRef.current.className = '';
        bankAccRef.current.className = '';
        bankBranchCodeRef.current.className = '';
        aboutRef.current.className = '';
        bankNameRef.current.classList.remove('red-border')
        bankTypeRef.current.classList.remove('red-border')


        // Refs
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        const cell = cellRef.current.value;
        const dob = dobRef.current.value;
        const id = idRef.current.value;
        const passport = ppRef.current.value;
        const accountHolder = accHolderRef.current.value;
        const bankBranchName = bankBranchNameRef.current.value;
        const bankAccNum = bankAccRef.current.value;
        const bankBranchCode = bankBranchCodeRef.current.value;
        const about = aboutRef.current.value;

        // First Name
        if (firstName === '') {
            firstNameRef.current.className = 'red-border';
            // firstNameRef.current.scrollIntoView({ behavior: 'smooth'});
            window.scrollTo({ top: 0, behavior: 'smooth'})
            setErrorMessage('Please input your first name')
            return false;
        }

        // Last Name
        if (lastName === '') {
            lastNameRef.current.className = 'red-border';
            window.scrollTo({ top: 0, behavior: 'smooth'})
            setErrorMessage('Please input your last name')
            return false;
        }

        // Cell
        if (cell === '') {
            cellRef.current.className = 'red-border';
            window.scrollTo({ top: 0, behavior: 'smooth'})
            setErrorMessage('Please input your cellphone number')
            return false;
        }

        // DOB
        if (dob === '') {
            dobRef.current.className = 'red-border';
            window.scrollTo({ top: 0, behavior: 'smooth'})
            setErrorMessage('Please input your date of birth')
            return false;
        }

        // ID
        if (id !== '' && id.length !== 13) {
            idRef.current.className = 'red-border';
            window.scrollTo({ top: 0, behavior: 'smooth'})
            setErrorMessage('Please input a valid ID number')
            return false;
        }

        // ID & Passport
        if (passport === '' && id === '') {
            idRef.current.className = 'red-border'
            ppRef.current.className = 'red-border'
            window.scrollTo({ top: 0, behavior: 'smooth'})
            setErrorMessage('Please input either a passport number or an ID number')
            return false;
        }

        // Passport
        if (passport !== '' && ppImage === '') {
            ppRef.current.className = 'red-border'
            window.scrollTo({ top: 0, behavior: 'smooth'})
            setErrorMessage('Please upload a passport image')
            return false;
        }

        // Bank Name
        if (bankSelected === '') {
            bankNameRef.current.classList.add('red-border')
            window.scrollTo({ top: 0, behavior: 'smooth'})
            setErrorMessage('Please select a bank name')
            return false;
        }

        // Bank Type
        if (bankTypeSelected === '') {
            bankTypeRef.current.classList.add('red-border')
            window.scrollTo({ top: 0, behavior: 'smooth'})
            setErrorMessage('Please select an account type')
            return false;
        }
        // Account Number
        if (bankAccNum === '') {
            bankAccRef.current.className = 'red-border';
            window.scrollTo({ top: 0, behavior: 'smooth'})
            setErrorMessage('Please input your bank account number')
            return false;
        }
        // Account Holder
        if (accountHolder === '') {
            accHolderRef.current.className = 'red-border';
            window.scrollTo({ top: 0, behavior: 'smooth'})
            setErrorMessage('Please input the account holder')
            return false;
        }


        // Branch Code
        if (bankBranchCode === '') {
            bankBranchCodeRef.current.className = 'red-border';
            window.scrollTo({ top: 0, behavior: 'smooth'})
            setErrorMessage('Please input your bank branch code')
            return false;
        }

        // Branch Name
        if (bankBranchName === '') {
            bankBranchNameRef.current.className = 'red-border';
            window.scrollTo({ top: 0, behavior: 'smooth'})
            setErrorMessage('Please input your bank branch name')
            return false;
        }



        // About
        if (about === '' || about.length < 40) {
            aboutRef.current.className = 'red-border';
            bankBranchCodeRef.current.scrollIntoView({ behavior: 'smooth'})
            setErrorMessage('Please enter at least 40 characters')
            return false;
        }

        // Image
        if (profileImage === '') {
            // dragRef.current.classList.add('red-border')
            setErrorMessage('Please upload a profile picture')
            return false;
        }


    }

    // Drag n drop
    function dragEnter(evt) {
        console.log('stopping');
        evt.stopPropagation();
        evt.preventDefault();
    }

    function dragOver(evt) {
        evt.stopPropagation();
        evt.preventDefault();
    }

    function imageOnDropEvent(evt) {
        console.log('doing')
        evt.preventDefault();
        setErrorMessage('');
        const currentFile = evt.dataTransfer.files[0];
        setProfileImage(currentFile);
        imageNameRef.current.innerText = currentFile.name;
    }



    return (
        <LoadingOverlay
  active={loading}
  spinner
  text={loadingMessage}
  >
        <div className="profile-creation">

            <div className="pc-header-container">
                <AccountCircleIcon className="account-icon" />
                <h1>Profile Creation</h1>
            </div>
            <p className='mt-20'>Fields marked <span className='req-asterisk'>*</span> are required</p>
            <div className="pc-personal-details">
                <h3>Personal Details</h3>
                <div className="pc-input-row">
                    <div className="pc-input-group">
                        <p>First Name<span className='req-asterisk'>*</span></p>
                        <input ref={firstNameRef} type="text" />
                    </div>
                    <div className="pc-input-group">
                        <p>Last Name<span className='req-asterisk'>*</span></p>
                        <input ref={lastNameRef} type="text" />
                    </div>
                </div>
                {/* <div className="pc-input-row">
                    <div className="pc-input-group">
                        <p>Email</p>
                        <input ref={emailRef} type="text" />
                    </div>
                    <div className="pc-input-group">
                        <p>Phone Number</p>
                        <input ref={phoneRef} type="text" />
                    </div>
                </div> */}
                <div className="pc-input-row">
                    <div className="pc-input-group">
                        <p>Cell Number<span className='req-asterisk'>*</span></p>
                        <input ref={cellRef} type="text" />
                    </div>
                    <div className="pc-input-group">
                        <p>Date of birth<span className='req-asterisk'>*</span></p>
                        <input ref={dobRef} type="date" />
                    </div>
                </div>
                <div className="pc-input-row">
                    <div className="pc-input-group">
                        <p>ID Number</p>
                        <input maxLength={13} ref={idRef} type="text" />
                    </div>
                    <div className="pe-input-group">
                        <p>VAT Number<span className='req-asterisk'></span></p>
                        <input ref={vatRef} type="text" />
                    </div>
                </div>
                <div className="pc-input-row">
                <div className="pc-input-group">
                        <p>Passport Number</p>
                        <input maxLength={20} ref={ppRef} type="text" />
                    </div>
                    <div className="pc-input-group">
                    <p>Upload Passport</p>
                    <div {...getPPRootProps({className: 'pp-dropzone'})}>
                                <input {...getPPInputProps()} />
                                <div className='pp-dzu dz-upload-container'>
                                    {/* No image */}
                                    {(ppImage === '') && <>
                                    <CloudUpload />
                                    {/* <p>Upload Passport Image</p> */}
                                    </> }

                                    {ppImage !== '' && <>
                                        {/* <img alt={ppImage.name} src={ppImage.preview} /> */}
                                        <p>{ppImage.name}</p>

                                    </> }

                                </div>
                    </div>
                    </div>


                </div>
            </div>
            <div className="pc-bank-details">
                <h3>Bank Details</h3>
                <div className="pc-input-row">
                    <div className="pc-input-group">
                        <p>Bank Name<span className='req-asterisk'>*</span></p>
                        <SelectSearch
                  closeOnSelect={true}
                  search={false}
                  placeholder={'Select a bank'}
                  options={bankNameArray}
                  onChange={(value) => {
                  console.log(value);
                  setBankSelected(value)
                  }}
                  ref={bankNameRef}
                  />
                    </div>
                    <div className="pc-input-group">
                        <p>Account Type<span className='req-asterisk'>*</span></p>
                        <SelectSearch
                  closeOnSelect={true}
                  search={false}
                  placeholder={'Select an account type'}
                  options={bankTypeArray}
                  onChange={(value) => {
                  console.log(value);
                    setBankTypeSelected(value)
                  }}
                  ref={bankTypeRef}
                  />
                    </div>

                </div>
                <div className="pc-input-row">
                <div className="pc-input-group">
                        <p>Acc Number<span className='req-asterisk'>*</span></p>
                        <input ref={bankAccRef} type="text" />
                    </div>
                    <div className="pc-input-group">
                        <p>Acc Holder<span className='req-asterisk'>*</span></p>
                        <input ref={accHolderRef} type="text" />
                    </div>

                </div>
                <div className="pc-input-row">

                    <div className="pc-input-group">
                        <p>Branch Code<span className='req-asterisk'>*</span></p>
                        <input ref={bankBranchCodeRef} type="text" />
                    </div>
                    <div className="pc-input-group">
                        <p>Branch Name<span className='req-asterisk'>*</span></p>
                        <input ref={bankBranchNameRef} type="text" />
                    </div>
                </div>
            </div>
            <div className="pc-personal-details">
                <h3>About You</h3>
                <span>Please write a brief description of yourself and your involvement in the facility<span className='req-asterisk'>*</span></span>
                {/* <span>(Min 40 words)</span> */}
                <textarea ref={aboutRef} rows={10} cols={1} />
            </div>

            <div className="pc-personal-details">
                <h3>Profile Pic</h3>
                <span>Put a face to the name<span className='req-asterisk'>*</span></span>
                <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <div className='dz-upload-container'>
                                    {/* No image */}
                                    {(profileImage === '') && <>
                                    <CloudUpload />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                    </> }

                                    {profileImage !== '' && <>
                                        <img alt={profileImage.name} src={profileImage.preview} />
                                        <p>{profileImage.name}</p>

                                    </> }

                                </div>
                    </div>
                {/* <div ref={dragRef} onDragOver={(evt) => dragOver(evt)} onDrop={(evt) => imageOnDropEvent(evt)} onDragEnter={(evt) => dragEnter(evt)}   className="pp-container">
                                    <FontAwesomeIcon className="profile-pic" icon={faUpload} />

                </div> */}
            </div>
            {errorMessage !== '' && <div className="error-message-row mt-20">
                <p>{errorMessage}</p>
            </div>}
            <div className="pc-button-row">
               <button onClick={uploadData}>Finish Set Up</button>
            </div>
        </div>
        </LoadingOverlay>

    )
}

export default ProfileCreation
