import React, { useState, useEffect } from 'react'
import './NotificationSnack.css'
import { useNavigate } from 'react-router';

import Snackbar from '@material-ui/core/Snackbar';
// Material UI
import AddCommentIcon from '@material-ui/icons/AddComment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

function NotificationSnack({title, description, type, documentID, hideNotification}) {

    const [chosenImage, setChosenImage] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        switch (type) {
            case 'booking': setChosenImage(<EventAvailableIcon />)
                break;
            case 'review': setChosenImage(<AddCommentIcon />)
                break;
            default: break;
        }
    }, []);

    function handleClick() {
        if (type === 'booking') {
            navigate('/bookings');
        }
        if (type === 'review') {
            navigate({
                pathname: '/analytics',
                state: {
                    tab: 'review',
                    reviewID: documentID,
                }
            })
        }
    }
    return (

        <div className='notification-snack'>

                  <div className="notification-image">
                {chosenImage}
            </div>
            <div className="notification-content">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
            <div className="notification-exit">
                <p onClick={() => hideNotification(documentID)}>x</p>
            </div>
        </div>
    )
}

export default NotificationSnack
