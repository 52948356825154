import React, {useState, useEffect, useRef } from 'react'
import './DocumentUploader.css'

import CloudUploadIcon from '@mui/icons-material/CloudUpload';


function DocumentUploader({ title, id, profile = false, defaultLink = '', inputFileRef, defaultCertFiles = [], defaultIDFile = null, defaultIDImage = '', defaultIDFileName = '' }) {

    const [idFile, setIdFile] = useState(defaultIDFile)
    const [idImage, setIdImage] = useState(defaultLink)
    const [idFileName, setIdFileName] = useState('')
    const [certFiles, setCertFiles] = useState(defaultCertFiles)

    if (id) {
        inputFileRef.current = idFile;
    } else {
        inputFileRef.current = certFiles;
    }

    useEffect(() => {
        if (defaultIDFile !== null && defaultIDFile !== undefined) {
            let src = URL.createObjectURL(defaultIDFile);
            if (defaultIDFile.type !== 'image/jpeg') {
                src = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png'
            }
            setIdFileName(defaultIDFile.name)
            // setIdFile(currentFile);
            setIdImage(src);
        }

        if (defaultCertFiles !== null && defaultCertFiles !== undefined) {
            for (let i = 0; i < defaultCertFiles.length; i++) {
                const currentFile = defaultCertFiles[i];

                let src = URL.createObjectURL(currentFile);
                if (currentFile.type !== 'image/jpeg') {
                    src = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png'
                }
                    const certMap = {
                        file: currentFile,
                        name: currentFile.name,
                        url: src,
                    }
                    setCertFiles(files => [...files, certMap])
                    }
        }
    }, [])


    const fileRef = useRef();

    // Drag n drop
    function dragEnter(evt) {
        evt.stopPropagation();
        evt.preventDefault();
    }

    function dragOver(evt) {
        evt.stopPropagation();
        evt.preventDefault();
    }

    function imageOnDropEvent(evt) {
        evt.preventDefault();
        for (let i = 0; i < evt.dataTransfer.files.length; i++) {
            const currentFile = evt.dataTransfer.files[i];

            var src = URL.createObjectURL(currentFile);
            if (currentFile.type !== 'image/jpeg') {
                src = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png'
            }
            console.log(currentFile)
            if (id) {
                setIdFileName(currentFile.name)
                setIdFile(currentFile);
                setIdImage(src);
            } else {
                if (certFiles.length < 4) {
                const certMap = {
                    file: currentFile,
                    name: currentFile.name,
                    url: src,
                }
                setCertFiles(files => [...files, certMap])
                console.log(certFiles)
                }
            }
        }


        // let currentBoxes = [...boxesRef.current];
        // setBoxes(current => [...current, <ImageDescription id={current.length} removeItem={removeItem} facilityID={facilityID} disableButtons={disableButtons} setError={setError} file={currentFile} img={src} className="img-desc-box" />])
    }

    function openFileBrowser() {
        fileRef.current.click();
    }

    function imageSelectEvent(evt) {
        for (let i = 0; i < evt.target.files.length; i++) {
            const currentFile = evt.target.files[i];
            var src = URL.createObjectURL(currentFile);
            if (currentFile.type !== 'image/jpeg') {
                src = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png'
            }
            console.log(src)
            if (id) {
                setIdFileName(currentFile.name)
                setIdFile(currentFile);
                setIdImage(src);
            } else {
                if (certFiles.length < 4) {
                const certMap = {
                    file: currentFile,
                    name: currentFile.name,
                    url: src,
                }
                setCertFiles(files => [...files, certMap])
                console.log(certFiles)
                }
            }
        }

    }

    function removeCertFile(file) {
        setCertFiles(files => files.filter(oldFile => oldFile.name !== file.name))
    }

    return (
        <div className='document-uploader'>
            <h4>{title}</h4>
            <div className="d-u-options">
                <span>Drag & Drop or</span>
                <button onClick={openFileBrowser}>Open File Selector</button>
                <input multiple onChange={(e) => imageSelectEvent(e)} ref={fileRef} type='file' />
            </div>
            <div className="d-u-container">
                <div className="d-u-left">
                    <div onDragEnter={dragEnter} onDragOver={dragOver} onDrop={(evt) => imageOnDropEvent(evt)} className="upload-box">
                        <CloudUploadIcon className='ic-upload-icon' />
                    </div>
                </div>
                <div className="d-u-right">
                    {id ?
                     <div className='du-id-container'>
                        {idImage !== '' && <img src={idImage} alt='id' />}
                        <p>{idFileName}</p>
                     </div>
                     :
                     <div className='du-cert-container'>
                         {certFiles.map((file, index) => {
                            return <div className="du-cert-item">
                                 <img src={file.url} alt={`${index}-cert`} />
                                 <p>{file.name}</p>
                                 <span onClick={() => removeCertFile(file)}>x</span>
                             </div>
                         })}
                     </div>
                     }
                </div>
            </div>

        </div>
    )
}

export default DocumentUploader
