import React, { useEffect, useState, useRef } from 'react'
import './StatsSettings.css'

import {db} from '../../../firebase'

import { Checkbox } from '@material-ui/core'

function StatsSettings({ closeSettings, uid, loadStats }) {

    const [selected, setSelected] = useState([]);
    const [update, setUpdate] = useState(0)
    const selectedRef = useRef();
    selectedRef.current = selected;
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        loadSettings(uid);
    }, [])

    async function loadSettings(userID) {
        const userDoc = await db.collection('users').doc(userID).get();
        const user = userDoc.data();
        const {selectedMetrics} = user;
        setSelected(selectedMetrics);
        console.log(selectedMetrics);
        setLoaded(true)
    }

    async function saveSettings() {
        await db.collection('users').doc(uid).update({
            selectedMetrics: selected,
        })
        loadStats()
        closeSettings()
    }

    function handleClick(item) {
        if (selectedRef.current.includes(item)) {
            setSelected(current => current.filter(metric => metric !== item));
        } else {

            setSelected(current => [...current, item]);
        }
        setUpdate(current => current + 1)
    }

    return (
        <div className='stats-settings'>
            <h1>Select which metrics to show:</h1>
            {loaded && <div className="metrics-choices">
                <h3>Income</h3>
                <div className="metrics-row">
                <div className="metrics-choice">
                    <Checkbox onClick={() => handleClick('Total Revenue')} checked={selectedRef.current.includes('Total Revenue')} />
                    <p>Total Revenue</p>
                </div>
                <div className="metrics-choice">
                    <Checkbox onClick={() => handleClick('Revenue / Booking')} checked={selectedRef.current.includes('Revenue / Booking')} />
                    <p>Average Revenue</p>
                </div>
                <div className="metrics-choice">
                    <Checkbox onClick={() => handleClick('Bookings (Month)')} checked={selectedRef.current.includes('Bookings (Month)')}/>
                    <p>Monthly Bookings</p>
                </div>
                <div className="metrics-choice">
                    <Checkbox onClick={() => handleClick('Bookings (All Time)')} checked={selectedRef.current.includes('Bookings (All Time)')} />
                    <p>Total Bookings</p>
                </div>
                </div>
                <h3>Clients</h3>
                <div className="metrics-row">
                <div className="metrics-choice">
                    <Checkbox onClick={() => handleClick('Clients (All Time)')} checked={selectedRef.current.includes('Clients (All Time)')}/>
                    <p>Total Clients</p>
                </div>
                <div className="metrics-choice">
                    <Checkbox onClick={() => handleClick('Total Clients (Month)')} checked={selectedRef.current.includes('Total Clients (Month)')}/>
                    <p>Monthly Clients</p>
                </div>
                <div className="metrics-choice">
                    <Checkbox onClick={() => handleClick('Capacity Taken')} checked={selectedRef.current.includes('Capacity Taken')}/>
                    <p>Capacity</p>
                </div>
                <div className="metrics-choice">
                    <Checkbox onClick={() => handleClick('Occupancy (Month)')} checked={selectedRef.current.includes('Occupancy (Month)')}/>
                    <p>Occupancy</p>
                </div>
                </div>
                <h3>Bulk Purchases</h3>
                <div className="metrics-row">
                <div className="metrics-choice">
                    <Checkbox onClick={() => handleClick('Credits Sold')} checked={selectedRef.current.includes('Credits Sold')} />
                    <p>Credits Sold</p>
                </div>
                <div className="metrics-choice">
                    <Checkbox onClick={() => handleClick('Unused Credits')} checked={selectedRef.current.includes('Unused Credits')} />
                    <p>Unused Credits</p>
                </div>
                </div>
            </div>}
            {(selectedRef.current.length !== 6 && loaded) && <p className="error-msg">Please select 6 metrics to display</p>}
            <div className="metrics-button-row">
            <button disabled={selectedRef.current.length !== 6} onClick={saveSettings}>Save</button>

            </div>
        </div>
    )
}

export default StatsSettings
