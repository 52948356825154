import React, {useState, useRef, useEffect } from 'react'
import './InstructorCreation.css'
import { makeStyles } from '@material-ui/core/styles';

// Auth
import { useAuth } from '../../contexts/AuthContext';
import { db, storage, admin } from '../../firebase';

import { useNavigate } from 'react-router';

// Icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RoomIcon from '@mui/icons-material/Room';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@mui/material/Slider';
import DocumentUploader from './DocumentUploader';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@material-ui/core/Tooltip'

import LoadingOverlay from 'react-loading-overlay';
import Multiselect from 'multiselect-react-dropdown';
import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import uuid from 'react-uuid'

import Geocode from "react-geocode";
import Geohash from 'latlon-geohash';

import RatesBox from './RatesBox';

const useStyles = makeStyles(() => ({
    tooltip: {
      fontSize: '14px',
    },
  }));


const bankNameArray = [
{
    name: 'Absa Group Limited',
    value: 'Absa Group Limited',
},
{
    name: 'African Bank Limited',
    value: 'African Bank Limited',
},
{
    name: 'Bidvest Bank Limited',
    value: 'Bidvest Bank Limited',
},
{
    name: 'Capitec Bank Limited',
    value: 'Capitec Bank Limited',
},
{
    name: 'Discovery Limited',
    value: 'Discovery Limited',
},
{
    name: 'First National Bank',
    value: 'First National Bank',
},
{
    name: 'FirstRand Bank',
    value: 'FirstRand Bank',
},
{
    name: 'Grindrod Bank Limited',
    value: 'Grindrod Bank Limited',
},
{
    name: 'Imperial Bank South Africa',
    value: 'Imperial Bank South Africa',
},
{
    name: 'Investec Bank Limited',
    value: 'Investec Bank Limited',
},
{
    name: 'Nedbank Limited',
    value: 'Nedbank Limited',
},
{
    name: 'Sasfin Bank Limited',
    value: 'Sasfin Bank Limited',
},
{
    name: 'Standard Bank of South Africa',
    value: 'Standard Bank of South Africa',
},
{
    name: 'Ubank Limited',
    value: 'Ubank Limited',
},
{
    name: 'TymeBank',
    value: 'TymeBank',
},

]

const bankTypeArray = [
    {
        name: 'Cheque',
        value: 'Cheque',
    },
    {
        name: 'Savings',
        value: 'Savings,'
    }
]


function InstructorCreation() {

    Geocode.setApiKey("AIzaSyBNVt036Vwj3EMeNMVyTEh9JPqCuZ6xsi8");
    const classes = useStyles();

    const {currentUser} = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState('Setting up your profile...')
    const [countryArray, setCountryArray] = useState([]);
    const [provinceArray, setProvinceArray] = useState([]);
    const [provinceDrop, setProvinceDrop] = useState([]);
    const [provinceDisabled, setProvinceDisabled] = useState(true)
    const [cityArray, setCityArray] = useState([]);
    const [cityDrop, setCityDrop] = useState([]);
    const [cityDisabled, setCityDisabled] = useState(true)
    const [suburbArray, setSuburbArray] = useState([]);
    const [suburbDrop, setSuburbDrop] = useState([]);
    const [suburbDisabled, setSuburbDisabled] = useState([]);
    const [country, setCountry] = useState()
    const [city, setCity] = useState()
    const [province, setProvince] = useState()
    const [suburb, setSuburb] = useState();
    const [currentStep, setCurrentStep] = useState('one')
    const [sliderVal, setSliderVal] = useState(20)
    const [errorMessage, setErrorMessage] = useState('')
    const [locationValue, setLocationValue] = useState('')
    const [placesEmpty, setPlacesEmpty] = useState(false)
    // const [firstName, setFirstName] = useState('')
    // const [lastName, setLastName] = useState('')
    // const [cellNumber, setCellNumber] = useState('')
    // const [bankName, setBankName] = useState('')
    // const [branchName, setBranchName] = useState('')
    // const [accountNumber, setAccountNumber] = useState('')
    // const [branchCode, setBranchCode] = useState('')

    // Payment methods
    const [cardPayments, setCardPayments] = useState(true)
    const [bankPayments, setBankPayments] = useState(false)
    const [cashPayments, setCashPayments] = useState(false)

    const [geoHash, setGeoHash] = useState();
    const [geoPoint, setGeoPoint] = useState()

    const [ptSelected, setPTSelected] = useState(false);
    const [yogaSelected, setYogaSelected] = useState(false);
    const [wnSelected, setWNSelected] = useState(false);
    const [rrSelected, setRRSelected] = useState(false);
    const [bankSelected, setBankSelected] = useState('');
    const [bankTypeSelected, setBankTypeSelected] = useState('');
    const [specialtyArray, setSpecialtyArray] = useState([]);

    const [firstNameStore, setFirstNameStore] = useState('');
    const [lastNameStore, setLastNameStore] = useState('');
    const [cellStore, setCellStore] = useState('');
    const [dobStore, setDobStore] = useState('');
    const [dobVal, setDobVal] = useState('')
    const [bankNameStore, setBankNameStore] = useState('');
    const [bankHolderStore, setBankHolderStore] = useState('');
    const [bankAccNumStore, setBankAccNumStore] = useState('');
    const [bankBranchNameStore, setBankBranchNameStore] = useState('');
    const [bankBranchCodeStore, setBankBranchCodeStore] = useState('');

    const [countryRed, setCountryRed] = useState(false)
    const [provinceRed, setProvinceRed] = useState(false)
    const [cityRed, setCityRed] = useState(false)
    const [suburbRed, setSuburbRed] = useState(false)

    const [wmChecked, setWMChecked] = useState(false);
    const [boxingChecked, setBoxingChecked] = useState(false);
    const [sportsSpecificChecked, setSportsSpecificChecked] = useState(false);
    const [recoveryFocusChecked, setReocveryFocusChecked] = useState(false);
    const [nutritionChecked, setNutritionChecked] = useState(false);
    const [toningShapingChecked, setToningShapingChecked] = useState(false);
    const [bodyBuildingChecked, setBodyBuildingChecked] = useState(false);
    const [prePostChecked, setPrePostChecked] = useState(false);
    const [hiitChecked, setHiitChecked] = useState(false);
    const [flexMobilChecked, setFlexMobilChecked] = useState(false);

    // Rates storage
    // PT
    const [ptFacRateStore, setPtFacRateStore] = useState('');
    const [ptFacCheckedStore, setPtFacCheckedStore] = useState(false);
    const [ptFacLocationStore, setPtFacLocationStore] = useState('');
    const [ptFacLocationCoords, setPtFacLocationCoords] = useState('');
    const [ptODLocationCoords, setPtODLocationCoords] = useState('');
    const [ptFacMemOnlyStore, setPtFacMemOnlyStore] = useState(false)
    const [ptHomeRateStore, setPtHomeRateStore] = useState('');
    const [ptHomeCheckedStore, setPtHomeCheckedStore] = useState(false)
    const [ptODRateStore, setPtODRateStore] = useState('');
    const [ptODCheckedStore, setPtODCheckedStore] = useState(false);
    const [ptODLocationStore, setPtOdLocationStore] = useState('')
    const [ptONRateStore, setPtONRateStore] = useState('');
    const [ptONCheckedStore, setPtONCheckedStore] = useState(false);
    const [ptONLinkStore, setPtONLinkStore] = useState('');
    // YOGA
    const [yogaFacLocationCoords, setYogaFacLocationCoords] = useState('');
    const [yogaODLocationCoords, setYogaODLocationCoords] = useState('');
    const [yogaFacRateStore, setYogaFacRateStore] = useState('');
    const [yogaFacCheckedStore, setYogaFacCheckedStore] = useState(false);
    const [yogaFacLocationStore, setYogaFacLocationStore] = useState('');
    const [yogaFacMemOnlyStore, setYogaFacMemOnlyStore] = useState(false)
    const [yogaHomeRateStore, setYogaHomeRateStore] = useState('');
    const [yogaHomeCheckedStore, setYogaHomeCheckedStore] = useState(false)
    const [yogaODRateStore, setYogaODRateStore] = useState('');
    const [yogaODCheckedStore, setYogaODCheckedStore] = useState(false);
    const [yogaODLocationStore, setYogaOdLocationStore] = useState('')
    const [yogaONRateStore, setYogaONRateStore] = useState('');
    const [yogaONCheckedStore, setYogaONCheckedStore] = useState(false);
    const [yogaONLinkStore, setYogaONLinkStore] = useState('');
    // WN
    const [wnFacLocationCoords, setWNFacLocationCoords] = useState('');
    const [wnODLocationCoords, setWNODLocationCoords] = useState('');
    const [wnFacRateStore, setWnFacRateStore] = useState('');
    const [wnFacCheckedStore, setWnFacCheckedStore] = useState(false);
    const [wnFacLocationStore, setWnFacLocationStore] = useState('');
    const [wnFacMemOnlyStore, setWnFacMemOnlyStore] = useState(false)
    const [wnHomeRateStore, setWnHomeRateStore] = useState('');
    const [wnHomeCheckedStore, setWnHomeCheckedStore] = useState(false)
    const [wnODRateStore, setWnODRateStore] = useState('');
    const [wnODCheckedStore, setWnODCheckedStore] = useState(false);
    const [wnODLocationStore, setWnOdLocationStore] = useState('')
    const [wnONRateStore, setWnONRateStore] = useState('');
    const [wnONCheckedStore, setWnONCheckedStore] = useState(false);
    const [wnONLinkStore, setWnONLinkStore] = useState('');
    // RR
    const [rrFacLocationCoords, setRrFacLocationCoords] = useState('');
    const [rrODLocationCoords, setRrODLocationCoords] = useState('');
    const [rrFacRateStore, setRrFacRateStore] = useState('');
    const [rrFacCheckedStore, setRrFacCheckedStore] = useState(false);
    const [rrFacLocationStore, setRrFacLocationStore] = useState('');
    const [rrFacMemOnlyStore, setRrFacMemOnlyStore] = useState(false)
    const [rrHomeRateStore, setRrHomeRateStore] = useState('');
    const [rrHomeCheckedStore, setRrHomeCheckedStore] = useState(false)
    const [rrODRateStore, setRrODRateStore] = useState('');
    const [rrODCheckedStore, setRrODCheckedStore] = useState(false);
    const [rrODLocationStore, setRrOdLocationStore] = useState('')
    const [rrONRateStore, setRrONRateStore] = useState('');
    const [rrONCheckedStore, setRrONCheckedStore] = useState(false);
    const [rrONLinkStore, setRrONLinkStore] = useState('');

    const [bioStore, setBioStore] = useState('');
    const [expStore, setExptStore] = useState('')
    const [idFileStore, setIDFileStore] = useState(null)
    const [saFileStore, setSAFileStore] = useState(null)
    const [ppFileStore, setPPFileStore] = useState(null)
    const [certArrayStore, setCertArrayStore] = useState([])

    const [yogaDrop, setYogaDrop] = useState('');
    const [rrDrop, setRRDrop] = useState('')

    // Details Refs
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const cellRef = useRef();
    const dobRef = useRef();
    const bankNameRef = useRef();
    const bankBranchNameRef = useRef();
    const bankHolderRef = useRef();
    const bankAccRef = useRef();
    const bankBranchCodeRef = useRef();
    const countryRef = useRef();
    const provinceRef = useRef();
    const cityRef = useRef();
    const suburbRef = useRef();
    const rangeRef = useRef();
    const bioRef = useRef();
    const expRef = useRef();
    const placesRef = useRef();


    // Checkboxes Ref
    const weightManagementRef = useRef();
    const boxingRef = useRef();
    const sportsSpecificRef = useRef();
    const recoveryFocusedRef = useRef();
    const nutritionRef = useRef();
    const toningShapingRef = useRef();
    const bodyBuildingRef = useRef();
    const prePostNatalRef = useRef();
    const hiitRef = useRef();
    const flexMobilRef = useRef();

    const specInputRef = useRef();

    const idRef = useRef();
    const certRef = useRef();
    const profilePicRef = useRef();
    const serviceAgreementRef = useRef();

    // Rates ref
    const ptFac = useRef();
    const ptHome = useRef();
    const ptOutdoor = useRef();
    const ptOnline = useRef();
    const yogaFac = useRef();
    const yogaHome = useRef();
    const yogaOutdoor = useRef();
    const yogaOnline = useRef();
    const yogaDropRef = useRef();
    const wnFac = useRef();
    const wnHome = useRef();
    const wnOutdoor = useRef();
    const wnOnline = useRef();
    const rrFac = useRef();
    const rrHome = useRef();
    const rrOutdoor = useRef();
    const rrOnline = useRef();
    const rrDropRef = useRef();

    const ptOnlineLinkRef = useRef();
    const yogaOnlineLinkRef = useRef();
    const wnOnlineLinkRef = useRef();
    const rrOnlineLinkRef = useRef();

    const ptFacLocation = useRef();
    const yogaFacLocation = useRef();
    const wnFacLocation = useRef();
    const rrFacLocation = useRef();

    const ptOutdoorLocation = useRef();
    const yogaOutdoorLocation = useRef();
    const wnOutdoorLocation = useRef();
    const rrOutdoorLocation = useRef();

    const ptFacMembersOnly = useRef();
    const yogaFacMembersOnly = useRef();
    const wnFacMembersOnly = useRef();
    const rrFacMembersOnly = useRef();

    const ptFacChecked = useRef();
    const ptHomeChecked = useRef();
    const ptOutdoorChecked = useRef();
    const ptOnlineChecked = useRef();
    const yogaFacChecked = useRef();
    const yogaHomeChecked = useRef();
    const yogaOutdoorChecked = useRef();
    const yogaOnlineChecked = useRef();
    const wnFacChecked = useRef();
    const wnHomeChecked = useRef();
    const wnOutdoorChecked = useRef();
    const wnOnlineChecked = useRef();
    const rrFacChecked = useRef();
    const rrHomeChecked = useRef();
    const rrOutdoorChecked = useRef();
    const rrOnlineChecked = useRef();

    const ptFacCoordsRef = useRef();
    const ptODCoordsRef = useRef();
    const yogaFacCoordsRef = useRef();
    const yogaODCoordsRef = useRef();
    const wnFacCoordsRef = useRef();
    const wnODCoordsRef = useRef();
    const rrFacCoordsRef = useRef();
    const rrODCoordsRef = useRef();

    useEffect(() => {
        loadLocations()
    }, [])

    async function loadLocations() {

        const countryArr = [];
        const locationDoc = await db.collection('eliteData').doc('locations').get();
        const location = locationDoc.data();
        const countries = location.countries;
        const cities = location.cities;
        const suburbs = location.suburbs;
        const provinces = location.provinces;

        for (var i = 0; i < countries.length; i++) {
            countryArr.push({
                name: countries[i],
            })
        }

        setCountryArray(countryArr)
        setCityArray(cities)
        setProvinceArray(provinces)
        setSuburbArray(suburbs)

    }

    function compare( a, b ) {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      }

    async function createDocument({ uid, email }) {
        await admin.functions().httpsCallable('newInstructorSignUp')({ uid, email});
        return
    }

    function changeProvinceArray(evt, type) {
        if (type === 'add') {
            setCountry(evt[0].name);
            const country = (evt[0].name);
            const array = [];
            for (var i = 0; i < provinceArray[country].length; i++) {
                array.push({
                    name: provinceArray[country][i]
                })
            }
            console.log(array)
            array.sort(compare);
            setProvinceDrop(array)
            setProvinceDisabled(false)
        }

        if (type === 'remove') {
            setCountry('')
            setProvinceDrop([])
            setProvinceDisabled(true)
        }
    }

    function changeCityArray(evt, type) {
        console.log(evt);
        if (type === 'add') {
            setProvince(evt[0].name);
            const province = (evt[0].name);
            const array = [];
            for (var i = 0; i < cityArray[province].length; i++) {
                array.push({
                    name: cityArray[province][i],
                })
            }
            array.sort(compare);
            setCityDrop(array);
            setCityDisabled(false);
        }

        if (type === 'remove') {
            setProvince('')
            setCityDrop([]);
            setCityDisabled(true);
        }
    }

    function changeSuburbArray(evt, type) {
        if (type === 'add') {
            setCity(evt[0].name);
            const city = evt[0].name;
            const array = [];
            for (var i = 0; i < suburbArray[city].length; i++) {
                array.push({
                    name: suburbArray[city][i],
                })
            }
            array.sort(compare);
            setSuburbDrop(array);
            setSuburbDisabled(false);
        }

        if (type === 'remove') {
            setCity('')
            setSuburbDisabled(true);
            setSuburbDrop([]);
            suburbRef.current.resetSelectedValues();
        }
    }


    async function uploadDetails(uid) {
        const accountStatus = 'pending';
        const addedSpecialties = [];
        const enabledDisciplines = {
            'biokineticist': false,
            'personalTraining': false,
            'physiotherapist': false,
            'pilates': false,
            'recovery': false,
            'wellness': false,
            'yoga': false,
            'yoga&pilates': false,
            'yogaOnly': false,
        }
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        let lowestPrice;
        const phonenumber = cellRef.current.value;
        const sessionsRadius = 0;

        // Bank Details
        const bankAccHolder = `${firstName} ${lastName}`;
        const bankAccNum = bankAccRef.current.value;
        const bankBranchCode = bankBranchCodeRef.current.value;
        const bankName = bankNameRef.current.value;
        const bankAccType = '';

        await db.collection('users').doc(uid).collection('confidentialInfo').doc('bankDetails').update({
            bankAccHolder,
            bankAccNum,
            bankBranchCode,
            bankAccType,
            bankName,
        });

        // Training Preferences

    }

    function handleClick(item) {
        if (specialtyArray.includes(item)) {
            setSpecialtyArray(list => list.filter(spec => spec !== item))
            // switch (item) {
            //     case 'Weight Management': setWMChecked(false)
            //         break;
            //     case 'Boxing': setBoxingChecked(false)
            //         break;
            //     case 'Sports Specific Training': setSportsSpecificChecked(false)
            //         break;
            //     case 'Recovery Focused Training': setReocveryFocusChecked(false)
            //         break;
            //     case 'Nutrition': setNutritionChecked(false)
            //         break;
            //     case 'Toning and Shaping': setToningShapingChecked(false)
            //         break;
            //     case 'Body Building': setBodyBuildingChecked(false)
            //         break;
            //     case 'Pre and Postnatal': setPrePostChecked(false)
            //         break;
            //     case 'HIIT': setHiitChecked(false)
            //         break;
            //     case 'Flexibility and Mobility': setFlexMobilChecked(false)
            //         break;
            //     default: break;
            // }

        } else {
            setSpecialtyArray(list => [...list, item])

        }
    }

    function validateStepOne() {
        return setCurrentStep('two')
        // console.log(countryRef.current)
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        const cellNumber = cellRef.current.value;
        const dob = dobRef.current.valueAsDate;
        const accNumber = bankAccRef.current.value;
        const bankHolder = bankHolderRef.current.value;
        const branchName = bankBranchNameRef.current.value;
        const branchCode = bankBranchCodeRef.current.value;
        console.log(dob)


        firstNameRef.current.style.borderColor = 'transparent'
        lastNameRef.current.style.borderColor = 'transparent'
        cellRef.current.style.borderColor = 'transparent'
        dobRef.current.style.borderColor = 'transparent'
        bankAccRef.current.style.borderColor = 'transparent'
        bankHolderRef.current.style.borderColor = 'transparent'
        bankBranchNameRef.current.style.borderColor = 'transparent'
        bankBranchCodeRef.current.style.borderColor = 'transparent'
        setPlacesEmpty(false)

        setFirstNameStore(firstName)
        setLastNameStore(lastName);
        setCellStore(cellNumber);
        setDobStore(dob);
        setDobVal(dobRef.current.value)
        setBankAccNumStore(accNumber);
        setBankHolderStore(bankHolder);
        setBankBranchNameStore(branchName)
        setBankBranchCodeStore(branchCode);

        setCountryRed(false)
        setProvinceRed(false)
        setCityRed(false)
        setSuburbRed(false)

        if (locationValue === '') {
            return setPlacesEmpty(true)
        }

        // return setCurrentStep('two')
        if (firstName === '') {

            window.scrollTo({top:0,behavior:'smooth'})
           return firstNameRef.current.style.borderColor = '#D94A4A';
        }

        if (lastName === '') {
            window.scrollTo({top:0,behavior:'smooth'})
         return lastNameRef.current.style.borderColor = '#D94A4A';
        }

        if (cellNumber === '') {
            window.scrollTo({top:0,behavior:'smooth'})
         return cellRef.current.style.borderColor = '#D94A4A';
        }

        if (dob === '' || dob === null) {
            window.scrollTo({top:0,behavior:'smooth'})
         return dobRef.current.style.borderColor = '#D94A4A';
        }

        if (accNumber === '') {
            window.scrollTo({top:0,behavior:'smooth'})

         return bankAccRef.current.style.borderColor = '#D94A4A';
        }

        if (bankHolder === '') {
            window.scrollTo({top:0,behavior:'smooth'})
         return bankHolderRef.current.style.borderColor = '#D94A4A';
        }

        if (branchName === '') {
            window.scrollTo({top:0,behavior:'smooth'})
         return bankBranchNameRef.current.style.borderColor = '#D94A4A';
        }

        if (branchCode === '') {
            window.scrollTo({top:0,behavior:'smooth'})
         return bankBranchCodeRef.current.style.borderColor = '#D94A4A';
        }

        if (country === undefined || country === '') {
            return setCountryRed(true);
        }
        if (province === undefined || province === '') {
            return setProvinceRed(true);
        }
        if (city === undefined || city === '') {
            return setCityRed(true);
        }
        if (suburb === undefined || suburb === '') {
            return setSuburbRed(true);
        }
        if (locationValue === '') {
            return setPlacesEmpty(true)
        }

        setCurrentStep('two')

    }

    function validateStepTwo() {

        // return setCurrentStep('three')

        setErrorMessage('');

        if ((ptSelected + yogaSelected + wnSelected + rrSelected) === 0) {
            return setErrorMessage('Please select at lease one discipline')
        }

        // Personal Training
        if (ptSelected) {
            ptFac.current.focus();
            ptHome.current.focus();
            ptOutdoor.current.focus();
            ptOnline.current.focus();
            ptOnlineLinkRef.current.focus();

            const ptFacRate = ptFac.current.value;
            const ptHomeRate = ptHome.current.value;
            const ptODRate = ptOutdoor.current.value;
            const ptONRate = ptOnline.current.value;
            const ptOnlineLink = ptOnlineLinkRef.current.value;

            const facChecked = ptFacChecked.current;
            const homeChecked = ptHomeChecked.current;
            const odChecked = ptOutdoorChecked.current;
            const onChecked = ptOnlineChecked.current;

            const facCoords = ptFacCoordsRef.current ?? '';
            const odCoords = ptODCoordsRef.current ?? '';

            const ptFacLoc = ptFacLocation.current;
            const ptFacMemOnly = ptFacMembersOnly.current.value;
            const ptODLoc = ptOutdoorLocation.current;

            console.log(facChecked, )
            console.log(facCoords)

            // Overall
            if ((facChecked + homeChecked + odChecked + onChecked) === 0) {
                console.log('init')
                return setErrorMessage('Please provide at least one venue rate')
            }

            // Facility
            if (facChecked && ptFacRate === '') {
                return setErrorMessage('Please provide a facility rate for your personal training services')
            }
            if (facChecked && facCoords === undefined) {
                return setErrorMessage('Please provide a location for your personal training services')
            }

            // Home
            if (homeChecked && ptHomeRate === '') {
                return setErrorMessage('Please provide a home rate for your personal training services')
            }

            // Outdoor
            if (odChecked && ptODRate === '') {
                return setErrorMessage('Please provide an outdoor rate for your personal training services')
            }
            if (odChecked && odCoords === undefined) {
                return setErrorMessage('Please provide an outdoor rate for your personal training services')
            }

            // Online
            if (onChecked && ptONRate === '') {
                return setErrorMessage('Please provide an online rate for your personal training services')
            }

            if (onChecked && ptOnlineLink === '') {
                return setErrorMessage('Please provide a link for your online classes')
            }

            setPtFacCheckedStore(facChecked);
            setPtHomeCheckedStore(homeChecked);
            setPtODCheckedStore(odChecked);
            setPtONCheckedStore(onChecked);
            setPtFacRateStore(ptFacRate);
            setPtHomeRateStore(ptHomeRate);
            setPtODRateStore(ptODRate);
            setPtONRateStore(ptONRate);
            setPtFacLocationStore(ptFacLoc);
            setPtFacMemOnlyStore(ptFacMemOnly)
            setPtOdLocationStore(ptODLoc);
            setPtFacLocationCoords(facCoords)
            setPtODLocationCoords(odCoords)

        }

        if (yogaSelected) {
            yogaFac.current.focus();
            yogaHome.current.focus();
            yogaOutdoor.current.focus();
            yogaOnline.current.focus();
            yogaOnlineLinkRef.current.focus();

            const yogaFacRate = yogaFac.current.value;
            const yogaHomeRate = yogaHome.current.value;
            const yogaODRate = yogaOutdoor.current.value;
            const yogaONRate = yogaOnline.current.value;
            const yogaOnlineLink = yogaOnlineLinkRef.current.value;

            const facChecked = yogaFacChecked.current;
            const homeChecked = yogaHomeChecked.current;
            const odChecked = yogaOutdoorChecked.current;
            const onChecked = yogaOnlineChecked.current;

            const facCoords = yogaFacCoordsRef.current;
            const odCoords = yogaODCoordsRef.current;

            const yogaFacLoc = yogaFacLocation.current;
            const yogaFacMemOnly = yogaFacMembersOnly.current.value;
            const yogaODLoc = yogaOutdoorLocation.current;

            console.log(facChecked, )

            // Overall
            if ((facChecked + homeChecked + odChecked + onChecked) === 0) {
                console.log('init')
                return setErrorMessage('Please provide at least one venue rate')
            }

            // Specialty
            if (yogaDrop === '') {
                return setErrorMessage('Please select a primary speciality')
            }

            // Facility
            if (facChecked && yogaFacRate === '') {
                return setErrorMessage('Please provide a facility rate for your yoga services')
            }
            if (facChecked && facCoords === undefined) {
                return setErrorMessage('Please provide a facility location for your yoga services')
            }

            // Home
            if (homeChecked && yogaHomeRate === '') {
                return setErrorMessage('Please provide a home rate for your yoga services')
            }

            // Outdoor
            if (odChecked && yogaODRate === '') {
                return setErrorMessage('Please provide an outdoor rate for your yoga services')
            }
            if (odChecked && odCoords === undefined) {
                return setErrorMessage('Please provide an outdoor location for your yoga services')
            }

            // Online
            if (onChecked && yogaONRate === '') {
                return setErrorMessage('Please provide an online rate for your yoga services')
            }

            if (onChecked && yogaOnlineLink === '') {
                return setErrorMessage('Please provide a link for your online classes')
            }

            setYogaFacCheckedStore(facChecked);
            setYogaHomeCheckedStore(homeChecked);
            setYogaODCheckedStore(odChecked);
            setYogaONCheckedStore(onChecked);
            setYogaFacRateStore(yogaFacRate);
            setYogaHomeRateStore(yogaHomeRate);
            setYogaODRateStore(yogaODRate);
            setYogaONRateStore(yogaONRate);
            setYogaFacLocationStore(yogaFacLoc);
            setYogaFacMemOnlyStore(yogaFacMemOnly)
            setYogaOdLocationStore(yogaODLoc);
            setYogaFacLocationCoords(facCoords)
            setYogaODLocationCoords(odCoords)
        }

        if (wnSelected) {
            wnFac.current.focus();
            wnHome.current.focus();
            wnOutdoor.current.focus();
            wnOnline.current.focus();
            wnOnlineLinkRef.current.focus();

            const wnFacRate = wnFac.current.value;
            const wnHomeRate = ptHome.current.value;
            const wnODRate = wnOutdoor.current.value;
            const wnONRate = wnOnline.current.value;
            const wnOnlineLink = wnOnlineLinkRef.current.value;

            const facChecked = wnFacChecked.current;
            const homeChecked = wnHomeChecked.current;
            const odChecked = wnOutdoorChecked.current;
            const onChecked = wnOnlineChecked.current;

            const facCoords = wnFacCoordsRef.current;
            const odCoords = wnODCoordsRef.current;

            const wnFacLoc = wnFacLocation.current;
            const wnFacMemOnly = wnFacMembersOnly.current.value;
            const wnODLoc = wnOutdoorLocation.current;

            console.log(facChecked, )

            // Overall
            if ((facChecked + homeChecked + odChecked + onChecked) === 0) {
                console.log('init')
                return setErrorMessage('Please provide at least one venue rate')
            }

            // Facility
            if (facChecked && wnFacRate === '') {
                return setErrorMessage('Please provide a facility rate for your wellness services')
            }
            if (facChecked && facCoords === undefined) {
                return setErrorMessage('Please provide a facility location for your wellness services')
            }

            // Home
            if (homeChecked && wnHomeRate === '') {
                return setErrorMessage('Please provide a home rate for your wellness services')
            }

            // Outdoor
            if (odChecked && wnODRate === '') {
                return setErrorMessage('Please provide an outdoor rate for your wellness services')
            }
            if (odChecked && odCoords === undefined) {
                return setErrorMessage('Please provide an outdoor location for your wellness services')
            }

            // Online
            if (onChecked && wnONRate === '') {
                return setErrorMessage('Please provide an online rate for your wellness services')
            }

            if (onChecked && wnOnlineLink === '') {
                return setErrorMessage('Please provide a link for your online classes')
            }

            setWnFacCheckedStore(facChecked);
            setWnHomeCheckedStore(homeChecked);
            setWnODCheckedStore(odChecked);
            setWnONCheckedStore(onChecked);
            setWnFacRateStore(wnFacRate);
            setWnHomeRateStore(wnHomeRate);
            setWnODRateStore(wnODRate);
            setWnONRateStore(wnONRate);
            setWnFacLocationStore(wnFacLoc);
            setWnFacMemOnlyStore(wnFacMemOnly)
            setWnOdLocationStore(wnODLoc);
            setWNFacLocationCoords(facCoords)
            setWNODLocationCoords(odCoords)
        }
        if (rrSelected) {
            rrFac.current.focus();
            rrHome.current.focus();
            rrOutdoor.current.focus();
            rrOnline.current.focus();
            rrOnlineLinkRef.current.focus();

            const rrFacRate = rrFac.current.value;
            const rrHomeRate = ptHome.current.value;
            const rrODRate = rrOutdoor.current.value;
            const rrONRate = rrOnline.current.value;
            const rrOnlineLink = rrOnlineLinkRef.current.value;

            const facChecked = rrFacChecked.current;
            const homeChecked = rrHomeChecked.current;
            const odChecked = rrOutdoorChecked.current;
            const onChecked = rrOnlineChecked.current;

            const facCoords = rrFacCoordsRef.current;
            const odCoords = rrODCoordsRef.current;

            const rrFacLoc = rrFacLocation.current;
            const rrFacMemOnly = rrFacMembersOnly.current.value;
            const rrODLoc = rrOutdoorLocation.current;

            console.log(facChecked, )

            // Overall
            if ((facChecked + homeChecked + odChecked + onChecked) === 0) {
                console.log('init')
                return setErrorMessage('Please provide at least one venue rate')
            }

            if (rrDrop === '') {
                return setErrorMessage('Please select a primary speciality')
            }

            // Facility
            if (facChecked && rrFacRate === '') {
                return setErrorMessage('Please provide a facility rate for your recovery services')
            }
            if (facChecked && facCoords === undefined) {
                return setErrorMessage('Please provide a facility location for your recovery services')
            }

            // Home
            if (homeChecked && rrHomeRate === '') {
                return setErrorMessage('Please provide a home rate for your recovery services')
            }

            // Outdoor
            if (odChecked && rrODRate === '') {
                return setErrorMessage('Please provide an outdoor rate for your recovery services')
            }
            if (odChecked && odCoords === undefined) {
                return setErrorMessage('Please provide an outdoor location for your recovery services')
            }

            // Online
            if (onChecked && rrONRate === '') {
                return setErrorMessage('Please provide an online rate for your recovery services')
            }

            if (onChecked && rrOnlineLink === '') {
                return setErrorMessage('Please provide a link for your online classes')
            }

            setRrFacCheckedStore(facChecked);
            setRrHomeCheckedStore(homeChecked);
            setRrODCheckedStore(odChecked);
            setRrONCheckedStore(onChecked);
            setRrFacRateStore(rrFacRate);
            setRrHomeRateStore(rrHomeRate);
            setRrODRateStore(rrODRate);
            setRrONRateStore(rrONRate);
            setRrFacLocationStore(rrFacLoc);
            setRrFacMemOnlyStore(rrFacMemOnly)
            setRrOdLocationStore(rrODLoc);
            setRrFacLocationCoords(facCoords)
            setRrODLocationCoords(odCoords)
        }

        return setCurrentStep('three')
    }

    function storeStepTwo() {
        if (ptSelected) {
            ptFac.current.focus();
            ptHome.current.focus();
            ptOutdoor.current.focus();
            ptOnline.current.focus();
            ptOnlineLinkRef.current.focus();

            const ptFacRate = ptFac.current.value;
            const ptHomeRate = ptHome.current.value;
            const ptODRate = ptOutdoor.current.value;
            const ptONRate = ptOnline.current.value;
            const ptOnlineLink = ptOnlineLinkRef.current.value;

            const facChecked = ptFacChecked.current;
            const homeChecked = ptHomeChecked.current;
            const odChecked = ptOutdoorChecked.current;
            const onChecked = ptOnlineChecked.current;

            const ptFacLoc = ptFacLocation.current;
            const ptFacMemOnly = ptFacMembersOnly.current.value;
            const ptODLoc = ptOutdoorLocation.current;

            const facCoords = ptFacCoordsRef.current;
            const odCoords = ptODCoordsRef.current;

            setPtFacCheckedStore(facChecked);
            setPtHomeCheckedStore(homeChecked);
            setPtODCheckedStore(odChecked);
            setPtONCheckedStore(onChecked);
            setPtFacRateStore(ptFacRate);
            setPtHomeRateStore(ptHomeRate);
            setPtODRateStore(ptODRate);
            setPtONRateStore(ptONRate);
            setPtFacLocationStore(ptFacLoc);
            setPtFacMemOnlyStore(ptFacMemOnly)
            setPtOdLocationStore(ptODLoc);
            setPtONLinkStore(ptOnlineLink);
            setPtFacLocationCoords(facCoords)
            setPtODLocationCoords(odCoords)

        }

        if (yogaSelected) {
            yogaFac.current.focus();
            yogaHome.current.focus();
            yogaOutdoor.current.focus();
            yogaOnline.current.focus();
            yogaOnlineLinkRef.current.focus();

            const yogaFacRate = yogaFac.current.value;
            const yogaHomeRate = yogaHome.current.value;
            const yogaODRate = yogaOutdoor.current.value;
            const yogaONRate = yogaOnline.current.value;
            const yogaOnlineLink = yogaOnlineLinkRef.current.value;

            const facChecked = yogaFacChecked.current;
            const homeChecked = yogaHomeChecked.current;
            const odChecked = yogaOutdoorChecked.current;
            const onChecked = yogaOnlineChecked.current;

            const yogaFacLoc = yogaFacLocation.current;
            const yogaFacMemOnly = yogaFacMembersOnly.current.value;
            const yogaODLoc = yogaOutdoorLocation.current;

            const facCoords = yogaFacCoordsRef.current;
            const odCoords = yogaODCoordsRef.current;

            setYogaFacCheckedStore(facChecked);
            setYogaHomeCheckedStore(homeChecked);
            setYogaODCheckedStore(odChecked);
            setYogaONCheckedStore(onChecked);
            setYogaFacRateStore(yogaFacRate);
            setYogaHomeRateStore(yogaHomeRate);
            setYogaODRateStore(yogaODRate);
            setYogaONRateStore(yogaONRate);
            setYogaFacLocationStore(yogaFacLoc);
            setYogaFacMemOnlyStore(yogaFacMemOnly)
            setYogaOdLocationStore(yogaODLoc);
            setYogaONLinkStore(yogaOnlineLink);
            setYogaFacLocationCoords(facCoords)
            setYogaODLocationCoords(odCoords)
        }

        if (wnSelected) {
            wnFac.current.focus();
            wnHome.current.focus();
            wnOutdoor.current.focus();
            wnOnline.current.focus();
            wnOnlineLinkRef.current.focus();

            const wnFacRate = wnFac.current.value;
            const wnHomeRate = ptHome.current.value;
            const wnODRate = wnOutdoor.current.value;
            const wnONRate = wnOnline.current.value;
            const wnOnlineLink = wnOnlineLinkRef.current.value;

            const facChecked = wnFacChecked.current;
            const homeChecked = wnHomeChecked.current;
            const odChecked = wnOutdoorChecked.current;
            const onChecked = wnOnlineChecked.current;

            const wnFacLoc = wnFacLocation.current;
            const wnFacMemOnly = wnFacMembersOnly.current.value;
            const wnODLoc = wnOutdoorLocation.current;

            const facCoords = wnFacCoordsRef.current;
            const odCoords = wnODCoordsRef.current;

            setWnFacCheckedStore(facChecked);
            setWnHomeCheckedStore(homeChecked);
            setWnODCheckedStore(odChecked);
            setWnONCheckedStore(onChecked);
            setWnFacRateStore(wnFacRate);
            setWnHomeRateStore(wnHomeRate);
            setWnODRateStore(wnODRate);
            setWnONRateStore(wnONRate);
            setWnFacLocationStore(wnFacLoc);
            setWnFacMemOnlyStore(wnFacMemOnly)
            setWnOdLocationStore(wnODLoc);
            setWnONLinkStore(wnOnlineLink);
            setWNFacLocationCoords(facCoords)
            setWNODLocationCoords(odCoords)
        }
        if (rrSelected) {
            rrFac.current.focus();
            rrHome.current.focus();
            rrOutdoor.current.focus();
            rrOnline.current.focus();
            rrOnlineLinkRef.current.focus();

            const rrFacRate = rrFac.current.value;
            const rrHomeRate = ptHome.current.value;
            const rrODRate = rrOutdoor.current.value;
            const rrONRate = rrOnline.current.value;
            const rrOnlineLink = rrOnlineLinkRef.current.value;

            const facChecked = rrFacChecked.current;
            const homeChecked = rrHomeChecked.current;
            const odChecked = rrOutdoorChecked.current;
            const onChecked = rrOnlineChecked.current;

            const rrFacLoc = rrFacLocation.current;
            const rrFacMemOnly = rrFacMembersOnly.current.value;
            const rrODLoc = rrOutdoorLocation.current;

            const facCoords = rrFacCoordsRef.current;
            const odCoords = rrODCoordsRef.current;

            setRrFacCheckedStore(facChecked);
            setRrHomeCheckedStore(homeChecked);
            setRrODCheckedStore(odChecked);
            setRrONCheckedStore(onChecked);
            setRrFacRateStore(rrFacRate);
            setRrHomeRateStore(rrHomeRate);
            setRrODRateStore(rrODRate);
            setRrONRateStore(rrONRate);
            setRrFacLocationStore(rrFacLoc);
            setRrFacMemOnlyStore(rrFacMemOnly)
            setRrOdLocationStore(rrODLoc);
            setRrONLinkStore(rrOnlineLink)
            setRrFacLocationCoords(facCoords)
            setRrODLocationCoords(odCoords)
        }
        return setCurrentStep('one');
    }

    function saveStepThree() {
        setErrorMessage('')
        setBioStore(bioRef.current.value);
        setExptStore(expRef.current.value)
        setIDFileStore(idRef.current);
        setSAFileStore(serviceAgreementRef.current)
        setPPFileStore(profilePicRef.current)
        setCertArrayStore(certRef.current)
        return setCurrentStep('two')
    }

    async function uploadData() {
        setLoading(true)

        let lowestRate = 0;


        if (profilePicRef.current === null) {
            setLoading(false)
            return setErrorMessage('Please upload a profile picture')
        }



        // Personal Details
        const firstName = firstNameStore;
        const lastName = lastNameStore;
        const phonenumber = cellStore;
        const dateOfBirth = dobVal;
        const bioText = bioRef.current.value;
        const experianceText = expRef.current.value;

        // return console.log(firstName);

        // Bank Details
        const bankAccHolder = bankHolderStore;
        const bankAccNum = bankAccNumStore;
        const bankAccType = bankTypeSelected;
        const bankBranchCode = bankBranchCodeStore;
        const bankBranchName = bankBranchNameStore
        const bankName = bankSelected;
        const paymentMethods = {
            acceptCardPayments: cardPayments,
            acceptCashPayments: cashPayments,
            acceptEFTPayments: bankPayments,
        }

        // Location
        const locationHash = geoHash;
        const locationPoint = geoPoint;
        const sessionsRadius = sliderVal;

        let atFacility = false;
        let atHome = false;
        let online = false;
        let outdoor = false;

        // Disciplines & Rates
        if (ptSelected) {
            // ptFacChecked.current.focus();
            // ptHomeChecked.current.focus();
            // ptOutdoorChecked.current.focus();
            // ptOnlineChecked.current.focus();
            // ptFac.current.focus();
            // ptHome.current.focus();
            // ptOutdoor.current.focus();
            // ptOnline.current.focus();

            if (ptFacCheckedStore) {
                atFacility = true;
            }

            if (ptHomeCheckedStore) {
                atHome = true;
            }

            if (ptONCheckedStore) {
                online = true;
            }

            if (ptODCheckedStore) {
                outdoor = true;
            }

            console.log(ptFacLocationStore)

            const facilityPrice = ptFacRateStore === '' ? 0 : ptFacRateStore;
            const homePrice = ptHomeRateStore === '' ? 0 : ptHomeRateStore;
            const outdoorPrice = ptODRateStore === '' ? 0 : ptODRateStore;
            const onlinePrice = ptONRateStore === '' ? 0 : ptONRateStore;
            const facilityLocationPoint = ptFacLocationCoords;
            const facilityLocation = ptFacLocationStore.label ?? '';
            const facilityMembersOnly = ptFacMembersOnly;
            const onlineLocation = ptONLinkStore;
            const outdoorLocation = ptODLocationStore.label ?? '';
            const outdoorLocationPoint = ptODLocationCoords;

            console.log(outdoorLocationPoint)

            try {
                await db.collection('users').doc(currentUser.uid).collection('trainingPrefs').doc('personalTraining').update({
                    enabled: true,
                    facilityPrice,
                    homePrice,
                    outdoorPrice,
                    onlinePrice,
                    facilityLocationPoint,
                    facilityLocation,
                    facilityMembersOnly,
                    onlineLocation,
                    outdoorLocation,
                    outdoorLocationPoint,
                })
            } catch (err) {
                setLoading(false);
                console.log(err)
                console.log(1)
                return setErrorMessage(err)
                // return setErrorMessage('Could not update your profile, please try again.')
            }
        }

        if (yogaSelected) {
            // yogaFacChecked.current.focus();
            // yogaHomeChecked.current.focus();
            // yogaOutdoorChecked.current.focus();
            // yogaOnlineChecked.current.focus();
            // yogaFac.current.focus();
            // yogaHome.current.focus();
            // yogaOutdoor.current.focus();
            // yogaOnline.current.focus();

            if (yogaFacCheckedStore) {
                atFacility = true;
            }

            if (yogaHomeCheckedStore) {
                atHome = true;
            }

            if (yogaONCheckedStore) {
                online = true;
            }

            if (yogaODCheckedStore) {
                outdoor = true;
            }

            const facilityPrice = yogaFacRateStore === '' ? 0 : yogaFacRateStore;
            const homePrice = yogaHomeRateStore === '' ? 0 : yogaHomeRateStore;
            const outdoorPrice = yogaODRateStore === '' ? 0 : yogaODRateStore;
            const onlinePrice = yogaONRateStore === '' ? 0 : yogaONRateStore;
            const facilityLocationPoint = yogaFacLocationCoords;
            const facilityLocation = yogaFacLocationStore.label ?? '';
            const facilityMembersOnly = yogaFacMembersOnly;
            const onlineLocation = yogaONLinkStore;
            const outdoorLocation = yogaODLocationStore.label ?? '';
            let outdoorLocationPoint = yogaODLocationCoords;
            if (outdoorLocationPoint === undefined) {
                outdoorLocationPoint = '';
            }



            try {
                await db.collection('users').doc(currentUser.uid).collection('trainingPrefs').doc('yoga').update({
                    enabled: true,
                    facilityPrice,
                    homePrice,
                    outdoorPrice,
                    onlinePrice,
                    facilityLocationPoint,
                    facilityLocation,
                    facilityMembersOnly,
                    onlineLocation,
                    outdoorLocation,
                    outdoorLocationPoint,
                    primarySpeciality: yogaDrop,
                })
            } catch (err) {
                setLoading(false);
                console.log(err)
                console.log(2)
                return setErrorMessage(err)
                // return setErrorMessage('Could not update your profile, please try again')
            }
        }

        if (wnSelected) {
            wnFacChecked.current.focus();
            wnHomeChecked.current.focus();
            wnOutdoorChecked.current.focus();
            wnOnlineChecked.current.focus();
            wnFac.current.focus();
            wnHome.current.focus();
            wnOutdoor.current.focus();
            wnOnline.current.focus();

            if (wnFacCheckedStore) {
                atFacility = true;
            }

            if (wnHomeCheckedStore) {
                atHome = true;
            }

            if (wnONCheckedStore) {
                online = true;
            }

            if (wnODCheckedStore) {
                outdoor = true;
            }

            const facilityPrice = wnFacRateStore === '' ? 0 : wnFacRateStore;
            const homePrice = wnHomeRateStore === '' ? 0 : wnHomeRateStore;
            const outdoorPrice = wnODRateStore === '' ? 0 : wnODRateStore;
            const onlinePrice = wnONRateStore === '' ? 0 : wnONRateStore;
            const facilityLocationPoint = wnFacLocationCoords;
            const facilityLocation = wnFacLocationStore.label ?? '';
            const facilityMembersOnly = wnFacMembersOnly;
            const onlineLocation = wnONLinkStore;
            const outdoorLocation = wnODLocationStore.label ?? '';
            const outdoorLocationPoint = wnODLocationCoords;



            try {
                await db.collection('users').doc(currentUser.uid).collection('trainingPrefs').doc('wellness').update({
                    enabled: true,
                    facilityPrice,
                    homePrice,
                    outdoorPrice,
                    onlinePrice,
                    facilityLocationPoint,
                    facilityLocation,
                    facilityMembersOnly,
                    onlineLocation,
                    outdoorLocation,
                    outdoorLocationPoint,
                })
            } catch (err) {
                setLoading(false);
                return setErrorMessage(err)
                // return setErrorMessage('Could not update your profile, please try again')
            }
        }

        if (rrSelected) {
            rrFacChecked.current.focus();
            rrHomeChecked.current.focus();
            rrOutdoorChecked.current.focus();
            rrOnlineChecked.current.focus();
            rrFac.current.focus();
            rrHome.current.focus();
            rrOutdoor.current.focus();
            rrOnline.current.focus();

            if (rrFacCheckedStore) {
                atFacility = true;
            }

            if (rrHomeCheckedStore) {
                atHome = true;
            }

            if (rrONCheckedStore) {
                online = true;
            }

            if (rrODCheckedStore) {
                outdoor = true;
            }

            const facilityPrice = rrFacRateStore === '' ? 0 : rrFacRateStore;
            const homePrice = rrHomeRateStore === '' ? 0 : rrHomeRateStore;
            const outdoorPrice = rrODRateStore === '' ? 0 : rrODRateStore;
            const onlinePrice = rrONRateStore === '' ? 0 : rrONRateStore;
            const facilityLocationPoint = rrFacLocationCoords;
            const facilityLocation = rrFacLocationStore.label ?? '';
            const facilityMembersOnly = rrFacMembersOnly;
            const onlineLocation = rrONLinkStore;
            const outdoorLocation = rrODLocationStore.label ?? '';
            const outdoorLocationPoint = rrODLocationCoords;



            try {
                await db.collection('users').doc(currentUser.uid).collection('trainingPrefs').doc('recovery').update({
                    enabled: true,
                    facilityPrice,
                    homePrice,
                    outdoorPrice,
                    onlinePrice,
                    facilityLocationPoint,
                    facilityLocation,
                    facilityMembersOnly,
                    onlineLocation,
                    outdoorLocation,
                    outdoorLocationPoint,
                    primarySpeciality: rrDrop,
                })
            } catch (err) {
                setLoading(false);
                return setErrorMessage(err)
                // return setErrorMessage('Could not update your profile, please try again')
            }

        }

        // Specialties
        const addedSpecialties = specialtyArray;
        const accountStatus = 'pendingVerification';
        const enabledDisciplines = {
            'biokineticist': rrDropRef.current === 'biokineticist' ?? false,
            'personalTraining': ptSelected,
            'physiotherapist': rrDropRef.current === 'physiotherapist' ?? false,
            'pilates': false,
            'recovery': rrSelected,
            'wellness': wnSelected,
            'yoga': yogaSelected,
            'yoga&pilates': yogaDropRef.current === 'yoga&pilates' ?? false,
            'yogaOnly': yogaDropRef.current === 'yogaOnly' ?? false,
        }
        const enabledLocations = {
            atFacility,
            atHome,
            online,
            outdoor
        }
        const position = {
            geohash: geoHash,
            geopoint: geoPoint,
        }

        // Image Uploads
        // Profile Image
        setLoadingMessage('Uploading your documents...')
        const profileImageFile = profilePicRef.current;
        const profileStorageRef = storage.ref().child(`images/${uuid()}`);
        const profileTask = profileStorageRef.put(profileImageFile);
        profileTask.on('state changed',
        (snapshot) => {
        },
        (err) => {
            setLoading(false);
            console.log(err)
            console.log(3)
        //  return setErrorMessage('Could not update your profile, please try again');
        return setErrorMessage(err)
        },
        () => {
         profileTask.snapshot.ref.getDownloadURL().then((url) => {
            db.collection('users').doc(currentUser.uid).update({
                imageLink: url,
                });
            });
        }

        );
        console.log('here')
        // ID
        const idImageFile = idRef.current;
        if (idImageFile !== undefined) {
            const idFileStorageRef = storage.ref().child(`images/${uuid()}`);
            const idTask = idFileStorageRef.put(idImageFile)
            idTask.on('state changed',
            (snapshot) => {
            },
            (err) => {
                setLoading(false);
                console.log(err)
                console.log(4)
            //  return setErrorMessage('Could not update your profile, please try again');
            return setErrorMessage(err)

            },
            () => {
             idTask.snapshot.ref.getDownloadURL().then((url) => {
                db.collection('users').doc(currentUser.uid).collection('confidentialInfo').doc('personalDetails').update({
                    idImageLink: url,
                    });
                });
            }

            );
        }

        // Service Agreement
        const saFile = serviceAgreementRef.current;
        if (serviceAgreementRef !== undefined) {
            const saFileStorageRef = storage.ref().child(`service-agreements/${uuid()}`);
            const saTask = saFileStorageRef.put(saFile)
            saTask.on('state changed',
            (snapshot) => {
            },
            (err) => {
                console.log(err)

            //  return setErrorMessage('Could not update your profile, please try again');
            return setErrorMessage(err)

            },
            () => {
             saTask.snapshot.ref.getDownloadURL().then((url) => {
                db.collection('users').doc(currentUser.uid).collection('confidentialInfo').doc('personalDetails').update({
                    serviceAgreementLink: url,
                    serviceAgreement: true,
                    });
                });
            }

            );
        }

        // Cert
        for (let i = 0; i < certRef.current.length; i++) {
            const file = certRef.current[i];
            const certFileStorage = storage.ref().child(`certificates/${uuid()}`);
            const certTask = certFileStorage.put(file);
            certTask.on('state changed',
            (snapshot) => {
            },
            (err) => {
             return setErrorMessage('Could not update your profile, please try again');
            },
            () => {
             certTask.snapshot.ref.getDownloadURL().then((url) => {
                db.collection('users').doc(currentUser.uid).collection('confidentialInfo').doc('personalDetails').update({
                        certificates: admin.firestore.FieldValue.arrayUnion({
                            accepted: false,
                            imageURL: url,
                            certificateName: file.name,
                        }),
                    });
                });
            }

            );
        }
        console.log('heeeeere')
        setLoadingMessage('Almost there...')
        const onboarding = {
            completedAbout: true,
            completedBasicInfo: true,
            completedPayments: true,
            completedQualifications: certArrayStore.length > 0,
            completedSkills: true,
        }
        try {
            // Personal Details
            await db.collection('users').doc(currentUser.uid).update({
                addedSpecialties,
                accountStatus,
                bioText,
                experianceText,
                enabledDisciplines,
                enabledLocations,
                firstName,
                lastName,
                dateOfBirth,
                phonenumber,
                locationHash,
                locationPoint,
                sessionsRadius,
                suburb,
                city,
                country,
                province,
                position,
                paymentMethods,
                locationString: locationValue.label,
                onboarding,
            });
            // Bank
            await db.collection('users').doc(currentUser.uid).collection('confidentialInfo').doc('bankDetails').update({
                bankAccHolder,
                bankAccNum,
                bankAccType,
                bankBranchCode,
                bankName,
                bankBranchName,
            })
            // Welcome notifications
            await db.collection('users').doc(currentUser.uid).collection('notifications').add({
                message: 'Welcome to the Forte Elite web platform',
                type: 'home',
                sentAt: new Date(),
                title: 'Welcome',
                documentID: '',
            });
            navigate('/');
        } catch (err) {
            setLoading(false);
            console.log(err)
            return setErrorMessage(err)
            // return setErrorMessage('Could not update your profile, please try again')
        }

        setLoading(false);
    }

    async function getUserCoords() {
        navigator.geolocation.getCurrentPosition((pos) => {
            const newHash = Geohash.encode(pos.coords.latitude, pos.coords.longitude);
            const newPoint = new admin.firestore.GeoPoint(pos.coords.latitude, pos.coords.longitude);
            console.log(newHash, newPoint)
            setGeoHash(newHash)
            setGeoPoint(newPoint);
        })
    }

    async function getPlaceCoords(address) {
        try {
            const response = await Geocode.fromAddress(address);
            const { lat, lng } = response.results[0].geometry.location;
            const newHash = Geohash.encode(lat, lng);
            const newPoint = new admin.firestore.GeoPoint(lat, lng);
            setGeoHash(newHash)
            setGeoPoint(newPoint);
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <LoadingOverlay
        active={loading}
        spinner
        text={loadingMessage}
        >
        {currentStep === 'one' && <div className='instructor-creation'>
            <div className="pc-header-container">
                <AccountCircleIcon className="account-icon" />
                <h1>Profile Creation</h1>
            </div>
            <p className='mt-20'>Fields marked <span className='req-asterisk'>*</span> are required</p>
            <div className="pc-personal-details">
                <h3>Personal Details</h3>
                <div className="pc-input-row">
                    <div className="pc-input-group">
                        <p>First Name<span className='req-asterisk'>*</span></p>
                        <input defaultValue={firstNameStore} ref={firstNameRef} type="text" />
                    </div>
                    <div className="pc-input-group">
                        <p>Last Name<span className='req-asterisk'>*</span></p>
                        <input defaultValue={lastNameStore} ref={lastNameRef} type="text" />
                    </div>
                </div>
                <div className="pc-input-row">
                    <div className="pc-input-group">
                        <p>Cell Number<span className='req-asterisk'>*</span></p>
                        <input defaultValue={cellStore} ref={cellRef} type="text" />
                    </div>
                    <div className="pc-input-group">
                        <p>D.O.B<span className='req-asterisk'>*</span></p>
                        <input defaultValue={dobVal} ref={dobRef} type="date" />
                    </div>
                </div>
            </div>
            <div className="pc-bank-details">
                <h3>Bank Details</h3>
                <div className="pc-input-row">
                    <div className="pc-input-group">
                        <p>Bank Name<span className='req-asterisk'>*</span></p>
                        <SelectSearch
                  closeOnSelect={true}
                  search={false}
                  placeholder={'Select a bank'}
                  options={bankNameArray}
                  onChange={(value) => {
                  console.log(value);
                  setBankSelected(value)
                  }}
                  value={bankSelected}
                  />
                    </div>
                    <div className="pc-input-group">
                        <p>Account Type<span className='req-asterisk'>*</span></p>
                        <SelectSearch
                  closeOnSelect={true}
                  search={false}
                  placeholder={'Select an account type'}
                  options={bankTypeArray}
                  onChange={(value) => {
                  console.log(value);
                    setBankTypeSelected(value)
                  }}
                  value={bankTypeSelected}
                  />
                    </div>

                </div>
                <div className="pc-input-row">
                <div className="pc-input-group">
                        <p>Acc Number<span className='req-asterisk'>*</span></p>
                        <input defaultValue={bankAccNumStore} ref={bankAccRef} type="text" />
                    </div>
                <div className="pc-input-group">
                        <p>Acc Holder<span className='req-asterisk'>*</span></p>
                        <input defaultValue={bankHolderStore} ref={bankHolderRef} type="text" />
                    </div>
                </div>
                <div className="pc-input-row">
                <div className="pc-input-group">
                        <p>Branch Code<span className='req-asterisk'>*</span></p>
                        <input defaultValue={bankBranchCodeStore} ref={bankBranchCodeRef} type="text" />
                    </div>
                <div className="pc-input-group">
                        <p>Branch Name<span className='req-asterisk'>*</span></p>
                        <input defaultValue={bankBranchNameStore} ref={bankBranchNameRef} type="text" />
                    </div>
                </div>
                <h4 className='mt-20'>Payment Methods</h4>
                <div className="payment-methods-group">
                    <div className="payment-methods-item">
                        <Checkbox checked={cardPayments} onClick={() => setCardPayments(current => !current)} color='default' />
                        <p>Credit Card</p>
                        <Tooltip classes={{tooltip: classes.tooltip}} title={'Please note that by selecting Credit Card as a payment offering, 3% (Ex Vat) will be charged to you for the service'}><InfoIcon /></Tooltip>
                    </div>
                    <div className="payment-methods-item">
                        <Checkbox checked={cashPayments} onClick={() => setCashPayments(current => !current)} color='default' />
                        <p>Cash</p>
                        <Tooltip classes={{tooltip: classes.tooltip}} title="If you wish to offer cash as a payment method, Forte cannot be held liable for any unpaid bookings. It is the specialist's responsibility to track and manage Cash payments with the client"><InfoIcon /></Tooltip>
                    </div>
                    <div className="payment-methods-item">

                        <Checkbox checked={bankPayments} onClick={() => setBankPayments(current => !current)} color='default' />
                        <p>EFT (Bank Transfer)</p>
                        <Tooltip classes={{tooltip: classes.tooltip}} title="If you wish to offer EFT as a payment method, Forte cannot be held liable for any unpaid bookings. It is the specialist's responsibility to track and manage EFT payments with the client"><InfoIcon /></Tooltip>                    </div>
                </div>
            </div>
            <div className="mt-40 ic-location-details">
                <h3>Location Details</h3>
                <p>Where you are based</p>
            <div className="location-input-row">
                    <div className="location-input-group-small">
                        <label for="country">Country<span className='req-asterisk'>*</span></label>
                        {/* <input defaultValue={countryValue} ref={countryRef} type="text"id="country" /> */}
                        <Multiselect
                            ref={countryRef}
                            options={countryArray}
                            placeholder=''
                            displayValue='name'
                            onSelect={(evt) => changeProvinceArray(evt, 'add')}
                            onRemove={(evt) => changeProvinceArray(evt, 'remove')}
                            // selectionLimit={1}
                            showArrow
                            singleSelect
                            style={{
                                searchBox: {
                                    borderColor: countryRed ? '#D94A4A' : 'lightgray',
                                }
                            }}
                            selectedValues={country !== undefined && [{name: country}]}
                        />
                    </div>
                    <div className="location-input-group-small">
                            <label for="city">Province<span className='req-asterisk'>*</span></label>
                            {/* <input defaultValue={cityValue} ref={cityRef} type="text"id="city" /> */}

                                                    <Multiselect
                            ref={provinceRef}
                            options={provinceDrop}
                            disable={provinceDisabled}
                            placeholder=''
                            displayValue='name'
                            onSelect={(evt) => changeCityArray(evt, 'add')}
                            onRemove={(evt) => changeCityArray(evt, 'remove')}
                            showArrow
                            singleSelect
                            style={{
                                searchBox: {
                                    borderColor: provinceRed ? '#D94A4A' : 'lightgray',
                                }
                            }}
                            selectedValues={province !== undefined && [{name: province}]}

                        />
                        </div>

                    </div>
            <div className="location-input-row">
                        <div className="location-input-group-small">
                            <label for="city">City<span className='req-asterisk'>*</span></label>
                            {/* <input defaultValue={suburbValue} ref={suburbRef} type="text"id="city" /> */}
                            <Multiselect
                                // selectedValues={city}
                                ref={cityRef}
                                options={cityDrop}
                                disable={cityDisabled}
                                // hidePlaceholder={true}
                                placeholder=''
                                displayValue='name'
                                onSelect={(evt) => changeSuburbArray(evt, 'add')}
                                onRemove={(evt) => changeSuburbArray(evt, 'remove')}
                                // selectionLimit={1}
                                showArrow
                                singleSelect
                                style={{
                                    searchBox: {
                                        borderColor: cityRed ? '#D94A4A' : 'lightgray',
                                    }
                                }}
                                selectedValues={city !== undefined && [{name: city}]}

                        />

                        </div>
                        <div className="location-input-group-small">
                            <label for="city">Suburb<span className='req-asterisk'>*</span></label>
                            {/* <input defaultValue={suburbValue} ref={suburbRef} type="text"id="city" /> */}
                            <Multiselect
                            // selectedValues={suburb}
                            ref={suburbRef}
                            options={suburbDrop}
                            disable={suburbDisabled}
                            placeholder=''
                            displayValue='name'
                            onSelect={(evt) => setSuburb(evt[0].name)}
                            onRemove={(evt) => setSuburb('')}
                            showArrow
                            singleSelect
                            style={{
                                searchBox: {
                                    borderColor: suburbRed ? '#D94A4A' : 'lightgray',
                                }
                            }}
                            onSearch={(e) => console.log(e)}

                            selectedValues={suburb !== undefined && [{name: suburb}]}

                        />
                        </div>

                    </div>
                  <div className="business-location-row">
                      <p>The location your business is based</p>
                      <div className="ic-location-input-group">
                      {/* <Autocomplete
                        apiKey={'AIzaSyBNVt036Vwj3EMeNMVyTEh9JPqCuZ6xsi8'}
                        onPlaceSelected={(e) => console.log(e)}
                        className='autocomplete-input'
                        /> */}
                            <GooglePlacesAutocomplete
                                apiKey="AIzaSyBNVt036Vwj3EMeNMVyTEh9JPqCuZ6xsi8"
                                selectProps={{
                                    value: locationValue,
                                    onChange: (e) => {setLocationValue(e); getPlaceCoords(e.label)},
                                    placeholder: 'Search address',
                                    className: placesEmpty ? 'red-border' : '',

                                }
                                }

                                ref={placesRef}
                                placeholder={'Enter your location here...'}
                                onLoadFailed={
                                    (e) => console.log(e)
                                }

                                // className='red-border'

    />
                        {/* <PlacesAutocomplete
                        value={locationValue}
                        onChange={setLocationValue}
                        onSelect={(e) => console.log(e)}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                                return <div>
                                    <input {...getInputProps({placeholder: 'Enter location here'})} />
                                    {loading && <div><p>Loading</p></div>}
                                    <div>
                                        {suggestions.map((suggestion) => {
                                            console.log(suggestion)
                                            const style = {
                                                backgroundColor: suggestion.active ? '#41b6e6' : '#fff'
                                            }
                                            return <div {...getSuggestionItemProps({ suggestion, style })}>
                                                {suggestion.description}
                                            </div>
                                        })}
                                    </div>
                                </div>
                            }}
                        </PlacesAutocomplete> */}
                        {/* <div className="use-location-group">
                            <RoomIcon onClick={getUserCoords} className='room-icon' />
                            <p>Use my current location</p>
                        </div> */}
                      </div>

                        {/* <input type='text' ref={ref} /> */}
                  </div>
                  <div className="travel-distance-row">
                      <p>How far are you willing to travel for sessions (km)?</p>
                      <div className="range-group">
                          <p>0</p>
                          {/* <input type='range' ref={rangeRef} min={0} max={50} defaultValue={20} onChange={(e) => {
                          }} onInput={(e) => setSliderVal(e.target.value)} /> */}
                          <Slider min={0} max={50} defaultValue={20} ref={rangeRef} onChange={(e) => setSliderVal(e.target.value)} />
                          {/* <p>{rangeRef.current && rangeRef.current.value}</p> */}
                          <p>{sliderVal}</p>
                      </div>
                  </div>
            </div>

            <div className="ic-button-row">
                <button onClick={() => validateStepOne()}>Next</button>
            </div>
        </div>}
        {currentStep === 'two' &&
        <div className='instructor-creation'>
            <div className="pc-header-container">
                <AccountCircleIcon className="account-icon" />
                <h1>Profile Creation</h1>
            </div>
            <p className='mt-20'>Fields marked <span className='req-asterisk'>*</span> are required</p>
            <div className="ic-skills-rates">
                <h3>Skills & Rates</h3>
                <div className="skills-rates-container">
                    <div onClick={() => setErrorMessage('')} className="s-r-left">
                        <div className="skills-row">
                            <div onClick={() => setPTSelected(current => !current)} className={ptSelected ? "skills-block skills-block-active" : "skills-block"}>
                                <div className="skills-block-top">
                                <Checkbox checked={ptSelected} color="default"/>
                                </div>
                                <div className="skills-block-bottom">
                                    <p>Personal Trainer</p>
                                </div>
                            </div>
                            <div onClick={() => setYogaSelected(current => !current)} className={yogaSelected ? "skills-block skills-block-active" : "skills-block"}>
                                <div className="skills-block-top">
                                <Checkbox checked={yogaSelected} color="default"/>
                                </div>
                                <div className="skills-block-bottom">
                                    <p>Yoga & Pilates</p>
                                </div>
                            </div>
                        </div>
                        <div className="skills-row">
                        <div onClick={() => setWNSelected(current => !current)} className={wnSelected ? "skills-block skills-block-active" : "skills-block"}>
                                <div className="skills-block-top">
                                <Checkbox checked={wnSelected} color="default"/>
                                </div>
                                <div className="skills-block-bottom">
                                    <p>Wellness Coach</p>
                                </div>
                            </div>
                            <div onClick={() => setRRSelected(current => !current)} className={rrSelected ? "skills-block skills-block-active" : "skills-block"}>
                                <div className="skills-block-top">
                                <Checkbox checked={rrSelected} color="default"/>
                                </div>
                                <div className="skills-block-bottom">
                                    <p>Recovery & Rehabilitation</p>
                                </div>
                            </div>
                        </div>
                        <div className="error-message">
                            <p>{errorMessage}</p>
                        </div>
                        <div className="rates-boxes">
                            {ptSelected && <RatesBox discipline='Personal Training' onlineLinkRef={ptOnlineLinkRef}
                            facCoordsRef={ptFacCoordsRef} odCoordsRef={ptODCoordsRef}
                            facCheckDefault={ptFacCheckedStore} facLocationDefault={ptFacLocationStore} facRateDefault={ptFacRateStore} facMemOnlyDefault={ptFacMemOnlyStore}
                            homeCheckDefault={ptHomeCheckedStore} homeRateDefault={ptHomeRateStore} odCheckedDefault={ptODCheckedStore} odRateDefault={ptODRateStore} odLocationDefault={ptODLocationStore}
                            onCheckedDefault={ptONCheckedStore} onRateDefault={ptONRateStore} onLinkDefault={ptONLinkStore}
                            facLocationRef={ptFacLocation} facMembersOnlyRef={ptFacMembersOnly} outdoorLocationRef={ptOutdoorLocation}
                            facCheckRef={ptFacChecked} homeCheckRef={ptHomeChecked} outdoorCheckRef={ptOutdoorChecked} onlineCheckRef={ptOnlineChecked}
                            facRef={ptFac} homeRef={ptHome} outdoorRef={ptOutdoor} onlineRef={ptOnline} />}

                            {yogaSelected && <RatesBox onlineLinkRef={yogaOnlineLinkRef}
                            facCoordsRef={yogaFacCoordsRef} odCoordsRef={yogaODCoordsRef} setYogaDrop={setYogaDrop} yogaDropVal={yogaDrop}
                            facCheckDefault={yogaFacCheckedStore} facLocationDefault={yogaFacLocationStore} facRateDefault={yogaFacRateStore} facMemOnlyDefault={yogaFacMemOnlyStore}
                            homeCheckDefault={yogaHomeCheckedStore} homeRateDefault={yogaHomeRateStore} odCheckedDefault={yogaODCheckedStore} odRateDefault={yogaODRateStore} odLocationDefault={yogaODLocationStore}
                            onCheckedDefault={yogaONCheckedStore} onRateDefault={yogaONRateStore} onLinkDefault={yogaONLinkStore}
                            facLocationRef={yogaFacLocation} facMembersOnlyRef={yogaFacMembersOnly} outdoorLocationRef={yogaOutdoorLocation}
                            facCheckRef={yogaFacChecked} homeCheckRef={yogaHomeChecked} outdoorCheckRef={yogaOutdoorChecked} onlineCheckRef={yogaOnlineChecked}
                            discipline='Yoga' facRef={yogaFac} homeRef={yogaHome} outdoorRef={yogaOutdoor} onlineRef={yogaOnline} />}

                            {wnSelected && <RatesBox onlineLinkRef={wnOnlineLinkRef}
                            facCoordsRef={wnFacCoordsRef} odCoordsRef={wnODCoordsRef}
                            facCheckDefault={wnFacCheckedStore} facLocationDefault={wnFacLocationStore} facRateDefault={wnFacRateStore} facMemOnlyDefault={wnFacMemOnlyStore}
                            homeCheckDefault={wnHomeCheckedStore} homeRateDefault={wnHomeRateStore} odCheckedDefault={wnODCheckedStore} odRateDefault={wnODRateStore} odLocationDefault={wnODLocationStore}
                            onCheckedDefault={wnONCheckedStore} onRateDefault={wnONRateStore} onLinkDefault={wnONLinkStore}
                            facLocationRef={wnFacLocation} facMembersOnlyRef={wnFacMembersOnly} outdoorLocationRef={wnOutdoorLocation}
                            facCheckRef={wnFacChecked} homeCheckRef={wnHomeChecked} outdoorCheckRef={wnOutdoorChecked} onlineCheckRef={wnOnlineChecked}
                            discipline='Wellness' facRef={wnFac} homeRef={wnHome} outdoorRef={wnOutdoor} onlineRef={wnOnline} />}

                            {rrSelected &&
                            <RatesBox onlineLinkRef={rrOnlineLinkRef}
                            facCoordsRef={rrFacCoordsRef} odCoordsRef={rrODCoordsRef} setRRDrop={setRRDrop} rrDropVal={rrDrop}
                            facCheckDefault={rrFacCheckedStore} facLocationDefault={rrFacLocationStore} facRateDefault={rrFacRateStore} facMemOnlyDefault={rrFacMemOnlyStore}
                            homeCheckDefault={rrHomeCheckedStore} homeRateDefault={rrHomeRateStore} odCheckedDefault={rrODCheckedStore} odRateDefault={rrODRateStore} odLocationDefault={rrODLocationStore}
                            onCheckedDefault={rrONCheckedStore} onRateDefault={rrONRateStore} onLinkDefault={rrONLinkStore}
                            facLocationRef={rrFacLocation} facMembersOnlyRef={rrFacMembersOnly} outdoorLocationRef={rrOutdoorLocation}
                            facCheckRef={rrFacChecked} homeCheckRef={rrHomeChecked} outdoorCheckRef={rrOutdoorChecked} onlineCheckRef={rrOnlineChecked}
                            discipline='Recovery' facRef={rrFac} homeRef={rrHome} outdoorRef={rrOutdoor} onlineRef={rrOnline} />}
                            {/* <RatesBox /> */}
                        </div>
                    </div>
                    <div className="s-r-right">
                        <p>Add your specialties</p>
                        <div className="specialties-container">
                            <div className="specialties-left">
                            <div className="check-group">
                                <Checkbox checked={wmChecked} onClick={(e) => {
                                    handleClick(e.target.id);
                                    setWMChecked(checked => !checked);
                                    }} ref={weightManagementRef} id="Weight Management" color="default"/>
                                <label for="Weight Management">Weight Management</label>
                            </div>
                            <div className="check-group">
                                <Checkbox checked={boxingChecked}
                                    onClick={(e) => {
                                    handleClick(e.target.id);
                                    setBoxingChecked(checked => !checked);
                                    }} ref={boxingRef} id="Boxing" color="default"/>
                                <label for="Boxing">Boxing</label>
                            </div>
                            <div className="check-group">
                                <Checkbox  checked={sportsSpecificChecked}
                                onClick={(e) => {
                                    handleClick(e.target.id);
                                    setSportsSpecificChecked(checked => !checked);
                                    }} ref={sportsSpecificRef} id="Sports Specific Training" color="default"/>
                                <label for="Sports Specific Training">Sports Specific Training</label>
                            </div>
                            <div className="check-group">
                                <Checkbox
                                 checked={recoveryFocusChecked}
                                onClick={(e) => {
                                    handleClick(e.target.id);
                                    setReocveryFocusChecked(checked => !checked);
                                    }} ref={recoveryFocusedRef} id="Recovery Focused Training" color="default"/>
                                <label for="Recovery Focused Training">Recovery Focused Training</label>
                            </div>
                            <div className="check-group">
                                <Checkbox
                                 checked={nutritionChecked}
                                onClick={(e) => {
                                    handleClick(e.target.id);
                                    setNutritionChecked(checked => !checked);
                                    }} ref={nutritionRef} id="Nutrition" color="default"/>
                                <label for="Nutrition">Nutrition</label>
                            </div>
                            </div>
                            <div className="specialities-right">
                            <div className="check-group">
                                <Checkbox
                                 checked={toningShapingChecked}
                                onClick={(e) => {
                                    handleClick(e.target.id);
                                    setWMChecked(checked => !checked);
                                    }} ref={toningShapingRef} id="Toning and Shaping" color="default"/>
                                <label for="Toning and Shaping">Toning and Shaping</label>
                            </div>
                            <div className="check-group">
                                <Checkbox
                                 checked={bodyBuildingChecked}
                                onClick={(e) => {
                                    handleClick(e.target.id);
                                    setBodyBuildingChecked(checked => !checked);
                                    }} ref={bodyBuildingRef} id="Body Building" color="default"/>
                                <label for="Body Building">Body Building</label>
                            </div>
                            <div className="check-group">
                                <Checkbox
                                 checked={prePostChecked}
                                onClick={(e) => {
                                    handleClick(e.target.id);
                                    setPrePostChecked(checked => !checked);
                                    }} ref={prePostNatalRef} id="Pre and Postnatal" color="default"/>
                                <label for="Pre and Postnatal">Pre and Postnatal</label>
                            </div>
                            <div className="check-group">
                                <Checkbox
                                 checked={hiitChecked}
                                onClick={(e) => {
                                    handleClick(e.target.id);
                                    setHiitChecked(checked => !checked);
                                    }} ref={hiitRef} id="HIIT" color="default"/>
                                <label for="HIIT">HIIT</label>
                            </div>
                            <div className="check-group">
                                <Checkbox
                                 checked={flexMobilChecked}
                                onClick={(e) => {
                                    handleClick(e.target.id);
                                    setFlexMobilChecked(checked => !checked);
                                    }} ref={flexMobilRef} id="Flexibility and Mobility" color="default"/>
                                <label for="Flexibility and Mobility">Flexibility and Mobility</label>
                            </div>
                            </div>
                        </div>
                        <div className="add-skills-container">
                            <input ref={specInputRef} placeholder='Other Speciality' type='text' />
                            <button onClick={() => {
                                const specInput = specInputRef.current.value;
                                if (specInput.length > 0 && !specialtyArray.includes(specInput)) {
                                  setSpecialtyArray(array => [...array, specInput]);
                                  specInputRef.current.value = '';
                                }

                            }} >Add</button>
                        </div>
                        <div className="skills-container">
                            {specialtyArray.length === 0 ? <div className='no-specialties'>
                            <p>You have no added specialties</p>
                            </div> : <div className='selected-specs'>
                                {specialtyArray.map((spec) => {
                                    return <div className='spec-item'><p>{spec}</p><span value={spec} onClick={() => handleClick(spec)}>X</span></div>
                                })}
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="rates-container">

            </div>
            <div className="ic-button-row button-row-step-two">
                <button className='sign-back-button' onClick={() => storeStepTwo()}>Back</button>
                <button onClick={() => validateStepTwo()}>Next</button>
            </div>
        </div>
        }
        {currentStep === 'three' &&
        <div className='instructor-creation'>
            <div className="pc-header-container">
                <AccountCircleIcon className="account-icon" />
                <h1>Profile Creation</h1>
            </div>
            <p className='mt-20'>Fields marked <span className='req-asterisk'>*</span> are required</p>

            <div className="ic-step-three">
                <div className="ic-step-three-left">
                    <div className="ic-profile-bio">
                        <h3>Tell us a bit about yourself</h3>
                    </div>
                    <textarea defaultValue={bioStore} ref={bioRef} rows={10} />
                    <div className="ic-profile-bio">
                        <h3>Tell us about your work experience</h3>
                    </div>
                    <textarea defaultValue={expStore} ref={expRef} rows={10} />
                    <div className="profile-pic-upload">
                        <div className="pp-upload-head">
                        <h3>Profile Picture</h3>
                        <span className='req-asterisk'>*</span>
                        </div>
                        <DocumentUploader defaultIDFile={ppFileStore} inputFileRef={profilePicRef} title='Upload your profile picture' profile={true} id={true} />
                        <div className="error-message">
                            <p>{errorMessage}</p>
                        </div>
                    </div>

                </div>
                <div className="ic-step-three-right">
                    <h3>Upload your Documents</h3>
                    <div className="ic-upload-container">
                        <DocumentUploader defaultIDFile={idFileStore} inputFileRef={idRef} title='Upload your ID / Passport' id={true} />
                        <DocumentUploader defaultCertFiles={certArrayStore} inputFileRef={certRef} title='Upload your certifications (max 4)' id={false} />
                        <DocumentUploader defaultIDFile={saFileStore} inputFileRef={serviceAgreementRef} title='Upload your service agreement' id={true} />
                        </div>
                    </div>
            </div>
            <div className="ic-button-row button-row-step-two">
                    <button className='sign-back-button' onClick={() => saveStepThree()}>Back</button>
                    <button onClick={() => uploadData()}>Finish</button>
            </div>


        </div>
        }
        </LoadingOverlay>
    )
}

export default InstructorCreation
