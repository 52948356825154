import React, { useState, useEffect} from 'react'
import './LandingPage.css'

import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

import screen1 from '../../assets/images/screen1.png'
import screen2 from '../../assets/images/screen2.png'
import screen3 from '../../assets/images/screen3.png'
import screen4 from '../../assets/images/screen4.png'

function LandingPage({ handleClick = () => {} }) {
  return (
    <div className='landing-page'>
        <div className="lp-header">
            <h1>Welcome to Forte Elite</h1>
            <p>Forte Elite is a web-based solution designed for Specialists and Facilities to better run their business while listing their services to prospective clients.</p>
        </div>


    <div className="lp-details">
        <div className="lpd-left">
            <div className="lp-dl-left">
                <img 
                alt='trainer'
                src='https://images.unsplash.com/photo-1571019614242-c5c5dee9f50b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80' />
            </div>
            <div className="lp-dl-right">
                <p>Specialists can now use Forte Elite to run and grow their business through a web-based platform alongside their mobile app
                <br /><br /> By using Forte Elite, you are able to expand your coverage when searching and booking facilities within which to train your clients.
                </p>
            </div>
        </div>
        <div className="lpd-right">
            <div className="lp-dr-left">
                <img 
                    alt='gym'
                    src='https://images.unsplash.com/photo-1637430308606-86576d8fef3c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80'
                />
            </div>
            <div className="lp-dr-right">
                <p>As a gym owner/manager, you can use Forte Elite to reduce dead time and increase profit margin by offering your facilities and making them available to external specialists at hourly rates.
                <br /><br /> Increase your foot traffic and increase your revenue.
                </p>
            </div>
        </div>
    </div>

    <div className="lp-button-row">
        <button className='lp-login picton-button' onClick={() => handleClick('login')}>LOGIN</button>
        <button className='lp-signup regal-button' onClick={() => handleClick('signup')}>SIGN UP</button>
    </div>

    <div className="lp-ic-header">
        <h2>Have a look at a few examples below</h2>
    </div>

    <div className="lp-ic">
    <Carousel showThumbs={false} interval={5000} showStatus={false} autoPlay infiniteLoop showIndicators={false}>
                <div>
                    <img 
                    alt='one'
                    // src="https://firebasestorage.googleapis.com/v0/b/forte-v2.appspot.com/o/facility-screenshots%2FImage%202022-05-04%20at%2015.23.jpeg?alt=media&token=bd0a0e11-1531-417e-9c7c-9280831c3ae5" 
                    src={screen1}
                    />
                    <p className="legend">Get an overview of all your bookings coming in</p>
                </div>
                <div>
                    <img 
                    alt='two'
                    // src="https://firebasestorage.googleapis.com/v0/b/forte-v2.appspot.com/o/facility-screenshots%2FImage%202022-05-04%20at%2015.28.jpeg?alt=media&token=79491f8d-c960-4282-a613-5f9d45c1f32a" 
                    src={screen2}
                    />
                    <p className="legend">Keep track of your client relationships</p>
                </div>
                <div>
                    <img 
                    alt='tnree'
                    // src="https://firebasestorage.googleapis.com/v0/b/forte-v2.appspot.com/o/facility-screenshots%2FImage%202022-05-04%20at%2015.31.jpeg?alt=media&token=c31d30fb-1849-4802-8f2d-ab7b7213cf00" 
                    src={screen3}
                    />
                    <p className="legend">Search and find venues suited to your needs</p>
                </div>
                <div>
                    <img 
                    alt='four'
                    // src="https://firebasestorage.googleapis.com/v0/b/forte-v2.appspot.com/o/facility-screenshots%2FImage%202022-05-04%20at%2015.35.jpeg?alt=media&token=2a6fb028-4f6d-403e-ae26-a5cdd2568e6e" 
                    src={screen4}
                    />
                    <p className="legend">Quick and easy booking process</p>
                </div>
            </Carousel>
    </div>

    </div>
  )
}

export default LandingPage