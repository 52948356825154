import React, { useState, useEffect } from 'react'
import './InstructorCredits.css'

import LoadingOverlay from 'react-loading-overlay'

import { useAuth } from '../../contexts/AuthContext'
import { db } from '../../firebase'

import CreditBox from './CreditBox'

import CreditsRecon from './CreditsRecon'

function InstructorCredits() {

    const {currentUser} = useAuth()

    const [loaded, setLoaded] = useState(false)
    const [purchases, setPurchases] = useState([])
    const [credits, setCredits] = useState([])
    const [bookings, setBookings] = useState([])

    const [showRecon, setShowRecon] = useState(false) 
    const [recon, setRecon] = useState()

    const [search, setSearch] = useState('')

    useEffect(() => {
        if (currentUser) {
            loadCredits(currentUser.uid)
        }
    }, [currentUser])

    function cap(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

    async function loadCredits(uid) {
        const userDoc = await db.collection('users').doc(uid).get()
        const user = userDoc.data()
        const facilityCredits = user.facilityCredits ?? ''
        if (facilityCredits === '') {
            return setLoaded(true)
        }

        const farr = []

        for (let [key, value] of Object.entries(facilityCredits)) {
            const facilityID = key;
            const outstanding = value;

            const facilityDoc = await db.collection('facilities').doc(facilityID).get()
            const facility = facilityDoc.data()
            const {listingName, imageLinks} = facility;
            const imageLink = imageLinks[0].link;
            console.log(imageLink)

            farr.push({
                listingName,
                imageLink,
                facilityID,
                outstanding,
            })
        }

        const purchases = await userDoc.ref.collection('bulkPurchases').get();
        
        const parr = []

        for (let i = 0; i < purchases.docs.length; i++) {
            const doc = purchases.docs[i].data()
            const {amount, date, facilityID, percentage, size} = doc;
            const capSize = cap(size)
            const purchaseDate = new Date(date.toDate())

            parr.push({
                amount, facilityID, percentage, size, capSize, purchaseDate
            })
        }

        const sessions = await db.collection('bookings').where('paidWithCredits', '==', true)
            .where('instructorID', '==', uid)
            .get()

        const sarr = []
        for (let i = 0; i < sessions.docs.length; i++) {
            const session = sessions.docs[i].data()
            const {facilityID, total, start,rateType, rate } = session;
            const date = new Date(start.toDate())
            const end = new Date(session.end.toDate())
            sarr.push({
                facilityID, date, total, end, rate, rateType,
            })

        }

        setCredits(farr)
        setPurchases(parr)
        setBookings(sarr)
        setLoaded(true)

    }

  return (
    <LoadingOverlay spinner active={!loaded} text='Loading Credits...'>
        {showRecon ? recon : <>
    <div className='instructor-credits client-relationships'>
        <div className="clients-header">
            <h2>Credits</h2>
        </div>

        <div className="credits-search">
            <input type='text' placeholder='Search for facility...' defaultValue={search} onChange={(e) => setSearch(e.target.value)} />
        </div>

        <div className="credits-container">
            {credits.map(credit => {
            if (search !== '') {
                if (!credit.listingName.toLowerCase().includes(search.toLowerCase())) {
                    return null
                }
            }
            return <CreditBox show={() => {
            setRecon(<CreditsRecon role={'owner'} bookings={bookings} credits={credit} purchases={purchases} key={credit.facilityID} goBack={() => setShowRecon(false)} />)
            setShowRecon(true)
            }} key={credit.facilityID} name={credit.listingName} facilityID={credit.facility} imageLink={credit.imageLink} outstanding={credit.outstanding} />})}
        </div>

    </div>
    </>}
    </LoadingOverlay>

  )
}

export default InstructorCredits