import React from 'react'
import './Message.css'

import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';

function Message({text, userImage, msgClass, sentAt, read}) {

    return (
        <div className={`message-box message-box-${msgClass} slide-in-top`}>
            <div className={`message ${msgClass}`}>
                <img src={userImage} alt="userImage" />
                <p>{text}</p>
            </div>
            {msgClass === 'sent' && <h4>{sentAt}{read ? <DoneAllIcon className='msg-icon' /> : <DoneIcon className='msg-icon' />}</h4>}
            {msgClass === 'received' && <h4>{sentAt}</h4>}
        </div>
    )
}

export default Message
