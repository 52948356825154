import React, { useState, useRef, useEffect } from 'react'
import './Steps.css'

// Components
import ImageDescription from './ImageDescription';

// Icons
import PublishIcon from '@material-ui/icons/Publish';
import CloudUpload from '@mui/icons-material/CloudUpload';

import {useDropzone} from 'react-dropzone';

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    tooltip: {
      fontSize: '14px',
    },
  }));


function StepFour({fwd = true, edit, setError, disableButtons, facilityID, imageArray }) {

    const classes = useStyles();

    // State
    const [boxes, setBoxes] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);
    const boxesRef = useRef();
    boxesRef.current = boxes;

    useEffect(() => {
        if (edit === true) {
            const uploaded = [];
            var key = 0;
            imageArray.forEach(image => {
                uploaded.push(<ImageDescription edit id={key} removeItem={removeEditItem} disableButton={disableButtons} className='img-desc-box' img={image.link} description={image.description} />)
                key += 1;
            });
            setUploadedImages(uploaded);
        }
    }, []);

    useEffect(() => {
        // console.log(boxes)
    }, [boxes])

    // const [images, setImages] = useState();

    // const [process, setProcess] = useState('upload');

    // Remove Image Item
    async function removeItem(key) {
        // console.log(key)
        setBoxes(current => current.filter(box => {
            console.log(box.props.id);
            return box.props.id !== key}))
            console.log(uploadedImages)
            // setBoxes(current => (current.filter(())))
        // boxesRef.current.forEach(box => {
        //     console.log(box.props.id);
        // });
    }

    async function removeEditItem(key) {
        console.log(uploadedImages[0].props.id)
    }

    // Drag n drop
    function dragEnter(evt) {
        evt.stopPropagation();
        evt.preventDefault();
    }

    function dragOver(evt) {
        evt.stopPropagation();
        evt.preventDefault();
    }

    function imageOnDropEvent(evt) {
        evt.preventDefault();
        const currentFile = evt.dataTransfer.files[0];
        // console.log(currentFile.type);
        if (currentFile.type !== 'image/png') {
            // console.log('setting ')
            setError('Please ensure to upload images only')
            return
        }

        var src = URL.createObjectURL(currentFile);
        // let currentBoxes = [...boxesRef.current];
        setBoxes(current => [...current, <ImageDescription id={current.length} removeItem={removeItem} facilityID={facilityID} disableButtons={disableButtons} setError={setError} file={currentFile} img={src} className="img-desc-box" />])
    }

    const {getRootProps, getInputProps} = useDropzone({
        // accept: 'image/*',
        onDrop: acceptedFiles => {
            console.log(acceptedFiles)
            const newFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
              }));
            for (let i = 0; i < acceptedFiles.length; i++) {
                setBoxes(current => [...current, <ImageDescription id={current.length} removeItem={removeItem} facilityID={facilityID} disableButtons={disableButtons} setError={setError} file={newFiles[i]} img={newFiles[i].preview} className="img-desc-box" />])
            }


        }
      });

    return (
        <div className={fwd ? 'step-four slide-in-right' : 'step-four slide-in-left'}>
            {!edit && <h1>Step 4</h1>}
            <div className="image-container">
                {/* <div onDragEnter={dragEnter} onDragOver={dragOver} onDrop={(evt) => imageOnDropEvent(evt)} className="left-col">
                    <h2>Upload pictures of your facility</h2>
                    <div className="image-drop">
                        <PublishIcon id="publish-icon" />
                        <p>Select your images</p>
                        <p id="drag-drop">Drag and Drop</p>
                    </div>
                </div> */}
                                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <div className='dz-upload-container'>
                                    <CloudUpload />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                            </div>
                <div className="right-col">
                    <div className="right-col-header">
                        <h2>Caption your images</h2>
                        <Tooltip classes={{tooltip: classes.tooltip}} title={"Give your images a caption - please remember to save the image once your are finished"}><InfoIcon /></Tooltip>                    </div>
                    <div className="description-box">
                        {uploadedImages}
                        {boxes}
                    {/* <ImageDescription className="img-desc-box" img="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png" /> */}
                    {/* <ImageDescription className="img-desc-box" img="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png" />
                    <ImageDescription className="img-desc-box" img="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png" />
                    <ImageDescription className="img-desc-box" img="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png" />
                    <ImageDescription className="img-desc-box" img="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png" />
                    <ImageDescription className="img-desc-box" img="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png" />
                    <ImageDescription className="img-desc-box" img="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png" /> */}
                    </div>
                </div>
            </div>
        </div>
        )
    }


    export default StepFour
