import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router';
import './Analytics.css'

// Components
import BusinessPerformance from './BusinessPerformance'
import FinancialStatements from './FinancialStatements';
import ReviewPage from './Reviews/ReviewPage';
import ClientRelationships from './Clients/ClientRelationships';
import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@material-ui/core/Drawer';
import InstructorAnnualStatement from './Financials/InstructorAnnualStatement';

// UI
import LoadingOverlay from 'react-loading-overlay';

// Firebase
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import OwnerAnnualStatement from './Financials/OwnerAnnualStatement';

function Analytics() {

    const { currentUser, userRole } = useAuth();

    const location = useLocation();

    const [busPerformActive, setBusPerformActive] = useState(true);
    const [finStatActive, setFinStatActive] = useState(false);
    const [finPerformActive, setFinPerformActive] = useState(false);
    const [reviewsActive, setReviewsActive] = useState();
    const [clientsActive, setClientsActive] = useState(false)
    const [userID, setUserID] = useState('')
    const [showDrawer, setShowDrawer] = useState(false)

    const [facilityArray, setFacilityArray] = useState([]);
    const [currentFacility, setCurrentFacility] = useState({});

    const [facilArray, setFacilArray] = useState([]);

    const [facilitySet, setFacilitySet] = useState(false);

    const [loading, setLoading] = useState(true);

    const currentFacilityRef = useRef();
    currentFacilityRef.current = currentFacility;

    useEffect(() => {
        // console.log(currentFacility);
    }, [currentFacility])

    function handleActive(item) {
        if (item === 'busPerform') {
            setBusPerformActive(true);
            setFinStatActive(false);
            setFinPerformActive(false);
            setReviewsActive(false);
            setClientsActive(false);
        }
        if (item === 'finStat') {
            setBusPerformActive(false);
            setFinStatActive(true);
            setFinPerformActive(false);
            setReviewsActive(false);
            setClientsActive(false);
        }
        if (item === 'finPerform') {
            setBusPerformActive(false);
            setFinStatActive(false);
            setFinPerformActive(true);
            setReviewsActive(false);
            setClientsActive(false);
        }
        if (item === 'reviews') {
            setBusPerformActive(false);
            setFinStatActive(false);
            setFinPerformActive(false);
            setReviewsActive(true);
            setClientsActive(false);
        }
        if (item === 'clients') {
            setBusPerformActive(false);
            setFinStatActive(false);
            setFinPerformActive(false);
            setReviewsActive(false);
            setClientsActive(true);
        }
    }

    async function loadFacilityForReview(facilityID) {
        const facilityDoc = await db.collection('facilities').doc(facilityID).get();
        const facilityName = facilityDoc.data().listingName;

        setCurrentFacility({
            facilityID,
            facilityName
        })

        handleActive('reviews')

    }

    useEffect(() => {
        if (location.state !== undefined && location.state !== null) {
            console.log(location.state);
            loadFacilityForReview(location.state.facilityID);
        }
    }, [])

    useEffect(() => {
        if (currentUser) {
            setUserID(currentUser.uid)
            loadFacilities(currentUser.uid);
        }
    }, [currentUser])


    async function loadFacilities(uid) {
        const facilities =  await db.collection('facilities').where('ownerIDs', 'array-contains', uid).get();
        const newArray = [];
        const facArray = [];
        // facilities.forEach(facility => {
        for (var i = 0; i < facilities.docs.length; i++) {
            const facility = facilities.docs[i];
            const doc = facility.data();
            const facilityID = facility.id;
            const facilityName = doc.listingName;
            newArray.push({
                name: facilityName,
                value: facilityID,
            });
            facArray.push({
                facilityID,
                facilityName,
            });
            if (i === 0) {
                setCurrentFacility({
                    facilityID,
                    facilityName,
                });
            }
        // });
    }
        setFacilArray(newArray);
        setFacilityArray(facArray);
        setLoading(false);
    }


    return (
        <LoadingOverlay
        active={loading}
        spinner
        >
        <div className="analytics">
            {/* <h1>Analytics</h1> */}
            <div className="analytics-menu-container">
                {/* <AnalyticsMenu /> */}
                <div className="analytics-menu">
            <div className="analytics-menu-item">
                <p onClick={() => handleActive('busPerform')} className={busPerformActive ? 'active' : null}>Performance</p>
                <hr className="ruler"/>
            </div>
            {/* <div className="analytics-menu-item">
                <p onClick={() => handleActive('finStat')} className={finStatActive ? 'active' : null}>Statements</p>
                <hr className="ruler"/>
            </div> */}
            <div className="analytics-menu-item">
                <p onClick={() => handleActive('finPerform')} className={finPerformActive ? 'active' : null}>Financial Analysis</p>
                <hr className="ruler"/>
            </div>
            <div className="analytics-menu-item">
                <p onClick={() => handleActive('reviews')} className={reviewsActive ? 'active' : null}>Reviews</p>
                <hr className="ruler"/>
            </div>
            {userRole === 'owner' && <div className="analytics-menu-item">
                <p onClick={() => handleActive('clients')} className={clientsActive ? 'active' : null}>Clients</p>
                <hr className='ruler' />
            </div>}
        </div>
            </div>
            <div className="analytics-content-container">
                {userRole === 'owner' && <div className="drop-container">
                    <SelectSearch
                    search={false}
                    closeOnSelect={true}
                    placeholder={currentFacility.facilityName}
                    options={facilArray}
                    onChange={(evt) => {
                        console.log(evt)
                        // console.log(evt.target.options[evt.target.selectedIndex].innerText);
                        const facilityID = evt;
                        const facilityName = '';
                        setCurrentFacility({facilityID, facilityName});
                    }}
                    />
                    <MenuIcon onClick={() => setShowDrawer(true)} className='anal-menu-icon' />
                    {/* <select onChange={(evt) => {
                        console.log(evt.target.options[evt.target.selectedIndex].innerText);
                        const facilityID = evt.target.options[evt.target.selectedIndex].value;
                        const facilityName = evt.target.options[evt.target.selectedIndex].innerText;
                        setCurrentFacility({facilityID, facilityName});
                    }}> */}
                        {/* {
                            facilityArray.map((facility, index) => {
                                const facilityID = facility.facilityID;
                                const facilityName = facility.facilityName;
                                return <option value={facilityID} >{facilityName}</option>
                            })
                        }
                    </select> */}
                </div>}
                <Drawer
                            onClick={() => setShowDrawer(false)}
                            className='calendar-menu-drawer'
                            anchor='left'
                            open={showDrawer}
                            onClose={() => setShowDrawer(false)}
            >
                <div className="analytics-menu drawer-menu">
            <div className="analytics-menu-item">
                <p onClick={() => handleActive('busPerform')} className={busPerformActive ? 'active' : null}>Performance</p>
                <hr className="ruler"/>
            </div>
            {/* <div className="analytics-menu-item">
                <p onClick={() => handleActive('finStat')} className={finStatActive ? 'active' : null}>Statements</p>
                <hr className="ruler"/>
            </div> */}
            <div className="analytics-menu-item">
                <p onClick={() => handleActive('finPerform')} className={finPerformActive ? 'active' : null}>Financial Analysis</p>
                <hr className="ruler"/>
            </div>
            <div className="analytics-menu-item">
                <p onClick={() => handleActive('reviews')} className={reviewsActive ? 'active' : null}>Reviews</p>
                <hr className="ruler"/>
            </div>
            {userRole === 'owner' && <div className="analytics-menu-item">
                <p onClick={() => handleActive('clients')} className={clientsActive ? 'active' : null}>Clients</p>
                <hr className='ruler' />
            </div>}
        </div>
              </Drawer>
                {(busPerformActive && userID !== '') && <BusinessPerformance userRole={userRole} venueName={currentFacilityRef.current.facilityName} facilityID={currentFacilityRef.current.facilityID} uid={userID} />}
                {(finPerformActive && userID !== '' && userRole === 'instructor') && <InstructorAnnualStatement setLoading={setLoading} uid={userID} />}
                {(finPerformActive && userID !== '' && userRole === 'owner') && <OwnerAnnualStatement setLoading={setLoading} facilityID={currentFacilityRef.current.facilityID} uid={userID} />}
                {finStatActive && <FinancialStatements venueName={currentFacilityRef.current.facilityName} facilityID={currentFacilityRef.current.facilityID}/>}
                {reviewsActive && <ReviewPage userRole={userRole} uid={currentUser.uid} setLoading={setLoading} venueName={currentFacilityRef.current.facilityName} facilityID={currentFacilityRef.current.facilityID} />}
                {clientsActive && <ClientRelationships uid={currentUser.uid} userRole={userRole} setLoading={setLoading} venueName={currentFacilityRef.current.facilityName} facilityID={currentFacilityRef.current.facilityID} />}
            </div>
        </div>
        </LoadingOverlay>
    )
}

export default Analytics
