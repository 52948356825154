import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import './ClientThumbnail.css'

import InstructorAddSessionModal from '../../../Calendar/InstructorModals/InstructorAddSessionModal';

import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PeopleIcon from '@mui/icons-material/People';

function ClientThumbnail({ imageLink, name, clientID, goToClient, uid, chatID = '', role }) {

    const [showAdd, setShowAdd] = useState(false);

    const navigate = useNavigate();

    return (
        <div  className='client-thumbnail'>

            {showAdd && <InstructorAddSessionModal athleteID={clientID} closeModal={() => setShowAdd(false)} uid={uid} />}

            <div className="ct-top">
                <img alt={name} src={imageLink} />
            </div>
            <div className="ct-bottom">
                <p>{name}</p>
            </div>
            <div className="ct-actions">
                {(chatID !== undefined && chatID !== '') && <div onClick={() => {
                    navigate(
                        '/inbox',
                        {
                            state: {chatID},
                    })
                }} className="ct-action">
                    <ChatBubbleIcon />
                    <p>Chat</p>
                </div>}
                {role === 'instructor' && <div onClick={() => setShowAdd(true)} className="ct-action">
                    <AddCircleIcon />
                    <p>Add Session</p>
                </div>}
                <div onClick={goToClient} className="ct-action">
                    <PeopleIcon />
                    <p>View</p>
                </div>
            </div>
        </div>
    )
}

export default ClientThumbnail
