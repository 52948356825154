import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import './NextAvailable.css'

import StarIcon from '@mui/icons-material/Star';

import CreditsModal from '../../../Components/CreditsModal/CreditsModal';

import { db, admin } from '../../../firebase';

import { useAuth } from '../../../contexts/AuthContext';

function NextAvaialable({ facilityID, rate, rating, favorite, userID, open = false }) {

    const [bookings, setBookings] = useState([])

    const [showModal, setShowModal] = useState(false)


    const {currentUser} = useAuth()

    useEffect(() => {



        loadTimes();
        console.log(favorite)
    }, [])

    const navigate = useNavigate();

    async function goToCalendar() {
        if (!favorite) {
            await db.collection('users').doc(userID).update({
                favoriteFacilities: admin.firestore.FieldValue.arrayUnion(facilityID)
            });
            navigate(
                '/calendar',
                {
                    state: {facilityID},
            })
        } else {
            navigate(
                '/calendar',
                {
                    state: {facilityID},
            })
        }
    }

    async function loadTimes() {
        const now = new Date()
        const availableDocs = await db.collection('facilities').doc(facilityID).collection('calendarEvents')
            .where('start', '>=', now)
            .where('type', '==', 'available')
            .limit(3)
            .orderBy('start', 'asc')
            .get().catch(err => console.log(err));

        console.log(availableDocs.docs.length)

        const bookingArray = [];

        for (let i = 0; i < availableDocs.docs.length; i++) {
            const booking = availableDocs.docs[i].data();

            const bookingDate = new Date(booking.start.toDate())
            const dateString = bookingDate.toLocaleDateString('en-us', { dateStyle: 'medium' });
            const timeString = bookingDate.toLocaleTimeString('en-us', { timeStyle: 'short'});
            bookingArray.push({
                dateString,
                timeString,
                capacity: booking.capacity
            })
        }

        setBookings(bookingArray);


    }

    function  handleScroll(e) {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        console.log(e)
        }

    return (
        <div className='next-available'>

            {showModal && <CreditsModal closeModal={() => setShowModal(false)} facilityID={facilityID} instructorID={currentUser.uid}  />}

            <div className="top-row">
                <div className="rates-container">
                    <h4 className='base-rate'>{`Base Rate: `}</h4>
                    <h4>R {rate} / h</h4>
                </div>
                <div className="rating-container">
                    <StarIcon />
                    <p>{rating}</p>
                </div>
            </div>
            <h4>Next Availability</h4>
            <div className="next-avail-container">
            {bookings.map((booking) => {
                return <p>{booking.dateString} - {booking.timeString} | {booking.capacity} spots left</p>
            })}
            {bookings.length === 0 && <p>Unfortunately there are no openings at this point in time</p>}
            </div>

            {!open && <div className="button-row">
                <button onClick={() => setShowModal(true)} className='saffron-button'>Credits</button>
                <button onClick={goToCalendar} className="confirm-button">
                    Book
                </button>
            </div>}

        </div>
    )
}

export default NextAvaialable
