import React from 'react'
import './MenuItem.css'

function MenuItem({ text, active, onClickEvent }) {

    return (
        <div onClick={onClickEvent} className={active ? "menu-item active" : "menu-item"}>
            <p>{text}</p>
            <hr />
        </div>
    )
}

export default MenuItem
