import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

const app = firebase.initializeApp({
  apiKey: "AIzaSyBNVt036Vwj3EMeNMVyTEh9JPqCuZ6xsi8",
  authDomain: "forte-v2.firebaseapp.com",
  databaseURL: "https://forte-v2-default-rtdb.firebaseio.com",
  projectId: "forte-v2",
  storageBucket: "forte-v2.appspot.com",
  messagingSenderId: "1073930701813",
  appId: "1:1073930701813:web:2df21c8bd42e6732736ebc",
  measurementId: "G-YFR77VQ8B5"
  });

export const admin = firebase;
export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export default app