import React, { useState, useEffect } from 'react'
import './BookingDetailModal.css'
import { useNavigate } from 'react-router'
import LoadingOverlay from 'react-loading-overlay';

import moment from 'moment'

import { db, admin } from '../../firebase'

function BookingDetailModal({ role, group = false, session = false, cancelOnly, confirmOnly, closeModal, title, total, start, end, bookingID, type, venueName, facilityID, reloadCalendar, chatID, calendarID, instructorID, startObj, endObj}) {
    const [startCancel, setStartCancel] = useState(false);
    const [hideCancel, setHideCancel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hideConfirm, setHideConfirm] = useState(false)
    const [charge, setCharge] = useState('')
    const [paidCredits, setPaidCredits] = useState(false)
    const [policy, setPolicy] = useState();
    const [loadingMessage, setLoadingMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [policyText, setPolicyText] = useState('')
    const navigate = useNavigate();

    const [refundAmount, setRefundAmount] = useState(0)
    const [refundComplete, setRefundComplete] = useState(false)

    useEffect(() => {

        console.log(type, role)

        if (type === 'booked' || role === 'instructor') {
            loadFacilityPolicy();
        }

        const now = new Date();
        if (now > startObj) {
            setHideCancel(true)
        }

        if (type === 'available' && role ==='instructor') {
            setHideCancel(true)
        }

        if (cancelOnly) {
            setHideConfirm(true)
        }

        if (type === 'pending' && role === 'instructor') {
            setHideConfirm(true)
        }
    }, [])

    async function cancelBooking() {
        setLoading(true);
        if (type === 'available') {
            try {
                await db.collection('facilities').doc(facilityID).collection('calendarEvents').doc(calendarID).update({
                    cancelled: true,
                });
                reloadCalendar();
                closeModal();
            } catch (err) {
                console.log(err)
            }
        }
        if (type === 'booked') {

            if (role === 'owner') {
                try {
                    await db.collection('facilities').doc(facilityID).collection('calendarEvents').doc(calendarID).update({
                        cancelled: true,
                    });
                    await db.collection('bookings').doc(bookingID).update({
                        status: 'cancelledByFacility'
                    });
                    reloadCalendar();
                    closeModal();
                } catch (err) {
                    console.log(err)
                }
            }

            if (role === 'instructor') {
                try {

                    const refundTotal = total * refundAmount / 100;


                    // Credits refund
                    if (paidCredits) {
                        if (refundAmount !== 0) {

                            try {
                                await db.collection('users').doc(instructorID).collection('facilityCredits').add({
                                    amount: refundTotal,
                                    date: new Date(),
                                    facilityID,
                                });

                                // User Credits
                                const userDoc = await db.collection('users').doc(instructorID).get();
                                const user = userDoc.data();
                                const { facilityCredits } = user;
                                facilityCredits[facilityID] += refundTotal;
                                await userDoc.ref.update({
                                    facilityCredits,
                                })

                                // Facility Credits
                                const facilityDoc = await db.collection('facilities').doc(facilityID).get();
                                const facility = facilityDoc.data();
                                const { instructorCredits } = facility;
                                instructorCredits[instructorID] += refundTotal;
                                await facilityDoc.ref.update({
                                    instructorCredits,
                                })
                                return setRefundComplete(true)
                            } catch (err) {
                                return setErrorMessage('Could not process your refund, please try again')
                            }



                        }
                    } else {
                        // Yoco refund

                        const yocoRefundCall = admin.functions().httpsCallable('bookingYocoRefund');
                        const refund = await yocoRefundCall({ bookingID, chargeID: charge})
                        if (refund === false) {
                            return setErrorMessage('Could not process you refund, please try again')
                        } else {
                            return setRefundComplete(true)
                        }
                    }



                    if (!session) {
                        await db.collection('users').doc(instructorID).collection('calendarEvents').doc(calendarID).update({
                            cancelled: true,
                        });
                        await db.collection('bookings').doc(bookingID).update({
                            status: 'cancelledByInstructor'
                        });
                    } else {
                        await db.collection('sessions').doc(bookingID).update({
                            status: 'cancelledByInstructor'
                        });
                    }
                    reloadCalendar();
                    closeModal();
                } catch (err) {
                    console.log(err)
                }
            }

        }
        if (type === 'pending') {
            if (role === 'owner') {
                try {
                    // Facility docs
                    await db.collection('facilities').doc(facilityID).collection('calendarEvents').doc(calendarID).update({
                        cancelled: true,
                        type: 'denied'
                    });
                    // Booking
                    await db.collection('bookings').doc(bookingID).update({
                        status: 'denied',
                    });
                    reloadCalendar();
                    closeModal();            }
                catch (err) {
                    console.log(err)
                }
            }

            if (role === 'instructor') {
                try {
                    await db.collection('users').doc(instructorID).collection('calendarEvents').doc(calendarID).update({
                        cancelled: true,
                        type: 'cancelled'
                    });
                    await db.collection('bookings').doc(bookingID).update({
                        status: 'cancelledByInstructor',
                    });
                    reloadCalendar();
                    closeModal();            }
                catch (err) {
                    console.log(err)
                }
            }

        }

        setLoading(false);

    }

    async function confirmPendingBooking() {


        setLoading(true)
        try {
            // return console.log(instructorID)
            const bookingDoc = await db.collection('bookings').doc(bookingID).get();
            const booking = bookingDoc.data();
            const availablesID = booking.availableID;
            const { instructorCalendarID, instructorID } = booking;

            // return console.log(facilityID)

            await db.collection('facilities').doc(facilityID).collection('calendarEvents').doc(calendarID).update({
                type: 'booked',
            });
            await db.collection('bookings').doc(bookingID).update({
                paymentStatus: 'paymentPending',
                status: 'booked',
            });
            await db.collection('users').doc(instructorID).collection('calendarEvents').doc(instructorCalendarID)
            .update({
                paymentStatus: 'paymentPending',
                type: 'booked'
            })



            await db.collection('facilities').doc(facilityID).collection('calendarEvents').doc(availablesID).update({
                bookingIDs: admin.firestore.FieldValue.arrayUnion(bookingID),
            })
            reloadCalendar();
            closeModal();
            } catch (err) {
            console.log(err)
        }
        setLoading(false)
    }

    function goToChat() {
        navigate(
            '/inbox',
            {
                state: {chatID},
        })
    }

    async function loadFacilityPolicy() {

        if (start < new Date()) {
            return
        }

        const facilityDoc = await db.collection('facilities').doc(facilityID).get();
        const facility = facilityDoc.data();

        const bookingDoc = await db.collection('bookings').doc(bookingID).get();
        const booking = bookingDoc.data();
        const {chargeID, paidWithCredits} = booking;
        setCharge(chargeID)
        setPaidCredits(paidWithCredits)
        const dateCreated = new Date(booking.dateCreated.toDate());
        const createdMoment = moment(dateCreated)

        const { cancellationPolicy } = facility;
        setPolicy(cancellationPolicy);

        const startMoment = moment(startObj)
        const nowMoment = moment(new Date());

        const daysDiff = startMoment.diff(nowMoment, 'days');
        const createDaysDiff = createdMoment.diff(nowMoment, 'days')
        console.log(daysDiff)

        if (cancellationPolicy === 'flexible') {
            if (daysDiff > 0) {
                setPolicyText('You will be refunded in full for this cancellation')
                setRefundAmount(100)
            } else {
                setPolicyText('As the booking is less than 24 hours away, you will not be refunded for this cancellation')
            }
        }

        if (cancellationPolicy === 'moderate') {
            if (daysDiff > 4) {
                setPolicyText('You will be refunded in full for this cancellation')
                setRefundAmount(100)
            } else {
                setRefundAmount(0)
                setPolicyText('As the booking is less than 24 hours away, you will not be refunded for this cancellation')
            }
        }

        if (cancellationPolicy === 'strict') {
            if (daysDiff > 14 && createDaysDiff < 3) {
                setRefundAmount(100)
                return setPolicyText('You will be refunded in full for this cancellation')
            } else if (daysDiff > 7) {
                setRefundAmount(100)
                setPolicyText('As the booking is more than 7 days away, you will be refunded 100% for this cancellation')
            } else {
                setRefundAmount(0)
                setPolicyText('As the booking is less than 7 days away, you will not be refunded for this cancellation')
            }
        }
    }

    return (
        <div onClick={(evt) => {
            if (evt.target.className === 'booking-detail-modal') {
                closeModal();
            }
        }} className='booking-detail-modal'>
            {startCancel ?
            <div className="booking-detail-modal-content">
                {refundComplete ? <>
                <div className="refund-complete-container">
                    <h1>Refund Complete</h1>
                    <p>Your booking was successfully cancelled</p>
                </div>
                </> :
                <>
                <div className='cancel-available-container'>
                    <h2>{type === 'available' ? 'Are you sure you want to remove this available slot?' : 'Are you sure you want to cancel this booking request?'}</h2>
                    <p>{venueName}</p>
                <div className="booking-date-group">
                    <p><span className='bold-text'>Start:</span> {startObj.toLocaleTimeString('en-us', { timeStyle: 'short'})}</p>
                    <p><span className='bold-text'>End:</span> {endObj.toLocaleTimeString('en-us', { timeStyle: 'short'})}</p>
                </div>
                              <div className="cancel-policy-container">
                    <p>{policyText}</p>
                </div>
                {errorMessage !== '' && <div className="error-message">{errorMessage}</div>}
                    <div className="booking-button-row button-row cancel-button-row">
                        <button onClick={() => setStartCancel(false)} className='cancel-back-button'>Back</button>
                        <button onClick={cancelBooking} className='close-button'>Confirm</button>
                    </div>
                </div>
                  </>}
            </div>
            : <div className="booking-detail-modal-content scale-in-center">
                <h1>Session Details</h1>
                {/* {(!(type === 'pending' && role === 'instructor') && type !== 'Pending Confirmation') && <p>Listing: {venueName}</p>} */}
                {!(type === 'pending' && role === 'instructor') &&<h2>{type === 'available' ? 'Status: ' : type === 'Pending Confirmation' ? 'Listing: ' : 'Listing: '}{title}</h2>}
                {(type === 'pending' && role === 'instructor') && <h2>Listing: {title}</h2>}

                <div className="booking-date-group">
                {type !== 'available' && <><p style={{textTransform: 'capitalize'}}><span className='bold-text'>Status: </span>{type}</p>
                </>}
                    <p><span className='bold-text'>Date:</span> {
                    startObj.getDate() === endObj.getDate() ?
                        startObj.toDateString() : <>{`${startObj.toDateString()} - ${endObj.toDateString()}`}</>
                    }</p>
                    <p><span className='bold-text'>Time:</span> {startObj.toLocaleTimeString('en-us', {timeStyle: 'short'})} - {endObj.toLocaleTimeString('en-us', {timeStyle: 'short'})}</p>
                </div>
                {(type === 'booked' && role === 'instructor') && <div className="cancel-policy-container">
                    <p>{policyText}</p>
                </div>}
                <div className="booking-button-row button-row">
                    {!confirmOnly && <button hidden={hideCancel} onClick={() => setStartCancel(true)} disabled={loading} id='cancel-button'>Cancel</button>}
                    {(type === 'booked' && !group) && <button onClick={() => goToChat()} id='confirm-button'>Chat</button>}
                    {(type === 'pending' || type === 'requested') && <button hidden={hideConfirm} onClick={() => confirmPendingBooking()} disabled={loading} id='confirm-button'>Confirm</button>}
                    {/* <button onClick={closeModal} className='close-button'>Close</button> */}
                </div>
            </div>}
        </div>
    )
}

export default BookingDetailModal
