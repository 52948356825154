import React, { useState, useRef, useEffect, forwardRef } from 'react'
import './Listings.css'

// Icons
import AddBoxIcon from '@material-ui/icons/AddBox';

// Components
import Listing from './Listing';
import StepOne from './Steps/StepOne';
import StepTwo from './Steps/StepTwo';
import StepThree from './Steps/StepThree';
import StepFour from './Steps/StepFour';
import StepFive from './Steps/StepFive';
import StepSix from './Steps/StepSix';
import StepSeven from './Steps/StepSeven';
import StepEight from './Steps/StepEight'
import ListingCompleteModal from './Steps/ListingCompleteModal';


// Firebase
import { admin, db, storage } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import uuid from 'react-uuid';


function Listings() {

    const {currentUser} = useAuth();

    // New listing selected
    const [newListing, setNewListing] = useState(false);

    // Error message
    const [errorMessage, setErrorMessage] = useState('');
    const [update, setUpdate] = useState(0)
    // Input State
    const [venueType, setVenueType] = useState();
    const [venueSpace, setVenueSpace] = useState();
    const [maxCapacity, setMaxCapacity] = useState();
    const [location, setLocation] = useState();
    const [locationHash, setLocationHash] = useState();
    const [locationTextValue, setLocationTextValue] = useState()
    const [facilities, setFacilities] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [description, setDescription] = useState('');
    const [imageLinks, setImageLinks] = useState([]);
    const [addressOne, setAddressOne] = useState();
    const [addressTwo, setAddressTwo] = useState();
    const [city, setCity] = useState();
    const [suburb, setSuburb] = useState();
    const [postalCode, setPostalCode] = useState();
    const [country, setCountry] = useState();
    const [province, setProvince] = useState();
    const [venueInfo, setVenueInfo] = useState();
    const [hourlyRate, setHourlyRate] = useState(0);
    const [hourlySelected, setHourlySelected] = useState(false);
    const [halfDayRate, setHalfDayRate] = useState(0);
    const [halfDaySelected, setHalfDaySelected] = useState(false);
    const [dayRate, setDayRate] = useState(0);
    const [daySelected, setDaySelected] = useState(false);
    const [weekRate, setWeekRate] = useState(0);
    const [weekSelected, setWeekSelected] = useState(false);
    const [monthRate, setMonthRate] = useState(0);
    const [monthSelected, setMonthSelected] = useState(false);
    const [listingName, setListingName] = useState();
    const [bookingPolicy, setBookingPolicy] = useState('');


    // Other state
    const [finished, setFinished] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [facilityID, setFacilityID] = useState();
    const [initiated, setInitiated] = useState(false);
    const [listings, setListings]= useState([]);

    // Prices
    const [increaseArray, setIncreaseArray] = useState([])
    const priceIncreaseRef = useRef();
    const [decreaseArray, setDecreaseArray] = useState([])
    const priceDecreaseRef = useRef();
    const [bulkArray, setBulkArray] = useState([])
    const bulkRef = useRef();

    const [policySelected, setPolicySelected] = useState('');
    const [houseRuleSelected, setHouseRuleSelected] = useState();
    const rulesStoreRef = useRef();
    rulesStoreRef.current = houseRuleSelected;

    // Input Refs
    // Step One
    const venueTypeRef = useRef();
    const venueSpaceRef = useRef();
    const maxCapacityRef = useRef();
    const locationRef = useRef();
    const locationHashRef = useRef();
    const locationTextRef = useRef();

    // Step Two
    const facRef = useRef();
    facRef.current = facilities;
    const amenRef = useRef();
    amenRef.current = amenities;

    // Step 3
    const aboutRef = useRef();

    // Step 5
    const addressOneRef = useRef();
    const addressTwoRef = useRef();
    const suburbRef = useRef();
    const cityRef = useRef();
    const postalRef = useRef();
    const countryRef = useRef();
    const facInfoRef = useRef();

    // Step 6
    const hourlyCheckedRef = useRef();
    const halfDayCheckedRef = useRef();
    const fullDayCheckedRef = useRef();
    const weeklyCheckedRef = useRef();
    const monthlyCheckedRef = useRef();

    const hourlyRef = useRef();
    const halfDayRef = useRef();
    const fullDayRef = useRef();
    const weeklyRef = useRef();
    const monthlyRef = useRef();

    // Step 7
    const facilityNameRef = useRef();

    // Step 8
    const policyRef = useRef();
    const bookingPolicyRef = useRef();
    const houseRulesRef = useRef();

    // Create listing doc
    async function createListing() {
        console.log(currentUser.uid);
        const currentUID = currentUser.uid;
        try {
        await db.collection('facilities').add({
            userID: currentUID,
            imageLinks: [],
        }).then((newListing) => {
            console.log('didded')
            console.log(newListing.id);
            setFacilityID(newListing.id);
            setInitiated(true);
        });
    } catch(err) {
        console.log(err);
    }
        console.log('diddeded')

    }

    // Load listings from firestore
    async function loadListings() {
        const listingArray = [];
        const memberListings = await db.collection('facilities').where('userID', '==', currentUser.uid).get();
        for (var i = 0; i < memberListings.docs.length; i++) {
            const currentListing = memberListings.docs[i];
            const doc = currentListing.data();
            console.log(doc)

            const imageArray = doc.imageLinks;
            // console.log(imageArray);
            const description = doc.description;
            if (description === undefined) {
                continue
            }
            const title = doc.listingName ?? "Harry's HIIT House"
            listingArray.push(<Listing facilityID={currentListing.id} images={imageArray} title={title} description={description} />)
        }
        if (memberListings.docs.length === 0) {
            listingArray.push(<h4>Added listings will appear here</h4>)
        }
        setListings(listingArray);
    }

    useEffect(() => {
        if (currentUser) {
        loadListings();
        }
    },[currentUser]);

    async function uploadRules() {
      const storageRef = storage.ref().child(`facility-rules/${uuid()}`);
       const task = storageRef.put(houseRulesRef.current);
       task.on('state changed',
       (snapshot) => {

       },
       (err) => {

       },
       () => {
        task.snapshot.ref.getDownloadURL().then(async (url) => {
          console.log(url);
          await db.collection('facilities').doc(facilityID).update({
            facilityRules: admin.firestore.FieldValue.arrayUnion(url)
        })
        });

       },
       );
      }

    // Save all data to firestore
    async function saveData() {
        const ownerIDs = [currentUser.uid];
        const now = new Date();
        const currentYear = now.getFullYear();

        console.log(increaseArray);
        console.log(decreaseArray);
        console.log(bulkArray)
        // return
        try {
            await db.collection('facilities').doc(facilityID).update({
                clientArray: [],
                dateAdded: now,
                fiveStarRewiews: 0,
                totalIncome: 0,
                totalBookings: 0,
                currentMonthIncome: 0,
                currentYearIncome: 0,
                currentMonthBookings: 0,
                currentYearBookings: 0,
                totalReviews: 0,
                reviewAverage: 0,
                ownerIDs,
                venueType,
                venueSpace,
                maxCapacity: parseInt(maxCapacity),
                locationPoint: location,
                locationHash: locationHash,
                venueFacilities: facilities,
                venueAmenities: amenities,
                description,
                addressOne,
                addressTwo,
                suburb,
                city,
                province,
                country,
                venueInfo,
                hourlyRate: parseInt(hourlyRate),
                listingName,
                priceIncreases: increaseArray,
                priceDecreases: decreaseArray,
                bulkDiscounts: bulkArray,
                facilityRules: [],
                bookingPolicy: bookingPolicyRef.current,
                cancellationPolicy: policyRef.current,
                locationText: locationTextValue,
            });
            await db.collection('facilities').doc(facilityID).collection('analytics').doc('general').set({
                totalIncome: 0,
                totalBookings: 0,
                currentMonthIncome: 0,
                currentYearIncome: 0,
                currentMonthBookings: 0,
                currentYearBookings: 0,
                [`${currentYear}`]: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            })
            await db.collection('users').doc(currentUser.uid).update({
                facilityIDs: admin.firestore.FieldValue.arrayUnion(facilityID)
            })
            uploadRules()
            setFinished(true);
            setNewListing(false);
            loadListings()
        } catch(err) {
            console.log(err);
            setErrorMessage('Could not save your listing, please try again');
        }
    }


    // Start a new listing
    function startNewListing() {
        setNewListing(!newListing);
        // setFinished(true);
    }

    // Focus refs in the first step
    function focusStepOneRef() {
        // maxCapacityRef.current.focus();
        venueTypeRef.current.focus();
        // venueSpaceRef.current.focus();
        // locationRef.current.focus();
        // locationHashRef.current.focus();
    }

    // Validate the first step
    function validateStepOne() {

        const storedLocation = locationRef.current;
        // console.log(venueSpaceRef.current.value)
        console.log(venueType)
        // Venue Type
        if (venueType === undefined) {
            setErrorMessage('Please select a venue type')
            return false
        }
        // Venue Space
        if (venueSpace  === undefined) {
            setErrorMessage('Please select a session type')
            return false
        }
        // Stored Max Capacity
        if (maxCapacity === undefined) {
            setErrorMessage('Please select a maximum capcity')
            return false
        }
        // Location
        if (storedLocation === undefined) {
            setErrorMessage('Please enter the facility location')
            return false
        }
    }

    // Step Two changes
    function handleFacilityChange(event) {
        // If target was checked, add to array
        if (event.target.checked) {
            setFacilities(facilities => [...facilities, event.target.id]);
        } else {
            // If target was unchecked, delete from array
            let newFacilities = [...facRef.current];
            const index = newFacilities.indexOf(event.target.id);
            newFacilities.splice(index, 1);
            setFacilities(newFacilities);
        }
        // console.log(newFacilities);
    }
    function handleAmenitiesChange(event) {
        // If target was checked, add to array
        if (event.target.checked) {
            setAmenities(amenities => [...amenities, event.target.id])
        } else {
            let newAmenities = [...amenRef.current];
            // If target was unchecked, delete from array
            const index = newAmenities.indexOf(event.target.id);
            newAmenities.splice(index, 1);
            setFacilities(newAmenities);
        }
    }

    // Step Three description
    function focusStepThreeRef() {
        aboutRef.current.focus();
    }

    // Step Five
    function focusStepFiveRef() {
        addressOneRef.current.focus();
        addressTwoRef.current.focus();
        // cityRef.current.focus();
        // countryRef.current.focus();
        // postalRef.current.focus();
        facInfoRef.current.focus();
    }

    // Validate step five
    function validateStepFive() {
        const addressOneStore = addressOneRef.current.value;
        // const postalCodeStore = postalRef.current.value;
        console.log(countryRef.current.value);
        console.log(country, city, suburb)

        if (addressOneStore === '') {
            setErrorMessage('Please enter an address for your facility');
            return false
        }
        if (country === undefined) {
            setErrorMessage('Please enter a country for your facility');
            return false
        }
        if (province === undefined) {
            setErrorMessage('Please select a province')
        }
        if (city === undefined) {
            setErrorMessage('Please select a city for your facility');
            return false
        }
        if (suburb === undefined) {
            setErrorMessage('Please select a suburb for your facility')
        }
    }

    // Step Six
    function focusStepSixRef() {
        hourlyCheckedRef.current.focus();
        hourlyRef.current.focus();
        halfDayCheckedRef.current.focus();
        halfDayRef.current.focus();
        fullDayCheckedRef.current.focus();
        fullDayRef.current.focus();
        weeklyRef.current.focus();
        weeklyCheckedRef.current.focus();
        monthlyRef.current.focus();
        monthlyCheckedRef.current.focus();
    }

    function validateStepSix() {
        return
        // Hourly rate
        hourlyRef.cu.rrent.focus()
        if (hourlyRef.current.value === '') {
            setErrorMessage('Please enter a base rate')
            return false
        }

        // Increase Array
        // console.log(priceIncreaseRef.current)

        for (let i = 0; i < priceIncreaseRef.current.length; i++) {
            const item = priceIncreaseRef.current[i];
            // console.log(item)
            const start = parseInt(item.startTime)
            const end = parseInt(item.endTime)
            if (end <= start) {
                setErrorMessage('Please ensure the times are correct')
                return false
            }

            // Discount
            if (item.amount === 0) {
                setErrorMessage('Please ensure to select a discount percentage')
                return false
            }

            // Days / Months
            if (item.type === 'daily') {
                if (item.days.length === 0) {
                    setErrorMessage('Please ensure to select at least one day')
                    return false
                }
            } else {
                if (item.months.length === 0) {
                    setErrorMessage('Please ensure to select at least one day')
                }
            }

        }

        for (let i = 0; i < priceDecreaseRef.current.length; i++) {
            const item = priceDecreaseRef.current[i];
            // console.log(item)
            const start = parseInt(item.startTime)
            const end = parseInt(item.endTime)

            // Times
            if (end <= start) {
                setErrorMessage('Please ensure the times are correctly formatted')
                return false
            }

            // Discount
            if (item.amount === 0) {
                setErrorMessage('Please ensure to select a discount percentage')
                return false
            }

            // Days / Months
            if (item.type === 'daily') {
                if (item.days.length === 0) {
                    setErrorMessage('Please ensure to select at least one day')
                    return false
                }
            } else {
                if (item.months.length === 0) {
                    setErrorMessage('Please ensure to select at least one day')
                }
            }
        }

        for (let i = 0; i < bulkRef.current.length; i++) {
            const item = bulkRef.current[i];
            console.log(item)

            // Amount
            if (item.amount === 0) {
                setErrorMessage('Please enter a purchase amount for this discount')
                return false
            }
            // Percentage
            if (item.percentage === 0) {
                setErrorMessage('Please enter a discount rate')
                return false
            }
            // Size
            if (item.size === '') {
                setErrorMessage('Please select a size for this discount')
                return false
            }
        }
    }

    // Validate Step Six
    function validateStepSixOld() {
        // Overall
        if (hourlyCheckedRef.current.checked === false && halfDayCheckedRef.current.checked === false && fullDayCheckedRef.current.checked === false
                && weeklyCheckedRef.current.checked === false && monthlyCheckedRef.current.checked === false) {
                    setErrorMessage('Please enter at least one rate')
                    return false
                }
        // Hourly
        console.log(hourlyCheckedRef.current.checked, hourlyRef.current.value);
        if (hourlyCheckedRef.current.checked === true && hourlyRef.current.value === "0") {
            setErrorMessage('Please enter an hourly rate');
            return false
        }
        // Half Day
        if (halfDayCheckedRef.current.checked === true && halfDayRef.current.value === "0") {
            setErrorMessage('Please enter a half day rate');
            return false
        }
        // Full Day
        if (fullDayCheckedRef.current.checked === true && fullDayRef.current.value === "0") {
            setErrorMessage('Please enter a full day rate');
            return false
        }
        // Weekly
        if (weeklyCheckedRef.current.checked === true && weeklyRef.current.value === "0") {
            setErrorMessage('Please enter a weekly rate');
            return false
        }
        // Monthly
        if (monthlyCheckedRef.current.checked === true && monthlyRef.current.value === "0") {
            setErrorMessage('Please enter a monthly rate');
            return false
        }
    }

    // Step Seven
    function focusStepSevenRef() {
        facilityNameRef.current.focus();
        setListingName(facilityNameRef.current.value);
    }

    function validateStepSeven() {
        if (facilityNameRef.current.value === '') {
            setErrorMessage('Please enter a facility name')
            return false
        }
    }

    function validateStepEight() {
        if (policyRef.current === '') {
            setErrorMessage('Please select a cancellation policy')
            return false
        }
    }
    // Set up first step and keep track of state
    const [currentStep, setCurrentStep] = useState('step-one');
    const [stepComponent, setStepComponent] = useState(<StepOne locationTextRef={locationTextRef} locationTextValue={locationTextValue} locationHashRef={locationHashRef} locationValue={location} locationHashValue={locationHash} setCapacity={setMaxCapacity} setSpace={setVenueSpace} setType={setVenueType} locationRef={locationRef} venueTypeRef={venueTypeRef} venueSpaceRef={venueSpaceRef} maxCapacityRef={maxCapacityRef} />);
    // const [stepComponent, setStepComponent] = useState(<StepTwo onFacilitySelect={handleFacilityChange} onAmenitySelect={handleAmenitiesChange} />);
    // const [stepComponent, setStepComponent] = useState(<StepEight bpStore={bookingPolicy} bookingPolicyRef={bookingPolicyRef} defaultSelected={policySelected} defaultFiles={rulesStoreRef.current} selectedRef={policyRef} filesRef={houseRulesRef}/>);
    // const [stepComponent, setStepComponent] = useState(<StepSix bulkRef={bulkRef} priceDecreaseRef={priceDecreaseRef} priceIncreaseRef={priceIncreaseRef} hourlyChecked={hourlyCheckedRef} hourlyRate={hourlyRef} halfDayChecked={halfDayCheckedRef} halfDayRate={halfDayRef} fullDayChecked={fullDayCheckedRef} fullDayRate={fullDayRef} weeklyChecked={weeklyCheckedRef} weeklyRate={weeklyRef} monthlyChecked={monthlyCheckedRef} monthlyRate={monthlyRef} />);
    // const [stepComponent, setStepComponent] = useState(<StepFive
    //     suburbRef={suburbRef} suburbValue={suburb} setCity={setCity} setProvince={setProvince} setCountry={setCountry} setSuburb={setSuburb}
    //     addressOneValue={addressOne} addressTwoValue={addressTwo} cityValue={city} postalValue={postalCode} countryValue={country} facInfoValue={venueInfo}
    //     addressOneRef={addressOneRef} addressTwoRef={addressTwoRef} cityRef={cityRef} postalRef={postalRef} facInfoRef={facInfoRef} countryRef={countryRef}
    //     />)
    // const [stepComponent, setStepComponent] = useState(<StepFour facilityID={facilityID} setError={setErrorMessage} disableButtons={setButtonsDisabled} />);

    // Next Step Pressed
    function nextStep() {
        setErrorMessage('');
        if (currentStep === 'step-one') {

            if (initiated === false) {
                createListing();
            }
            // Focus Refs
            focusStepOneRef();
            // Validate Input
            if (validateStepOne() === false) {
                return
            }
            // Store Refs
            // console.log(venueTypeRef.current.state)
            // setVenueType(venueTypeRef.current.state.selectedValues[0].name);
            // setVenueSpace(venueSpaceRef.current.state.selectedValues[0].name);
            // setMaxCapacity(maxCapacityRef.current.state.selectedValues[0].name);

            // console.log(venueType, venueSpace, maxCapacity)
            setLocation(locationRef.current);
            setLocationHash(locationHashRef.current);
            setLocationTextValue(locationTextRef.current)


            // Change the step
            setCurrentStep('step-two');
            setStepComponent(<StepTwo facilityArray={facilities} amenityArray={amenities} onFacilitySelect={handleFacilityChange} onAmenitySelect={handleAmenitiesChange} />);
        }
        if (currentStep === 'step-two') {
            setCurrentStep('step-three');
            setStepComponent(<StepThree columns={75} descRef={aboutRef} descriptionValue={description} />);
        }
        if (currentStep === 'step-three') {
            focusStepThreeRef();
            if (aboutRef.current.value.length < 40) {
                return setErrorMessage('Minimum 40 characters')
            }
            setDescription(aboutRef.current.value);
            setCurrentStep('step-four');
            setStepComponent(<StepFour facilityID={facilityID} setError={setErrorMessage} disableButtons={setButtonsDisabled} />);
        }
        if (currentStep === 'step-four') {
            setCurrentStep('step-five');
            setStepComponent(<StepFive
                suburbRef={suburbRef} setProvince={setProvince} suburbValue={suburb} setCity={setCity} setCountry={setCountry} setSuburb={setSuburb}
                addressOneValue={addressOne} addressTwoValue={addressTwo} cityValue={city} postalValue={postalCode} countryValue={country} facInfoValue={venueInfo}
                addressOneRef={addressOneRef} addressTwoRef={addressTwoRef} cityRef={cityRef} postalRef={postalRef} facInfoRef={facInfoRef} countryRef={countryRef} />);
        }
        if (currentStep === 'step-five') {
            focusStepFiveRef();
            if (validateStepFive() === false) {
                return
            }
            setAddressOne(addressOneRef.current.value);
            setAddressTwo(addressTwoRef.current.value);
            setVenueInfo(facInfoRef.current.value);
            // setSuburb(suburbRef.current.value)
            setCurrentStep('step-six');
            setStepComponent(<StepSix
                bulkRef={bulkRef} priceDecreaseRef={priceDecreaseRef} priceIncreaseRef={priceIncreaseRef}
                hourlyValue={hourlyRate} halfDayValue={halfDayRate} dayValue={dayRate} weeklyValue={weekRate} monthlyValue={monthRate}
                hourlyCheckedValue={hourlySelected} halfDayCheckedValue={halfDaySelected} dayCheckedValue={daySelected} weeklyCheckedValue={weekSelected} monthlyCheckedValue={monthSelected}
                hourlyChecked={hourlyCheckedRef} hourlyRate={hourlyRef} halfDayChecked={halfDayCheckedRef} halfDayRate={halfDayRef} fullDayChecked={fullDayCheckedRef} fullDayRate={fullDayRef} weeklyChecked={weeklyCheckedRef} weeklyRate={weeklyRef} monthlyChecked={monthlyCheckedRef} monthlyRate={monthlyRef} />);
        }
        if (currentStep === 'step-six') {
            // focusStepSixRef();
            setUpdate(current => current + 1);
            if (validateStepSix() === false) {
                return setStepComponent(<StepSeven facNameRef={facilityNameRef} defaultName={listingName} />);

            }
            // // State
            // console.log(priceDecreaseRef.current);
            // console.log(priceIncreaseRef.current);
            setHourlyRate(hourlyRef.current.value)
            setIncreaseArray(priceIncreaseRef.current)
            setDecreaseArray(priceDecreaseRef.current)
            setBulkArray(bulkRef.current)
            // setHalfDayRate(halfDayRef.current.value)
            // setDayRate(fullDayRef.current.value)
            // setWeekRate(weeklyRef.current.value)
            // setMonthRate(monthlyRef.current.value)
            // setHourlySelected(hourlyCheckedRef.current.checked);
            // setHalfDaySelected(halfDayCheckedRef.current.checked);
            // setDaySelected(fullDayCheckedRef.current.checked);
            // setWeekSelected(weeklyCheckedRef.current.checked);
            // setMonthSelected(monthlyCheckedRef.current.checked);
            setCurrentStep('step-seven');
            setStepComponent(<StepSeven facNameRef={facilityNameRef} defaultName={listingName} />);
        }
        if (currentStep === 'step-seven') {
            focusStepSevenRef();
            console.log(houseRuleSelected)
            if (validateStepSeven() === false) {
                return
            }
            setListingName(facilityNameRef.current.value)
            setCurrentStep('step-eight');
            setStepComponent(<StepEight bpStore={bookingPolicy} bookingPolicyRef={bookingPolicyRef} defaultSelected={policyRef.current} defaultFiles={rulesStoreRef.current} selectedRef={policyRef} filesRef={houseRulesRef}/>);
        }

        if (currentStep === 'step-eight') {
            if (validateStepEight() === false) {
                return
            }
            saveData();

        }

    }

    // Previous step pressed
    function previousStep() {
        setErrorMessage('');

        if (currentStep === 'step-two') {
            setCurrentStep('step-one');
            setStepComponent(<StepOne fwd={false} locationTextRef={locationTextRef} locationTextValue={locationTextValue} locationHashRef={locationHashRef}  setCapacity={setMaxCapacity} setSpace={setVenueSpace} setType={setVenueType} venueTypeValue={venueType} venueSpaceValue={venueSpace} maxCapacityValue={maxCapacity} locationValue={location} locationRef={locationRef} venueTypeRef={venueTypeRef} venueSpaceRef={venueSpaceRef} maxCapacityRef={maxCapacityRef} />);
            console.log(locationTextValue)
        }
        if (currentStep === 'step-three') {
            focusStepThreeRef();
            // if (aboutRef.current.value.length < 40) {
            //     return setErrorMessage('Minimum 40 characters')
            // }
            setDescription(aboutRef.current.value);
            setCurrentStep('step-two');
            setStepComponent(<StepTwo fwd={false} facilityArray={facilities} amenityArray={amenities} onFacilitySelect={handleFacilityChange} onAmenitySelect={handleAmenitiesChange} />);
        }
        if (currentStep === 'step-four') {

            setCurrentStep('step-three');
            setStepComponent(<StepThree fwd={false} columns={75} descRef={aboutRef} descriptionValue={description} />);
        }
        if (currentStep === 'step-five') {
            focusStepFiveRef();
            // if (validateStepFive() === false) {
            //     return
            // }
            setAddressOne(addressOneRef.current.value);
            setAddressTwo(addressTwoRef.current.value);
            // setCity(cityRef.current.value);
            // setPostalCode(postalRef.current.value);
            // setCountry(countryRef.current.value);
            setVenueInfo(facInfoRef.current.value);
            // setSuburb(suburbRef.current.value);
            setCurrentStep('step-four');
            setStepComponent(<StepFour fwd={false} disableButtons={setButtonsDisabled} setError={setErrorMessage} />);
        }
        if (currentStep === 'step-six') {
            // focusStepSixRef();
            if (validateStepSix() === false) {
                return
            }
            // State
            console.log(priceDecreaseRef.current.value);
            console.log(priceIncreaseRef.current.value);
            setHourlyRate(hourlyRef.current.value)
            setIncreaseArray(priceIncreaseRef.current)
            setDecreaseArray(priceDecreaseRef.current)
            setBulkArray(bulkRef.current)
            setCurrentStep('step-five');
            setStepComponent(<StepFive fwd={false}
                suburbRef={suburbRef} setProvince={setProvince} suburbValue={suburb} setCity={setCity} setCountry={setCountry} setSuburb={setSuburb}
                addressOneValue={addressOne} addressTwoValue={addressTwo} cityValue={city} postalValue={postalCode} countryValue={country} facInfoValue={venueInfo}
                addressOneRef={addressOneRef} addressTwoRef={addressTwoRef} cityRef={cityRef} postalRef={postalRef} facInfoRef={facInfoRef} countryRef={countryRef} />);        }
        if (currentStep === 'step-seven') {
            setCurrentStep('step-six');
            setStepComponent(<StepSix fwd={false}
                bulkDefaults={bulkArray} decreaseDefaults={decreaseArray} increaseDefaults={increaseArray}
                bulkRef={bulkRef} priceDecreaseRef={priceDecreaseRef} priceIncreaseRef={priceIncreaseRef}
                hourlyValue={hourlyRate} halfDayValue={halfDayRate} dayValue={dayRate} weeklyValue={weekRate} monthlyValue={monthRate}
                hourlyCheckedValue={hourlySelected} halfDayCheckedValue={halfDaySelected} dayCheckedValue={daySelected} weeklyCheckedValue={weekSelected} monthlyCheckedValue={monthSelected}
                hourlyChecked={hourlyCheckedRef} hourlyRate={hourlyRef} halfDayChecked={halfDayCheckedRef} halfDayRate={halfDayRef} fullDayChecked={fullDayCheckedRef} fullDayRate={fullDayRef} weeklyChecked={weeklyCheckedRef} weeklyRate={weeklyRef} monthlyChecked={monthlyCheckedRef} monthlyRate={monthlyRef} />);
        }
        if (currentStep === 'step-eight') {
            console.log(houseRulesRef.current)
            setPolicySelected(policyRef.current);
            setBookingPolicy(bookingPolicy.current);
            setHouseRuleSelected(houseRulesRef.current);
            setCurrentStep('step-seven')
            setStepComponent(<StepSeven defaultName={listingName} facNameRef={facilityNameRef} />);
        }
    }

    return (
        <div className="listings">
            {finished && <ListingCompleteModal closeModal={() => setFinished(false)} /> }
            { !newListing ?
            <>
                <div onClick={startNewListing} className="new-listing">
                <AddBoxIcon className="add-icon"/>
                <h1>Create new listing</h1>
                </div>
                <h1 id="current-listings-header">Your listings</h1>
                <div className="listings-container">
                    {listings}
                    {/* <Listing title="Yoga Shtudio" description="ELLO WOTS ALL DIS DEN In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available."/>
                    <Listing title="Yoga Shtudio" description="ELLO WOTS ALL DIS DEN In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available."/>
                    <Listing title="Yoga Shtudio" description="ELLO WOTS ALL DIS DEN In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available."/> */}
                </div>
            </> : <section>
            {stepComponent}
            {errorMessage !== '' && <div className="listing-error-row">
                <p>{errorMessage}</p>
            </div>}
            <div className={currentStep === 'step-seven' ? "special-button-row" : currentStep === 'step-one' ? 'start-button-row' : "step-button-row"}>
                            {currentStep !== 'step-one' && <button disabled={buttonsDisabled} className="back-button" type="button" onClick={previousStep} >Back</button>}
                            <button disabled={buttonsDisabled} className="next-button" type="button" onClick={nextStep} >{currentStep === 'step-eight' ? 'COMPLETE' : 'Next'}</button>

            </div>
                </section>
}
        </div>
    )
}

export default Listings
