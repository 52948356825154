import React, { useState, useRef } from 'react'
import './ScheduleClassModal.css'

import { db } from '../../../firebase'

function ScheduleClassModal({ classDetails, closeModal, uid }) {

    const dateRef = useRef();
    const timeRef = useRef();

    const [error, setError] = useState('')

    console.log(classDetails)


    function validateData() {
        // Date
        if (dateRef.current.value === '') {
            setError('Please select a date')
            return false
        }
        // Time
        if (timeRef.current.value === '') {
            setError('Please select a date')
            return false
        }
    }

    async function saveSession() {

        if (validateData() === false) {
            return
        }

        const date = new Date(dateRef.current.value);
        const startHours = parseInt(timeRef.current.value.slice(0,2));
        const startMinutes = parseInt(timeRef.current.value.slice(3,5));
        date.setHours(startHours);
        date.setMinutes(startMinutes)

        // Check calendar for exsting bookings
        const calendarDocs = await db.collection('users').doc(uid).collection('calendar')
            .where('date', '==', date)
            .get();
        if (calendarDocs.docs.length > 0) {
            const calendarDoc = calendarDocs.docs[0];
            const calData = calendarDoc.data();
            const sessions = calData.Sessions;
            if (sessions[startHours] !== 'Available' || sessions[startHours] !== null || sessions[startHours] !== undefined) {
                return setError('You already have a session scheduled at this time')
            }
        }


        try {
            await db.collection('sessions').add({
                athleteID: [],
                classID: classDetails.classID,
                cost: classDetails.cost,
                dateCreated: new Date(),
                dateTimeStamp: date,
                duration: 60,
                instructorID: uid,
                location: classDetails.locationSuburb,
                isGroupSession: true,
                isOnlineSession: classDetails.locationString === 'online',
                limit: classDetails.limit,
                onlineURL: classDetails.onlineURL,
                paidWithCredits: [],
                position: classDetails.position,
                sessionSpeciality: classDetails.subCategories,
                sessionType: classDetails.sessionType,
                status: 'booked',
            });
            return closeModal()

        } catch(err) {
            setError('Could not schedule your session, please try again')
        }


    }


    return (
        <div className='schedule-class-modal' onClick={(e) => {if (e.target.className === 'schedule-class-modal') {closeModal()}}}>
            <div className="schedule-class-modal-content scale-in-center">
                <h2>Schedule Group Session</h2>
                <h4>{classDetails.className}</h4>
                <div className="date-time-container">
                    <div className="date-time-group">
                        <p>Date:</p>
                        <input ref={dateRef} type='date' />
                    </div>
                    <div className="date-time-group">
                        <p>Time:</p>
                        <input ref={timeRef}  type='time' />
                    </div>
                </div>
                {error !== '' && <div className='error-message-row'>{error}</div>}
                <div className="button-row schedule-button-row">
                    <button onClick={saveSession} className="confirm-button">Confirm</button>
                </div>
            </div>
        </div>
    )
}

export default ScheduleClassModal
