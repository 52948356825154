import React, { useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext'

import './SignUpForm.css'

// Material UI
import { Checkbox } from '@material-ui/core';

import { admin } from '../../firebase';

// Components
import SignUpConfirmationModal from './SignUpConfirmationModal';
import CircularProgress from '@mui/material/CircularProgress';

import logo from '../../assets/images/logowhite.png'

function SignUpForm({ switchAction }) {

    // Ref
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const instructorRef = useRef();
    const ownerRef = useRef();

    // Sign Up Function
    const { returnUser } = useAuth();
    const createUser = admin.functions().httpsCallable('newInstructorSignUp');


    // States
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentChecked, setCurrentChecked] = useState('')
    const checkedRef = useRef();
    checkedRef.current = currentChecked;

    // History
    const navigate = useNavigate();

    // Sign Up Pressed
    async function handleClick() {

        // return console.log(currentChecked)

        if (validateInput() === false) {
            return
        }

        try {
            setError('Creating your account...');
            setLoading(true);
            const newUser = await returnUser(emailRef.current.value, passwordRef.current.value)
            setError('Setting up your documents...')
            if (currentChecked === 'instructor') {
                await createUser({ uid: newUser.user.uid, email: newUser.user.email})
            }
            setShowModal(true);
        } catch(err) {
            console.log(err.code)
            // Error message handling
            if (err.code === 'auth/weak-password') {
                setError('Please ensure your password has at least 6 characters')
            } else if (err.code === 'auth/email-already-in-use') {
                setError('This email account is already in use')
            }
            else {
            setError('Failed to create an account, please try again')
            }
        }

        setLoading(false);
    }

    function handleSelection(evt) {
        setError('');
        const id = evt.target.id;
        // if (id === 'instructor') {
        //     console.log(ownerRef.current.checked)
        //     ownerRef.current.checked = false;
        // }
        // if (id === 'owner') {
        //     instructorRef.current.checked = false;
        // }
        setCurrentChecked(id)
        console.log(currentChecked)
    }

    // Validation
    function validateInput() {
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        const confirmPassword = passwordConfirmRef.current.value;
        const ownerChecked = ownerRef.current.checked;
        const instructorChecked = instructorRef.current.checked;

        // Email empty
        if (email === '') {
            setError('Please enter an email address');
            return false;
        }
        // Email format
        if (validateEmail(email) !== true) {
            setError('Please make sure your email is a valid format');
            return false;
        }

        // If less than 8 characters
        if (password.length < 8) {
            setError('Please ensure your password has at least 8 characters');
            return false;
        }

        // Password Matches
        if (password !== confirmPassword) {
            setError('Please make sure both passwords match');
            return false;
        }

        // Role selected
        if (!ownerChecked && !instructorChecked) {
            setError('Please ensure to select a role')
            return false
        }

        return true

    }

    // Validate
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }

    return (
        <div className="signup-form">
                        {showModal && <SignUpConfirmationModal proceed={() => {
                            if (currentChecked === 'owner') {
                                navigate("/profile-creation")
                            } else if (currentChecked === 'instructor') {
                                navigate("/instructor-creation")
                            }
                            }} />}
            {!showModal && <div className='signup-form'>
            <img
            alt="logo"
            src={logo}
            />
                        <div className="role-selection-row">
                <div className="role-selection-item">
                            <Checkbox id='owner' checked={currentChecked === 'owner'} onChange={(evt) => handleSelection(evt)} inputRef={ownerRef} color="default"/>
                            <label for="weight-section">Facility</label>
                </div>
                <div className="role-selection-item">
                            <Checkbox id='instructor' checked={currentChecked === 'instructor'} onChange={(evt) => handleSelection(evt)} inputRef={instructorRef} color="default"/>
                            <label for="weight-section">Specialist</label>
                </div>
            </div>
            <div className="login-input-container">
                <div className="input-group">
                    <p>Email</p>
                    <input required type="email" autoComplete="on" ref={emailRef} />
                </div>
                <div className="input-group">
                    <p>Password <span className="password-hint">(minimum 8 characters)</span></p>
                    <input className="half" type="password" autoComplete="off" ref={passwordRef} />
                </div>
                <div className="input-group">
                    <p>Confirm Password</p>
                    <input className="half" type="password" autoComplete="off" ref={passwordConfirmRef} />
                </div>
            </div>

            {loading && <div className="signup-spinner-container">
                <CircularProgress color='warning' />
            </div>}
            <div className="signup-error-container">
                {error !== '' && <p className="fade-in">{error}</p>}
            </div>
            <div className="signup-button-row">
                <button className='valencia-button' disabled={loading} onClick={handleClick}>Create Account</button>
            </div>
            <div className="register-action-container">
                <p>Already have an account?</p>
                <span onClick={loading ? () => {} : switchAction}>Login</span>
            </div>
            </div>}
        </div>

    )
}

export default SignUpForm
