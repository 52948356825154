import React, { useState, useRef, useEffect } from 'react'
import './Steps.css'
import {useDropzone} from 'react-dropzone';


// Material UI
import { Checkbox } from '@material-ui/core'
import CloudUpload from '@mui/icons-material/CloudUpload';
import InfoIcon from '@mui/icons-material/Info';
import Switch from 'react-ios-switch';


function StepEight({ edit = false, selectedRef, filesRef, defaultSelected = '', defaultFiles = [], bookingPolicyRef, bpStore = 'accept' }) {
    const [selectedBox, setSelectedBox] = useState(defaultSelected)
    const [selectedBookingPolicy, setSelectedBookingPolicy] = useState(bpStore);
    const valRef = useRef();
    valRef.current = selectedBookingPolicy
    bookingPolicyRef.current = selectedBookingPolicy;

    useEffect(() => {
        // console.log(bpStore)
        setSelectedBookingPolicy(bpStore)
    }, [bpStore])

    useEffect(() => {
        // console.log(defaultSelected)
        setSelectedBox(defaultSelected)
    }, [defaultSelected])

    const selectRef = useRef();
    selectRef.current = selectedBox;
    selectedRef.current = selectedBox;
    const [files, setFiles] = useState(defaultFiles);
    const fileRef = useRef();
    fileRef.current = files;
    filesRef.current = files;
    const {getRootProps, getInputProps} = useDropzone({
        // accept: 'image/*',
        onDrop: acceptedFiles => {
            const newFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
              }));
        //   setFiles(old => [...old, newFiles]);
        for (let i = 0; i < fileRef.current.length; i++) {
            newFiles.push(fileRef.current[i])
        }

        setFiles(newFiles)
        }
      });

    return (
        <div className='step-eight slide-in-right'>
            {!edit && <h1>Step 8</h1>}
            <h2>Bookings Policy</h2>
            <div className="bookings-policy">
                <p>Accept all bookings automatically</p>
                <div className="tooltip">
            <InfoIcon className='info-icon' />
                            <span className='bubble tooltiptext'>By activating Instant Bookings, you allow specialists to book your venue without approval. If you prefer to review all bookings before approving them, please deactivate Instant Bookings</span>
            </div>
            <Switch className='switch' checked={selectedBookingPolicy === 'accept'} onChange={(e) => {
                if (e) {
                    setSelectedBookingPolicy('accept')
                } else {
                    setSelectedBookingPolicy('approve')
                }
            }} />
                {/* <div className="selected-client-menu">
                        <button onClick={() => setSelectedBookingPolicy('accept')} className={selectedBookingPolicy === 'accept' ? 'active-client-menu-button left-button' : 'left-button'}>Accept</button>
                        <button onClick={() => setSelectedBookingPolicy('approve')} className={selectedBookingPolicy === 'approve' ? 'active-client-menu-button right-button' : 'right-button'}>Approve</button>
                    </div> */}
            </div>
            <h2>Cancellations Policy</h2>
            <p>Please select your preferred cancellation policy</p>
            <div className="policy-choices-container">
                <div className="policy-group">
                    <div className="pg-top-row">
                    <Checkbox checked={selectRef.current === 'flexible'} onClick={(e) => {
                        if (selectRef.current === 'flexible') {
                            setSelectedBox('')
                        } else {
                            setSelectedBox ('flexible')
                        }

                    }} />
                    <h3>Flexible</h3>
                    </div>
                    <div className="pg-bottom-row">
                        <p>Full refund is issued for cancellations up to 24 hours in advance</p>
                    </div>
                </div>
                <div className="policy-group">
                    <div className="pg-top-row">
                    <Checkbox checked={selectRef.current === 'moderate'} onClick={(e) => {
                        if (selectRef.current === 'moderate') {
                            setSelectedBox('')
                        } else {
                            setSelectedBox ('moderate')
                        }

                    }} />
                    <h3>Moderate</h3>
                    </div>
                    <div className="pg-bottom-row">
                        <p>Full refund is issued for cancellations up to 5 days prior</p>
                    </div>
                </div>
                <div className="policy-group">
                    <div className="pg-top-row">
                    <Checkbox checked={selectRef.current === 'strict'} onClick={(e) => {
                        if (selectRef.current === 'strict') {
                            setSelectedBox('')
                        } else {
                            setSelectedBox ('strict')
                        }

                    }} />
                    <h3>Strict</h3>
                    </div>
                    <div className="pg-bottom-row">
                        <p>Full refund is issued for cancellations made within 48 hours of booking, if the booking date is at least 14 days away. 50% refund is issued for cancellations made at least 7 days in advance. No refunds issued for cancellation made within 7 days of the booking date.</p>
                    </div>
                </div>
            </div>
            <div className="house-rules-container">
                <h2>House Rules</h2>
                <p>Do you have any house rules for your facility?</p>

                <div className="hr-upload-container">
                <section className="container">
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <div className='dz-upload-container'>
                                    <CloudUpload />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                            </div>
                            {/* <aside style={thumbsContainer}>
                                {thumbs}
                            </aside> */}
                        </section>
                        {fileRef.current.map(file => file.name)}
                </div>

            </div>
        </div>
    )
}

export default StepEight
