import React from 'react'
import './BlackHeader.css'

import logo from '../../assets/images/logowhite.png'

function BlackHeader() {
    return (
        <div className="black-header">
            <img
            className="header-img"
            alt="forte-logo"
            src={logo} />
            {/* src="https://firebasestorage.googleapis.com/v0/b/forte-v2.appspot.com/o/images%2Flogowhite.png?alt=media&token=db8abc1e-b79e-4d51-bcdc-734fe52fdd48" /> */}
        </div>
    )
}

export default BlackHeader
