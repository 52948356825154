import React, { useState, useRef, useEffect } from 'react'
import './EditListing.css'

import { useLocation, useNavigate } from 'react-router'

import { db } from '../../firebase'

// Components
import StepOne from './Steps/StepOne'
import StepTwo from './Steps/StepTwo'
import StepThree from './Steps/StepThree'
import StepFour from './Steps/StepFour'
import StepFive from './Steps/StepFive'
import StepSix from './Steps/StepSix'
import StepSeven from './Steps/StepSeven'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingOverlay from 'react-loading-overlay'
import StepEight from './Steps/StepEight'

function EditListing() {
    const location = useLocation();
    const navigate = useNavigate();

    // useEffect(() => {
        // console.log(location.state)
        if (location.state === undefined || location.state === null) {
            console.log('pushing')
            navigate('/listings');
        }
    // }, [])



    const facilityID = location.state.facilityID ?? '';

    // State
    const [loading, setLoading] = useState(false);
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [facilityName, setFacilityName] = useState();
    const [facilityArray, setFacilityArray] = useState([]);
    const [amenityArray, setAmenityArray] = useState([]);
    const [venueType, setVenueType] = useState();
    const [venueSpace, setVenueSpace] = useState();
    const [maxCapacity, setMaxCapacity] = useState();
    const [venueLocation, setVenueLocation] = useState();
    const [venueDescription, setVenueDescription] = useState();
    const [imageArray, setImageArray] = useState();
    const [bookingPolicy, setBookingPolicy] = useState('');
    const [cancellationPolicy, setCancellationPolicty] = useState()
    const [locationText, setLocationText] = useState()
    const [errorMessage, setErrorMessage] = useState('')

    const polStoreRef = useRef();
    polStoreRef.current = bookingPolicy;

    const canStoreRef = useRef();
    canStoreRef.current = cancellationPolicy;


        // Prices
    const [increaseArray, setIncreaseArray] = useState([])
    const priceIncreaseRef = useRef();
    const [decreaseArray, setDecreaseArray] = useState([])
    const priceDecreaseRef = useRef();
    const [bulkArray, setBulkArray] = useState([])
    const bulkRef = useRef();

    const [policySelected, setPolicySelected] = useState('');
    const [houseRuleSelected, setHouseRuleSelected] = useState();
    const rulesStoreRef = useRef();
    rulesStoreRef.current = houseRuleSelected;

    const [hourly, setHourlyRate] = useState(0)
    const hourlyStoreRef = useRef();
    hourlyStoreRef.current = hourly;


    const policyRef = useRef();
    const bookingPolicyRef = useRef();
    const cancellationPolicyRef = useRef();
    const houseRulesRef = useRef();

    const [stepTwoModal, setStepTwoModal] = useState();
    const [stepFourModal, setStepFourModal] = useState();
    const [stepFiveModal, setStepFiveModal] = useState();
    const [stepSixModal, setStepSixModal] = useState();

    const [city, setCity] = useState();
    const [suburb, setSuburb] = useState();
    const [postalCode, setPostalCode] = useState();
    const [province, setProvince] = useState();
    const [country, setCountry] = useState();

    // Refs
    const venueTypeRef = useRef();
    const venueSpaceRef = useRef();
    const maxCapacityRef = useRef();
    const locationRef = useRef();
    const locationHashRef = useRef();
    const locationTextRef = useRef();
    const venueDescriptionRef = useRef();

    const hourlyRef = useRef();
    const halfDayRef = useRef();
    const fullDayRef = useRef();
    const weeklyRef = useRef();
    const monthlyRef = useRef();

    const hourlyCheckedRef = useRef();
    const halfDayCheckedRef = useRef();
    const fullDayCheckedRef = useRef();
    const weeklyCheckedRef = useRef();
    const monthlyCheckedRef = useRef();

    const addressOneRef = useRef();
    const addressTwoRef = useRef();
    const cityRef = useRef();
    const suburbRef = useRef();
    const postalRef = useRef();
    const countryRef = useRef();
    const facilityInfoRef = useRef();

    const facilityNameRef = useRef();

    const deleteRef = useRef();

    // Step Two
    const facRef = useRef();
    facRef.current = facilityArray;
    const amenRef = useRef();
    amenRef.current = amenityArray;
    // const

    // Focus all refs
    function focusRefs() {
        venueTypeRef.current.focus();
        venueSpaceRef.current.focus();
        maxCapacityRef.current.focus();
        locationRef.current.focus();
        venueDescriptionRef.current.focus();
        hourlyRef.current.focus();
        halfDayRef.current.focus();
        fullDayRef.current.focus();
        weeklyRef.current.focus();
        monthlyRef.current.focus();
        hourlyCheckedRef.current.focus();
        halfDayCheckedRef.current.focus();
        fullDayCheckedRef.current.focus();
        weeklyCheckedRef.current.focus();
        monthlyCheckedRef.current.focus();
        addressOneRef.current.focus();
        addressTwoRef.current.focus();
        cityRef.current.focus();
        postalRef.current.focus();
        countryRef.current.focus();
        facilityInfoRef.current.focus();
        facilityNameRef.current.focus();
        }

    // Step Two changes
    function handleFacilityChange(event) {
        // If target was checked, add to array
        if (event.target.checked) {
            setFacilityArray(facilities => [...facilities, event.target.id]);
        } else {
            // If target was unchecked, delete from array
            let newFacilities = [...facRef.current];
            const index = newFacilities.indexOf(event.target.id);
            newFacilities.splice(index, 1);
            setFacilityArray(newFacilities);
        }
        // console.log(newFacilities);
    }

    function handleAmenitiesChange(event) {
        // If target was checked, add to array
        if (event.target.checked) {
            setAmenityArray(amenities => [...amenities, event.target.id])
        } else {
            let newAmenities = [...amenRef.current];
            // If target was unchecked, delete from array
            const index = newAmenities.indexOf(event.target.id);
            newAmenities.splice(index, 1);
            setFacilityArray(newAmenities);
        }
    }


    useEffect(() => {
        loadFacilityDetails();
        window.scrollTo(0, 0)
    }, [])

    async function loadFacilityDetails() {
        setLoading(true)
        const facilityDoc = await db.collection('facilities').doc(facilityID).get();
        const doc = facilityDoc.data();
        // console.log(doc);
        facilityNameRef.current.defaultVale = doc.listingName;
        policyRef.current = doc.bookingPolicy
        setCancellationPolicty(doc.cancellationPolicy)
        setBookingPolicy(doc.bookingPolicy)
        setFacilityName(doc.listingName);
        setFacilityArray(doc.venueFacilities);
        setAmenityArray(doc.venueAmenities);
        setVenueType(doc.venueType);
        setVenueSpace(doc.venueSpace);
        setMaxCapacity(doc.maxCapacity);
        // console.log(doc.locationText.label)
        setVenueLocation(doc.locationPoint);
        setVenueDescription(doc.description)
        setCountry(doc.country);
        setProvince(doc.province)
        setCity(doc.city);
        setSuburb(doc.suburb);

        setBulkArray(doc.bulkDiscounts)
        setLocationText(doc.locationText ?? '');
        setStepTwoModal(<StepTwo edit facilityArray={facRef.current} amenityArray={amenRef.current} onAmenitySelect={handleAmenitiesChange} onFacilitySelect={handleFacilityChange}  />)
        const { hourlyRate, halfDayRate, dayRate, weekRate, monthRate } = doc;
        setHourlyRate(hourlyRate)

        const incs = doc.priceIncreases ?? [];
        for (let i = 0; i < incs.length; i++) {
            if (incs[i].type === 'monthly') {
                continue;
            }
            const start = incs[i].startTime;
            const end = incs[i].endTime;
            if (start < 10) {
                incs[i].startTime = `0${start}:00`;
            } else {
                incs[i].startTime = `${start}:00`;
            }
            if (end < 10) {
                incs[i].endTime = `0${end}:00`;
            } else {
                incs[i].endTime = `${end}:00`;
            }
        }
        // console.log(incs)
        const decs = doc.priceDecreases ?? [];
        for (let i = 0; i < decs.length; i++) {
            if (decs[i].type === 'monthly') {
                continue;
            }
            const start = decs[i].startTime;
            const end = decs[i].endTime;
            if (start < 10) {
                decs[i].startTime = `0${start}:00`;
            } else {
                decs[i].startTime = `${start}:00`;
            }
            if (end < 10) {
                decs[i].endTime = `0${end}:00`;
            } else {
                decs[i].endTime = `${end}:00`;
            }
        }
        setIncreaseArray(incs)
        setDecreaseArray(decs)
        // setStepSixModal(<StepSix edit hourlyRate={hourlyRef} halfDayRate={halfDayRef} fullDayRate={fullDayRef} weeklyRate={weeklyRef} monthlyRate={monthlyRef}
        //                     hourlyCheckedValue={hourlyRate !== '0'} halfDayCheckedValue={halfDayRate !== '0'} dayCheckedValue={dayRate !== '0'} monthlyCheckedValue={monthRate !== '0'} weeklyCheckedValue={weekRate !== '0'}
        //                     hourlyChecked={hourlyCheckedRef} halfDayChecked={halfDayCheckedRef} fullDayChecked={fullDayCheckedRef} weeklyChecked={weeklyCheckedRef} monthlyChecked={monthlyCheckedRef}
        //                     hourlyValue={hourlyRate} halfDayValue={halfDayRate} dayValue={dayRate} weeklyValue={weekRate} monthlyValue={monthRate}
        //     />)
        setStepSixModal(<StepSix bulkDefaults={doc.bulkDiscounts} increaseDefaults={doc.priceIncreases} decreaseDefaults={doc.priceDecreases} edit={true} hourlyRate={hourlyRef} hourlyValue={hourlyRate} bulkRef={bulkRef} priceDecreaseRef={priceDecreaseRef} priceIncreaseRef={priceIncreaseRef} />);
        setStepFiveModal(<StepFive edit suburbValue={doc.suburb} provinceValue={doc.province} setCity={setCity} setCountry={setCountry} setProvince={setProvince} setSuburb={setSuburb} addressOneRef={addressOneRef} addressTwoRef={addressTwoRef} suburbRef={suburbRef} cityRef={cityRef} postalRef={postalRef} countryRef={countryRef} facInfoRef={facilityInfoRef}
                            addressOneValue={doc.addressOne} addressTwoValue={doc.addressTwo} cityValue={doc.city} postalValue={doc.postalCode} countryValue={doc.country} facInfoValue={doc.venueInfo}
        />)
        setStepFourModal(<StepFour edit disableButtons={setButtonsDisabled} imageArray={doc.imageLinks} />)
        // const hourlyRate = {doc};
        setLoading(false);


    }



    // Validate input
    function validateInput() {

        // Listing name
        if (facilityNameRef.current.value === '') {
            setErrorMessage('Please enter a name for your facility.')
            return false;
        }

        return true
    }

    // Save Changes to Firebase
    async function saveListingChanges() {

        const validated = validateInput();

        const addressOne = addressOneRef.current.value;
        const addressTwo = addressTwoRef.current.value;
        // const city = cityRef.current.value;
        // const country = countryRef.current.value;
        // const province = p
        const venueInfo = facilityInfoRef.current.value;

        const incs = priceIncreaseRef.current;
        for (let i = 0; i < incs.length; i++) {
            if (incs[i].type === 'monthly') {
                continue;
            }
            const start = incs[i].startTime;
            console.log(start)
            const startSlice = start.slice(0,2)
            console.log(startSlice)
            const end = incs[i].endTime;
            const endSlice = end.slice(0,2);
            incs[i].startTime = parseInt(startSlice);
            incs[i].endTime = parseInt(endSlice);
        }
        const decs = priceDecreaseRef.current;
        for (let i = 0; i < decs.length; i++) {
            if (decs[i].type === 'monthly') {
                continue;
            }
            const start = decs[i].startTime;
            console.log(start)
            const startSlice = start.slice(0,2)
            console.log(startSlice)
            const end = decs[i].endTime;
            const endSlice = end.slice(0,2);
            decs[i].startTime = parseInt(startSlice);
            decs[i].endTime = parseInt(endSlice);
        }

        // return console.log(bulkArray, bookingPolicyRef.current, policyRef.current)

        if (validated === false) {
            return
        }

        setLoading(true);
        setButtonsDisabled(true)


        const hourlyRate = hourlyRef.current.value;
        const halfDayRate = halfDayRef.current.value;
        const dayRate = fullDayRef.current.value;
        const weekRate = weeklyRef.current.value;
        const monthRate = monthlyRef.current.value;

        const listingName = facilityNameRef.current.value;;

        try {
            await db.collection('facilities').doc(facilityID).update({
                venueType,
                venueSpace,
                maxCapacity,
                // location,
                venueFacilities: facilityArray,
                venueAmenities: amenityArray,
                description: venueDescription,
                addressOne,
                addressTwo,
                city,
                country,
                suburb,
                province,
                venueInfo,
                hourlyRate: parseInt(hourlyRate),
                listingName,
                priceIncreases: incs,
                priceDecreases: decs,
                bulkDiscounts: bulkArray,
                facilityRules: [],
                bookingPolicy: bookingPolicyRef.current,
                cancellationPolicy: policyRef.current,
            });
            navigate(-1)
        } catch (err) {
            setErrorMessage('Could not update your profile, please try again.')
        }
    }

    async function deleteListing() {
        try {
            await db.collection('facilities').doc(facilityID).delete()
            navigate('/listings')

        } catch (err) {
            setErrorMessage('Could not delete your listing, please try again')
        }
    }

    return (
        <LoadingOverlay
        active={loading}
        spinner
        >
        <div className='edit-listing'>
            <div className="back-row">
                <ArrowBackIcon />
                <p onClick={() => navigate(-1)}>Back</p>
            </div>
            <h1>Edit Listing: {facilityName}</h1>
            <div className="listing-name-group-edit">
                <label for="listing-name-input-edit">Give your listing a name...</label>
                <input ref={facilityNameRef} defaultValue={facilityName} type="text" id="listing-name-input-edit" />
            </div>
            <div className="edit-listing-container">

                <div className="edit-listing-left">
                <div className="mt-40"></div>



                          {!loading && <StepOne locationTextValue={locationText} locationHashRef={locationHashRef} locationTextRef={locationTextRef} edit venueTypeValue={venueType} venueTypeRef={venueTypeRef} venueSpaceValue={venueSpace} venueSpaceRef={venueSpaceRef}
                            maxCapacityValue={maxCapacity} maxCapacityRef={maxCapacityRef} locationValue={venueLocation} locationRef={locationRef} />}
                    {/* <div className="mt-40"></div> */}
                    {/* <StepTwo edit facilityArray={facilityArray} amenityArray={amenityArray} /> */}
                    {stepTwoModal}

                    </div>
                <div className="edit-listing-right">
                    <div className="mt-40"></div>
                    <StepThree edit columns={40} descRef={venueDescriptionRef} descriptionValue={venueDescription} />
                    {/* <div className="mt-40"></div> */}
                    {/* <StepSix edit={true} hourlyRate={hourlyRef} hourlyValue={hourlyStoreRef.current} bulkRef={bulkRef} priceDecreaseRef={priceDecreaseRef} priceIncreaseRef={priceIncreaseRef} />                    <StepSeven edit /> */}
                </div>

            </div>
            <div className="mt-40"></div>
            {stepSixModal}

            <StepEight edit={true} bpStore={polStoreRef.current} bookingPolicyRef={bookingPolicyRef} defaultSelected={canStoreRef.current} defaultFiles={rulesStoreRef.current} selectedRef={policyRef} filesRef={houseRulesRef}/>

            <div className="mt-40"></div>

            {stepFiveModal}
            <div className="mt-40"></div>
            {stepFourModal}
            <div className="mt-40"></div>
            <div className="edit-listing-delete-function">
                <h2>Delete Listing</h2>
                <p>Type DELETE to confirm</p>
                <div className="edit-listing-delete-row">
                    <input onKeyUp={(evt) => {
                        if (evt.target.value === 'DELETE') {
                            setDeleteButtonDisabled(false)
                        } else {
                            setDeleteButtonDisabled(true)
                        }
                    }} type='text' placeholder='DELETE' />
                    <button onClick={deleteListing} className='black-button' disabled={deleteButtonDisabled}>CONFIRM</button>
                </div>
            </div>

            {errorMessage !== '' && <div className="error-message-row">{errorMessage}</div>
}
            <div className="edit-listing-button-row">
            <button disabled={buttonsDisabled} onClick={() => navigate(-1)} className="back-button" type="button" >Back</button>
            <button disabled={buttonsDisabled} onClick={saveListingChanges} className="next-button" type="button" >Save</button>

            </div>

        </div>
        </LoadingOverlay>
    )
}

export default EditListing
