import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import './Listing.css'
import ImageCarousel from './ImageCarousel';


function Listing({title, images, description, facilityID}) {

    const navigate = useNavigate();
    function showEdit() {
        // navigate('/edit-listing', {params: facilityID});
        navigate(
            '/edit-listing',
            {
                state: {facilityID},
        })
    }

    function showView() {
        navigate(
            '/view-listing',
            {
                state: {facilityID},
        })
    }

    return (
        <div className="listing scale-in-hor-center">

            <div className="listing-title">
                <h1>{title}</h1>
            </div>
            <div className="listing-details">
                <div className="listing-images">
                {/* <p>Images</p> */}
                <ImageCarousel showThumb={false}  imageArray={images} />
                </div>
                <div className="listing-description">
                    <h2 className="description-header">Description:</h2>
                    <div className="description-container">
                        <div className="description-text">
                        <p>{description}</p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="listing-button-row">
                <button onClick={showEdit} className="edit-button saffron-button list-button">Edit</button>
                <button onClick={showView} className="view-button picton-button list-button">View</button>
            </div>
        </div>
    )
}

export default Listing
