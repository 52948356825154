import React, { useState, useEffect } from 'react'
import './ProfileSummary.css'

// Firebase
import { useAuth } from '../../contexts/AuthContext'
import { db } from '../../firebase'

function ProfileSummary() {

    const { currentUser, userRole } = useAuth();

    // State
    const [currentYear, setCurrentYear] = useState('');
    const [currentMonth, setcurrentMonth] = useState('');
    const [yearIncome, setYearIncome] = useState('');
    const [monthIncome, setMonthIncome] = useState('');

    const [rating, setRating] = useState('');
    const [totalReviews, setTotalReviews] = useState('');
    const [fiveStars, setFiveStars] = useState('');

    const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

    useEffect(() => {
        if (currentUser && userRole) {
            if (userRole === 'instructor') {
                return loadInstructorStats()
            } else {
                loadUserStats(currentUser.uid);
            }
        }
    }, [currentUser, userRole])

    async function loadUserStats(uid) {
        // Dates
        const now = new Date();
        setCurrentYear(now.getFullYear());
        setcurrentMonth(monthNames[now.getMonth()]);

        // User Stats
        const userDoc = await db.collection('users').doc(uid).get();
        const user = userDoc.data();
        const facilities = user.facilityIDs ?? [];

        // Set up variables
        let currentYearIncome = 0;
        let currentMonthIncome = 0;
        let ratingTotal = 0;
        let totalReviews = 0;
        let totalFiveStars = 0;
        let facsReviewed = 0;

        // Cycle through owner facilities
        for (var i = 0; i < facilities.length; i++) {
            const facilityDoc = await db.collection('facilities').doc(facilities[i]).get();
            const facility = facilityDoc.data();
            currentYearIncome += facility.currentYearIncome;
            currentMonthIncome += facility.currentMonthIncome;
            ratingTotal += facility.reviewAverage;
            totalReviews += facility.totalReviews;
            totalFiveStars += facility.fiveStarReviews ?? 0;
            if (facility.reviewAverage !== 0) {
                facsReviewed += 1;
            }

        }

        let totalReviewAverage = (Math.round((ratingTotal / facsReviewed) * 100)) / 100;
        if (isNaN(totalReviewAverage)) {
            totalReviewAverage = 'N/A'
        }
        setYearIncome(currentYearIncome);
        setMonthIncome(currentMonthIncome);
        setRating(totalReviewAverage);
        setTotalReviews(totalReviews);
        setFiveStars(totalFiveStars);

    }

    async function loadInstructorStats() {
        // Dates
        const now = new Date();
        setCurrentYear(now.getFullYear());
        setcurrentMonth(monthNames[now.getMonth()]);

        const firstOfYear = new Date();
        firstOfYear.setMonth(0);
        firstOfYear.setDate(1);
        firstOfYear.setMinutes(0)
        const firstOfMonth = new Date();
        firstOfMonth.setDate(1);
        firstOfMonth.setMinutes(0)

        let month = 0;
        let year = 0;

        // Month Income
        const monthSessions = await db.collection('sessions').where('dateTimeStamp', '>=', firstOfMonth)
            .where('instructorID', '==', currentUser.uid)
            .where('status', '==', 'completed')
            .get();

        for (let i = 0; i < monthSessions.docs.length; i++) {
            const session = monthSessions.docs[i].data();
            if (session.isGroupSession) {
                month += (session.cost * session.athleteID.length);
            } else {
                month += session.cost;

            }
        }

        // Year Income
        const yearSessions = await db.collection('sessions').where('dateTimeStamp', '>=', firstOfYear)
        .where('instructorID', '==', currentUser.uid)
        .where('status', '==', 'completed')
        .get();
        console.log(yearSessions.docs.length)

        for (let i = 0; i < yearSessions.docs.length; i++) {
            const session = yearSessions.docs[i].data();
            if (session.isGroupSession) {
                year += (session.cost * session.athleteID.length);
            } else {
                year += session.cost;

            }
        }



        setYearIncome(year)
        setMonthIncome(month)

        // Ratings
        const userDoc = await db.collection('users').doc(currentUser.uid).get();
        const user = userDoc.data();
        const { reviewAverage, totalReviews } = user;
        console.log(user)
        setTotalReviews(totalReviews)
        setRating(reviewAverage)

        let totalFives = 0;

        const reviews = await db.collection('reviews').doc('reviewCollections').collection('public')
            .where('instructorID', '==', currentUser.uid).get();
        for (let i = 0; i < reviews.docs.length; i++) {
            const review = reviews.docs[i].data();
            const { rating } = review;

            if (rating === 5) {
                totalFives += 1;
            }
        }
        console.log(totalFives)
        setFiveStars(totalFives)



    }

    return (
<div className="profile-summary scale-in-ver-center">
                    <h3>Profile Summary</h3>
                    <div className="bookings-hr-container">
                        <hr />
                    </div>
                    <div className="bookings-summary-row">
                        <p>{currentYear} Earnings</p>
                        <p>R {yearIncome}</p>
                    </div>
                    <div className="bookings-summary-row">
                        <p>{currentMonth} Earnings</p>
                        <p>R {monthIncome}</p>
                    </div>
                    {/* <div className="bookings-summary-row">
                        <p>30 Day Views</p>
                        <p>32</p>
                    </div> */}
                    <div className="bookings-hr-container">
                    <hr />

                    </div>
                    <div className="bookings-summary-row">
                        <p>Overall Rating</p>
                        <p>{rating}</p>
                    </div>
                    <div className="bookings-summary-row">
                        <p>Total Reviews</p>
                        <p>{totalReviews}</p>
                    </div>
                    <div className="bookings-summary-row">
                        <p>5-star Reviews</p>
                        <p>{fiveStars}</p>
                    </div>
                </div>
    )
}

export default ProfileSummary
