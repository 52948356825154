import React from 'react'
import './ReviewItem.css'

function ReviewItem({ imageLink, textContent, reviewID, instructorName, showReportModal, showReplyModal, dateString, rating }) {
    return (
        <div className='review-item'>
            <div className="review-content">
                <img
                alt='review-profile'
                src={imageLink} />
                <div className="review-text">
                    <h2>{instructorName}</h2>
                    <p>{textContent}</p>
                    <div className="date-container">
                        <p>{dateString}</p>
                    </div>
                </div>
            </div>
            <div className="review-button-row">
                <button onClick={() => showReportModal(reviewID)} className='review-report-button'>Report</button>
                <button onClick={() => showReplyModal(reviewID)} className='review-reply-button'>Reply</button>
            </div>
        </div>
    )
}

export default ReviewItem
