import React, { useState } from 'react'
import './ClassItem.css'

// MUI
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import CreditCardIcon from '@mui/icons-material/CreditCard';

// Components
import AddClassModal from './AddClassModal';
import ScheduleClassModal from './ScheduleClassModal'
import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons';

function ClassItem({ classDetails, uid }) {

    const [showEdit, setShowEdit] = useState(false)
    const [showSchedule, setShowSchedule] = useState(false)


    return (
        <div className='class-item'>
            {showEdit && <AddClassModal edit={true} classDetails={classDetails} uid={uid} closeModal={() => setShowEdit(false)} />}
            {showSchedule && <ScheduleClassModal classDetails={classDetails} closeModal={() => setShowSchedule(false)} />}
            <div className="ci-container">
            <div className="class-item-left">
                <img alt={classDetails.className} src={classDetails.imageLinks} />
            </div>
            <div className="class-item-right">
            <h2>{classDetails.className}</h2>
                <div className="class-detail-item">
                    <LocationOnIcon />
                    <p>{classDetails.locationName}</p>
                </div>
                <div className="class-detail-item">
                    <FitnessCenterIcon />
                    <p>{classDetails.difficulty}</p>
                </div>
                <div className="class-detail-item">
                    <AccessibilityNewIcon />
                    <p>{classDetails.sessionType}</p>
                </div>
                <div className="class-detail-item">
                    <CreditCardIcon />
                    <p>R {classDetails.cost}</p>
                </div>
            </div>

            </div>
            <div className="button-row class-button-row">
                <button onClick={() => setShowEdit(true)} className="cancel-button">Edit</button>
                <button onClick={() => setShowSchedule(true)} className="confirm-button">Add Session</button>
            </div>

        </div>
    )
}

export default ClassItem
