import React, {useEffect, useState} from 'react'
import Checkbox from '@material-ui/core/Checkbox';

import './Steps.css'

function StepTwo({ fwd = true, edit, onFacilitySelect, onAmenitySelect, facilityArray, amenityArray, }) {


    useEffect(() => {
        // console.log(facilityArray)
        // console.log(facilityArray);
    }, [])

    return (
        <div className={fwd ? 'step-two slide-in-right' : "step-two slide-in-left"}>
            {!edit && <h1>Step 2</h1>}
            <div className="options-container">
                <div className="option-left">
                    <p>What exercise facilities does your venue offer?</p>
                    <div className="box-container">
                        <div className="check-group">
                        <Checkbox defaultChecked={facilityArray.includes('weight-section')} onChange={(evt) => {onFacilitySelect(evt);}} id="weight-section" color="default"/>
                         <label for="weight-section">Weight Section</label>
                        </div>
                        <div className="check-group">
                        <Checkbox defaultChecked={facilityArray.includes('function-floor')} onChange={(evt) => {onFacilitySelect(evt);}} id="function-floor" color="default"/>
                            <label for="function-floor">Functional Floor</label>
                        </div>
                        <div className="check-group">
                        <Checkbox defaultChecked={facilityArray.includes('cardio-area')} onChange={(evt) => {onFacilitySelect(evt);}} id="cardio-area" color="default"/>
                            <label for="cardio-area">Cardio Area</label>
                        </div>
                        <div className="check-group">
                        <Checkbox defaultChecked={facilityArray.includes('yoga-studio')} onChange={(evt) => {onFacilitySelect(evt);}} id="yoga-studio" color="default"/>
                            <label for="yoga-studio">Yoga Studio</label>
                        </div>
                        <div className="check-group">
                        <Checkbox defaultChecked={facilityArray.includes('dance-studio')} onChange={(evt) => {onFacilitySelect(evt);}} id="dance-studio" color="default"/>
                            <label for="dance-studio">Dance Studio</label>
                        </div>
                        <div className="check-group">
                        <Checkbox defaultChecked={facilityArray.includes('massage-room')} onChange={(evt) => {onFacilitySelect(evt);}} id="massage-room" color="default"/>
                        <label for="massage-room">Massage Therapy Rooms</label>
                        </div>
                        <div className="check-group">
                        <Checkbox defaultChecked={facilityArray.includes('parking')} onChange={(evt) => {onFacilitySelect(evt);}} id="parking" color="default"/>
                            <label for="parking">Parking</label>
                        </div>
                    </div>
                </div>
                <div className="option-right">
                    <p>What amenities does your facility offer?</p>
                    <div className="box-container">
                        <div className="check-group">
                        <Checkbox defaultChecked={amenityArray.includes('both-bathrooms')} onChange={(evt) => onAmenitySelect(evt)} id="both-bathrooms" color="default"/>
                            <label for="both-bathrooms">Male and Female Bathrooms</label>
                        </div>
                        <div className="check-group">
                        <Checkbox defaultChecked={amenityArray.includes('uni-bathrooms')} onChange={(evt) => onAmenitySelect(evt)} id="uni-bathrooms" color="default"/>
                            <label for="uni-bathrooms">Unisex Bathroom</label>
                        </div>
                        <div className="check-group">
                        <Checkbox defaultChecked={amenityArray.includes('showers')} onChange={(evt) => onAmenitySelect(evt)} id="showers" color="default"/>
                            <label for="showers">Showers</label>
                        </div>
                        <div className="check-group">
                        <Checkbox defaultChecked={amenityArray.includes('sauna')} onChange={(evt) => onAmenitySelect(evt)} id="sauna" color="default"/>
                            <label for="sauna">Sauna</label>
                        </div>
                        <div className="check-group">
                        <Checkbox defaultChecked={amenityArray.includes('steam-room')} onChange={(evt) => onAmenitySelect(evt)} id="steam-room" color="default"/>
                            <label for="steam-room">Steam Room</label>
                        </div>
                        <div className="check-group">
                        <Checkbox defaultChecked={amenityArray.includes('food-cafe')} onChange={(evt) => onAmenitySelect(evt)} id="food-cafe" color="default"/>
                            <label for="food-cafe">Food Cafe</label>
                        </div>
                        <div className="check-group">
                        <Checkbox defaultChecked={amenityArray.includes('smoothie-bar')} onChange={(evt) => onAmenitySelect(evt)} id="smoothie-bar" color="default"/>
                            <label for="smoothie-bar">Smoothie Bar</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default StepTwo
