import React, { useEffect, useState } from 'react'
import './InstructorAnnualStatement.css'

import { db } from '../../../firebase'

import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';

function InstructorAnnualStatement({ uid, setLoading }) {
    const now = new Date();

    const [yearOptions, setYearOptions] = useState([]);
    const [year, setYear] = useState(now.getFullYear())
    const [months, setMonths] = useState([])


    const [totalIncome, setTotalIncome] = useState([])
    const [sessions, setSessions] = useState([])
    const [sessionIncome, setSessionIncome] = useState([])
    const [classes, setClasses] = useState([]);
    const [classIncome, setClassIncome] = useState([])

    const [totalClients, setTotalClients] = useState([])

    const [totalExpenses, setTotalExpenses] = useState([])
    const [forteFee, setForteFee] = useState([]);
    const [bookings, setBookings] = useState([])
    const [paid, setPaid] = useState([])
    const [facs, setFacs] = useState([])

    const [profit, setProfit] = useState([]);

    const [yearClients, setYearClients] = useState(0)
    const [yearFacs, setYearFacs] = useState(0)

    useEffect(() => {
        loadUserYears()
        loadCalendar()
        loadFinancials(now.getFullYear())
    }, [])

    function loadCalendar() {
        const now = new Date();
        const calArray = [];

        for (let i = 0; i < 12; i++) {
            now.setMonth(i);
            calArray.push(now.toLocaleDateString('en-us', { month: 'short'}))
        }

        setMonths(calArray)
    }

    async function loadUserYears() {
        const instructorDoc = await db.collection('users').doc(uid).get();
        const instructor = instructorDoc.data();
        const dateJoined = new Date(instructor.dateJoinedStamp.toDate());
        const diff = now.getFullYear() - dateJoined.getFullYear();
        const currentYear = now.getFullYear();

        const options = [
            {
                name: currentYear.toString(),
                value: currentYear,
            },
        ];

        for (let i = 1; i < diff + 1; i++) {
            options.push({
                name: (currentYear - i).toString(),
                value: (currentYear - i),
            })
        }

        setYearOptions(options)
    }

    async function loadFinancials(year) {
        const now = new Date();
        setLoading(true)
        let startDate, endDate;

        if (now.getYear === year) {
            startDate = new Date();
            startDate.setMonth(0);
            startDate.setDate(1)
            startDate.setHours(0);

            endDate = new Date();
        } else {
            startDate = new Date();
            startDate.setFullYear(year)
            startDate.setMonth(0);
            startDate.setDate(1)
            startDate.setHours(0);

            endDate = new Date();
            endDate.setFullYear(year + 1)
            endDate.setMonth(11);
            endDate.setDate(30)
            endDate.setHours(23);
        }

        const sessionDocs = await db.collection('sessions')
            .where('instructorID', '==', uid)
            .where('dateTimeStamp', '>=', startDate)
            .where('dateTimeStamp', '<=', endDate)
            .where('status', '==', 'completed')
            .get().catch(err => console.log(err));

        const bookingDocs = await db.collection('bookings')
            .where('instructorID', '==', uid)
            .where('start', '>=', startDate)
            .where('start', '<=', endDate)
            .get().catch(err => console.log(err));

        const totalIncArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const totalExpArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const profitArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const sessionArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const sessionIncArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const classArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const classIncArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const clientArr = [[], [], [], [], [], [], [], [], [], [], [], [], ];
        const allClients = [];
        const feeArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const bookingArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const paidArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const facBooked = [[], [], [], [], [], [], [], [], [], [], [], [], ];
        const allFacs = [];

        for (let i = 0; i < sessionDocs.docs.length; i++) {
            const session = sessionDocs.docs[i].data();
            const { athleteID, isGroupSession, cost } = session;
            const month = new Date(session.dateTimeStamp.toDate()).getMonth();



            if (isGroupSession) {
                const total = athleteID.length * cost;

                classArr[month] += 1;
                classIncArr[month] += total;
                totalIncArr[month] += total;

                for (let j = 0; j < athleteID.length; j++) {
                    if (!clientArr[month].includes(athleteID[j])) {
                        clientArr[month].push(athleteID[j])
                    }
                    if (!allClients.includes(athleteID[j])) {
                        allClients.push(athleteID[j])
                    }
                }
            } else {
                sessionArr[month] += 1;
                sessionIncArr[month] += cost;
                totalIncArr[month] += cost;

                if (!clientArr[month].includes(athleteID)) {
                    clientArr[month].push(athleteID)
                }
                if (!allClients.includes(athleteID)) {
                    allClients.push(athleteID)
                }
            }
        }

        for (let i = 0; i < bookingDocs.docs.length; i++) {
            const booking = bookingDocs.docs[i].data();
            const { facilityID, total } = booking;

            const month = new Date(booking.start.toDate()).getMonth();

            bookingArr[month] += 1;
            paidArr[month] += total;
            totalExpArr[month] += total;

            if (!facBooked[month].includes(facilityID)) {
                facBooked[month].push(facilityID);
            }
            if (!allFacs.includes(facilityID)) {
                allFacs.push(facilityID)
            }

        }

        for (let i = 0; i < profitArr.length; i++) {
            profitArr[i] = totalIncArr[i] - totalExpArr[i];
        }

        totalIncArr.push(
            totalIncArr.reduce((a, b) => a + b)
        )
        sessionArr.push(
            sessionArr.reduce((a, b) => a + b)
        )
        sessionIncArr.push(
            sessionIncArr.reduce((a, b) => a + b)
        )
        classArr.push(
            classArr.reduce((a, b) => a + b)
        )
        classIncArr.push(
            classIncArr.reduce((a, b) => a + b)
        )
        bookingArr.push(
            bookingArr.reduce((a, b) => a + b)
        )
        feeArr.push(
            feeArr.reduce((a, b) => a + b)
        )
        profitArr.push(
            profitArr.reduce((a, b) => a + b)
        )
        totalExpArr.push(
            totalExpArr.reduce((a, b) => a + b)
        )
        paidArr.push(
            paidArr.reduce((a, b) => a + b)
        )



        // Populate
        setTotalIncome(totalIncArr)
        setSessions(sessionArr)
        setSessionIncome(sessionIncArr)
        setClasses(classArr)
        setClassIncome(classIncArr)
        setTotalClients(clientArr)
        setBookings(bookingArr)
        setPaid(paidArr)
        setFacs(facBooked)
        setForteFee(feeArr)
        setTotalExpenses(totalExpArr)
        setProfit(profitArr)

        setYearClients(allClients.length)
        setYearFacs(allFacs.length)

        // console.log(totalIncArr)
        setLoading(false)
    }

    return (
        <div className='instructor-annual-statement'>
            <div className="ias-header-row">
                <h2>Year Overview</h2>
                <SelectSearch
                  closeOnSelect={true}
                  search={false}
                  placeholder={year.toString()}
                  options={yearOptions}
                  onChange={(value) => {
                    setYear(value);
                    loadFinancials(value)
                  }}
                  />
            </div>
            <div className="ias-table-container">
                <table>
                  <thead>
                      <tr className='month-row'>
                          <th className='start-item'></th>
                          {months.map((month) => <th>{month}</th>)}
                          <th>TOTAL</th>
                      </tr>
                  </thead>
                  <tbody>
                  <tr className='grey-row'>
                          <td className="main-td start-item">
                              INCOME
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>                      </tr>
                      <tr>
                          <td className="title-td start-item">
                              Total Income
                          </td>
                          {totalIncome.map(val => <td>{val}</td>)}
                      </tr>
                      <tr>
                          <td className="title-td start-item">
                              Total Clients
                          </td>
                          {totalClients.map(val => <td>{val.length}</td>)}
                          <td>{yearClients}</td>
                      </tr>
                      <tr className='grey-row'>
                          <td className="title-td start-item">
                                1-ON-1 SESSIONS
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td className="sub-td start-item">
                              Total Income
                          </td>
                          {sessionIncome.map(val => <td>{val}</td>)}
                      </tr>
                      <tr>
                          <td className="sub-td start-item">
                              Total Sessions
                          </td>
                          {sessions.map(val => <td>{val}</td>)}
                      </tr>
                      <tr className='grey-row'>
                          <td className="title-td start-item">
                                GROUP SESSIONS
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td className="sub-td start-item">
                              Total Income
                          </td>
                          {classIncome.map(val => <td>{val}</td>)}
                      </tr>
                      <tr>
                          <td className="sub-td start-item">
                              Total Sessions
                          </td>
                          {classes.map(val => <td>{val}</td>)}
                      </tr>
                      <tr className='grey-row'>
                          <td className="main-td start-item">
                              EXPENSES
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td className="sub-td start-item">Total Expenses</td>
                          {totalExpenses.map(val => <td>{val}</td>)}

                      </tr>
                      <tr>
                          <td className="sub-td start-item">Forte Fee</td>
                          {forteFee.map(val => <td>{val}</td>)}

                      </tr>
                      <tr className='grey-row'>
                          <td className="title-td start-item">
                               FACILITY BOOKINGS
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td className="sub-td start-item">
                              Total Paid
                          </td>
                          {paid.map(val => <td>{val}</td>)}
                      </tr>
                      <tr>
                          <td className="sub-td start-item">
                              Total Bookings
                          </td>
                          {bookings.map(val => <td>{val}</td>)}
                      </tr>
                      <tr>
                          <td className="sub-td start-item">
                              Facilities Booked
                          </td>
                          {facs.map(val => <td>{val.length}</td>)}
                          <td>{yearFacs}</td>
                      </tr>
                      <tr className='grey-row'>
                          <td className="main-td start-item">
                            PROFIT
                          </td>
                          {profit.map(val => <td>{val}</td>)}
                      </tr>
                  </tbody>
                </table>
            </div>
        </div>
    )
}

export default InstructorAnnualStatement
