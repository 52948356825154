import React, { useState, useEffect } from 'react'
import './NotificationDrop.css'

import NotificationItem from './NotificationItem'

import { db } from '../../firebase';

import { useAuth } from '../../contexts/AuthContext';
import PayBookingModal from '../../Pages/Bookings/PayBookingModal/PayBookingModal';

function NotificationDrop({ show, hideDrop, loadPayModal }) {

    const { currentUser, userRole } = useAuth();

    const [notifications, setNotifications] = useState([]);
    const [showPayModal, setShowPayModal] = useState(false)
    const [payModal, setPayModal] = useState(false)

    useEffect(() => {

        if (currentUser && userRole) {
            loadUserNotifications(currentUser.uid);
        }

        return () => {
            setNotificationsRead()
        }

    }, [currentUser, userRole])


    async function setNotificationsRead() {
        if (currentUser === null || currentUser === undefined) {
            return
        }
        db.collection('users').doc(currentUser.uid).collection('notifications').onSnapshot((notifications) => {
    const current = notifications.docs.length;
    notifications.docChanges().forEach((change) => {
        if (change.type === 'added') {
            const notification = change.doc.data();
            change.doc.ref.update({
                read: true,
            });
        }
    });
});
}



    // Load all notifications
    async function loadUserNotifications(uid) {

        if (userRole === 'owner') {
            db.collection('users').doc(uid).collection('notifications').orderBy('dateCreated', 'desc').limit(8).onSnapshot(async (snapshot) => {
                const notificationArray = [];
                snapshot.forEach((notification) => {
                    const doc = notification.data();
                    const dateObj = new Date(doc.dateCreated.toDate());
                    const dateString = dateObj.toDateString();
                    let facilityID = '';
                    if (doc.type === 'review') {
                        facilityID = doc.facilityID;
                    }
                    const notMap = {
                        type: doc.type,
                        description: doc.message,
                        title: doc.title,
                        read: doc.read,
                        dateCreated: dateString,
                        facilityID,
                        documentID: notification.id,
                    }
                    notificationArray.push(notMap);
                });
                setNotifications(notificationArray);
            });
        }

        if (userRole === 'instructor') {
            db.collection('users').doc(uid).collection('notifications').orderBy('sentAt', 'desc').limit(8).onSnapshot(async (snapshot) => {
                const notificationArray = [];
                snapshot.forEach((notification) => {
                    const doc = notification.data();
                    // console.log(doc)
                    const dateObj = new Date(doc.sentAt.toDate());
                    const dateString = dateObj.toDateString();
                    const title = doc.title;
                    const textContent = doc.textContent ?? doc.message;
                    let facilityID = '';
                    if (doc.type === 'review') {
                        facilityID = doc.facilityID;
                    }
                    let type = notification.type ?? '';
                    if (type === '') {
                        if (title.toLowerCase() === 'new chat message') {
                            type = 'chat';
                        }
                        if (title.toLowerCase().includes('booking')) {
                            type = 'booking';
                        }
                        if (textContent.toLowerCase().includes('group')) {
                            type ='group'
                        }
                        if (title.toLowerCase().includes('refund')) {
                            type = 'refund'
                        }
                        if (textContent.toLowerCase().includes('session')) {
                            type = 'session';
                        }
                        if (doc.type === 'booking-payment') {
                            type = doc.type;
                        }
                    }
                    // console.log(doc)
                    const notMap = {
                        type: type,
                        description: doc.message ?? doc.textContent,
                        title: doc.title,
                        read: doc.read,
                        dateCreated: dateString,
                        facilityID,
                        documentID: doc.documentID,
                    }
                    notificationArray.push(notMap);
                });
                setNotifications(notificationArray);
            });
        }



    }

    async function markAllAsRead() {
        if (currentUser === undefined) {
            return
        }

        // if (userRole === 'instructor') {
        const nots = await db.collection('users').doc(currentUser.uid).collection('notifications').where('read', '==', false).get();
        nots.forEach(not => {
            not.ref.update({
                read: true,
            })
        })

    }

    return (
        <div className={show ? 'notification-drop  scale-in-ver-top' : 'notification-drop  scale-out-ver-top'}>
                        {showPayModal && payModal}
            <div onClick={markAllAsRead} className="mark-read"><p>Mark all as read</p></div>
            {currentUser && notifications.map((notification, index) => {
                return <NotificationItem loadPayModal={loadPayModal} userRole={userRole} uid={currentUser.uid} docID={notification.documentID} facilityID={notification.facilityID} dateCreated={notification.dateCreated} hideDrop={hideDrop} read={notification.read} type={notification.type} description={notification.description} title={notification.title} />
            })}
            {/* <NotificationItem hideDrop={hideDrop} type='booking' description='Johnny Johnson - Wednesday 13th October 2021' title='New Booking' /> */}
            {/* <NotificationItem hideDrop={hideDrop} type='review' description='Peter Parker left you a review' title='New Review' /> */}
        </div>
    )
}

export default NotificationDrop
