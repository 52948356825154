import React from 'react'
import './CreditBox.css'

function CreditBox({ name, outstanding, imageLink, facilityID, show, instructorID = '' }) {
  return (
    <div className='credit-box'>
            <div className="ct-top">
                <img alt={name} src={imageLink} />
            </div>

            <div className="ct-bottom">
                <p>{name}</p>
            </div>

            <div className="cb-text">
                <p>Current Balance: R {outstanding}</p>
            </div>

            <div className="credits-button-row button-row">
                <button onClick={show} className='picton-button'>View</button>
            </div>
    </div>
  )
}

export default CreditBox