import React, { useState, useEffect } from 'react'
import './BookingItem.css'

import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SpaIcon from '@mui/icons-material/Spa';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import HealingIcon from '@mui/icons-material/Healing';

function BookingItem({ start = new Date(), end = new Date(), type, rate, total, venue, userRole, sessionType, location, isOffline = false, }) {
    var dateOptions = { year: 'numeric', month: 'long', day: 'numeric'};
    var timeOptions = { hour: '2-digit', minute: '2-digit'}

    const [chosenIcon, setChosenIcon] = useState()

    console.log(start)


    useEffect(() => {


        switch (sessionType) {
            case 'Personal Training': setChosenIcon(<FitnessCenterIcon />)
                break;
            case 'Wellness': setChosenIcon(<SpaIcon />)
                break;
            case 'Yoga': setChosenIcon(<SelfImprovementIcon />)
                break;
            case 'Recovery': setChosenIcon(<HealingIcon />)
                break;
            default: break;
        }
    }, [])

    return (
        <div className='booking-item'>
            {type === 'hourly' &&
            <div className="hourly-item">
            <div className="hourly-item-left">
                {chosenIcon}
            </div>
            <div className="hourly-item-right">
            <h1>{start.toLocaleDateString('en-us', dateOptions)}</h1>
                <p>{start.toLocaleTimeString('en-us', timeOptions)} - {end.toLocaleTimeString('en-us', timeOptions)}</p>
                {userRole === 'owner' && <p>{venue}</p>}
                {userRole === 'instructor' && <p>{location} · {sessionType} Session</p>}
                {isOffline && <p>Offline Session</p>}
                <p className='money-line'>R {total} (R {rate} / hour)</p>
                <p></p>
            </div>
            </div>

            }
                                <hr className='mt-20' />

        </div>
    )
}

export default BookingItem
