import React, { useState } from 'react'
import './PendingBookingItem.css'

import { useNavigate } from 'react-router';

import { db } from '../../firebase';

// Components
import BookingDetailModal from '../Calendar/BookingDetailModal';
import RequestedSessionModal from './RequestedSession/RequestedSessionModal'
import PayBookingModal from './PayBookingModal/PayBookingModal'

// MUI
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeIcon from '@mui/icons-material/Home';
import PaymentIcon from '@mui/icons-material/Payment';

function PendingBookingItem({ chatID, rate, reloadPage, paymentStatus, requestStatus = '', confirmOnly, imageLink, venue, name, type, startDate, endDate, totalPrice, viewBooking, facilityID, calendarID, bookingID, instructorID, start, end, role, availableID}) {
    const [showDecisionModal, setShowDecisionModal] = useState(false);
    const [currentModal, setCurrentModal] = useState();
    const [showPayModal, setShowPayModal] = useState(false)
    const navigate = useNavigate();

    const now = new Date()

    async function confirmBooking() {
        try {
            await db.collection('facilities').doc(facilityID).collection('calendarEvents').doc(bookingID).update({
                type:'booked'
            });
            // await db.collection('bookings').add({
            //     instructorID,
            //     start,
            //     end,
            //     facilityID,
            //     bookingID
            // });
        } catch(err) {

        }

    }

    function goToChat() {
        navigate(
            '/inbox',
            {
                state: {chatID},
        })
    }

    function handleCancel() {
        setCurrentModal(<BookingDetailModal availableID={availableID} total={totalPrice} reloadCalendar={() => {reloadPage()}} cancelOnly type={type} closeModal={() => setShowDecisionModal(false)} start={startDate} end={endDate} venueName={venue} title={name} instructorID={instructorID} calendarID={calendarID} bookingID={bookingID} startObj={start} endObj={end} facilityID={facilityID} />)
        setShowDecisionModal(true);
    }

    function handleConfirm() {

        setCurrentModal(<BookingDetailModal availableID={availableID} total={totalPrice} reloadCalendar={() => {reloadPage()}} confirmOnly type='pending' closeModal={() => setShowDecisionModal(false)} start={startDate} end={endDate} venueName={venue} title={name} instructorID={instructorID} calendarID={calendarID} bookingID={bookingID} startObj={start} endObj={end} facilityID={facilityID} />)
        setShowDecisionModal(true);

    }

    function handleRequestConfirm() {
        setCurrentModal(<RequestedSessionModal closeModal={() => setShowDecisionModal(false)} total={totalPrice} reloadCalendar={() => reloadPage()} sessionID={bookingID} venue={venue} athleteName={name} start={start} end={end} />)
        setShowDecisionModal(true);

    }

    function handleDeny() {
        setCurrentModal(<BookingDetailModal total={totalPrice} reloadCalendar={() => {reloadPage()}} cancelOnly type='pending' closeModal={() => setShowDecisionModal(false)} start={startDate} end={endDate} venueName={venue} title={name} instructorID={instructorID} calendarID={calendarID} bookingID={bookingID} startObj={start} endObj={end} facilityID={facilityID} />)
        setShowDecisionModal(true);
    }

    async function denyBooking() {
        try {
            await db.collection('facilities').doc(facilityID).collection('calendarEvents').doc(bookingID).update({
                type:'Denied'
            });
        } catch (err) {

        }
    }

    var dateOptions = { year: 'numeric', month: 'long', day: 'numeric'};
    var timeOptions = { hour: '2-digit', minute: '2-digit'}

    return (
        <div className="pending-booking-item">
            {showPayModal && <PayBookingModal reloadPage={reloadPage} instructorID={instructorID} facilityID={facilityID} closeModal={() => setShowPayModal(false)} start={start} end={end} facilityName={name} bookingID={bookingID} total={totalPrice} />}
            {showDecisionModal && currentModal}
            <div className="pb-left-col">
                <img alt="user-img" src={imageLink} />
            </div>
            <div className="pb-right-col">
            <div className="pb-text-container">
                    <div className="pb-text-right">
                    <h1>{start.toLocaleDateString('en-us', dateOptions)}</h1>
                    <div className="pb-info-group">
                        <AccessTimeIcon />
                        <p>{start.toLocaleTimeString('en-us', timeOptions)} - {end.toLocaleTimeString('en-us', timeOptions)}</p>
                    </div>

                    </div>
                    <div className="pb-text-left">
                        <div className="pb-info-group">
                            <PersonIcon />
                            <p className='booking-name'>{name}</p>
                        </div>
                        <div className="pb-info-group">
                            <HomeIcon />
                            <p>{venue}</p>
                        </div>
                        <div className="pb-info-group">
                            <PaymentIcon />
                            <p className='money-line'>R {totalPrice}</p>
                        </div>

                    </div>
                </div>
                <div className="pb-button-row">
                    {requestStatus !== 'requestedByAthlete' && <button onClick={viewBooking} className="pb-view-button">View</button>}
                    {role === 'instructor' && requestStatus === 'requestedByAthlete' && <button onClick={handleRequestConfirm} className="pb-confirm-button">Confirm</button>}
                    {requestStatus === 'requestedByAthlete' && <button onClick={goToChat} className="pb-view-button saffron-button">Chat</button>}
                    {role !== 'instructor' && <button onClick={handleConfirm} className="pb-confirm-button saffron-button">Confirm</button>}
                    {(role === 'instructor' && start > now && requestStatus !== 'requestedByAthlete') && <button onClick={handleCancel} className='pb-deny-button'>Cancel</button>}
                    {(role === 'instructor' && start > now && requestStatus === 'requestedByAthlete') && <button onClick={handleRequestConfirm} className='pb-deny-button'>Cancel</button>}
                    {paymentStatus === 'paymentPending' && <button onClick={() => setShowPayModal(true)} className='pb-confirm-button'>Pay</button>}
                    {role !== 'instructor' && <button onClick={handleDeny} className="pb-deny-button">Cancel</button>}
                </div>
            </div>
        </div>
    )
}

export default PendingBookingItem
