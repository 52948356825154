import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import './BookingsContainer.css'

import { db } from '../../../firebase'

import BookingItem from '../../Analytics/Clients/BookingItem'
import HomeBookingItem from './HomeBookingItem'

function BookingsContainer({ userID, userRole }) {

    const [bookings, setBookings] = useState([])

    const navigate = useNavigate();

    useEffect(() => {
        if (userID !== null && userID !== undefined) {
            if (userRole === 'owner') {
                loadUpcomingBookings(userID)
            } else {
                loadInstructorBookings(userID)
            }

        }
    }, [userID, userRole])

    async function loadUpcomingBookings(uid) {
        // console.log(uid)
        const userProfile = await db.collection('users').doc(uid).get();
        const facilityIDs = userProfile.data().facilityIDs ?? [];
        const bookingArray = [];
        const now = new Date();
        for (var i = 0; i < facilityIDs.length; i++) {
            const facID = facilityIDs[i];
            console.log(facID)
            const facilityDoc = await db.collection('facilities').doc(facID).get();
            const facility = facilityDoc.data();
            const facilityName = facility.listingName;
            const confirmedBookings = await db.collection('facilities').doc(facID).collection('calendarEvents')
            .where('type', '==', 'booked')
            .where('start', '>=', now)
            .get();
            // console.log(confirmedBookings.docs.length)
        for (var j = 0; j < confirmedBookings.docs.length; j++) {
            const bookingDoc = confirmedBookings.docs[j];
            const booking = bookingDoc.data();
            const calendarID = bookingDoc.id;
            const bookingID = booking.bookingID;
            const instructorID = booking.instructorID;
            const instructorProfile = await db.collection('users').doc(instructorID).get();
            const instructor = instructorProfile.data();
            const instructorImage = instructor.imageLink;
            const total = booking.total;
            const bookingMap = {
                rate: booking.rate,
                calendarID,
                instructorID,
                facilityID: facID,
                bookingID,
                start: new Date(booking.start.toDate()),
                end: new Date(booking.end.toDate()),
                startDate: (new Date(booking.start.toDate())).toLocaleString(),
                endDate: (new Date(booking.end.toDate())).toLocaleString(),
                name: booking.title,
                imageLink: instructorImage,
                venue: facilityName,
                total,
            }

            bookingArray.push(bookingMap);
                }
            // });


        }
        setBookings(bookingArray)
    }

    async function loadInstructorBookings(uid) {

        const now = new Date();

        const bookingArray = [];

        const userBookings = await db.collection('users').doc(uid).collection('calendarEvents')
            .where('type', '==', 'booked')
            .where('start', '>=', now)
            .orderBy('start', 'asc')
            .get()
            .catch(err => console.log(err));
        const bookings = userBookings.docs;
        for (let i = 0; i < bookings.length; i++) {
            const booking = bookings[i].data();
            const calendarID = bookings[i].id;
            const {facilityID, total, instructorID, bookingID, title, rate, start, end} = booking;
            bookingArray.push({
                calendarID,
                instructorID,
                facilityID,
                bookingID,
                start: new Date(start.toDate()),
                end: new Date(end.toDate()),
                startDate: (new Date(start.toDate())).toLocaleString(),
                endDate: (new Date(end.toDate())).toLocaleString(),
                venue: booking.title,
                total,
            })

        }

        setBookings(bookingArray)

    }

    return (
        <div className='bookings-container'>
            <h2>Upcoming Bookings</h2>
            <div className="booking-scroll">
              {bookings.map(booking => {
                // console.log(booking)
                return <HomeBookingItem
                end={booking.end}
                rate={booking.rate}
                start={booking.start}
                total={booking.total}
                venue={booking.venue}
                type={'hourly'}
                bookingID={booking.calendarID}
                facilityID={booking.facilityID}
                />
            })}
            {userRole === 'instructor' && <> {bookings.length === 0 && <h3 className='no-bokings-item' onClick={() => navigate('/search')} style={{textAlign:'center'}}>No upcoming bookings - click here to view available facilities</h3>} </>}
            {userRole === 'owner' && <> {bookings.length === 0 && <h3 className='no-bokings-item' onClick={() => navigate('/calendar')} style={{textAlign:'center'}}>No upcoming bookings - click here to open up time on your calendar</h3>} </>}
            </div>

        </div>
    )
}

export default BookingsContainer
