import React from 'react'
import './BookingConfirmation.css'

import { db } from '../../firebase'

function BookingConfirmation({name, facilityID, start, end, instructorID, bookingID, bookingPeriod, startTime, endTime, about, info, revenue, forteFee, netRevenue, imageLink, goBack, role}) {

    async function confirmBooking() {
        try {
            await db.collection('facilities').doc(facilityID).collection('calendarEvents').doc(bookingID).update({
                type:'booked'
            });
            // await db.collection('bookings').add({
            //     instructorID,
            //     start,
            //     end,
            //     facilityID,
            //     bookingID
            // });
        } catch(err) {

        }
    }

    async function denyBooking() {
        try {
            await db.collection('facilities').doc(facilityID).collection('calendarEvents').doc(bookingID).update({
                type:'Denied'
            });
        } catch (err) {

        }
    }

    return (
        <div className='booking-confirmation'>
            <h3>Pending Booking Information</h3>
            <div className="booking-details-div scale-in-ver-center">
            <div className="bc-left-col">
                <img alt="user-img" src={imageLink} />
            </div>
            <div className="bc-right-col">
                <p className='mb-20'><span className='bold-text'>Name:</span> {name}</p>
                <p><span className='bold-text'>Booking Period:</span> {bookingPeriod}</p>
                <p><span className='bold-text'>Booking Start Time:</span> {startTime}</p>
                <p className='mb-20'><span className='bold-text'>Booking End Time:</span> {endTime}</p>
                <div className="about-instructor-div mb-20">
                    <p className='bold-text'>About {name}</p>
                    <p>{about}</p>
                </div>
                <div className="additional-info-div mb-20">
                    <p className='bold-text'>Additional Information:</p>
                    <p>{info}</p>
                </div>
                <div className="revenue-div mb-20">
                    <div className="revenue-left">
                        {role === 'owner' && <><p>Booking Revenue</p>
                        <p>(-) Forte Fees</p>
                        <p>Net Revenue</p></>}
                        {role === 'instructor' && <p>Booking Cost</p>}
                    </div>
                    <div className="revenue-right">
                        <p>R{revenue}</p>
                        {role === 'owner' && <><p className='forte-fee'>(R{forteFee})</p>
                        <p className='net-revenue'>R{netRevenue}</p></>}
                    </div>
                </div>

            </div>
            </div>
            <div className="bc-button-row">
                    {/* <div className="button-margin">

                    </div> */}
                <div className="button-left">
                <button onClick={goBack} className="bc-view-button">Back</button>

                </div>
                <div className="button-right">
                {role === 'instructor' && <button className="bc-deny-button">Cancel</button>}
                {role === 'owner' && <button className="bc-deny-button">Deny</button>}
                {role === 'owner' && <button className="bc-confirm-button">Confirm</button>}
                </div>

                </div>
        </div>
    )
}

export default BookingConfirmation
