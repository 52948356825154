import React, { useEffect, useState, useRef } from 'react'
import './InstructorBookingRequestModal.css'

// Components
import DateTimePicker from 'react-datetime-picker';
import LoadingOverlay from 'react-loading-overlay';
import { makeStyles } from '@material-ui/core/styles';

// MUI
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@material-ui/core/Tooltip';


import ReactDOM from 'react-dom'

import moment from 'moment';

import Switch from 'react-ios-switch';


// Firebase
import { db, admin } from '../../firebase';

const useStyles = makeStyles(() => ({
    tooltip: {
      fontSize: '14px',
    },
  }));



function InstructorBookingRequestModal({ availableID, minDate, maxDate, start, end, instructorID, facilityID, rateType = 'hourly', closeModal, facilityName, reloadCalendar = () => {}
    }) {

    useEffect(() => {
        loadInstructorCredits()
        loadFacilityDetails()
    }, [])

    const classes = useStyles();

    useEffect(() => {

        const script = document.createElement('script');

        script.src = "https://js.yoco.com/sdk/v1/yoco-sdk-web.js";
        script.async = true;

        document.body.appendChild(script);
        // alert('loaded script')
        // return () => {
        //   document.body.removeChild(script);
        // }
      }, []);

      const requestPayment = admin.functions().httpsCallable('requestYocoPayment');
      const testPayment = admin.functions().httpsCallable('yocoTestPayment');

    const [startingDate, setStartingDate] = useState(new Date())
    const [endingDate, setEndingDate] = useState(new Date());
    const [dateSet, setDateSet] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(true)
    const [startPayment, setStartPayment] = useState(false)
    const [creditPayment, setCreditPayment] = useState(false)
    const [paymentType, setPaymenType] = useState('card')

    const [facilityIncreases, setFacilityIncreases] = useState([])
    const [facilityDecreases, setFacilityDecreases] = useState([])
    const [facilityDiscounts, setFacilityDiscounts] = useState([])
    const [acceptBookings, setAcceptBookings] = useState(false)
    const [policy, setPolicy] = useState('')

    const [completed, setCompleted] = useState(false)

    const [enabledIncreases, setEnabledIncreases] = useState([])
    const [increaseHoursArray, setIncreaseHoursArray] = useState([])
    const [increaseAmountArray, setIncreaseAmountArray] = useState([])
    const [enabledDecreases, setEnabledDecreases] = useState([])
    const [decreaseHoursArray, setDecreaseHoursArray] = useState([])
    const [decreaseAmountArray, setDecreaseAmountArray] = useState([])
    const [grossTotal, setGrossTotal] = useState(0)

    const [paymentForm, setPaymentForm] = useState()

    const [credits, setCredits] = useState(0)

    const yocoRef = useRef();
    const formRef = useRef();
    const payButtonRef = useRef();

    const [storeInline, setStoreInline] = useState()

    const [errorMessage, setErrorMessage] = useState('');
    const [rate, setRate] = useState(0);
    const [total, setTotal] = useState(0)
    const totalRef = useRef();
    totalRef.current = total;
    const startRef = useRef();
    const endRef = useRef();
    startRef.current = startingDate;
    endRef.current = endingDate;

    // const minDate = new Date();
    // minDate.setDate(minDate.getDate() + 1);
    // minDate.setHours(0);
    // minDate.setMinutes(0);

    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };

    async function loadFacilityDetails() {
        setStartingDate(start);
        setEndingDate(end);
        // console.log(facilityID)
        const facilityDoc = await db.collection('facilities').doc(facilityID).get();
        const facility = facilityDoc.data();
        const { hourlyRate, bookingPolicy, cancellationPolicy} = facility;
        // console.log(bookingPolicy)
        const priceIncreases = facility.priceIncreases ?? []
        const priceDecreases = facility.priceDecreases ?? []
        const bulkDiscounts = facility.bulkDiscounts ?? []
        setAcceptBookings(bookingPolicy === 'accept')
        setFacilityIncreases(priceIncreases)
        setFacilityDecreases(priceDecreases)
        setFacilityDiscounts(bulkDiscounts)



        // console.log(priceIncreases)
        // console.log(priceDecreases)


        const hourRate = parseInt(hourlyRate);
        setRate(hourRate);

        const startHour = start.getHours();
        const endHour = end.getHours();

        const addedIncreases = [];
        const increaseHours = []
        const increases = []
        let priceIncrease = 0;

        const addedDecreases = [];
        const decreaseHours = [];
        const decreases = [];
        let priceDecrease = 0;

        const weekDay = start.toLocaleString('en-us', {  weekday: 'long' });
        const month = start.toLocaleDateString('en-us', { month: 'long' })
        var diff =(end.getTime() - start.getTime()) / 1000;
        diff /= 3600;
        let newTotal = diff * hourRate;
        setGrossTotal(newTotal)
        // Price Decreases
        for (let i = 0; i < priceIncreases.length; i++) {
            const increase = priceIncreases[i];
            // Daily increases
            if (increase.type === 'daily') {
                let dayFound = false;
                for (let j = 0; j < increase.days.length; j++) {
                    if (increase.days[j].name === weekDay) {
                        dayFound = true;
                    }
                }
                // If day found
                if (dayFound) {
                    // Increase ends during booking
                    if (startHour >= increase.startTime && endHour > increase.endTime) {
                        console.log('1')
                        const hours = increase.endTime - startHour;
                        const priceHike = (hours * hourRate) * (increase.amount / 100);
                        addedIncreases.push(increase);
                        increases.push(priceHike);
                        increaseHours.push(hours)
                        priceIncrease += priceHike;
                    }

                    // Increase starts during booking
                    if (startHour < increase.startTime && endHour > increase.startTime && endHour <= increase.endTime) {
                        console.log('2')
                        const hours = endHour - increase.startTime;
                        const priceHike = (hours * hourRate) * (increase.amount / 100);
                        addedIncreases.push(increase);
                        increaseHours.push(hours)
                        increases.push(priceHike)
                        priceIncrease += priceHike                        }

                    // Increase starts and ends during booking
                    if (startHour <= increase.startTime && endHour >= increase.endTime) {
                        console.log('3')
                        const hours = increase.endTime - increase.startTime;
                        const priceHike = (hours * hourRate) * (increase.amount / 100);
                        addedIncreases.push(increase);
                        increases.push(priceHike);
                        increaseHours.push(hours)
                        priceIncrease += priceHike;
                    }

                    // Booking is during window
                    if (startHour >= increase.startTime && endHour <= increase.endTime) {
                        console.log('4')
                        const priceHike = newTotal * (increase.amount / 100);
                        addedIncreases.push(increase);
                        increases.push(priceHike);
                        increaseHours.push(diff)
                        priceIncrease += priceHike;

                    }
                }
            }

            // Monthly increases
            if (increase.type === 'monthly') {
                let monthFound = false;
                for (let j = 0; j < increase.months.length; j++) {
                    if (increase.months[j] === month) {
                        monthFound = true;
                    }
                }

                if (monthFound) {
                    const priceHike = newTotal * (increase.amount / 100);
                    addedIncreases.push(increase);
                    increases.push(priceHike);
                    increaseHours.push(diff)
                    priceIncrease += priceHike;
                }
            }

        }

        // Price Decreases
        for (let i = 0; i < priceDecreases.length; i++) {
            const decrease = priceDecreases[i];
            // Daily increases
            if (decrease.type === 'daily') {
                let dayFound = false;
                for (let j = 0; j < decrease.days.length; j++) {
                    if (decrease.days[j].name === weekDay) {
                        dayFound = true;
                    }
                }
                // If day found
                if (dayFound) {
                    // Increase ends during booking
                    if (startHour >= decrease.startTime && endHour > decrease.endTime) {
                        console.log('1')
                        const hours = decrease.endTime - startHour;
                        const priceHike = (hours * hourRate) * (decrease.amount / 100);
                        addedDecreases.push(decrease);
                        decreases.push(priceHike);
                        decreaseHours.push(hours)
                        priceDecrease += priceHike;
                    }

                    // Increase starts during booking
                    if (startHour < decrease.startTime && endHour <= decrease.endTime) {
                        console.log('2')
                        const hours = endHour - decrease.startTime;
                        const priceHike = (hours * hourRate) * (decrease.amount / 100);
                        addedDecreases.push(decrease);
                        decreaseHours.push(hours)
                        decreases.push(priceHike)
                        priceDecrease += priceHike                        }

                    // Increase starts and ends during booking
                    if (startHour <= decrease.startTime && endHour >= decrease.endTime) {
                        console.log('3')
                        const hours = decrease.endTime - decrease.startTime;
                        const priceHike = (hours * hourRate) * (decrease.amount / 100);
                        addedDecreases.push(decrease);
                        decreases.push(priceHike);
                        decreaseHours.push(hours)
                        priceDecrease += priceHike;
                    }

                    // Booking is during window
                    if (startHour >= decrease.startTime && endHour <= decrease.endTime) {
                        console.log('4')
                        const priceHike = newTotal * (decrease.amount / 100);
                        addedDecreases.push(decrease);
                        decreases.push(priceHike);
                        decreaseHours.push(diff)
                        priceDecrease += priceHike;

                    }
                }
            }

            // Monthly increases
            if (decrease.type === 'monthly') {
                let monthFound = false;
                for (let j = 0; j < decrease.months.length; j++) {
                    if (decrease.months[j].name === month) {
                        monthFound = true;
                    }
                }
                if (monthFound) {
                    const priceHike = newTotal * (decrease.amount / 100);
                    addedDecreases.push(decrease);
                    decreases.push(priceHike);
                    decreaseHours.push(diff)
                    priceDecrease += priceHike;
                }
            }

        }

        newTotal += priceIncrease
        newTotal -= priceDecrease
        // console.log(increases)
        // console.log(decreases)
        setTotal(newTotal);
        setEnabledIncreases(addedIncreases)
        setIncreaseHoursArray(increaseHours)
        setIncreaseAmountArray(increases);
        setEnabledDecreases(addedDecreases)
        setDecreaseHoursArray(decreaseHours)
        setDecreaseAmountArray(decreases);
        setPolicy(cancellationPolicy)

        setLoading(false)

    }

    async function loadInstructorCredits() {
        const instructorDoc = await db.collection('users').doc(instructorID).get();
        const instructor = instructorDoc.data();
        const facilityCredits = instructor.facilityCredits ?? {};

        const remaining = facilityCredits[facilityID] ?? 0

        setCredits(remaining)

    }

    async function storeBooking(chargeID, status, paidWithCredits = false) {
        // alert('storing')
        setLoading(true)
        try {
            console.log('1')
            await db.collection('bookings').add({
                start: startingDate,
                end: endingDate,
                facilityID,
                rate,
                status,
                rateType,
                total,
                instructorID,
                availableID,
                paidWithCredits,
                chargeID,
                calendarID: '',
                dateCreated: new Date(),
            });
            console.log('2')
            setCompleted(true)
        } catch(err) {
            console.log(err)
            setErrorMessage(err.errorMessage)
        }

        setLoading(false)
    }

    function calcTotalTwoDates(d1, d2) {
        let diff =(d1.getTime() - d2.getTime()) / 1000;
        diff /= 60;
        const newTotal = (diff / 60) * rate;
        setTotal(newTotal)
    }

    function calculateTotal(evt, type) {
        // if (dateSet === false) {
        //     alert('returning')
        //     return setDateSet(true);
        // }
        // console.log(evt)

        let startHour;
        let endHour;

        const addedIncreases = [];
        const increaseHours = []
        const increases = []
        let priceIncrease = 0;

        const addedDecreases = [];
        const decreaseHours = [];
        const decreases = [];
        let priceDecrease = 0;

        let newTotal = 0;

        let diff;

        if (type === 'start') {
            diff = (endRef.current.getTime() - evt.getTime()) / 1000;
            startHour = evt.getHours();
            endHour = endRef.current.getHours();
            diff /= 60;
            newTotal = (diff / 60) * rate;
        }
        if (type === 'end') {
            diff = (evt.getTime() - startRef.current.getTime()) / 1000;
            startHour = startRef.current.getHours();
            endHour = evt.getHours();
            diff /= 60;
            newTotal = (diff / 60) * rate;
        }

        const weekDay = start.toLocaleString('en-us', {  weekday: 'long' });
        const month = start.toLocaleDateString('en-us', { month: 'long' })

        // Price Decreases
        for (let i = 0; i < facilityIncreases.length; i++) {
            const increase = facilityIncreases[i];
            // Daily increases
            if (increase.type === 'daily') {
                let dayFound = false;
                for (let j = 0; j < increase.days.length; j++) {
                    if (increase.days[j].name === weekDay) {
                        dayFound = true;
                    }
                }
                // If day found
                if (dayFound) {
                    // Increase ends during booking
                    if (startHour >= increase.startTime && endHour > increase.endTime) {
                        console.log('1')
                        const hours = increase.endTime - startHour;
                        const priceHike = (hours * RTCStatsReport) * (increase.amount / 100);
                        addedIncreases.push(increase);
                        increases.push(priceHike);
                        increaseHours.push(hours)
                        priceIncrease += priceHike;
                    }

                    // Increase starts during booking
                    if (startHour < increase.startTime && endHour <= increase.endTime) {
                        console.log('2')
                        const hours = endHour - increase.startTime;
                        const priceHike = (hours * rate) * (increase.amount / 100);
                        addedIncreases.push(increase);
                        increaseHours.push(hours)
                        increases.push(priceHike)
                        priceIncrease += priceHike                        }

                    // Increase starts and ends during booking
                    if (startHour <= increase.startTime && endHour >= increase.endTime) {
                        console.log('3')
                        const hours = increase.endTime - increase.startTime;
                        const priceHike = (hours * rate) * (increase.amount / 100);
                        addedIncreases.push(increase);
                        increases.push(priceHike);
                        increaseHours.push(hours)
                        priceIncrease += priceHike;
                    }

                    // Booking is during window
                    if (startHour >= increase.startTime && endHour <= increase.endTime) {
                        console.log('4')
                        const priceHike = newTotal * (increase.amount / 100);
                        addedIncreases.push(increase);
                        increases.push(priceHike);
                        increaseHours.push(diff)
                        priceIncrease += priceHike;

                    }
                }
            }

            // Monthly increases
            if (increase.type === 'monthly') {
                let monthFound = false;
                for (let j = 0; j < increase.months.length; j++) {
                    if (increase.months[j] === month) {
                        monthFound = true;
                    }
                }

                if (monthFound) {
                    const priceHike = newTotal * (increase.amount / 100);
                    addedIncreases.push(increase);
                    increases.push(priceHike);
                    increaseHours.push(diff)
                    priceIncrease += priceHike;
                }
            }

        }

        // Price Decreases
        for (let i = 0; i < facilityDecreases.length; i++) {
            const decrease = facilityDecreases[i];
            // Daily increases
            if (decrease.type === 'daily') {
                let dayFound = false;
                for (let j = 0; j < decrease.days.length; j++) {
                    if (decrease.days[j].name === weekDay) {
                        dayFound = true;
                    }
                }
                // If day found
                if (dayFound) {
                    // Increase ends during booking
                    if (startHour >= decrease.startTime && endHour > decrease.endTime) {
                        console.log('1')
                        const hours = decrease.endTime - startHour;
                        const priceHike = (hours * rate) * (decrease.amount / 100);
                        addedDecreases.push(decrease);
                        decreases.push(priceHike);
                        decreaseHours.push(hours)
                        priceDecrease += priceHike;
                    }

                    // Increase starts during booking
                    if (startHour < decrease.startTime && endHour <= decrease.endTime) {
                        console.log('2')
                        const hours = endHour - decrease.startTime;
                        const priceHike = (hours * rate) * (decrease.amount / 100);
                        addedDecreases.push(decrease);
                        decreaseHours.push(hours)
                        decreases.push(priceHike)
                        priceDecrease += priceHike                        }

                    // Increase starts and ends during booking
                    if (startHour <= decrease.startTime && endHour >= decrease.endTime) {
                        console.log('3')
                        const hours = decrease.endTime - decrease.startTime;
                        const priceHike = (hours * rate) * (decrease.amount / 100);
                        addedDecreases.push(decrease);
                        decreases.push(priceHike);
                        decreaseHours.push(hours)
                        priceDecrease += priceHike;
                    }

                    // Booking is during window
                    if (startHour >= decrease.startTime && endHour <= decrease.endTime) {
                        console.log('4')
                        const priceHike = newTotal * (decrease.amount / 100);
                        addedDecreases.push(decrease);
                        decreases.push(priceHike);
                        decreaseHours.push(diff)
                        priceDecrease += priceHike;

                    }
                }
            }

            // Monthly increases
            if (decrease.type === 'monthly') {
                let monthFound = false;
                for (let j = 0; j < decrease.months.length; j++) {
                    if (decrease.months[j] === month) {
                        monthFound = true;
                    }
                }

                if (monthFound) {
                    const priceHike = newTotal * (decrease.amount / 100);
                    addedDecreases.push(decrease);
                    decreases.push(priceHike);
                    decreaseHours.push(diff)
                    priceDecrease += priceHike;
                }
            }

        }

                newTotal += priceIncrease
                newTotal -= priceDecrease

                setTotal(newTotal);
                setEnabledIncreases(addedIncreases)
                setIncreaseHoursArray(increaseHours)
                setIncreaseAmountArray(increases);
                setEnabledDecreases(addedDecreases)
                setDecreaseHoursArray(decreaseHours)
                setDecreaseAmountArray(decreases);

        // setLoading(false)

    }

    function mountYoco() {

        var sdk = new window.YocoSDK({
            publicKey: 'pk_test_71402d41jB90nKE024a4'
          });
          console.log(1);
          const amountInCents = totalRef.current * 100;
          console.log(amountInCents)
          // Create a new dropin form instance
          var inline = sdk.inline({
            layout: 'plain',
            amountInCents,
            currency: 'ZAR'
          });
          setStoreInline(inline)
          // this ID matches the id of the element we created earlier.
          inline.mount(yocoRef.current);
        // setPaymentForm(inline)
        console.log(3);
    }

    async function onYocoSubmit() {
        setLoading(true)
        var form = formRef.current;
        var submitButton = payButtonRef.current;
        form.addEventListener('submit', function (event) {
          event.preventDefault()
          // Disable the button to prevent multiple clicks while processing
          submitButton.disabled = true;
          // This is the inline object we created earlier with the sdk
          storeInline.createToken().then(function (result) {
            // Re-enable button now that request is complete
            // (i.e. on success, on error and when auth is cancelled)
            submitButton.disabled = false;
            if (result.error) {
              const error = result.error.message;
              setErrorMessage(error)
            //   errorMessage && alert("error occured: " + errorMessage);
            } else {
              const token = result;
              console.log(token);
            //   alert("card successfully tokenised: " + token.id);
              testPayment({token: token.id, amountInCents: (totalRef.current * 100)}).then((res) => {
                console.log(res)
                if (res.data === 'yoco error') {
                    return setErrorMessage('An error occurred trying to process your payment, please try again')
                } else {
                    setStartPayment(false);
                    storeBooking(res.data, 'booked')
                }

              })
            }
          }).catch(function (error) {
              setLoading(false)
            // Re-enable button now that request is complete
            submitButton.disabled = false;
            setErrorMessage('An error occurred trying to process your payment, please try again')
            // alert("error occured: " + error);
          });
        });
    }

    async function payWithCredits() {
        const instructorDoc = await db.collection('users').doc(instructorID).get();
        const instructor = instructorDoc.data();
        const { facilityCredits } = instructor;

        facilityCredits[facilityID] -= totalRef.current;

        try {

            await instructorDoc.ref.update({
                facilityCredits,
            })

            storeBooking('', 'booked', true)
            setCreditPayment(false)

        } catch(err) {
            console.log(err)
            setErrorMessage('Could not process your purchase, please try again')
        }


    }

    return (
        <div onClick={(evt) => {
            if (evt.target.className === 'instructor-booking-request-modal') {
                closeModal();
            }        }} className='instructor-booking-request-modal'>
                <LoadingOverlay active={loading} spinner >
            <div className="instructor-booking-request-modal-content">
            {(!startPayment && !completed && !creditPayment) && <>
            <h1>Booking Request: {facilityName}</h1>
            <div className="booking-request-times">
            <div className="booking-request-row mt-10">
                <p className="fat-pee">
                    Date:
                </p>
                <p className='middle-pee'>{startingDate.toLocaleDateString('en-us', { dateStyle: 'long' })}</p>
            </div>
            <div className="booking-request-row">
                        <p className='fat-pee'>Start:</p>
                        <p className='middle-pee'>{startingDate.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})}</p>
                    </div>
                    <div className="booking-request-row">
                        <p className='fat-pee'>End:</p>
                        <p className='middle-pee'>{endingDate.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})}</p>
                    </div>

            </div>
            <div className="booking-request-details">
                <div className="booking-details-row rate-row mt-10">
                <p className='fat-pee'>Bass Rate:</p>
                <p>R {rate} / hour</p>
                </div>
                {(enabledDecreases.length > 0 || enabledIncreases.length < 0) &&<div className="booking-details-row mb-10">
                    <p className='fat-pee'>Gross Total:</p>
                    <p>R {grossTotal}</p>
                </div>}
                {(enabledDecreases.length > 0 || enabledIncreases.length < 0) && <p className="fat-pee">
                    Price Changes:
                </p>}
                {enabledIncreases.length > 0 && <div className='enabled-increases'>
                   {enabledIncreases.map((item, index) => {
                    //    console.log(item)
                    let toolText = '';
                    if (item.type === 'daily') {
                        let daysText = '';
                        if (item.days.length > 1) {
                            for (let i = 0; i < item.days.length; i++) {
                                if (i === item.days.length - 1) {
                                    daysText += ` and ${item.days[i].name}`;
                                } else if (i === 0) {
                                    daysText += `${item.days[i].name}`
                                } else {
                                    daysText += `, ${item.days[i].name}`
                                }
                            }

                            toolText = `${facilityName} adds a ${item.amount}% increase on ${daysText} between ${item.startTime}:00 and ${item.endTime}:00`

                        } else {
                            daysText = item.days[0].name;

                            toolText = `${facilityName} adds a ${item.amount}% increase on ${daysText} between ${item.startTime}:00 and ${item.endTime}:00`

                        }

                    } else {
                        let monthText = '';
                        if (item.months.length > 1) {
                            for (let i = 0; i < item.months.length; i++) {
                                if (i === item.months.length - 1) {
                                    monthText += `and ${item.months[i].name}`
                                } else if (i === 0) {
                                    monthText += item.months[i].name
                                } else {
                                    monthText += `, ${item.months[i].name}`;
                                }
                            }

                            toolText = `${facilityName} adds a ${item.amount}% increase during ${monthText}`

                        } else {
                            monthText = item.months[0].name
                            toolText = `${facilityName} adds a ${item.amount}% increse during ${monthText}`

                        }
                    }
                   return <div className='change-item'>
                   <div className="c-i-left">
                        <p className='-pee'>
                       {`${item.type} markup - ${item.amount}%`}
                   </p>
                   <Tooltip classes={{tooltip: classes.tooltip}} title={toolText}><InfoIcon /></Tooltip>
                   </div>


                   <p>R {increaseAmountArray[index]}</p>
                   </div>})}
                </div>}
                {enabledDecreases.length > 0 && <div className='enabled-decreases'>
                   {enabledDecreases.map((item, index) => {
                    let toolText = '';

                    if (item.type === 'daily') {
                        let daysText = '';
                        if (item.days.length > 1) {
                            for (let i = 0; i < item.days.length; i++) {
                                if (i === item.days.length - 1) {
                                    daysText += ` and ${item.days[i].name}`;
                                } else if (i === 0) {
                                    daysText += `${item.days[i].name}`
                                } else {
                                    daysText += `, ${item.days[i].name}`
                                }
                            }

                            toolText = `${facilityName} offers a ${item.amount}% decrease on ${daysText} between ${item.startTime}:00 and ${item.endTime}:00`

                        } else {
                            daysText = item.days[0].name;

                            toolText = `${facilityName} offers a ${item.amount}% decrease on ${daysText} between ${item.startTime}:00 and ${item.endTime}:00`

                        }

                    } else {
                        let monthText = '';
                        if (item.months.length > 1) {
                            for (let i = 0; i < item.months.length; i++) {
                                if (i === item.months.length - 1) {
                                    monthText += `and ${item.months[i].name}`
                                } else if (i === 0) {
                                    monthText += item.months[i].name
                                } else {
                                    monthText += `, ${item.months[i].name}`;
                                }
                            }

                            toolText = `${facilityName} adds a ${item.amount}% decrease during ${monthText}`

                        } else {
                            monthText = item.months[0].name;
                            toolText = `${facilityName} offers a ${item.amount}% decrease during ${monthText}`

                        }
                    }

                   return <div className='change-item'> <div className="c-i-left">
                   <p className='-pee'>
                  {`${item.type} markup - ${item.amount}%`}
              </p>
              <Tooltip classes={{tooltip: classes.tooltip}} title={toolText}><InfoIcon /></Tooltip>
              </div>
               <p>(R {decreaseAmountArray[index]})</p>
               </div>})}
                </div>}
                <div className="booking-details-row">
                <p className='fat-pee'>Total:</p>
                <p className='bold-text'>R {totalRef.current}</p>
                </div>

                {(credits !== 0 && acceptBookings)&& <div className="credits-info-container">
                    <div className="remaining-credits-row">
                        <p className="fat-pee">
                            Credits Remaining
                        </p>
                        <p>{credits}</p>
                    </div>
                    {(credits > totalRef.current) && <div className="remaining-credits-row">
                        <p className="fat-pee">
                            Pay with credits
                        </p>
                        <Switch checked={paymentType === 'credits'} onChange={((e) => {
                            if (e) {
                                setPaymenType('credits')
                            } else {
                                setPaymenType('card')
                            }
                        })} />
                    </div>}
                </div>}

                <div className="booking-notes-container">
                    <h4>Notes</h4>
                {!acceptBookings && <div className="booking-policy">
                    <h5>Booking Policy</h5>
                    <p>Once the booking is placed, the facility will need to approve it</p>
                </div>}
                <div className="cancellation-policy">
                    <h5>Cancellation Policy:</h5>
                    {policy === 'flexible' && <p>Full refund is issued for cancellations up to 24 hours in advance</p>}
                    {policy === 'moderate' && <p>Full refund is issued for cancellations up to 5 days prior</p>}
                    {policy === 'strict' && <p>Full refund is issued for cancellations made within 48 hours of booking, if the booking date is at least 14 days away. 50% refund is issued for cancellations made at least 7 days in advance. No refunds issued for cancellation ade within 7 days of the booking date.</p>}
                </div>
                </div>


            </div>
            {errorMessage !== '' && <div className="booking-error-row">
                <p>{errorMessage}</p>
            </div>}
            <div className="booking-request-button-row">
                <button onClick={closeModal} className='cancel-button'>Cancel</button>
                {acceptBookings && <button onClick={() => {
                    if (paymentType === 'card') {
                        setStartPayment(true)
                        mountYoco()
                    } else {
                        setCreditPayment(true)

                    }
                    }}
                    disabled={buttonDisabled} className='confirm-button'>Pay</button>}
                {!acceptBookings &&
                <button onClick={() => storeBooking('', 'pending')} className="confirm-button">
                    Book
                </button>}
            </div>
            </>}
            {creditPayment && <div className='credit-payment-container'>
            <h1>Booking Request: {facilityName}</h1>

            <div className="booking-request-row mt-10">
                <p className="fat-pee">
                    Date:
                </p>
                <p className='middle-pee'>{startingDate.toLocaleDateString('en-us', { dateStyle: 'long' })}</p>
            </div>
                             <div className="booking-request-row">
                        <p className='fat-pee'>Start:</p>
                        <p className='middle-pee'>{startingDate.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})}</p>
                    </div>
                    <div className="booking-request-row">
                        <p className='fat-pee'>End:</p>
                        <p className='middle-pee'>{endingDate.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})}</p>
                    </div>
                    <div className="booking-request-row">
                <p className='fat-pee'>Total:</p>
                <p className='middle-pee'>R {totalRef.current}</p>
                </div>
                <div className="button-row">
                    <button onClick={payWithCredits} className="general-button">
                        Confirm Credit Purchase
                    </button>
                </div>
                </div>}
            {completed && <div className='booking-completed'>
                {acceptBookings ?
                <h2>Payment Completed</h2>
                 : <h2>Booking Requested</h2>}
                             <div className="booking-request-row mt-10">
                <p className="fat-pee">
                    Date:
                </p>
                <p className='middle-pee'>{startingDate.toLocaleDateString('en-us', { dateStyle: 'long' })}</p>
            </div>
                             <div className="booking-request-row">
                        <p className='fat-pee'>Start:</p>
                        <p className='middle-pee'>{startingDate.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})}</p>
                    </div>
                    <div className="booking-request-row">
                        <p className='fat-pee'>End:</p>
                        <p className='middle-pee'>{endingDate.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'})}</p>
                    </div>
                    {acceptBookings ? <p>Enjoy your time at {facilityName}</p> : <p>{facilityName} will need to approve the booking once received</p>}
                    <div className="button-row">
                        <button onClick={() => {
                            reloadCalendar()
                            closeModal()

                        }
                            } className="general-button">
                            Close
                        </button>
                    </div>

            </div>}
            {/* {startPayment && <> */}
                <form ref={formRef} hidden={!startPayment} style={{fontFamily:'LibreFranklin'}} id="payment-form" method="POST">
                    <div class="one-liner">
                        <div ref={yocoRef} id="card-frame">
                        </div>
                        {/* {paymentForm} */}
                        {errorMessage !== '' && <div className="error-message-row">
                            <p>{errorMessage}</p>
                        </div>}
                        <div className="button-row">
                        <button hidden={!startPayment} onClick={onYocoSubmit} ref={payButtonRef} id="pay-button">
                        PAY ZAR {totalRef.current}.00
                        </button>
                        </div>

                    </div>
                    <p class="success-payment-message" />
                </form>
            {/* </>} */}
            </div>
                            </LoadingOverlay>
        </div>
    )
}

export default InstructorBookingRequestModal
