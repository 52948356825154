import React from 'react'
import './Steps.css'

function StepThree({ fwd = true, edit, descRef, descriptionValue, columns }) {
    return (
        <div className={fwd ? "step-three slide-in-right" : "step-three slide-in-left"}>
            {!edit && <h1>Step 3</h1>}
            <p>Write a description about your facility</p>
            <textarea defaultValue={descriptionValue} ref={descRef} rows={12} cols={columns} />
        </div>
    )
}

export default StepThree
