import React, { useState, useEffect, useRef } from 'react'
import './ClientRelationships.css'
import './SearchDropModal.css'

// Graphs
import { defaults } from 'react-chartjs-2';
import { Bar, Line } from 'react-chartjs-2'

// Components
import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';
import InfoCard from '../../../Components/Cards/InfoCard';
import BookingItem from './BookingItem';
import ClientThumbnail from './ClientThumbnail/ClientThumbnail'
import ReviewItem from '../Reviews/ReviewItem'
import ReplyReviewModal from '../Reviews/ReplyReviewModal';
import ReportReviewModal from '../Reviews/ReportReviewModal';
import AddClientModal from '../../Clients/AddClientModal/AddClientModal'

// Icons
import AddBoxIcon from '@material-ui/icons/AddBox';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// Firebase
import { db } from '../../../firebase';

function ClientRelationships({ facilityID, venueName, setLoading, userRole, uid }) {

    // Bar Chart Style
    defaults.font.family = 'LibreFranklin';
    defaults.elements.bar.backgroundColor = 'black';

    const [selectedClient, setSelectedClient] = useState('');
    const [clientDropDown, setClientDropDown] = useState([]);
    const [incomeArray, setIncomeArray] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState('overview');
    const [loadedBookings, setLoadedBookings] = useState([]);
    const [loadedReviews, setLoadedReviews] = useState([])
    const [clientThumbs, setClientThumbs] = useState([]);
    const [replyModalOpen, setReplyModalOpen] = useState(false);
    const [replyModalID, setReplyModalID] = useState()
    const [reportModalOpen, setReportModalOpen] = useState(false);
    const [reportModalID, setReportModalID] = useState('');
    const [showAddClient, setShowAddClient] = useState(false)

    const bookingsRef = useRef();
    bookingsRef.current = loadedBookings;

    useEffect(() => {
        if (userRole === 'owner') {
            loadClients()
        }
        if (userRole === 'instructor') {
            loadInstructorClients();
        }

    }, [userRole]);



    async function loadClients() {
        if (facilityID === null || facilityID === undefined) {
            return setLoading(false)
        }

        const facilityDoc = await db.collection('facilities').doc(facilityID).get();
        const facility = facilityDoc.data();
        const clientIDs = facility.clientArray;

        const clientArray = [];
        const thumbArray = [];

        for (var i = 0; i < clientIDs.length; i++) {
            const clientDoc = await db.collection('users').doc(clientIDs[i]).get();
            const client = clientDoc.data();
            const clientID = clientIDs[i];
            const clientName = `${client.firstName} ${client.lastName}`;
            const {imageLink} = client;
            clientArray.push({
                value: clientID,
                name: clientName,
            });
            thumbArray.push({
                clientID,
                name: clientName,
                img: imageLink,
            })
        }
        setClientDropDown(clientArray);
        setClientThumbs(thumbArray);
    }

    async function loadInstructorClients() {
        const sessionDocs = await db.collection('sessions')
            .where('instructorID', '==', uid)
            .get();
        const sessions = sessionDocs.docs;
        const instructorDoc = await db.collection('users').doc(uid).get();
        const instructor = instructorDoc.data();


        const clientIDs = [];
        for (let i = 0; i < sessions.length; i++) {
            const athleteID = sessions[i].data().athleteID;
            const status = sessions[i].data().status;
            if (sessions[i].data().isGroupSession) {
                for (let j = 0; j < athleteID.length; j++) {
                    const ath = athleteID[j];
                    if (!clientIDs.includes(ath) && !status.includes('cancel')) {
                        clientIDs.push(ath)
                    }
                }
            } else {
                if (!clientIDs.includes(athleteID) && !status.includes('cancel')) {
                    clientIDs.push(athleteID)
                }
            }

        }

        const clientArray = [];
        const thumbArray = [];

        // Offline clients
        const clients = instructor.clients ?? [];
        console.log(clients);
        for (let i = 0; i < clients.length; i++) {
            console.log(i)
            if (!clientIDs.includes(clients[i])) {
                clientIDs.push(clients[i]);
            }
        }

        for (var i = 0; i < clientIDs.length; i++) {
            console.log(clientIDs[i])
            if (clientIDs[i] === '7stW1jPiQOfzxpuzkjKISYAabTd2') {
                continue;
            }
            const clientDoc = await db.collection('users').doc(clientIDs[i]).get();
            const client = clientDoc.data();
            const clientID = clientIDs[i];
            const clientName = `${client.firstName} ${client.lastName}`;
            const imageLink = client.imageLink ?? 'https://static.thenounproject.com/png/363640-200.png';

            let chatID = '';

            if (client.isOfflineUser !== true) {
                // Chat
                const chatDocs = await db.collection('chat')
                    .where('instructorID', '==', uid)
                    .where('athleteID', '==', clientID)
                    .get();
                chatID = chatDocs.docs[0].id;
            }



            clientArray.push({
                value: clientID,
                name: clientName,
                chatID,
            });
            thumbArray.push({
                clientID,
                name: clientName,
                img: imageLink,
                chatID,
            })
        }
        setClientDropDown(clientArray);
        setClientThumbs(thumbArray);
        setLoading(false)
    }

    const today = new Date()

    async function loadClientDetails(clientID) {
        if (clientID === selectedClient.clientID) {
            return;
        }
        setLoading(true);

        // Current month
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.toLocaleDateString('en-us', { month: 'long' })

        // Relationship Document
        const clientRelDoc = await db.collection('facilities').doc(facilityID).collection('analytics').doc(clientID).get();
        const clientRel = clientRelDoc.data();
        const firstSessionDate = new Date(clientRel.firstSessionDate.toDate());
        var options = { year: 'numeric', month: 'long', day: 'numeric'};
        const firstSessionString = firstSessionDate.toLocaleDateString('en-us', options);

        // Overall Stats
        const { totalBookings, totalIncome, currentMonthBookings, currentMonthIncome } = clientRel;

        // Income Array
        const currentYearArray = clientRel[`${currentYear}`];
        console.log(currentYearArray)
        setIncomeArray(currentYearArray)

        // Client Document
        const clientDoc = await db.collection('users').doc(clientID).get();
        const client = clientDoc.data();

        // Client Location
        let city = client.city;
        if (city.includes('Cape Town')) {
            city = 'Cape Town';
        }
        if (city.includes('Johannesburg')) {
            city = 'Johannesburg'
        }
        const country = client.country;
        const location = `${city}, ${country}`;
        const clientName = `${client.firstName} ${client.lastName}`;
        const imageLink = client.imageLink;
        setSelectedClient({
            currentMonth,
            totalBookings,
            totalIncome,
            currentMonthIncome,
            currentMonthBookings,
            clientID,
            clientName,
            imageLink,
            location,
            firstSessionString,
        });
        loadClientBookings(clientID);
        loadClientReviews(clientID)
        setLoading(false)

    }

    async function loadAthleteDetails(athleteID) {
        console.log(athleteID)
        if (athleteID === selectedClient.athleteID) {
            return;
        }
        setLoading(true);

        // Current month
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.toLocaleDateString('en-us', { month: 'long' })

        // Relationship Document
        const sessionDocs = await db.collection('sessions')
            .where('instructorID', '==', uid)
            .where('athleteID', '==', athleteID)
            .orderBy('dateTimeStamp', 'desc')
            .get();

        const sessions = sessionDocs.docs;

        const totalBookings = sessions.length;
        let totalIncome = 0;
        let currentMonthBookings = 0;
        let currentMonthIncome = 0;
        let firstSessionString;

        // Income Array
        const incomeArray = [];
        for (let i = 0; i < 12; i++) {
            incomeArray.push(0);
        }

        for (let i = 0; i < sessions.length; i++) {
            if (sessions[i].data().status.includes('cancel')) {
                console.log(`continuing`)
                continue;
            }
            totalIncome += sessions[i].data().cost;
            const date = new Date (sessions[i].data().dateTimeStamp.toDate());
            if (i === 0) {
                firstSessionString = date.toLocaleDateString('en-us', { dateStyle: 'long'})
            }

            if (date.getFullYear === currentYear && date.getMonth() === now.getMonth()) {
                currentMonthBookings += 1;
                currentMonthIncome += sessions[i].data().cost;
            }

            if (date.getFullYear() === currentYear) {
                incomeArray[date.getMonth()] += sessions[i].data().cost;
            }
        }

        // const currentYearArray = clientRel[`${currentYear}`];
        setIncomeArray(incomeArray)

        // Athlete Document
        const athleteDoc = await db.collection('users').doc(athleteID).get();
        const athlete = athleteDoc.data();

        // Client Location
        let city = athlete.city ?? 'Cape Town';
        if (city.includes('Cape Town')) {
            city = 'Cape Town';
        }
        if (city.includes('Johannesburg')) {
            city = 'Johannesburg'
        }
        const country = athlete.country;
        const location = `${city}, ${country}`;
        const clientName = `${athlete.firstName} ${athlete.lastName}`;
        const imageLink = athlete.imageLink ?? 'https://static.thenounproject.com/png/363640-200.png';
        setSelectedClient({
            currentMonth,
            totalBookings,
            totalIncome,
            currentMonthIncome,
            currentMonthBookings,
            clientID: athleteID,
            clientName,
            imageLink,
            location,
            firstSessionString,
        });
        loadAthleteBookings(athleteID, sessions);
        loadAthleteReviews(athleteID)
        setLoading(false)
    }

    async function loadClientBookings(clientID) {
        const bookings = await db.collection('bookings').where('instructorID', '==', clientID)
        .orderBy('start', 'desc')
        .get()
        .catch(err => console.log(err));
        // console.log(bookings.docs.length)
        const bookingArray = [];

        for (let i = 0; i < bookings.docs.length; i++) {
            const booking = bookings.docs[i];
            const doc = booking.data();
            const bookingID = booking.id;

            const start = new Date(doc.start.toDate());
            const end = new Date(doc.end.toDate());

            const {rateType, rate, total} = doc;

            bookingArray.push({
                bookingID,
                start,
                end,
                rateType,
                rate,
                total,
            });
        }
        setLoadedBookings(bookingArray);

    }

    async function loadAthleteBookings(athleteID, sessions) {
        const sessionArray = [];
        for (let i = 0; i < sessions.length; i++) {
            const session = sessions[i].data();
            const sessionID = sessions[i].id;
            if (sessions[i].data().status.includes('cancel')) {
                console.log(`continuing`)
                continue;
            }
            const start = new Date(session.dateTimeStamp.toDate());
            const end = new Date(start);
            end.setHours(end.getHours() + (session.duration / 60));

            let sessionType = session.sessionType;

            switch (sessionType) {
                case 'personalTraining': sessionType = 'Personal Training';
                    break;
                case 'recovery': sessionType = 'Recovery';
                    break;
                case 'yoga': sessionType = 'Yoga';
                    break;
                case 'wellness': sessionType = 'Wellness';
                    break;
                default: break;
            }

            const isOffline = session.isOffineSession;
            const total = session.cost;
            const rate = total / (session.duration / 60);

            sessionArray.push({
                sessionID,
                start,
                end,
                rateType: 'hourly',
                rate,
                total,
                sessionType,
                venue: session.location,
                isOffline,
            })
        }

        setLoadedBookings(sessionArray)
    }

    async function loadClientReviews(clientID) {

        const reviewArray = [];

        const reviews = await db.collection('facilityReviews')
            .where('facilityID', '==', facilityID)
            .where('instructorID', '==', clientID)
            .orderBy('dateTimeStamp', 'desc')
            .get()
        for (let i = 0; i < reviews.docs.length; i++) {
            const reviewDoc = reviews.docs[i];
            const review = reviewDoc.data();
            const reviewID = reviewDoc.id;
            const instructorID = review.instructorID;
            const textContent = review.textContent;
            const dateObject = new Date(review.dateTimeStamp.toDate());
            const dateString = dateObject.toDateString();
            const rating = review.rating;

        // Instructor Details
        const instructorDoc = await db.collection('users').doc(instructorID).get();
        const instructor = instructorDoc.data();
        const instructorName = `${instructor.firstName} ${instructor.lastName}`;
        const imageLink = instructor.imageLink;

        // Add to aray
        reviewArray.push({
            reviewID,
            instructorID,
            instructorName,
            textContent,
            dateString,
            imageLink,
            rating,
        });
        }

        setLoadedReviews(reviewArray)
    }

    async function loadAthleteReviews(athleteID) {
        const reviewArray = [];
        const reviews = await db.collection('reviews').doc('reviewCollections').collection('public')
            .where('instructorID', '==', uid)
            .where('athleteID', '==', athleteID)
            .orderBy('dateTimeStamp', 'desc')
            .get();

        for (let i = 0; i < reviews.docs.length; i++) {
            const reviewDoc = reviews.docs[i];
            const review = reviewDoc.data();
            const reviewID = reviewDoc.id;
            const {athleteID} = review;
            const textContent = review.textContent;
            const dateObject = new Date(review.dateTimeStamp.toDate());
            const dateString = dateObject.toDateString();
            const rating = review.rating;

            // Athlete Details
            const athleteDoc = await db.collection('users').doc(athleteID).get();
            const athlete = athleteDoc.data();
            const athleteName = `${athlete.firstName} ${athlete.lastName}`;
            const imageLink = athlete.imageLink;

            reviewArray.push({
                reviewID,
                instructorID: athleteID,
                instructorName: athleteName,
                textContent,
                dateString,
                imageLink,
                rating,
            });


        }

        setLoadedReviews(reviewArray)
    }

    function showReportModal(reviewID) {
        setReportModalID(reviewID)
        setReportModalOpen(true);
    }

    function showReplyModal(reviewID) {
        setReplyModalID(reviewID)
        setReplyModalOpen(true)
    }

    return (
        <div className='client-relationships'>

{reportModalOpen && <ReportReviewModal reviewID={reportModalID} closeModal={() => setReportModalOpen(false)} />}
            {replyModalOpen && <ReplyReviewModal reviewID={replyModalID} closeModal={() => setReplyModalOpen(false)} />}
            {showAddClient && <AddClientModal closeModal={() => setShowAddClient(false)} uid={uid} />}
            {selectedClient !== '' && <div onClick={() => {setSelectedClient('');setSelectedMenu('overview') }} className="back-button-row">
                <ArrowBackIosNewIcon />
                <p>Back</p>
            </div>}
            <div className="clients-header">
            <h2>Clients</h2>
            <div onClick={() => setShowAddClient(true)} className="new-client">
                <PersonAddIcon className='add-client-icon' />
                <p>Add</p>
            </div>
            </div>

            <div className="clients-container">
            <SelectSearch autoComplete search={true}
            // filterOptions={options => options}
            closeOnSelect={true}
            filterOptions={ fuzzySearch }
            onChange={(evt) => {
                console.log(userRole)
                if (userRole === 'owner') {
                    loadClientDetails(evt)
                }
                if (userRole === 'instructor') {
                    loadAthleteDetails(evt)
                }
            } }
            options={clientDropDown} placeholder="Choose a client" />

                {selectedClient === '' ?
                <div className="no-clients">
                    {/* <h3>Please select a client to view your relationship</h3> */}
                    <div className="client-thumbs">
                    {clientThumbs.map(client => <ClientThumbnail chatID={client.chatID} role={userRole} uid={uid} goToClient={() => {
                if (userRole === 'owner') {
                    loadClientDetails(client.clientID)
                }
                if (userRole === 'instructor') {
                    loadAthleteDetails(client.clientID)
                }                        }} name={client.name} imageLink={client.img} clientID={client.clientID} />)}

                    </div>
                </div>
                : <div className="selected-client">
                    <div className="selected-client-header">
                        <img alt='client' src={selectedClient.imageLink} />
                        <div className="client-header-text">
                        <h3>{selectedClient.clientName}</h3>
                        <p>{selectedClient.location}</p>
                        <span>Client Since: {selectedClient.firstSessionString}</span>
                        </div>
                    </div>
                    <div className="client-content-divider">
                    <hr />

                    </div>
                    <div className="selected-client-menu">
                        <button onClick={() => setSelectedMenu('overview')} className={selectedMenu === 'overview' ? 'active-client-menu-button left-button' : 'left-button'}>Overview</button>
                        <button onClick={() => setSelectedMenu('bookings')} className={selectedMenu === 'bookings' && 'active-client-menu-button'} >Bookings</button>
                        <button onClick={() => setSelectedMenu('reviews')} className={selectedMenu === 'reviews' ? 'active-client-menu-button right-button' : 'right-button'}>Reviews</button>
                    </div>
                    {selectedMenu === 'overview' && <div className="selected-client-content">
                        <div className="client-card-row">
                            <InfoCard icon='money' type='general' info={'Total Bookings'} value={selectedClient.totalBookings} flex={0.24} hideChange fixedHeight />
                            <InfoCard icon='money' type='red' info={'Total Income'} value={`R ${selectedClient.totalIncome}`} flex={0.24} hideChange fixedHeight />
                            <InfoCard icon='money' type='general' info={`${selectedClient.currentMonth} Bookings`} value={selectedClient.currentMonthBookings} flex={0.24} hideChange fixedHeight />
                            <InfoCard icon='money' type='blue' info={`${selectedClient.currentMonth} Income`} flex={0.24} value={`R ${selectedClient.currentMonthIncome}`} hideChange fixedHeight />
                        </div>
                        <div className="bar-chart-header">
                            <h2>Income Earned</h2>
                        </div>
                        <div className="bar-chart-select">
                            <SelectSearch
                            
                             search={false}
                             closeOnSelect={true}
                             filterOptions={ fuzzySearch }
                             placeholder={'2021'}
                             options={[{
                                 value: '2021',
                                 name:'2021',
                             },
                             {
                                 value: '2022',
                                 name:'2022',
                             },
                             ]}
                             />
                        </div>
                        <div className="client-content-bottom">
                            <Bar
                                height={'300'}
                                width={'500'}
                                options={{
                                    maintainAspectRatio: false,
                                    font: {
                                        family: 'LibreFranklin'
                                    }
                                }}
                                data={{
                                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',],
                                    datasets: [{
                                        label: 'Income Received',
                                        // data: [400, 600, 250, 300, 550, 200, 200, 200, 200, 200, 500, 400],
                                        data: incomeArray,
                                    }]
                                }}
                            />
                        </div>
                    </div>}
                    {selectedMenu === 'bookings' && <div className='selected-client-content'>
                    <div className="bar-chart-header">
                            <h2>Bookings</h2>
                        </div>
                        <div className="bookings-container">
                        {loadedBookings.map((booking, index) => {
                            return <BookingItem location={booking.venue} sessionType={booking.sessionType} userRole={userRole} start={booking.start} end={booking.end} type={booking.rateType} total={booking.total} rate={booking.rate} venue={venueName} />
                        })}
                        </div>

                                              </div>}
                    {selectedMenu === 'reviews' && <div className='selected-client-content'>
                    {loadedReviews.map((review, index) => {
                    return <ReviewItem
                        dateString={review.dateString}
                        showReportModal={showReportModal}
                        showReplyModal={showReplyModal}
                        instructorName={review.instructorName}
                        textContent={review.textContent}
                        reviewID={review.reviewID}
                        imageLink={review.imageLink}
                        rating={review.rating}
                        />
                })}                        </div>}
                </div> }
            </div>

            {/* </div> */}
        </div>
    )
}

export default ClientRelationships
