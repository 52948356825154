import React, { useState, useEffect } from 'react'
import './ViewListing.css'

import { useLocation, useNavigate } from 'react-router';

import ImageCarousel from './ImageCarousel';
import LoadingOverlay from 'react-loading-overlay';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


// Firebase
import { db } from '../../firebase'

function ViewListing() {

    const location = useLocation();
    const navigate = useNavigate();


    // Facility State
    const [listingName, setListingName] = useState();
    const [maxCapacity, setMaxCapacity] = useState();
    const [addressOne, setAddressOne] = useState();
    const [addressTwo, setAddressTwo] = useState();
    const [postalCode, setPostalCode] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState();
    const [description, setDescription] = useState();
    const [hourlyRate, setHourlyRate] = useState();
    const [halfDayRate, setHalfDayRate] = useState();
    const [dayRate, setDayRate] = useState();
    const [monthRate, setMonthRate] = useState();
    const [weekRate, setWeekRate] = useState();
    const [venueLocation, setVenueLocation] = useState();
    const [venueAmenities, setVenueAmenities] = useState([]);
    const [venueFacilities, setVenueFacilities] = useState([]);
    const [venueInfo, setVenueInfo] = useState();
    const [venueSpace, setVenueSpace] = useState();
    const [venueType, setVenueType] = useState();
    const [loading, setLoading] = useState(true);
    const [increases, setIncreases] = useState([])
    const [decreases, setDecreases] = useState([])
    const [discounts, setDiscounts] = useState([])
    const [bookingPolicy, setBookingPolicy] = useState('')
    const [cancellationPolicy, setCancellationPolicy] = useState('')

    const [imageCarousel, setImageCarousel] = useState();

    console.log(decreases)


    useEffect(() => {
        loadFacility();
    }, [])

    const facilityID = location.state.facilityID;

    function goBack() {
        navigate('/listings');
    }

    function goToEdit() {
        navigate(
            '/edit-listing',
            {
                state: {facilityID},
        })
    }

    async function loadFacility() {
        const facilityDoc = await db.collection('facilities').doc(facilityID).get();
        const facility = facilityDoc.data();

        console.log(facility.priceDecreases)
        setBookingPolicy(facility.bookingPolicy ?? 'accept')
        setCancellationPolicy(facility.cancellationPolicy ?? 'moderate')
        setDiscounts(facility.bulkDiscounts ?? [])
        setIncreases(facility.priceIncreases ?? [])
        setDecreases(facility.priceDecreases ?? []);
        setListingName(facility.listingName);
        setMaxCapacity(facility.maxCapacity);
        setAddressOne(facility.addressOne);
        setAddressTwo(facility.addressTwo);
        setPostalCode(facility.postalCode);
        setCity(facility.city);
        setCountry(facility.country);
        setDescription(facility.description);
        setHourlyRate(facility.hourlyRate);
        setHalfDayRate(facility.halfDayRate);
        setDayRate(facility.dayRate);
        setMonthRate(facility.monthRate);
        setWeekRate(facility.weekRate);
        setVenueLocation(facility.location);
        setVenueInfo(facility.venueInfo);
        setVenueSpace(facility.venueSpace);
        setVenueType(facility.venueType);
        setVenueAmenities(facility.venueAmenities);
        setVenueFacilities(facility.venueFacilities);
        // Image Links
        setImageCarousel(<ImageCarousel showThumb={true}  imageArray={facility.imageLinks} />)
        setLoading(false)
        // setImageLinks(facility.imageLinks);
    }

    return (
        <LoadingOverlay active={loading} spinner >
        <div className='view-listing'>
        <div className="back-row">
                <ArrowBackIcon />
                <p onClick={() => navigate(-1)}>Back</p>
            </div>
            <div className="view-listing-header">
                <h1>View Listing: {listingName}</h1>
            </div>
            <div className="view-listing-address">
                    <p>{addressOne}, {addressTwo}, {postalCode} {city}, {country}</p>
                </div>
            <div className="view-listing-types">
                <p>{venueSpace} {venueType}</p>
            </div>
            <div className="view-listing-content">
                <div className="view-listing-carousel">
                    {/* <ImageCarousel imageArray={imageLinks} /> */}
                    {imageCarousel}
                </div>
                <div className="view-listing-details">
                    <div className="view-listing-details-left">
                        <h3>Description</h3>
                        <p>{description}</p>
                        <h3>Information</h3>
                        <p>{venueInfo}</p>
                        <div className="view-listing-rates">
                            <h3>Rates</h3>
                            {hourlyRate !== '0' && <p><span className='bold-text'>Hourly Rate:</span>R {hourlyRate}</p>}
                            {increases.length > 0 && <div className='price-container'>
                                <h3>Increases:</h3>
                                {increases.map(item => {
                                    return <div className='price-item'>
                                        <p>Type: <span style={{textTransform: 'capitalize'}}>{item.type}</span></p>
                                        {item.type === 'monthly' && <p>{item.months.map((month, index) => {
                                            if (index === item.months.length - 1) {
                                                return `${month.name}`
                                            } else {
                                                return `${month.name}, `
                                            }
                                        })}</p>}
                                        {item.type === 'daily' && <p>
                                            {item.days.map((day, index) => {
                                             if (index === item.days.length - 1) {
                                                return `${day.name}`
                                            } else {
                                                return `${day.name}, `
                                            }
                                            })}
                                            </p>}
                                        <p>{item.amount}% increase</p>

                                    </div>
                                })}
                                </div>}
                            {decreases.length > 0 && <div className='price-container'>
                                <h3>Decreases:</h3>
                                {decreases.map(item => {
                                    return <div className='price-item'>
                                        <p>Type: <span style={{textTransform: 'capitalize'}}>{item.type}</span></p>
                                        {item.type === 'monthly' && <p>{item.months.map((month, index) => {
                                            if (index === item.months.length - 1) {
                                                return `${month.name}`
                                            } else {
                                                return `${month.name}, `
                                            }
                                        })}</p>}
                                        {item.type === 'daily' && <p>
                                            {item.days.map((day, index) => {
                                             if (index === item.days.length - 1) {
                                                return `${day.name}`
                                            } else {
                                                return `${day.name}, `
                                            }
                                            })}
                                            </p>}
                                        <p>{item.amount}% increase</p>

                                    </div>
                                })}
                                </div>}

                            {discounts.length > 0 && <div className='price-container'>
                            <h3>Bulk Discounts:</h3>
                                {discounts.map(discount => {
                                    return <div className='price-item'>
                                        <p style={{textTransform:'capitalize'}}>{discount.size}</p>
                                        <p>Amount: R{discount.amount}</p>
                                        <p>Discount: %{discount.percentage}</p>
                                    </div>
                                })}
                                </div>}
                        </div>
                    </div>
                    <div className="view-listing-details-right">
                        <div className="view-listing-selected">
                            <div className="view-listing-facilities">
                                <h3>Amenities</h3>
                                <ul>
                                {venueFacilities.map((facility, index) => {
                                    let fac = facility;
                                    if (facility === 'weight-section') {
                                        fac = 'Weight Section';
                                    }
                                    if (facility === 'function-floor') {
                                        fac = 'Functional Floor';
                                    }
                                    if (facility === 'yoga-studio') {
                                        fac = 'Yoga Studio';
                                    }
                                    return <li>{fac}</li>
                                })}

                                </ul>
                            </div>
                            <div className="view-listing-amenities">
                                <h3>Facilities</h3>
                                <ul>
                                {venueAmenities.map((amenity, index) => {
                                        let amen = amenity;
                                        if (amenity === 'steam-room') {
                                            amen = 'Steam Room'
                                        }
                                        return <li>{amen}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="view-listing-max-capacity">
                            <h3>Max Capacity:</h3><p>{maxCapacity} people</p>
                        </div>
                        <div className="view-listing-max-capacity">
                            <h3>Booking Policy:</h3><p>{bookingPolicy === 'accept' ? 'Accept all bookings' : 'Approve all bookings'}</p>
                        </div>
                        <div className="view-listing-max-capacity">
                            <h3>Cancellation Policy:</h3><p>{cancellationPolicy === 'flexible' ? 'Full refund is issued for cancellations up to 24 hours in advance' :
                            cancellationPolicy === 'moderate' ? 'Full refund is issued for cancellations up to 5 days prior' : 'Full refund is issued for cancellations made within 48 hours of booking, if the booking date is at least 14 days away. 50% refund is issued for cancellations made at least 7 days in advance. No refunds issued for cancellation ade within 7 days of the booking date.'
                            }</p>
                        </div>


                    </div>
                </div>
                <div className="view-listing-button-row">
                    <button onClick={goBack} className='back-button'>Back</button>
                    <button onClick={goToEdit} className='next-button'>Edit</button>
                </div>
            </div>

        </div>
        </LoadingOverlay>
    )
}

export default ViewListing
