import React, { useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';

import './Steps.css'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import Geocode from "react-geocode";
import Geohash from 'latlon-geohash';
import { admin } from '../../../firebase';

import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    tooltip: {
      fontSize: '18px',
    },
  }));



// function StepOne({ locationRef, venueTypeRef, venueSpaceRef, maxCapacityRef, performValidationRef }) {
    function StepOne({ fwd = true, edit, venueTypeValue, venueSpaceValue, maxCapacityValue, locationValue, locationRef, locationHashRef, venueTypeRef, venueSpaceRef, maxCapacityRef, setType, setSpace, setCapacity,
                    locationTextRef, locationTextValue, locationHashValue, rentalTypeRef, rentalTypeValue
    }) {

        Geocode.setApiKey("AIzaSyBNVt036Vwj3EMeNMVyTEh9JPqCuZ6xsi8");
        const classes = useStyles();

        const [update, setUpdate] = useState(0)

        useEffect(() => {
            console.log('hello')
            console.log(locationTextValue);
            setUpdate(current => current + 1);
        }, [locationTextValue])

        const [locValue, setLocValue] = useState(locationTextValue)
        const [geoHash, setGeoHash] = useState(locationHashValue)
        const [geoPoint, setGeoPoint] = useState(locationValue)

        const placesRef = useRef();

        locationRef.current = geoPoint;
        locationHashRef.current = geoHash;
        locationTextRef.current = locValue;

        async function getPlaceCoords(address) {
            try {
                const response = await Geocode.fromAddress(address);
                const { lat, lng } = response.results[0].geometry.location;
                const newHash = Geohash.encode(lat, lng);
                const newPoint = new admin.firestore.GeoPoint(lat, lng);
                setGeoHash(newHash)
                setGeoPoint(newPoint);
                console.log(lat, lng);

            } catch (err) {
                console.log(err)
            }
        }

        const venueTypeOptions = [
            {
                name: 'Full Gym',
                value: 'full-gym',
            },
            {
                name: 'Private Space',
                value: 'private-space',
            },
            {
                name: 'Yoga Studio',
                value: 'yoga-studio',
            },
            {
                name: 'Pilates Studio',
                value: 'pilates-studio',
            },
            {
                name: 'Boxing Gym',
                value: 'boxing-gym',
            },
            {
                name: 'Treatmeant Facility',
                value: 'treatment-facility',
            },
        ];

        const spaceOptions = [
            {
                name: 'Private Space',
                value: 'private',
            },
            {
                name: 'Full Venue',
                value: 'public'
            },
        ];

        const capacityOptions = [
            {
                name: '5',
                value: 5,
            },
            {
                name: '10',
                value: 10,
            },
            {
                name: '15',
                value: 15,
            },
            {
                name: '20',
                value: 20,
            },
            {
                name: '25',
                value: 25,
            },
        ];

        const rentalOptions = [
            {
                name: 'Group Classes',
                value: 'group-classes',
            },
            {
                name: '1-on-1 Sessions',
                value: 'single-classes',
            },
            {
                name: 'Both',
                value: 'both-classes',
            },
        ]

        return (
        <div className={fwd ? "step-one slide-in-right" : "step-one slide-in-left"}>
            {!edit && <h1>Step 1</h1>}
            <div className="input-container">
                <div className="venue-row">
                {/* <Tooltip classes={{tooltip: classes.tooltip}} title={'Venue Type'}> */}
                    <SelectSearch
                        ref={venueTypeRef}
                        autoComplete search={true}
                        closeOnSelect={true}
                        filterOptions={ fuzzySearch }
                        onChange={(evt) => setType(evt)}
                        options={venueTypeOptions}
                        placeholder="Venue Type"
                        value={venueTypeValue}
                        />
                        {/* </Tooltip> */}
                    {/* <select defaultValue={venueSpaceValue} ref={venueSpaceRef} className="venue-space-drop">
                        <option disabled selected>Full Venue / Private Space</option>
                        <option selected={venueSpaceValue === 'Option 1'}>Option 1</option>
                        <option selected={venueSpaceValue === 'Option 2'}>Option 2</option>
                        <option selected={venueSpaceValue === 'Option 3'}>Option 3</option>
                        <option selected={venueSpaceValue === 'Option 4'}>Option 4</option>
                        <option selected={venueSpaceValue === 'Option 5'}>Option 5</option>
                    </select> */}
                    {/* <SelectSearch
                        ref={venueSpaceRef}
                        autoComplete search={true}
                        closeOnSelect={true}
                        filterOptions={ fuzzySearch }
                        onChange={(evt) => setSpace(evt)}
                        options={spaceOptions }
                        placeholder="Full Venue / Private Space"
                        value={venueSpaceValue}
                        /> */}

{/* <Tooltip classes={{tooltip: classes.tooltip}} title={'Max Capacity'}> */}

                                        <SelectSearch
                        ref={maxCapacityRef}
                        autoComplete
                        closeOnSelect={true}
                        filterOptions={ fuzzySearch }
                        onChange={(evt) => setCapacity(evt)}
                        options={capacityOptions}
                        placeholder="Max Capacity"
                        value={maxCapacityValue}
                        />
                        {/* </Tooltip> */}
                </div>
                <div className="venue-row">
                <p>What types of sessions would you like to make the space available for?</p>
                <SelectSearch
                        ref={venueSpaceRef}
                        autoComplete
                        closeOnSelect={true}
                        filterOptions={ fuzzySearch }
                        onChange={(evt) => setSpace(evt)}
                        options={rentalOptions}
                        placeholder="Rental Type"
                        value={venueSpaceValue}
                        />

                </div>
                        <h3>Enter your location</h3>
                        <GooglePlacesAutocomplete
                                apiKey="AIzaSyBNVt036Vwj3EMeNMVyTEh9JPqCuZ6xsi8"
                                selectProps={{
                                    value: locValue,
                                    defaultValue: locValue,
                                    onChange: (e) => {
                                        if (e === null) {
                                            return setLocValue('')
                                        }
                                        setLocValue(e); console.log(e); getPlaceCoords(e.label)
                                    },
                                    placeholder: 'Enter your location here...',
                                    isClearable: true,
                                }
                                }
                                ref={placesRef}
                                placeholder={'Enter your location here...'}
                                onLoadFailed={
                                    (e) => console.log(e)
                                }

    />
                {/* <input ref={locationRef} defaultValue={locationValue} onKeyUp={() => {}} type="text" className="location-input" placeholder="Enter Location" /> */}

            </div>

        </div>
    )
}

export default forwardRef(StepOne)
