import React from 'react'
import './FeedItem.css'

function FeedItem({Icon, title, message, time}) {
    return (
        <div>
        <div className="feed-item">
            {Icon && <Icon className="feed-item-icon" />}
            <div className="feed-info-container">
                <h1 className="feed-title">
                    {title}
                </h1>
                <div className="feed-info">
                    <h2 className="feed-message">
                        {message}
                    </h2>
                    <p className="feed-time">
                        {time}
                    </p>
                </div>
            </div>
        </div>
        <hr className="feed-divider" />
        </div>
    )
}

export default FeedItem
