import React, { useState, useEffect, useRef } from 'react'
import './StatsBox.css'

import InfoCard from '../../../Components/Cards/InfoCard'
import SettingsIcon from '@mui/icons-material/Settings';
import StatsSettings from './StatsSettings';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingOverlay from 'react-loading-overlay';


import { db } from '../../../firebase';

function StatsBox({ uid, selectedArray, analytics = false, userRole }) {

    const [showSettings, setShowSettings] = useState(false)
    const [rowOne, setRowOne] = useState([])
    const [rowTwo, setRowTwo] = useState([])
    const [rowThree, setRowThree] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (userRole === 'owner') {
            loadStats()
        }
        if (userRole === 'instructor') {
            loadInstructorStats()
        }
    }, [userRole])

    async function loadStats() {
        setLoading(true)
        const firstOfThisMonth = new Date();
        firstOfThisMonth.setDate(1);
        firstOfThisMonth.setHours(0);

        const firstOfLastMonth = new Date();
        firstOfLastMonth.setMonth(firstOfLastMonth.getMonth() - 1);
        firstOfLastMonth.setDate(1);
        firstOfLastMonth.setHours(0);


        const userDoc = await db.collection('users').doc(uid).get();
        const user = userDoc.data();
        const selectedMetrics = user.selectedMetrics ?? [];
        const facilityIDs = user.facilityIDs ?? [];
        // Total Bookings
        let totalBookingsThisMonth = 0;
        let totalBookingsLastMonth = 0;
        let totalBookings = 0;

        // Total Clients
        let totalClientsThisMonthArray = [];
        let totalClientsLastMonthArray = [];
        let totalClientsArray = [];

        // Total Available Hours
        let totalAvailableHoursThisMonth = 0;
        let totalAvailableHoursLastMonth = 0;

        // Total Booked Hours
        let totalBookedHoursThisMonth = 0;
        let totalBookedHoursLastMonth = 0;

        // Total Revenue
        let totalRevenueLastMonth = 0;
        let totalRevenueThisMonth = 0;

        // Total Capacity
        let totalCapacityThisMonth = 0;
        let totalCapacityLastMonth = 0;

        // Bulk Credits Sold
        let totalCreditsSoldThisMonth = 0;
        let totalCreditsSoldLastMonth = 0;

        let totalCreditBookingsThisMonth = 0;
        let totalCreditBookingsLastMonth = 0;

        // Bulk Credits not spent
        const instructorsWhoPurchased = [];
        let unspentCredits = 0;
        let totalUnspentCredits = 0;


        // Days made available
        let availableThisMonth = 0;
        let availableLastMonth = 0;
        const daysAvailableThisMonth = [];
        const daysAvailableLastMonth = [];


        for (let i = 0; i < facilityIDs.length; i++) {
            const facilityID = facilityIDs[i];
            const facilityDoc = await db.collection('facilities').doc(facilityID).get();
            const facility = facilityDoc.data();
            const allFacilityBookings = await db.collection('facilities').doc(facilityID).collection('calendarEvents')
                .where('type', '==', 'booked')
                .get()
            const thisMonthFacilityBookings = await db.collection('facilities').doc(facilityID).collection('calendarEvents')
                .where('type', '==', 'booked')
                .where('start', '>=', firstOfThisMonth)
                .get();
            const lastMonthFacilityBookings = await db.collection('facilities').doc(facilityID).collection('calendarEvents')
                .where('type', '==', 'booked')
                .where('start', '>=', firstOfLastMonth)
                .where('start', '<=', firstOfThisMonth)
                .get();
            const thisMonthAvailableSessions = await db.collection('facilities').doc(facilityID).collection('calendarEvents')
                .where('type', '==', 'available')
                .where('start', '>=', firstOfThisMonth)
                .get();
            const lastMonthAvailableSessions = await db.collection('facilities').doc(facilityID).collection('calendarEvents')
                .where('type', '==', 'available')
                .where('start', '>=', firstOfLastMonth)
                .where('start', '<=', firstOfThisMonth)
                .get();
            // const sessionsPaidWithCreditsThisMonth = await db.collection('facilities').doc(facilityID).collection('calendarEvents')
            //     .where('paidWithCredits', '==', true)
            //     .where('start', '>=', firstOfThisMonth)
            //     .get()
            // const sessionsPaidWithCreditsLastMonth = await db.collection('facilities').doc(facilityID).collection('calendarEvents')
            //     .where('paidWithCredits', '==', true)
            //     .where('start', '>=', firstOfLastMonth)
            //     .get()
            const creditsBoughtThisMonth = await db.collection('facilities').doc(facilityID).collection('bulkPurchases')
                .where('date', '>=', firstOfThisMonth)
                .get()
                .catch(err => console.log(err))
            const creditsBoughtLastMonth = await db.collection('facilities').doc(facilityID).collection('bulkPurchases')
                .where('date', '>=', firstOfLastMonth)
                .where('date', '<=', firstOfThisMonth)
                .get()
                .catch(err => console.log(err))

            // Credit Sales
            for (let j = 0; j < creditsBoughtThisMonth.docs.length; j++) {
                const purchase = creditsBoughtThisMonth.docs[j].data();
                totalCreditsSoldThisMonth += purchase.amount;
                if (!instructorsWhoPurchased.includes(purchase.instructorID)) {
                    instructorsWhoPurchased.push(purchase.instructorID)
                }
            }
            for (let j = 0; j < creditsBoughtLastMonth.docs.length; j++) {
                const purchase = creditsBoughtLastMonth.docs[j].data();
                totalCreditsSoldLastMonth += purchase.amount;
                if (!instructorsWhoPurchased.includes(purchase.instructorID)) {
                    instructorsWhoPurchased.push(purchase.instructorID)
                }
            }

            // Credits outstanding
            for (let j = 0; j < instructorsWhoPurchased.length; j++) {
                const instructorID = instructorsWhoPurchased[j];
                const instructorDoc = await db.collection('users').doc(instructorID).get();
                const instructor = instructorDoc.data();
                const {facilityCredits} = instructor;
                const outstandingWithFacility = facilityCredits[facilityID];
                unspentCredits += outstandingWithFacility;
            }

            // Total Bookings this / last month & overall
            totalBookingsThisMonth += thisMonthFacilityBookings.docs.length;
            totalBookingsLastMonth += lastMonthFacilityBookings.docs.length;
            totalBookings += allFacilityBookings.docs.length;
            // This Month Bookings
            for (let j = 0; j < thisMonthFacilityBookings.docs.length; j++) {
                const booking = thisMonthFacilityBookings.docs[j].data();
                const {instructorID, rate, total} =  booking;
                const paidWithCredits = booking.paidWitCredits ?? false;

                // Income
                totalRevenueThisMonth += total;

                // Client Array
                if (!totalClientsThisMonthArray.includes(instructorID)) {
                    totalClientsThisMonthArray.push(instructorID);
                }
                if (!totalClientsArray.includes(instructorID)) {
                    totalClientsArray.push(instructorID);
                }

                // Booked Hours
                const ratio = (total / rate)
                totalBookedHoursThisMonth += ratio;

                // Credits
                if (paidWithCredits) {
                    totalCreditBookingsThisMonth += total;
                }

            }

            // Last Month Bookings
            for (let j = 0; j < lastMonthFacilityBookings.docs.length; j++) {
                const booking = lastMonthFacilityBookings.docs[j].data();
                const {instructorID, rate, total} =  booking;
                const paidWithCredits = booking.paidWitCredits ?? false;

                // Income
                totalRevenueLastMonth += total;

                // Client Array
                if (!totalClientsLastMonthArray.includes(instructorID)) {
                    totalClientsLastMonthArray.push(instructorID);
                }
                if (!totalClientsArray.includes(instructorID)) {
                    totalClientsArray.push(instructorID);
                }

                // Booked Hours
                totalBookedHoursLastMonth += (total / rate);

                // Credits
                if (paidWithCredits) {
                    totalCreditBookingsThisMonth += total;
                }

            }
            // This Month Available
            for (let j = 0; j < thisMonthAvailableSessions.docs.length; j++) {
                const booking = thisMonthAvailableSessions.docs[j].data();
                const start = new Date(booking.start.toDate());
                const end = new Date(booking.end.toDate());
                const dom = start.getDate()
                const capacity = booking.capacity ?? 5;

                if (!daysAvailableThisMonth.includes(dom)) {
                    daysAvailableThisMonth.push(dom);
                    availableThisMonth += 1;
                }

                // Available Hours
                const timeDiff = end.getHours() - start.getHours();
                totalAvailableHoursThisMonth += (timeDiff * capacity);
                totalCapacityThisMonth += capacity;
            }

            // Last Month Available
            for (let j = 0; j < lastMonthAvailableSessions.docs.length; j++) {
                const booking = lastMonthAvailableSessions.docs[j].data();
                const start = new Date(booking.start.toDate());
                const end = new Date(booking.end.toDate());
                const capacity = booking.capacity ?? 5;
                const dom = start.getDate()

                if (!daysAvailableLastMonth.includes(dom)) {
                    daysAvailableLastMonth.push(dom);
                    availableLastMonth += 1;
                }

                // Available Hours
                const timeDiff = end.getHours() - start.getHours();
                totalAvailableHoursLastMonth += (timeDiff * capacity);
                totalCapacityLastMonth += capacity;
            }

        // Unspent credits
            const instructorCredits = facility.instructorCredits ?? {};
            for (let [key, value] of Object.entries(instructorCredits)) {
                totalUnspentCredits += value;
              }
        }

        // Percentage Changes
        let bookingChangeThisMonth = 100 - Math.round((totalBookingsThisMonth / totalBookingsLastMonth) * 100 )
        if (isNaN(bookingChangeThisMonth) || bookingChangeThisMonth === Infinity || bookingChangeThisMonth === -Infinity) {
            bookingChangeThisMonth = 0;
        }
        let totalBookingChangeThisMonth = Math.round((totalBookingsLastMonth / (totalBookings - totalBookingsThisMonth)) * 100)
        if (isNaN(totalBookingChangeThisMonth)) {
            totalBookingChangeThisMonth = 0;
        }
        let totalClientsChange = 100 - Math.round((totalClientsThisMonthArray.length / totalClientsLastMonthArray.length) * 100);
        if (isNaN(totalClientsChange) || totalClientsChange === Infinity || totalClientsChange === -Infinity) {
            totalClientsChange = 0;
        }
        let totalClientsChangeThisMonth = 100 - Math.round((totalClientsLastMonthArray.length / (totalClientsArray.length)) * 100);
        if (isNaN(totalClientsChangeThisMonth) || totalClientsChangeThisMonth === Infinity || totalClientsChangeThisMonth === -Infinity) {
            totalClientsChangeThisMonth = 0;
        }
        // console.log(totalBookedHoursLastMonth)
        let occupancyThisMonth = Math.round((totalBookedHoursThisMonth / totalAvailableHoursThisMonth) * 100);
        let occupancyLastMonth = Math.round((totalBookedHoursLastMonth / totalAvailableHoursLastMonth) * 100);
        let occupancyChange = 100 - Math.round((occupancyThisMonth / occupancyLastMonth) * 100);
        if (isNaN(occupancyChange)) {
            occupancyChange = 0;
        }
        if (isNaN(occupancyLastMonth)) {
            occupancyLastMonth = 0;
        }
        if (isNaN(occupancyThisMonth)) {
            occupancyThisMonth = 0;
        }
        let capacityTakenThisMonth = Math.round((totalClientsThisMonthArray.length / totalCapacityThisMonth) * 100);
        let capacityTakenLastMonth = Math.round((totalClientsLastMonthArray.length / totalCapacityLastMonth) * 100);
        let capacityChange = Math.round((capacityTakenThisMonth / capacityTakenLastMonth) * 100);
        if (isNaN(capacityTakenThisMonth)) {
            capacityTakenThisMonth = 0;
        }
        if (isNaN(capacityTakenLastMonth)) {
            capacityTakenLastMonth = 0;
        }
        if (isNaN(capacityChange)) {
            capacityChange = 0;
        }
        // console.log(`total booings this month is ${totalBookingsThisMonth}`)
        let revenueChangeThisMonth = 100 - Math.round((totalRevenueThisMonth / totalRevenueLastMonth) * 100);
        if (isNaN(revenueChangeThisMonth) || revenueChangeThisMonth === Infinity || revenueChangeThisMonth === -Infinity) {
            revenueChangeThisMonth = 0;
        }
        let revenuePerBookingThisMonth = Math.round((totalRevenueThisMonth / totalBookingsThisMonth));
        if (isNaN(revenuePerBookingThisMonth)) {
            revenuePerBookingThisMonth = 0;
        }
        let revenuePerBookingLastMonth = Math.round((totalRevenueLastMonth / totalBookingsLastMonth));
        if (isNaN(revenuePerBookingLastMonth)) {
            revenuePerBookingLastMonth = 0;
        }
        let revenuePerBookingChange = 100 - Math.round((revenuePerBookingThisMonth / revenuePerBookingLastMonth) * 100);
        if (isNaN(revenuePerBookingChange) || revenuePerBookingChange === Infinity || revenuePerBookingChange === -Infinity) {
            revenuePerBookingChange = 0;
        }

        // Days available
        const daysAvailableChange = 100 - Math.round((availableThisMonth / availableLastMonth) * 100);

        // Bulk purchases
        let creditSaleChangeThisMonth = 100 - Math.round((totalCreditsSoldThisMonth / totalCreditsSoldLastMonth) * 100)
        if (isNaN(creditSaleChangeThisMonth)) {
            creditSaleChangeThisMonth = 0;
        }
        // console.log(unspentCredits)

        // Unspent calculation
        if (isNaN(totalUnspentCredits)) {
            totalUnspentCredits = 0;
        }
        const creditsEndOfLastMonth = totalUnspentCredits + totalCreditBookingsThisMonth - totalCreditsSoldThisMonth;
        let creditsChange = 100 - Math.round((unspentCredits / creditsEndOfLastMonth) * 100);
        if (isNaN(creditsChange)) {
            creditsChange = 0;
        }

        const statsArray = [
            {
                info: 'Bookings (Month)',
                thisMonth: totalBookingsThisMonth,
                lastMonth: totalBookingsLastMonth,
                change: bookingChangeThisMonth,
            },
            {
                info: 'Bookings (All Time)',
                thisMonth: totalBookings,
                lastMonth: totalBookingsThisMonth,
                change: totalBookingChangeThisMonth,
            },
            {
                info: 'Total Clients (Month)',
                thisMonth: totalClientsThisMonthArray.length,
                lastMonth: totalClientsLastMonthArray.length,
                change: totalClientsChange,
            },
            {
                info: 'Clients (All Time)',
                thisMonth: totalClientsArray.length,
                lastMonth: totalClientsThisMonthArray.length,
                change: totalClientsChangeThisMonth,
            },
            {
                info: 'Occupancy (Month)',
                thisMonth: `${occupancyThisMonth}%`,
                lastMonth: `${occupancyLastMonth}%`,
                change: `${occupancyChange}`,
            },
            {
                info: 'Capacity Taken',
                thisMonth: `${capacityTakenThisMonth}%`,
                lastMonth: `${capacityTakenLastMonth}%`,
                change: capacityChange,
            },
            {
                info: 'Total Revenue',
                thisMonth: totalRevenueThisMonth,
                lastMonth: totalRevenueLastMonth,
                change: revenueChangeThisMonth,
            },
            {
                info: 'Revenue / Booking',
                thisMonth: revenuePerBookingThisMonth,
                lastMonth: revenuePerBookingLastMonth,
                change: revenuePerBookingChange,
            },
            {
                info: 'Credits Sold',
                thisMonth: totalCreditsSoldThisMonth,
                lastMonth: totalCreditsSoldLastMonth,
                change: creditSaleChangeThisMonth,
            },
            {
                info: 'Unused Credits',
                thisMonth: totalUnspentCredits,
                lastMonth: creditsEndOfLastMonth,
                change: creditsChange,
            },
            {
                info: 'Days Avaiable',
                thisMonth: availableThisMonth,
                lastMonth: availableLastMonth,
                change: daysAvailableChange
            }
        ]

        const arrayOne = [];
        const arrayTwo = [];
        const arrayThree = []

        if (analytics) {
            for (let i = 0; i < 9; i++) {
                const stat = statsArray[i];
                if (i === 0 || i === 3 || i === 6) {
                    stat['type'] = 'general';
                }
                if (i === 1 || i === 4 || i === 7) {
                    stat['type'] = 'general';
                }
                if (i === 2 || i === 5 || i === 8) {
                    stat['type'] = 'general';
                }

                if (i >= 0 && i < 3) {
                    arrayOne.push(stat)
                }
                if (i >= 3 && i < 6) {
                    arrayTwo.push(stat)
                }
                if (i >= 6 && i < 9) {
                    arrayThree.push(stat)
                }
            }
        } else {
            for (let i = 0 ; i < statsArray.length; i++) {
                const stat = statsArray[i];
                if ((arrayOne.length + arrayTwo.length) === 0 || (arrayOne.length + arrayTwo.length) === 3) {
                    stat['type'] = 'general';
                }
                if ((arrayOne.length + arrayTwo.length) === 1 || (arrayOne.length + arrayTwo.length) === 4) {
                    stat['type'] = 'general';
                }
                if ((arrayOne.length + arrayTwo.length)=== 2 || (arrayOne.length + arrayTwo.length) === 5) {
                    stat['type'] = 'general';
                }
                if (selectedMetrics.includes(stat.info)) {
                    if (arrayOne.length < 3) {
                        arrayOne.push(stat)
                    } else {
                        arrayTwo.push(stat)
                    }
                }
            }
        }


        setRowOne(arrayOne);
        setRowTwo(arrayTwo);
        setRowThree(arrayThree);
        // console.log(arrayTwo)
        setLoading(false)


    }

    async function loadInstructorStats() {
        setLoading(true)
        const firstOfThisMonth = new Date();
        firstOfThisMonth.setDate(1);
        firstOfThisMonth.setHours(0);

        const firstOfLastMonth = new Date();
        firstOfLastMonth.setMonth(firstOfLastMonth.getMonth() - 1);
        firstOfLastMonth.setDate(1);
        firstOfLastMonth.setHours(0);

        // Variables
        // Total Sessions - 1 on 1
        let totalSessionsThisMonth = 0;
        let totalSessionsLastMonth = 0;
        let totalSessions = 0;

        // Total Sessions - Classes
        let totalClassesThisMonth = 0;
        let totalClassesLastMonth = 0;
        let totalClasses = 0;

        // Total Income - 1 on 1
        let totalIncomeThisMonth = 0;
        let totalIncomeLastMonth = 0;
        let totalIncome = 0;

        // Total Income - classes
        let totalClassesIncomeThisMonth = 0;
        let totalClassesIncomeLastMonth = 0;
        let totalClassesIncome = 0;

        // Total Bookings
        let totalBookingsThisMonth = 0;
        let totalBookingsLastMonth = 0;
        let totalBookings = 0;

        // Total Spend
        let totalSpendThisMonth = 0;
        let totalSpendLastMonth = 0;
        let totalSpend = 0;

        // Total Clients - 1 on 1
        let totalClientsThisMonthArray = [];
        let totalClientsLastMonthArray = [];
        let totalClientsArray = [];

        // Total Clients - Classes
        let totalClassesClientsThisMonthArray = [];
        let totalClassesClientsLastMonthArray = [];
        let totalClassesClientsArray = [];

        // Total Credits Sold
        let totalCreditsSoldLastMonth = [];
        let totalCreditsSoldThisMonth = [];
        //

        // Sessions
        const allSessions = await db.collection('sessions')
            .where('instructorID', '==', uid)
            .where('status', '==', 'completed')
            .get()
        totalSessions = allSessions.docs.length
        const sessionsLastMonth = await db.collection('sessions')
            .where('instructorID', '==', uid)
            .where('status', '==', 'completed')
            .where('dateTimeStamp', '>=', firstOfLastMonth)
            .where('dateTimeStamp', '<=', firstOfThisMonth)
            .get()
            .catch(err => console.log(err));
        const sessionsThisMonth = await db.collection('sessions')
            .where('instructorID', '==', uid)
            .where('status', '==', 'completed')
            .where('dateTimeStamp', '>=', firstOfThisMonth)
            .get()
            .catch(err => console.log(err));

        // Bookings
        const bookingsLastMonth = await db.collection('bookings')
            .where('instructorID', '==', uid)
            .where('type', '==', 'booked')
            .where('dateTimeStamp', '>=', firstOfLastMonth)
            .where('dateTimeStamp', '<=', firstOfThisMonth)
            .get()
            .catch(err => console.log(err));

        const bookingsThisMonth = await db.collection('sessions')
            .where('instructorID', '==', uid)
            .where('type', '==', 'booked')
            .where('dateTimeStamp', '>=', firstOfThisMonth)
            .get()
            .catch(err => console.log(err));

        // Last month session
        for (let i = 0; i < sessionsLastMonth.docs.length; i++) {
            const session = sessionsLastMonth.docs[i].data();
            const {cost, athleteID, isGroupSession} = session;

            if (isGroupSession) {
                totalClassesLastMonth += 1;
                totalClassesIncomeLastMonth += cost;
                for (let j = 0; j < athleteID.length; j++) {
                    if (!totalClientsLastMonthArray.includes(athleteID[j])) {
                        totalClientsLastMonthArray.push(athleteID[j])
                    }
                }
            } else {
                totalSessionsLastMonth += 1;
                totalIncomeLastMonth += cost;
                if (!totalClientsLastMonthArray.includes(athleteID)) {
                    totalClientsLastMonthArray.push(athleteID)
                }

            }
        }

        // This month sessions
        for (let i = 0; i < sessionsThisMonth.docs.length; i++) {
            const session = sessionsThisMonth.docs[i].data();
            const {cost, athleteID, isGroupSession} = session;

            if (isGroupSession) {
                totalClassesIncomeThisMonth += cost;
                for (let j = 0; j < athleteID.length; j++) {
                    if (!totalClientsThisMonthArray.includes(athleteID[j])) {
                        totalClientsThisMonthArray.push(athleteID[j])
                    }
                }
            } else {
                totalIncomeThisMonth += cost;
                if (!totalClientsThisMonthArray.includes(athleteID)) {
                    totalClientsThisMonthArray.push(athleteID)
                }

            }
        }

        // Last month bookings
        for (let i = 0; i < bookingsLastMonth.docs.length; i++) {
            const booking = bookingsLastMonth.docs[i].data();
            const {total} = booking;

            totalBookingsLastMonth += 1;
            totalSpendLastMonth += total;

        }

        // This month bookings
        for (let i = 0; i < bookingsThisMonth.docs.length; i++) {
            const booking = bookingsThisMonth.docs[i].data();
            const {total} = booking;

            totalBookingsThisMonth += 1;
            totalSpendThisMonth += total;

        }

        // Total Income - 1 on 1s
        let totalIncomeChange = 100 - Math.round((totalIncomeThisMonth / totalIncomeLastMonth) * 100)
        if (isNaN(totalIncomeChange)) {
            totalIncomeChange = 0;
        }

        // Total Income - Classes
        let totalClassesIncomeChange = 100 - Math.round((totalClassesIncomeThisMonth / totalBookingsLastMonth) * 100)
        if (isNaN(totalClassesIncomeChange)) {
            totalClassesIncomeChange = 0;
        }

        // All time sessions
        const totalSessionsPercentage = Math.round((totalSessionsLastMonth / totalSessions) * 100) / 100;

        // Total Sessions - 1 on 1s
        let totalSessionChange = 100 - Math.round((totalSessionsThisMonth / totalSessionsLastMonth) * 100)
        if (isNaN(totalSessionChange)) {
            totalSessionChange = 0;
        }

        // Total Sessions - Classes
        let totalClassesChange = 100 - Math.round((totalClassesThisMonth / totalClassesLastMonth) * 100)
        if (isNaN(totalClassesChange)) {
            totalClassesChange = 0;
        }

        // Total Clients - 1 on 1s
        let totalClientsChange = 100 - Math.round((totalClientsThisMonthArray.length / totalClientsLastMonthArray.length) * 100)
        if (isNaN(totalClientsChange)) {
            totalClientsChange = 0;
        }

        // Total Clients - Classes
        let totalClassesClientsChange = 100 - Math.round((totalClassesClientsThisMonthArray.length / totalClassesClientsLastMonthArray))
        if (isNaN(totalClassesClientsChange)) {
            totalClassesClientsChange = 0;
        }

        // Total Bookings
        let totalBookingsChange = 100 - Math.round((totalBookingsThisMonth / totalBookingsLastMonth) * 100);
        if (isNaN(totalBookingsChange)) {
            totalBookingsChange = 0;
        }

        // Total Spend
        let totalSpendChange = 100 - Math.round((totalSpendThisMonth / totalSpendLastMonth) * 100);
        if (isNaN(totalSpendChange)) {
            totalSpendChange = 0;
        }

        // Credits Outstanding


        const statsArray = [
            {
                info: 'Income (1 on 1)',
                thisMonth: totalIncomeThisMonth,
                lastMonth: totalIncomeLastMonth,
                change: totalIncomeChange,
                icon: 'money',
            },
            {
                info: 'Income (Groups)',
                thisMonth: totalClassesIncomeThisMonth,
                lastMonth: totalClassesIncomeLastMonth,
                change: totalClassesIncomeChange,
                icon: 'money',
            },
            {
                info: 'Sessions (All Time)',
                thisMonth: totalSessions,
                lastMonth: totalSessionsLastMonth,
                change: totalSessionsPercentage,
                icon: 'session',
            },
            {
                info: 'Sessions (1 0n 1)',
                thisMonth: totalSessionsThisMonth,
                lastMonth: totalSessionsLastMonth,
                change: totalSessionChange,
                icon: 'session',
            },
            {
                info: 'Sessions (Classes)',
                thisMonth: totalClassesThisMonth,
                lastMonth: totalClassesLastMonth,
                change: totalClassesChange,
                icon: 'group',
            },
            {
                info: 'Clients (1 on 1)',
                thisMonth: totalClientsThisMonthArray.length,
                lastMonth: totalClientsLastMonthArray.length,
                change: totalClientsChange,
                icon: 'person',
            },
            {
                info: 'Clients (Classes)',
                thisMonth: totalClassesClientsThisMonthArray.length,
                lastMonth: totalClassesClientsLastMonthArray.length,
                change: totalClassesClientsChange,
                icon: 'people',
            },
            {
                info: 'Total Bookings',
                thisMonth: totalBookingsThisMonth,
                lastMonth: totalBookingsLastMonth,
                change: totalBookingsChange,
                icon: 'booking',
            },
            {
                info: 'Total Spend',
                thisMonth: totalSpendThisMonth,
                lastMonth: totalSpendLastMonth,
                change: totalSpendChange,
                icon: 'money',
            },

        ]

        const arrayOne = [];
        const arrayTwo = [];
        const arrayThree = []

        if (analytics) {
            for (let i = 0; i < 9; i++) {
                const stat = statsArray[i];
                if (i === 0 || i === 3 || i === 6) {
                    stat['type'] = 'general';
                }
                if (i === 1 || i === 4 || i === 7) {
                    stat['type'] = 'general';
                }
                if (i === 2 || i === 5 || i === 8) {
                    stat['type'] = 'general';
                }

                if (i >= 0 && i < 3) {
                    arrayOne.push(stat)
                }
                if (i >= 3 && i < 6) {
                    arrayTwo.push(stat)
                }
                if (i >= 6 && i < 9) {
                    arrayThree.push(stat)
                }
            }
        } else {
            for (let i = 0 ; i < statsArray.length; i++) {
                const stat = statsArray[i];
                if ((arrayOne.length + arrayTwo.length) === 0 || (arrayOne.length + arrayTwo.length) === 3) {
                    stat['type'] = 'general';
                }
                if ((arrayOne.length + arrayTwo.length) === 1 || (arrayOne.length + arrayTwo.length) === 4) {
                    stat['type'] = 'general';
                }
                if ((arrayOne.length + arrayTwo.length)=== 2 || (arrayOne.length + arrayTwo.length) === 5) {
                    stat['type'] = 'general';
                }
                if (arrayOne.length < 3) {
                    arrayOne.push(stat)
                } else if (arrayTwo.length < 3) {
                    arrayTwo.push(stat)
                } else if (arrayThree.length < 3) {
                    arrayThree.push(stat)
                }

            }
        }


        setRowOne(arrayOne);
        setRowTwo(arrayTwo);
        setRowThree(arrayThree);
        setLoading(false)

    }


    return (
        <div className="stats-box-box scale-in-ver-top">
        <LoadingOverlay
        active={loading}
        spinner
        >
        <div className='stats-box'>
            {(!analytics && userRole === 'owner') && <div className="stats-settings-row">
                <SettingsIcon onClick={() => setShowSettings(current => !current)} />
            </div>}
            {showSettings && <StatsSettings loadStats={loadStats} uid={uid} closeSettings={() => setShowSettings(false)} />}

            <h1 style={{marginTop: userRole === 'instructor' ? '20px' : '0px'}}>Key Metrics</h1>
            <div className="stats-box-container">
                <div className="stats-box-row">
                    {rowOne.map((row, index) => <InfoCard daily={false} info={row.info} value={row.thisMonth} type={row.type} lastMonth={row.lastMonth} dir={row.thisMonth > row.lastMonth ? 'up' : 'down'} change={row.change} icon={row.icon}  />)}

                </div>
                <div className="stats-box-row">
                    {rowTwo.map((row, index) => <InfoCard daily={false} info={row.info} value={row.thisMonth} type={row.type} lastMonth={row.lastMonth} dir={row.thisMonth > row.lastMonth ? 'up' : 'down'} change={row.change} icon={row.icon}  />)}

                </div>
                {analytics && <div className="stats-box-row">
                    {rowThree.map((row, index) => <InfoCard daily={false} info={row.info} value={row.thisMonth} type={row.type} lastMonth={row.lastMonth} dir={row.thisMonth > row.lastMonth ? 'up' : 'down'} change={row.change} icon={row.icon}  />)}

                </div>}

                 </div>
        </div>
        </LoadingOverlay>
        </div>
    )
}

export default StatsBox
