import React, { useState, useEffect } from 'react'
import './ConfirmedBookingItem.css'

import BookingDetailModal from '../Calendar/BookingDetailModal'
import InstructorFacilityReviewModal from '../../Components/Review/InstructorFacilityReviewModal';
import moment from 'moment';



// MUI
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeIcon from '@mui/icons-material/Home';
import PaymentIcon from '@mui/icons-material/Payment';

function ConfirmedBookingItem({ reviewID, uid, role, rate, startObj, endObj, imageLink, venue, name, type = 'booking', startDate, endDate, totalPrice, showBooking, calendarID, bookingID, facilityID, instructorID, chatID, openReschedule }) {


    const [showModal, setShowModal] = useState();
    const [showReview, setShowReview] = useState(false)
    const [hideCancel, setHideCancel] = useState(false)
    useEffect(() => {
        const now = moment();
        const startMoment = moment(startObj);


        if (startMoment.isBefore(now)) {
            setHideCancel(true)
        }
    }, []);

    var dateOptions = { year: 'numeric', month: 'long', day: 'numeric'};
    var timeOptions = { hour: '2-digit', minute: '2-digit'}

    const now = new Date();

    if (now > endObj) {
        // console.log(reviewID)
    }

    return (
        <div className='confirmed-booking-item'>
            {showModal && <BookingDetailModal role={role} total={totalPrice} cancelOnly closeModal={() => setShowModal(false)} bookingID={bookingID} calendarID={calendarID} facilityID={facilityID}
               start={startObj} end={endObj} startObj={startObj} endObj={endObj} group={true} session={true} venueName={venue} title={name} type='booked' instructorID={instructorID} chatID={chatID} reloadCalendar={() => {}}
            />}
            {showReview && <InstructorFacilityReviewModal closeModal={() => setShowReview(false)} uid={uid} facilityName={name} />}
            <div className="cb-left-col">
                <img alt="user-img" src={imageLink} />
            </div>
            <div className="pb-right-col">
                <div className="pb-text-container">
                    <div className="pb-text-right">
                    <h1>{startObj.toLocaleDateString('en-us', dateOptions)}</h1>
                    <div className="cb-info-group">
                        <AccessTimeIcon />
                    <p>{startObj.toLocaleTimeString('en-us', timeOptions)} - {endObj.toLocaleTimeString('en-us', timeOptions)}</p>

                    </div>

                    </div>
                    <div className="pb-text-left">
                    <div className="cb-info-group">
                        <PersonIcon />
                                            <p className='booking-name'>{name}</p>

                        </div>

                    {role === 'owner' &&

                    <div className="cb-info-group">
                        <HomeIcon />
                        <p>{venue}</p>
                    </div>}
                    <div className="cb-info-group">
                        <PaymentIcon />
                                                 <p className='money-line'>R {totalPrice}</p>

                        </div>
                    </div>
                </div>
                <div className="pb-button-row">
                <button onClick={showBooking} className="pb-view-button">View</button>
                {(endObj < now && reviewID === '') && <button onClick={() => setShowReview(true)} className="confirm-button saffron-button">
                    Review
                </button>}
                    {(type === 'session' && !hideCancel) && <button onClick={openReschedule} className='cb-reschedule-button'>Reschedule</button>}
                    <button hidden={hideCancel}
                     onClick={() => setShowModal(true)} className="pb-deny-button">Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmedBookingItem
