import React, { useState } from 'react'
import './CertItem.css'

import { db } from '../../../firebase'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteForever from '@mui/icons-material/DeleteForever';


function CertItem({ name, link, status, uid , certs = []}) {

    const [remove, setRemove] = useState(false)

    async function removeCert() {
        const newCerts = certs.filter(cert => cert.imageURL !== link);
        await db.collection('users').doc(uid).collection('confidentialInfo').doc('personalDetails').update({
            certificates: newCerts,
        })
    }

    return (
        <div className='cert-item'>
            <div className="cert-item-left">
                <img alt={name} src={link} />
            </div>
            <div className="cert-item-right">
                <h4>{name}</h4>
                <div className="cert-item-group">
                    <p>Accepted:</p>
                    {status ? <p className='ci-accepted'>True</p> : <p className='ci-not-accepted'>False</p> }
                </div>
                <div className="button-row">
                    <button className="cancel-button">Remove</button>
                    {/* <DeleteForeverIcon /> */}
                </div>
            </div>
        </div>
    )
}

export default CertItem
