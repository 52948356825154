import React from 'react'
import './CompletionModal.css'

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

function CompletionModa({ completionArray = [], closeModal }) {
    return (
        <div className='completion-modal' onClick={(e) => {if (e.target.className === 'completion-modal') {closeModal()}}}>
            <div className="completion-modal-content">
                <h4>My Profile</h4>
                <div className="cm-container mt-20">
                    <div className="cm-group">
                        <p>Basic Info</p>
                        {completionArray.includes('basic-info') ? <DoneIcon className='done-icon' /> : <CloseIcon className='close-icon' />}
                    </div>
                    <div className="cm-group">
                        <p>About</p>
                        {completionArray.includes('about-info') ? <DoneIcon className='done-icon' /> : <CloseIcon className='close-icon' />}
                    </div>
                    <div className="cm-group">
                        <p>Qualifications</p>
                        {completionArray.includes('cert-info') ? <DoneIcon className='done-icon' /> : <CloseIcon className='close-icon' />}
                    </div>
                    <div className="cm-group">
                        <p>Skills & Rates</p>
                        {completionArray.includes('skills-info') ? <DoneIcon className='done-icon' /> : <CloseIcon className='close-icon' />}
                    </div>
                    <div className="cm-group">
                        <p>Payment Info</p>
                        {completionArray.includes('bank-info') ? <DoneIcon className='done-icon' /> : <CloseIcon className='close-icon' />}
                    </div>
                </div>
                <button onClick={closeModal}>Dismiss</button>
            </div>
        </div>
    )
}

export default CompletionModa
