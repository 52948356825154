import React from 'react'
import './StatementRow.css'

function StatementRow({startDate, endDate, year, link}) {
    return (
        <div className="statement-row">
            <p>Weekly Statement for period {startDate} - {endDate} {year} <a href={link} target='blank'>can be found here</a></p>
        </div>
    )
}

export default StatementRow
