import React, { useState, useEffect } from 'react'
import './CreditsRecon.css'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BookingItem from '../Analytics/Clients/BookingItem';
import CreditPurchase from './CreditPurchase';

function CreditsRecon({ credits, purchases = [], bookings = [], goBack, role }) {

    const [selectedMenu, setSelectedMenu] = useState('overview')

    const [totalPurchased, setTotalPurchased] = useState(0)
    const [totalUsed, setTotalUsed] = useState(0)

    useEffect(() => {
        calcs()
    }, [])

    function calcs() {
        console.log(purchases, bookings)

        // Purchased
        let purch = 0;
        for (let i = 0; i < purchases.length; i++) {
            if (purchases[i].facilityID === credits.facilityID) {
                purch += purchases[i].amount

            }
        }

        setTotalPurchased(purch)

        let used = 0;
        // Used
        for (let i = 0; i < bookings.length; i++) {
            if (bookings[i].facilityID === credits.facilityID) {
                used += bookings[i].total;

            }
        }
        setTotalUsed(used)

    }

  return (
    <div className='credits-recon'>
                    <div onClick={() => goBack()} className="back-row">
                <ArrowBackIcon />
                <p>Back</p>
            </div>
        <div className="cr-header">
            <h2>Credits Overview: {credits.listingName}</h2>
        </div>

        <div style={{marginLeft: '20px'}} className="selected-client-menu">
                        <button onClick={() => setSelectedMenu('overview')} className={selectedMenu === 'overview' ? 'active-client-menu-button left-button' : 'left-button'} >Overview</button>
                        <button onClick={() => setSelectedMenu('purchases')} className={selectedMenu === 'purchases' ? 'active-client-menu-button' : ''}>Purchases</button>
                        <button onClick={() => setSelectedMenu('bookings')} className={selectedMenu === 'bookings' ? 'active-client-menu-button right-button' : 'right-button'}>Bookings</button>
                    </div>


        {selectedMenu === 'overview' && <div className='credits-overview'>

                <div className="co-item">
                <h3>Current Balance:</h3>
                <h3>R {credits.outstanding}</h3>
                </div>

                <div className="co-item">
                    <p className='fixed-pee'>Total Purchased:</p>
                    <p>R {totalPurchased}</p>
                </div>
                <div className="co-item">
                    <p className='fixed-pee'>Total Used:</p>
                    <p>R {totalUsed}</p>
                </div>
        </div> }

        {selectedMenu === 'purchases' && <div className='credits-purchases'>
            {purchases.map(purchase => {
                if (purchase.facilityID !== credits.facilityID) {
                    return null
                }

                return <CreditPurchase date={purchase.purchaseDate} amount={purchase.amount} />
            })}
            </div>}

        {selectedMenu === 'bookings' && <div className='credits-bookings'>
            {bookings.map(booking => {
            if (booking.facilityID !== credits.facilityID) {
                return null
            }
            return <BookingItem userRole={role} type='hourly' rate={booking.rate} total={booking.total} start={booking.date} end={booking.end} venue={credits.listingName} />})}
        </div>}
    </div>
  )
}

export default CreditsRecon