import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router';
import './ProfileEditPage.css'

import { useAuth } from '../../contexts/AuthContext';
import { db, storage } from '../../firebase';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloudUpload from '@mui/icons-material/CloudUpload';

import LoadingOverlay from 'react-loading-overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

import {useDropzone} from 'react-dropzone';
import { Avatar } from '@material-ui/core';

function ProfileEditPage() {

    // Dropzone
    const {getRootProps, getInputProps} = useDropzone({
        // accept: 'image/*',
        onDrop: acceptedFiles => {
            const newFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
              }));
        //   setFiles(old => [...old, newFiles]);
        // for (let i = 0; i < profPicRef.current.length; i++) {
        //     newFiles.push(profPicRef.current[i])
        // }
        console.log(newFiles);
        profPicRef.current.src = newFiles[0].preview;
        setProfileImage(newFiles[0])
        }
      });

    // Auth
    const {currentUser} = useAuth();

    // History
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            setCurrentUserID(currentUser.uid);
            loadDetails(currentUser.uid);
        }
    }, [currentUser])

    // Refs
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const cellRef = useRef();
    const dobRef = useRef();
    const idRef = useRef();
    const ppRef = useRef();
    const bankNameRef = useRef();
    const bankBranchNameRef = useRef();
    const bankAccRef = useRef();
    const bankBranchCodeRef = useRef();
    const aboutRef = useRef();
    const imageNameRef = useRef();
    const dragRef = useRef();
    const profPicRef = useRef();
    const fileRef = useRef()
    const vatRef = useRef();

    // State
    const [errorMessage, setErrorMessage] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentUserID, setCurrentUserID] = useState('');
    const [ppImage, setPPImage] = useState('')
    const [imgUrl, setImgUrl] = useState('')

    // Load details
    async function loadDetails(uid) {
        const profile = await db.collection('users').doc(uid).get();
        const doc = profile.data();

        firstNameRef.current.defaultValue = doc.firstName ?? '';
        lastNameRef.current.defaultValue = doc.lastName ?? '';
        // emailRef.current.defaultValue = doc.emailAddress ?? '';
        // phoneRef.current.defaultValue = doc.phoneNumber ?? '';
        cellRef.current.defaultValue = doc.cellNumber ?? '';
        const dob = (new Date(doc.dob.toDate())).toISOString();
        dobRef.current.defaultValue = dob;
        idRef.current.defaultValue = doc.idNumber;
        bankNameRef.current.defaultValue = doc.bankName;
        bankBranchNameRef.current.defaultValue = doc.bankBranchName;
        bankAccRef.current.defaultValue = doc.bankAccNum;
        bankBranchCodeRef.current.defaultValue = doc.bankBranchCode;
        aboutRef.current.defaultValue = doc.about;
        profPicRef.current.src = doc.profileImage;
        setImgUrl(doc.profileImage)
        vatRef.current.defaultValue = doc.vatNumber ?? '';
        setLoading(false);

    }

    // Validate input
    function validateInput() {
        // Reset borders
        firstNameRef.current.className = '';
        lastNameRef.current.className = '';
        ppRef.current.className = '';
        cellRef.current.className = '';
        dobRef.current.className = '';
        idRef.current.className = '';
        bankNameRef.current.className = '';
        bankBranchNameRef.current.className = '';
        bankAccRef.current.className = '';
        bankBranchCodeRef.current.className = '';
        aboutRef.current.className = '';
        dragRef.current.classList.remove('red-border')


        // Refs
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        const passport = ppRef.current.value;
        const cell = cellRef.current.value;
        const dob = dobRef.current.value;
        const id = idRef.current.value;
        const bankName = bankNameRef.current.value;
        const bankBranchName = bankBranchNameRef.current.value;
        const bankAccNum = bankAccRef.current.value;
        const bankBranchCode = bankBranchCodeRef.current.value;
        const about = aboutRef.current.value;

        // First Name
        if (firstName === '') {
            firstNameRef.current.className = 'red-border';
            return false;
        }

        // Last Name
        if (lastName === '') {
            lastNameRef.current.className = 'red-border';
            return false;
        }

        // Cell
        if (cell === '') {
            cellRef.current.className = 'red-border';
            return false;
        }

        // DOB
        if (dob === '') {
            dobRef.current.className = 'red-border';
            return false;
        }

        // ID
        if (id === '' || id.length !== 13) {
            firstNameRef.current.className = 'red-border';
            return false;
        }

        // ID & Passport
        if (passport === '' && id === '') {
            idRef.current.className = 'red-border'
            ppRef.current.className = 'red-border'
            return false;
        }

        // Passport
        if (passport !== '' && ppImage === '') {
            ppRef.current.className = 'red-border'
            return false;
        }

        // Bank Name
        if (bankName === '') {
            bankNameRef.current.className = 'red-border';
            return false;
        }

        // Branch Name
        if (bankBranchName === '') {
            bankBranchNameRef.current.className = 'red-border';
            return false;
        }

        // Account Number
        if (bankAccNum === '') {
            bankAccRef.current.className = 'red-border';
            return false;
        }

        // Branch Code
        if (bankBranchCode === '') {
            bankBranchCodeRef.current.className = 'red-border';
            return false;
        }

        // About
        if (about === '' || about.length < 40) {
            aboutRef.current.className = 'red-border';
            return false;
        }

        // Image
        if (profileImage === '') {
            console.log('ello')
            dragRef.current.classList.add('red-border')
            return false;
        }


    }

// Upload data to firestore
async function uploadData() {
    setLoading(true);
    // Refs
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;
    var emailAddress = emailRef.current.value;
    const phoneNumber = phoneRef.current.value;
    const cellNumber = cellRef.current.value;
    var dob = dobRef.current.value;
    dob = new Date(dob);
    const idNumber = idRef.current.value;
    const bankName = bankNameRef.current.value;
    const bankBranchName = bankBranchNameRef.current.value;
    const bankAccNum = bankAccRef.current.value;
    const bankBranchCode = bankBranchCodeRef.current.value;
    const about = aboutRef.current.value;
    const vatNumber = vatRef.current.value;


    const validated = validateInput();
    if (validated === false) {
        setLoading(false);
        return setErrorMessage('Please ensure all fields are filled in correctly')
    }

    // If email is blank, use account address
    if (emailAddress === '') {
        emailAddress = currentUser.email;
    }


    // Upload Image
    let passportImageLink = '';
    if (ppImage !== '') {
        const ppStorageRef = storage.ref().child(`passport-images/${currentUser.uid}`);
        const task = ppStorageRef.put(profileImage);

        task.on('state changed',
            (snapshot) => {
                var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(percentage);
            },
            (err) => {
                setLoading(false);
                setErrorMessage('Error uploading images, please refresh the page and try again')
            },
            () => {
                task.snapshot.ref.getDownloadURL((link) => {
                    passportImageLink = link
                })
            }
        )
    }

    // Upload Image
    const storageRef = storage.ref().child(`profile-images/${currentUser.uid}`);
        const task = storageRef.put(profileImage);

        task.on('state changed',
        (snapshot) => {
            var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(percentage);
        },
        (err) => {
            setLoading(false);
            setErrorMessage('Error uploading images, please refresh the page and try again')
        },
        () => {
            task.snapshot.ref.getDownloadURL().then((profileImage) => {
            console.log(profileImage);
            // Upload Data
            db.collection('users').doc(currentUser.uid).set({
                role: 'owner',
                firstName,
                lastName,
                emailAddress,
                phoneNumber,
                cellNumber,
                dob,
                idNumber,
                bankName,
                bankBranchName,
                bankAccNum,
                bankBranchCode,
                about,
                profileImage,
                vatNumber
            }).then(() => {
                navigate('/');
            });
        });
        },
        );

}

const {getInputProps: getPPInputProps, getRootProps: getPPRootProps} = useDropzone({
    // accept: 'image/*',
    onDrop: acceptedFiles => {
        const newFiles = acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
          }));
          console.log(newFiles[0])
        setPPImage(newFiles[0]);

    }
  });

    // Drag n drop
    function dragEnter(evt) {
        console.log('stopping');
        evt.stopPropagation();
        evt.preventDefault();
    }

    function dragOver(evt) {
        evt.stopPropagation();
        evt.preventDefault();
    }

    function imageOnDropEvent(evt) {
        console.log('doing')
        evt.preventDefault();
        setErrorMessage('');
        const currentFile = evt.dataTransfer.files[0];
        setProfileImage(currentFile);
        console.log(currentFile);
        imageNameRef.current.innerText = currentFile.name;
    }

    return (
<LoadingOverlay
  active={loading}
  spinner
  text='Loading...'
  >
        <div className="profile-edit">

            <div className="pe-header-container">
                {/* <AccountCircleIcon className="account-icon" /> */}
                <Avatar src={imgUrl} alt='profile' />
                <h1>Edit Profile</h1>
            </div>
            <p className='mt-20'>Fields marked <span className='req-asterisk'>*</span> are required</p>
            <div className="pe-personal-details">
                <h3>Personal Details</h3>
                <div className="pe-input-row">
                    <div className="pe-input-group">
                        <p>First Name<span className='req-asterisk'>*</span></p>
                        <input ref={firstNameRef} type="text" />
                    </div>
                    <div className="pe-input-group">
                        <p>Last Name<span className='req-asterisk'>*</span></p>
                        <input ref={lastNameRef} type="text" />
                    </div>
                </div>
                {/* <div className="pe-input-row">
                    <div className="pe-input-group">
                        <p>Email</p>
                        <input ref={emailRef} type="text" />
                    </div>
                    <div className="pe-input-group">
                        <p>Phone Number</p>
                        <input ref={phoneRef} type="text" />
                    </div>
                </div> */}
                <div className="pe-input-row">
                    <div className="pe-input-group">
                        <p>Cell Number<span className='req-asterisk'>*</span></p>
                        <input ref={cellRef} type="text" />
                    </div>
                    <div className="pe-input-group">
                        <p>Date of birth<span className='req-asterisk'>*</span></p>
                        <input ref={dobRef} type="date" />
                    </div>
                </div>
                <div className="pe-input-row">
                    <div className="pe-input-group">
                        <p>ID Number<span className='req-asterisk'>*</span></p>
                        <input maxLength={13} ref={idRef} type="text" />
                    </div>
                    <div className="pe-input-group">
                        <p>VAT Number<span className='req-asterisk'></span></p>
                        <input ref={vatRef} type="text" />
                    </div>
                </div>
                <div className="pc-input-row">
                <div className="pc-input-group">
                        <p>Passport Number</p>
                        <input maxLength={20} ref={ppRef} type="text" />
                    </div>
                    <div className="pc-input-group">
                    <p>Upload Passport</p>
                    <div {...getPPRootProps({className: 'pp-dropzone'})}>
                                <input {...getPPInputProps()} />
                                <div className='pp-dzu dz-upload-container'>
                                    {/* No image */}
                                    {(ppImage === '') && <>
                                    <CloudUpload />
                                    {/* <p>Upload Passport Image</p> */}
                                    </> }

                                    {ppImage !== '' && <>
                                        {/* <img alt={ppImage.name} src={ppImage.preview} /> */}
                                        <p>{ppImage.name}</p>

                                    </> }

                                </div>
                    </div>
                    </div>


                </div>
            </div>
            <div className="pe-bank-details">
                <h3>Bank Details</h3>
                <div className="pe-input-row">
                    <div className="pe-input-group">
                        <p>Bank Name<span className='req-asterisk'>*</span></p>
                        <input ref={bankNameRef} type="text" />
                    </div>
                    <div className="pe-input-group">
                        <p>Branch Name<span className='req-asterisk'>*</span></p>
                        <input ref={bankBranchNameRef} type="text" />
                    </div>
                </div>
                <div className="pe-input-row">
                    <div className="pe-input-group">
                        <p>Acc Number<span className='req-asterisk'>*</span></p>
                        <input ref={bankAccRef} type="text" />
                    </div>
                    <div className="pe-input-group">
                        <p>Branch Code<span className='req-asterisk'>*</span></p>
                        <input ref={bankBranchCodeRef} type="text" />
                    </div>
                </div>
            </div>
            <div className="pe-personal-details">
                <h3>About You</h3>
                <span>Please write a brief description of yourself and your involvement in the facility<span className='req-asterisk'>*</span></span>
                {/* <span>(Min 40 words)</span> */}
                <textarea ref={aboutRef} rows={10} cols={1} />
            </div>
            <div className="pe-personal-details">
                <h3>Profile Pic</h3>
                <span>Choose a new profile picture</span>
                <p className='drag-drop' ref={imageNameRef}>Drag & Drop or Select</p>
                <div className="profile-img-container">

                {/* <div ref={dragRef} onDragOver={(evt) => dragOver(evt)} onDrop={(evt) => imageOnDropEvent(evt)} onDragEnter={(evt) => dragEnter(evt)}   className="pp-container">
                                    <FontAwesomeIcon className="profile-pic" icon={faUpload} />

                </div> */}
                                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <div className='dz-upload-container'>
                                    <CloudUpload />
                                <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                            </div>
                <img alt='profile-pic' ref={profPicRef} />
                </div>

            </div>
            <div className="pe-button-row">
               <button onClick={uploadData}>Save Changes</button>
            </div>
        </div>
        </LoadingOverlay>
    )
}

export default ProfileEditPage
