import React, { useState, useEffect } from 'react'
import ImageGallery from 'react-image-gallery';
import './ImageCarousel.css'
import "react-image-gallery/styles/css/image-gallery.css";


// Icons
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
function ImageCarousel({ imageArray, showThumb, showFullScreen }) {

  // State
  const [images, setImages] = useState([]);

  function renderLeftNav(onClick, disabled) {
    return (
      <button
        className='image-gallery-custom-left-nav'
        disabled={disabled}
        onClick={onClick}
        style={{marginTop: '25%', marginLeft:'10px', position:'absolute', zIndex:'999',}}
        ><KeyboardArrowLeftIcon /></button>
    )
  }

  useEffect(() => {
    const newArray = [];
    imageArray.forEach((coll) => {
      newArray.push({
        original: coll.link,
        thumbnail: coll.link,
      })
    });
    setImages(newArray);
  }, [])

    // const imagesArray = [
    //     {
    //       original: 'http://lorempixel.com/1000/600/nature/1/',
    //       thumbnail: 'http://lorempixel.com/250/150/nature/2/',
    //     },
    //     {
    //       original: 'http://lorempixel.com/1000/600/nature/2/',
    //       thumbnail: 'http://lorempixel.com/250/150/nature/2/'
    //     },
    //     {
    //       original: 'http://lorempixel.com/1000/600/nature/3/',
    //       thumbnail: 'http://lorempixel.com/250/150/nature/3/'
    //     }
    //   ]

    return (
        <div className="image-carousel">
                  <ImageGallery
                  className="image-gallery"
                  autoPlay={false}
                  showPlayButton={false}
                  showFullscreenButton={showFullScreen ?? false}
                  disableKeyDown={true}
                  items={images}
                  showThumbnails={showThumb}
                  // renderLeftNav={renderLeftNav}
                  />


        </div>
    )
}


export default ImageCarousel
