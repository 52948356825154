import React, { useEffect, useState } from 'react'
import './OwnerAnnualStatement.css'

import { db } from '../../../firebase';

import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';

import LoadingOverlay from 'react-loading-overlay'

function OwnerAnnualStatement({ facilityID, uid, setLoading }) {

    const now = new Date()

    const [yearOptions, setYearOptions] = useState([]);
    const [year, setYear] = useState(now.getFullYear())
    const [months, setMonths] = useState([])

    const [totalIncome, setTotalIncome] = useState([])
    const [sessions, setSessions] = useState([])
    const [sessionIncome, setSessionIncome] = useState([])
    const [classes, setClasses] = useState([]);
    const [classIncome, setClassIncome] = useState([])

    const [totalClients, setTotalClients] = useState([])

    const [totalExpenses, setTotalExpenses] = useState([])
    const [forteFee, setForteFee] = useState([]);
    const [bookings, setBookings] = useState([])
    const [paid, setPaid] = useState([])
    const [facs, setFacs] = useState([])

    const [profit, setProfit] = useState([]);

    const [yearClients, setYearClients] = useState(0)
    const [yearFacs, setYearFacs] = useState(0)

    useEffect(() => {
        loadCalendar();
        loadUserYears()
    }, [])

    useEffect(() => {
        loadFinancials(now.getFullYear())

    }, [facilityID])

    function loadCalendar() {
        const now = new Date();
        const calArray = [];

        for (let i = 0; i < 12; i++) {
            now.setMonth(i);
            calArray.push(now.toLocaleDateString('en-us', { month: 'short'}))
        }

        setMonths(calArray)
    }

    async function loadUserYears() {
        const ownerDoc = await db.collection('users').doc(uid).get();
        const owner = ownerDoc.data();
        const dateJoined = new Date(owner.dateJoinedStamp.toDate());
        const diff = now.getFullYear() - dateJoined.getFullYear();
        const currentYear = now.getFullYear();

        const options = [
            {
                name: currentYear.toString(),
                value: currentYear,
            },
        ];

        for (let i = 1; i < diff + 1; i++) {
            options.push({
                name: (currentYear - i).toString(),
                value: (currentYear - i),
            })
        }

        setYearOptions(options)
    }

    async function loadFinancials(year) {

        setLoading(true)

        const now = new Date();
        console.log(year)
        let startDate, endDate;

        if (now.getFullYear() === year) {
            startDate = new Date();
            startDate.setMonth(0);
            startDate.setDate(1)
            startDate.setHours(0);

            endDate = new Date();
        } else {
            startDate = new Date();
            startDate.setFullYear(year)
            startDate.setMonth(0);
            startDate.setDate(1)
            startDate.setHours(0);

            endDate = new Date();
            endDate.setFullYear(year + 1)
            endDate.setMonth(11);
            endDate.setDate(30)
            endDate.setHours(23);
        }

        const totalIncArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const profitArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const clientArr = [[], [], [], [], [], [], [], [], [], [], [], [], ];
        const allClients = [];
        const feeArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const bookingArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        if (facilityID === 'all') {

            const ownerDoc = await db.collection('users').doc(uid).get();
            const owner = ownerDoc.data();
            const { facilityIDs } = owner;

            for (let j = 0; j < facilityIDs.length; j++) {
                const facID = facilityIDs[j];

                const bookings = await db.collection('bookings')
                .where('facilityID', '==', facID)
                .where('start', '>=', startDate)
                .where('start', '<=', endDate)
                .get();

            for (let i = 0; i < bookings.docs.length; i++) {
                const session = bookings.docs[i].data();
                const { instructorID, total } = session;

                const month = new Date(session.start.toDate()).getMonth();

                totalIncArr[month] += total;
                bookingArr[month] += 1;

                if (!clientArr[month].includes(instructorID)) {
                    clientArr[month].push(instructorID)
                }

                if (!allClients.includes(instructorID)) {
                    allClients.push(instructorID);
                }

            }

            }


        } else if (facilityID !== undefined) {
            const bookings = await db.collection('bookings')
                .where('facilityID', '==', facilityID)
                .where('start', '>=', startDate)
                .where('start', '<=', endDate)
                .get();



            for (let i = 0; i < bookings.docs.length; i++) {
                const session = bookings.docs[i].data();
                const { instructorID, total } = session;

                const month = new Date(session.start.toDate()).getMonth();

                totalIncArr[month] += total;
                bookingArr[month] += 1;

                if (!clientArr[month].includes(instructorID)) {
                    clientArr[month].push(instructorID)
                }

                if (!allClients.includes(instructorID)) {
                    allClients.push(instructorID);
                }

            }

        }

        for (let i = 0; i < profitArr.length; i++) {
            profitArr[i] = totalIncArr[i] - feeArr[i];
        }

        totalIncArr.push(
            totalIncArr.reduce((a, b) => a + b)
        )
        profitArr.push(
            profitArr.reduce((a, b) => a + b)
        )
        feeArr.push(
            totalIncArr.reduce((a, b) => a + b)
        )
        bookingArr.push(
            totalIncArr.reduce((a, b) => a + b)
        )

        setTotalIncome(totalIncArr)
        setBookings(bookingArr)
        setTotalClients(clientArr)
        setProfit(profitArr)
        setForteFee(feeArr)
        setYearClients(allClients.length)

        setLoading(false)
    }


    return (
        <div className='owner-annual-statement'>
                        <div className="ias-header-row">
                <h2>Year Overview</h2>
                <SelectSearch
                  closeOnSelect={true}
                  search={false}
                  placeholder={year.toString()}
                  options={yearOptions}
                  onChange={(value) => {
                    setYear(value);
                    loadFinancials(value)
                  }}
                  />
            </div>
            <div className="oas-table-container">
                <table>
                  <thead>
                      <tr className='month-row'>
                          <th className='start-item'></th>
                          {months.map((month) => <th>{month}</th>)}
                          <th>TOTAL</th>
                      </tr>
                  </thead>
                  <tbody>
                  <tr className='grey-row'>
                          <td className="main-td start-item">
                              INCOME
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td className="sub-td start-item">
                              Total Income
                          </td>
                          {totalIncome.map(val => <td>{val}</td>)}
                      </tr>
                      <tr>
                          <td className="sub-td start-item">
                              Total Bookings
                          </td>
                          {bookings.map(val => <td>{val}</td>)}
                      </tr>

                      <tr>
                          <td className="sub-td start-item">
                              Total Clients
                          </td>
                          {totalClients.map(val => <td>{val.length}</td>)}
                          <td>{yearClients}</td>
                      </tr>
                      <tr className='grey-row'>
                          <td className="main-td start-item">
                              EXPENSES
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                      </tr>
                      <tr>
                          <td className="sub-td start-item">Forte Fee</td>
                          {forteFee.map(val => <td>{val}</td>)}

                      </tr>
                      <tr className='grey-row'>
                          <td className="main-td start-item">
                            PROFIT
                          </td>
                          {profit.map(val => <td>{val}</td>)}
                      </tr>
                  </tbody>
                </table>
            </div>
        </div>
    )
}

export default OwnerAnnualStatement
