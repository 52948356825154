import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import './FacilityProfile.css'

// MUI
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import StarIcon from '@mui/icons-material/Star';
import PeopleIcon from '@mui/icons-material/People';
import PaymentIcon from '@mui/icons-material/Payment';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import GridViewIcon from '@mui/icons-material/GridView';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import SpaIcon from '@mui/icons-material/Spa';
import WcIcon from '@mui/icons-material/Wc';
import ShareIcon from '@mui/icons-material/Share';
import ShowerIcon from '@mui/icons-material/Shower';
import HotTubIcon from '@mui/icons-material/HotTub';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';

// Components
import ImageCarousel from '../../Listings/ImageCarousel';
import Tooltip from '@mui/material/Tooltip';
import NextAvailable from '../NextAvailable/NextAvaialable'
import ReviewsBox from '../ReviewsBox/ReviewsBox'
import { db } from '../../../firebase';


const facilities = [
    {
        name: 'Weight Section',
        id: 'weight-section',
    },
    {
        name: 'Functional Floor',
        id: 'function-floor',
    },
    {
        name: 'Yoga Studio',
        id: 'yoga-studio',
    },
    {
        name: 'Dance Studio',
        id: 'dance-studio',
    },
    {
        name: 'Massage Rooms',
        id: 'massage-room',
    },
    {
        name: 'Parking',
        id: 'parking',
    }

];
const amenities = [
    {
        name: 'Gender Specific Bathrooms',
        id: 'both-bathrooms',
    },
    {
        name: 'Unisex Bathrooms',
        id: 'uni-bathrooms',
    },
    {
        name: 'Showers',
        id: 'showers',
    },
    {
        name: 'Sauna',
        id: 'sauna',
    },
    {
        name: 'Steam Room',
        id: 'steam-room',
    },
    {
        name: 'Food Cafe',
        id: 'food-cafe',
    },
    {
        name: 'Smoothie Bar',
        id: 'smoothie-bar',
    },
];

function FacilityProfile({ listing = {}, goBack, userID, favorites = [] }) {


    const [facilitiesArray, setFacilitiesArray] = useState([]);
    const [amenitiesArray, setAmenitiesArray] = useState([]);
    const [favorite, setFavorite] = useState(false);
    const [scrollHeight, setScrollHeight] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [userHash, setUserHash] = useState('')
    const [update, setUpdate] = useState(0)


    const [details, setDetails] = useState(listing)


    const {id} = useParams()

    useEffect(() => {

        console.log(id)

        if (id !== null && id !== undefined) {
            loadFacility(id)
        } else {
            console.log('poop')
        window.addEventListener('scroll', (e) => {
            setScrollHeight(e.target.scrollingElement.scrollTop)
        }
            );


        console.log(favorites)
        formatFacilities()
        formatAmenities()      
        setLoaded(true)      
        }



        return () => {
            window.removeEventListener('scroll', () => {})
        }

    }, [])

    async function loadFacility(facilityID) {
        const facilityDoc = await db.collection('facilities').doc(facilityID).get()
        const fac = facilityDoc.data();
        const { country, city, suburb } = fac;
            const { addressOne, addressTwo , postalCode } = fac;
            const { listingName, description, ownerIDs, imageLinks } = fac;
            const { hourlyRate } = fac;
            const { totalReviews, reviewAverage } = fac;
            const { venueAmenities, venueFacilities, maxCapacity, totalBookings, venueType } = fac;
            const locationHash = fac.locationHash ?? '';

            let distance = 10;
            // console.log(locationHash, userHash)
            if (locationHash !== '' && userHash !== '') {
                // distance = Math.round((GeohashDistance.inKm(locationHash, userHash) * 10)) / 10;
                // console.log(distance)
            }



            let venueTypeStore = '';

            switch (venueType) {
                case 'full-gym': venueTypeStore = 'Full Gym'
                    break;
                case 'private-space': venueTypeStore = 'Private Space'
                    break;
                case 'yoga-studio': venueTypeStore = 'Yoga Studio'
                    break;
                case 'pilates-studio': venueTypeStore = 'Pilates Studio'
                    break;
                case 'boxing-gym': venueTypeStore = 'Boxing Gym'
                    break;
                case 'treatment-facility': venueTypeStore = 'Treatment Facility'
                    break;
                default: break;
            }

            // Owner Name
            const ownerID = ownerIDs[0];
            const ownerDoc = await db.collection('users').doc(ownerID).get();
            const owner = ownerDoc.data();
            const ownerName = owner.firstName;

            // let favorite = false;

            // if (favorites.includes(facilityID)) {
            //     favorite = true;
            // }

            setDetails({
                facilityID, ownerIDs,
                country, city, suburb,
                addressOne, addressTwo, postalCode,
                listingName, description, favorite,
                hourlyRate, ownerName, distance,
                totalReviews, reviewAverage, maxCapacity, totalBookings,
                venueAmenities, venueFacilities, imageLinks, venueTypeStore,
            })

            setUpdate(current => current + 1)

            loadFormatFacilities(venueFacilities)
            loadFormatAmenities(venueAmenities)

            setLoaded(true)


    }



    function loadFormatFacilities(facs) {
        const facArray = [];
        for (var i = 0; i < facs.length; i++) {
            for (var j = 0; j < facilities.length; j++) {
                if (facs[i] === facilities[j].id) {
                    facArray.push(facilities[j].name);
                }
            }
        }
        setFacilitiesArray(facArray)
    }

    function loadFormatAmenities(amens) {
        const facArray = [];
        for (var i = 0; i < amens.length; i++) {
            for (var j = 0; j < amenities.length; j++) {
                if (amens[i] === amenities[j].id) {
                    facArray.push(amenities[j].name);
                }
            }
        }
        setAmenitiesArray(facArray);
    }

    function formatFacilities() {
        const facArray = [];
        for (var i = 0; i < details.venueFacilities.length; i++) {
            for (var j = 0; j < facilities.length; j++) {
                if (details.venueFacilities[i] === facilities[j].id) {
                    facArray.push(facilities[j].name);
                }
            }
        }
        setFacilitiesArray(facArray)
    }

    function formatAmenities() {
        const facArray = [];
        for (var i = 0; i < details.venueAmenities.length; i++) {
            for (var j = 0; j < amenities.length; j++) {
                if (details.venueAmenities[i] === amenities[j].id) {
                    facArray.push(amenities[j].name);
                }
            }
        }
        setAmenitiesArray(facArray);
    }

    function returnIcon(type) {
        switch (type) {
            case 'Weight Section': return <Tooltip title={type}><FitnessCenterIcon /></Tooltip>;
            case 'Functional Floor': return <Tooltip title={type}><GridViewIcon /></Tooltip>;
            case 'Yoga Studio': return <Tooltip title={type}><SelfImprovementIcon /></Tooltip>;
            case 'Dance Studio': return <Tooltip title={type}><LibraryMusicIcon /></Tooltip>;
            case 'Massage Rooms': return <Tooltip title={type}><SpaIcon /></Tooltip>;
            case 'Parking': return <Tooltip title={type}><LocalParkingIcon /></Tooltip>;
            case 'Gender Specific Bathrooms': return <Tooltip title={type}><WcIcon /></Tooltip>;
            case 'Unisex Bathrooms': return <Tooltip title={type}><ShareIcon /></Tooltip>;
            case 'Showers': return <Tooltip title={type}><ShowerIcon /></Tooltip>;
            case 'Sauna': return <Tooltip title={type}><WhatshotIcon /></Tooltip>;
            case 'Steam Room': return <Tooltip title={type}><HotTubIcon /></Tooltip>;
            case 'Food Cafe': return <Tooltip title={type}><RestaurantIcon /></Tooltip>;
            case 'Smoothie Bar': return <Tooltip title={type}><LocalDrinkIcon /></Tooltip>;
            default: return type;
        }
    }

    useEffect(() => {
        // console.log(listing)
    }, [])

    return (
        <>
        {loaded && <div onScroll={(e) => console.log(e)} className='facility-profile scale-in-ver-center'>
{(id === undefined) && <div onClick={goBack} className="back-button-row">
                <ArrowBackIosIcon />
                <p>Back</p>
            </div>}
            <div className="listing-header">
                <h1>{details.listingName}</h1>
                <div className="fp-header-row">
                    <div className="review-container">
                        <StarIcon />
                        <p>{details.reviewAverage}</p>
                    </div>
                    <p className='listing-address'>{details.addressOne}{details.addressTwo !== '' && `, ${details.addressTwo} `}, {details.suburb}, {details.city}</p>
                </div>
                <div className="carousel-container">
                    <ImageCarousel showThumb={false} imageArray={details.imageLinks} showFullScreen={true} />
                </div>
                <div className="fp-container">
                    <div className="fp-left">
                        <h2>{details.venueTypeStore ?? 'Gym'} hosted by {details.ownerName}</h2>
                        <div className="cap-fac">
                            <h4>Capacity: {details.maxCapacity}</h4>
                            <h4 className="divider">
                                |
                            </h4>
                            <div className="fac-icons">
                            {facilitiesArray.map((facility) => {
                                    // return <li>{facility}</li>
                                    return <div className='info-icon'>{returnIcon(facility)}</div>
                                })}
                            </div>
                        </div>
                        <hr />
                        <div className="fp-description">
                            <h4>Venue Description</h4>
                            <p>{details.description}</p>
                        </div>
                        <hr />
                        <div className="fp-fac-amen">
                            <div className="fp-fa-left">
                                <h4>Exercise Facilities</h4>
                                <div className="fp-fac-container">
                                    {facilitiesArray.map((facility) => {
                                        return <div className='fp-fac-item'>
                                            {returnIcon(facility)}
                                            <p>{facility}</p>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className="fp-fa-right">
                                <h4>Amenities</h4>
                                <div className="fp-amen-container">
                                    {amenitiesArray.map((amenity) => {
                                        return <div className='fp-fac-item'>
                                            {returnIcon(amenity)}
                                            <p>{amenity}</p>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="reviews-box-container">
                            <ReviewsBox facilityID={details.facilityID} />
                        </div>
                    </div>
                    <div style={{justifyContent: ((window.innerHeight - window.scrollY) <= 180) ? 'flex-end' : 'flex-start'}} className="fp-right">
                        <div style={{position: ((window.innerHeight - window.scrollY) <= 180) ? 'absolute' : 'fixed'}} className="n-a-cont">
                            <NextAvailable open={id !== null} favorite={favorites.includes(details.facilityID)} userID={userID} facilityID={details.facilityID} rate={details.hourlyRate} rating={details.reviewAverage} />
                        </div>
                    </div>
                </div>
            </div>
        </div>}</>
    )
}

export default FacilityProfile
