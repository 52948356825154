import React, { useState, useEffect} from 'react'
import './ReviewsBox.css'

import ReviewItem from './ReviewItem'

import { db } from '../../../firebase'

function ReviewsBox({ facilityID }) {

    const [reviews, setReviews] = useState([])

    useEffect(() => {
        loadReviews()
    }, [])

    async function loadReviews() {
        const reviewDocs = await db.collection('facilityReviews').where('facilityID', '==', facilityID).get();

        const reviewArray = [];

        for (let i = 0; i < reviewDocs.docs.length; i++) {
            const review = reviewDocs.docs[i].data();
            const { instructorID, dateTimeStamp, rating, textContent} = review;
            const dateString = new Date(dateTimeStamp.toDate()).toLocaleDateString('en-us', { dateStyle: 'long'});

            // Instructor
            const instructorDoc = await db.collection('users').doc(instructorID).get();
            const instructor = instructorDoc.data();
            const instructorName = `${instructor.firstName} ${instructor.lastName}`;
            const { imageLink } = instructor;

            reviewArray.push({
                dateString,
                imageLink,
                textContent,
                rating,
                instructorName,
            })

        }

        setReviews(reviewArray);
    }

    return (
        <div className='reviews-box'>
            {reviews.map(review => <ReviewItem img={review.imageLink} textContent={review.textContent} rating={review.rating}
                name={review.instructorName} date={review.dateString}
            />)}
        </div>
    )
}

export default ReviewsBox
