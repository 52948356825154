import React from 'react'
import './Steps.css'

function StepSeven({ edit, facNameRef, defaultName, fwd = true, }) {
    return (
        <div className={fwd ? 'step-seven slide-in-right' : `step-seven slide-in-left`}>
            {!edit && <h1>Step 7</h1>}
            <div className="listing-name-group">
                <label for="listing-name-input">Give your listing a name...</label>
                <input ref={facNameRef} defaultValue={defaultName} type="text" id="listing-name-input" />
            </div>
        </div>
    )
}


export default StepSeven
