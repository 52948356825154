import React, { useState, useEffect } from 'react'
import './OwnerCredits.css'

import LoadingOverlay from 'react-loading-overlay'
import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';

import { useAuth } from '../../contexts/AuthContext'
import { db } from '../../firebase'

import CreditBox from './CreditBox'
import OwnerCreditsRecon from './OwnerCreditsRecon'

function OwnerCredits() {

    const {currentUser} = useAuth()

    const [loaded, setLoaded] = useState(false)
    const [purchases, setPurchases] = useState([])
    const [credits, setCredits] = useState([])
    const [facs, setFacs] = useState([])
    const [selFac, setSelFac] = useState('')
    const [bookings, setBookings] = useState([])

    const [showRecon, setShowRecon] = useState(false) 
    const [recon, setRecon] = useState()


    useEffect(() => {
        if (currentUser) {
            loadCredits(currentUser.uid)
        }
    }, [currentUser])

    function cap(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

      async function loadCredits(uid) {
        const userDoc = await db.collection('users').doc(uid).get()
        const user = userDoc.data()      
        const facilityIDs = user.facilityIDs ?? []

        const carr = []
        const parr = []
        const barr = []
        const farr = []


        for (let i = 0; i < facilityIDs.length; i++) {
            const facilityID = facilityIDs[i];
            const facilityDoc = await db.collection('facilities').doc(facilityID).get()
            const facility = facilityDoc.data()
            console.log(facilityID)
            farr.push({
                name: facility.listingName,
                value: facilityID,
            })

            const instructorCredits = facility.instructorCredits ?? ''
            if (instructorCredits === '') {
                continue
            }

            for (let [key, value] of Object.entries(instructorCredits)) {

                const instructorID = key;
                const outstanding = value;

                const instructorDoc = await db.collection('users').doc(instructorID).get();
                const instructor = instructorDoc.data()
                const instructorName = `${instructor.firstName} ${instructor.lastName}` 
                const imageLink = instructor.imageLink


                carr.push({
                    instructorName,
                    instructorID,
                    imageLink,
                    facilityID,
                    outstanding,
                })
            }
            const purchases = await facilityDoc.ref.collection('bulkPurchases').get();
            for (let j = 0; j < purchases.docs.length; j++) {
                const doc = purchases.docs[j].data()
                const {amount, date, percentage, size, instructorID} = doc;
                const capSize = cap(size)
                const purchaseDate = new Date(date.toDate())
                console.log(facilityID)
                parr.push({
                    amount, facilityID, percentage, size, capSize, purchaseDate, instructorID
                })                
            }

            const sessions = await db.collection('bookings').where('paidWithCredits', '==', true)
            .where('facilityID', '==', facilityID)
            .get()

                for (let j = 0; j < sessions.docs.length; j++) {
                    const session = sessions.docs[j].data()
                    const {facilityID, total, start,rateType, rate, instructorID } = session;
                    const date = new Date(start.toDate())
                    const end = new Date(session.end.toDate())
                    
                    barr.push({
                        facilityID, date, total, end, rate, rateType, instructorID
                    })

                }
        }
        console.log(carr)
        setPurchases(parr)
        setCredits(carr)
        setBookings(barr)
        setFacs(farr)

        setLoaded(true)
        
    }

  return (
      <LoadingOverlay active={!loaded} text='Loading Credits...' spinner>
          {showRecon ? recon :  <>
    <div className='owner-credits instructor-credits'>
        <div className="fac-select-row">
            <SelectSearch value={selFac} placeholder='Select Facility' options={facs} onChange={(e) => {console.log(e); setSelFac(e)}} />
        </div>
        {selFac === '' && <div className='no-fac-sel'>
                <h3>Select a facility to view their credits</h3>
                </div>}
        <div className="credits-container">
            {credits.map(credit => {
                // console.log(selFac)
                if (selFac !== credit.facilityID) {
                    // console.log('not returning')
                    return null
                }
                // console.log('returning')
                return <CreditBox facilityID={credit.facilityID} instructorID={credit.instructorID} 
                show={() => {
                    setRecon(<OwnerCreditsRecon credits={credit} goBack={() => setShowRecon(false)} role='owner' bookings={bookings} purchases={purchases}/>)
                    setShowRecon(true)
                }}
                    key={credit.instructorID} imageLink={credit.imageLink} name={credit.instructorName} outstanding={credit.outstanding}
                />
            })}
        </div>

    </div>
    </>}
    </LoadingOverlay>
  )
}

export default OwnerCredits