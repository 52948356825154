import React, { useState, useEffect, useRef } from 'react'
import './InstructorAddSessionModal.css'

import { db } from '../../../firebase'

import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';


function InstructorAddSessionModal({ closeModal, uid, athleteID = '' }) {

    const [chosenID, setChosenID] = useState(athleteID)
    const [athletes, setAthletes] = useState([])
    const [disciplines, setDisciplines] = useState([])
    const [locations, setLocations] = useState([])
    const [type, setType] = useState('')

    const [complete, setComplete] = useState(false)

    const [discipline, setDiscipline] = useState('');
    const [location, setLocation] = useState()
    const [payment, setPayment] = useState('')
    const dateRef = useRef();
    const timeRef = useRef();
    const priceRef = useRef();

    const discRef = useRef();
    discRef.current = discipline;
    const locRef = useRef();
    locRef.current = location;

    useEffect(() => {
        loadInstructorClients()
    }, [])

    function formatTime(date) {
        const d = new Date(date);
        return (d.toLocaleTimeString('en-gb', {
            hour: '2-digit',
            minute: '2-digit'
        }))
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const typeArray = [
        {
            name: 'Full Rate',
            value: 'full'
        },
        {
            name: 'Half Rate',
            value: 'half'
        },
        {
            name: 'Custom Rate',
            value: 'custom',
        }

    ]
    const paymentArray = [
        {
            name: 'Request Payment',
            value: 'paymentRequested'
        },
        {
            name: 'Payment Received',
            value: 'paymentReceived'
        },

    ]

    async function loadInstructorClients() {
        const instructorDoc = await db.collection('users').doc(uid).get();
        const instructor = instructorDoc.data();
        const { enabledDisciplines, enabledLocations } = instructor;
        const clients = instructor.clients ?? [];

        const discArray = [];
        const locArray = [];

        for (let [key, value] of Object.entries(enabledDisciplines)) {
            if (value === true) {
                switch (key) {
                    case 'biokineticist':
                    discArray.push({
                        name: 'Biokinetics',
                        value: 'recovery',
                    });
                    break;
                    case 'personalTraining':
                    discArray.push({
                        name: 'Personal Training',
                        value: 'personalTraining',
                    });
                    break;
                    case 'physiotherapist':
                    discArray.push({
                        name: 'Physiotherapy',
                        value: 'recovery',
                    });
                    break;
                    case 'pilates':
                    discArray.push({
                        name: 'Pilates',
                        value: 'yoga',
                    });
                    break;
                    case 'recovery':
                    discArray.push({
                        name: 'Recovery',
                        value: 'recovery',
                    });
                    break;
                    case 'wellness':
                    discArray.push({
                        name: 'Wellness',
                        value: 'wellness',
                    });
                    break;
                    case 'yoga':
                    discArray.push({
                        name: 'Yoga',
                        value: 'yoga',
                    });
                    break;
                    case 'yoga&pilates':
                    discArray.push({
                        name: 'Yoga',
                        value: 'yoga',
                    });
                    break;
                    case 'yogaOnly':
                    discArray.push({
                        name: 'Yoga',
                        value: 'yoga',
                    });
                    break;
                    default: break;
                }
            }
        }
        setDisciplines(discArray)

        for (let [key, value] of Object.entries(enabledLocations)) {
            if (value === true) {
                switch (key) {
                    case 'atFacility':
                    locArray.push({
                        name: 'Facility',
                        value: 'facility',
                    });
                    break;
                    case 'atHome':
                    locArray.push({
                        name: 'Home',
                        value: 'home',
                    });
                    break;
                    case 'online':
                    locArray.push({
                        name: 'Online',
                        value: 'online',
                    });
                    break;
                    case 'outdor':
                    locArray.push({
                        name: 'Outdoors',
                        value: 'outdoor',
                    });
                    break;
                    default: break;
                }
            }
        }
        setLocations(locArray)

        const athleteArray = [];
        console.log(clients)
        for (let i = 0; i < clients.length; i++) {
            const athleteID = clients[i];

            const athleteDoc = await db.collection('users').doc(athleteID).get();
            const athlete = athleteDoc.data();
            if (athlete === undefined) {
                continue;
            }
            const athleteName = `${athlete.firstName} ${athlete.lastName}`;

            athleteArray.push({
                name: athleteName,
                value: athleteID,
            })

        }

        setAthletes(athleteArray)
    }

    async function saveBooking() {

        const date = new Date(formatDate(dateRef.current.value));
        const hours = timeRef.current.value.slice(0,2);
        const minutes = timeRef.current.value.slice(3,5);

        date.setHours(hours)
        date.setMinutes(minutes);



        // Location
        const chosenLoc = locRef.current;
        const chosenDisc = discRef.current;
        const discDoc = await db.collection('users').doc(uid).collection('trainingPrefs').doc(discipline).get()
        const discData = discDoc.data();
        console.log(discData)

        const discLocation = discData[`${chosenLoc}Location`]
        const discLocationPoint = discData[`${chosenLoc}LocationPoint`]


        let cost;

        if (type === 'full') {
            cost = parseInt(discData[`${location}Price`])
        }

        if (type === 'half') {
            cost = parseInt(discData[`${location}Price`]) * 0.5;

        }

        if (type === 'custom') {
            cost = priceRef.current.value;
        }

        await db.collection('sessions').add({
            athleteRefundChosen: false,
            athleteID: chosenID,
            calendarID: '',
            cost,
            dateCreated: new Date(),
            dateTimeStamp: date,
            duration: 60,
            forteFee: 0,
            instructorID: uid,
            isOfflineBooking: true,
            isRescheduled: false,
            paidWithCredits: false,
            paymentProcess: payment,
            rescheduledByInstructor: false,
            sessionSpeciality: '',
            status: 'booked',
            trainingLocationGeoPoint: discLocationPoint,
            discipline: chosenDisc,
            locationString: chosenLoc,
            location: discLocation
        });

        setComplete(true)
    }

    return (
        <div className='instructor-add-session-modal' onClick={(e) => {if (e.target.className === 'instructor-add-session-modal') { closeModal() }}}>
            <div className="instructor-add-session-modal-content scale-in-center">
                {!complete ? <> 
                <h1>Add Session</h1>
                <div className="select-row">
                {athleteID === '' &&
                    <SelectSearch
                    closeOnSelect={true}
                    search={true}
                    placeholder={'Select Athlete'}
                    options={athletes}
                    onChange={(value) => {
                        setChosenID(value)
                    }}
                    />}
                </div>
                <div className="add-session-container">
                    <div className="add-session-row">
                        <div className="add-session-item">
                            <p>Date</p>
                            <input ref={dateRef} type='date' />
                        </div>
                    </div>
                    <div className="add-session-row">
                        <div className="add-session-item">
                            <p>Time</p>
                            <input ref={timeRef} type='time' />
                        </div>
                    </div>
                    <div className="add-session-row">
                        <div className="add-session-item">
                            <p>Type</p>
                            <SelectSearch
                                closeOnSelect={true}
                                search={false}
                                placeholder={'Select Type'}
                                options={typeArray}
                                onChange={(value) => {
                                    setType(value)
                                }}
                            />
                            {/* <div className="price-group">
                                <p>R</p>
                                <input ref={priceRef} min={0} type='number' step={50} />
                            </div> */}

                        </div>
                    </div>
                    {type === 'custom' && <div className="add-session-row">
                        <div className="add-session-item">
                                <p>R</p>
                                <input ref={priceRef} min={0} type='number' step={50} />
                        </div>
                    </div>}
                    <div className="add-session-row">
                        <div className="add-session-item">
                            <p>Discipline</p>
                            <SelectSearch
                                closeOnSelect={true}
                                search={false}
                                placeholder={'Select Discipline'}
                                options={disciplines}
                                onChange={(value) => {
                                    console.log(value)
                                    setDiscipline(value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="add-session-row">
                        <div className="add-session-item">
                            <p>Location</p>
                            <SelectSearch
                                closeOnSelect={true}
                                search={false}
                                placeholder={'Select Location'}
                                options={locations}
                                onChange={(value) => {
                                    setLocation(value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="add-session-row">
                        <div className="add-session-item">
                            <p>Payment Details</p>
                            <SelectSearch
                                closeOnSelect={true}
                                search={false}
                                placeholder={'Select Payment'}
                                options={paymentArray}
                                onChange={(value) => {
                                    setPayment(value)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="button-row ias-button-row">
                    <button disabled={chosenID === ''} onClick={saveBooking} className="confirm-button picton-button">Save</button>
                </div>
                </> : <>
                <div className="success-msg">
                    <h1>Success</h1>
                    <p>Session successfully added</p>
                </div>
                <div className="button-row ias-button-row">
                    <button disabled={chosenID === ''} onClick={closeModal} className="confirm-button saffron-button">Close</button>
                </div>
                </>}
            </div>
        </div>
    )
}

export default InstructorAddSessionModal
