import React, { useEffect, useState, useRef } from 'react'
import './BroadcastMessageModal.css'

// Components
import Multiselect from 'multiselect-react-dropdown';
import Checkbox from '@material-ui/core/Checkbox';

import { db } from '../../firebase';

function BroadcastMessageModal({ closeModal, contacts, senderID, role }) {


    const [options, setOptions] = useState();
    const [chosenContacts, setChosenContacts] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [selected, setSelected] = useState([])

    const selectedRef = useRef;
    selectedRef.current = selected;

    const messageRef = useRef();

    useEffect(() => {
        console.log(chosenContacts);
    }, [chosenContacts] )

    useEffect(() => {
        console.log(contacts)
    }, [])

    async function sendBroadcastMessage() {
        setErrorMessage('');
        const message = messageRef.current.value;
        console.log(message);
        if (message === '') {
            return setErrorMessage('Please ensure to write up a message')
        }

        for (var i = 0; i < chosenContacts.length; i++) {
            const contact = chosenContacts[i]
            console.log(contact);
            await db.collection('chat').doc(contact.chatID).collection('Messages').add({
                textContent: message,
                sender: senderID,
                receiver: contact.instructorID,
                timeStamp: new Date(),
                read: false,
            });
            await db.collection('chat').doc(contact.chatID).update({
                lastMessage: {
                    textContent: message,
                    sender: senderID,
                    receiver: contact.instructorID,
                    timeStamp: new Date(),
                    read: false,
                }
            });
        }
        closeModal();
    }



    function contactSelected(evt) {
        // console.log(evt)
        const chatID = evt[evt.length - 1].id;
        const instructorID = evt[evt.length - 1].instructorID;
        const name = evt[evt.length - 1].name;
        console.log(chatID);
        setChosenContacts(contacts => [...contacts, {chatID: chatID, instructorID: instructorID, name: name}]);
    }

    function contactDeselection(evt) {

        const newArray = [];

        for (var i = 0; i < evt.length; i++) {
            const contact = evt[i];
            newArray.push({name: contact.name, id: contact.chatID, instructorID: contact.instructorID});
        }

        setChosenContacts(newArray);

    }

    useEffect(() => {
        const optionsArray = [];
        for (var i = 0; i < contacts.length; i++) {
            const contact = contacts[i];
            optionsArray.push({name: contact.name, id: contact.chatID, instructorID: contact.instructorID})
        }
        setOptions(optionsArray)
    }, [])

    // const options = [{name: 'Option 1️⃣', id: 1},{name: 'Option 2️⃣', id: 2}];

    return (
        <div onClick={(evt) => {
            if (evt.target.className === 'broadcast-message-modal') {
                closeModal();
            }
        }} className='broadcast-message-modal'>
            <div className="broadcast-message-modal-content scale-in-center">
                <h1>Broadcast Message</h1>
                <p>Select recipients</p>
                <div className="bmm-select-all">
                <Checkbox onChange={(evt) => {
                    if (evt.target.checked) {
                        setSelected(options)
                    } else {
                        setSelected([]);
                    }
                    }} color="default"/>
                <p>Select All</p>
                </div>
                <div className="bmm-select-all">
                <Checkbox onChange={(evt) => {
                    if (evt.target.checked) {
                        const arr = contacts.filter(item => item.type === 'athlete')
                        setSelected(arr)
                    } else {
                        setSelected([]);
                    }
                    }} color="default"/>
                <p>All Clients</p>
                </div>
                <Multiselect options={options}
                    displayValue='name'
                    onSelect={(evt) => contactSelected(evt)}
                    onRemove={(evt) => contactDeselection(evt)}
                    selectedValues={selected}
                    showCheckbox={true}
                    closeOnSelect={false}

                />
                <textarea onKeyUp={() => setErrorMessage('')} ref={messageRef} placeholder='Enter your message here...' rows="5"></textarea>
                <div hidden={errorMessage === ''} className="broadcast-error-row">
                    {errorMessage !== '' && <p>{errorMessage}</p>}
                </div>
                <div className="broadcast-button-row">
                    <button onClick={() => closeModal()} className='broadcast-close-button'>Close</button>
                    <button onClick={() => sendBroadcastMessage()} className='broadcast-send-button'>Send</button>
                </div>
            </div>
        </div>
    )
}

export default BroadcastMessageModal
