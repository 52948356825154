import React from 'react'
import './CalendarErrorModal.css'

function CalendarErrorModal({ message, closeModal }) {
    return (
        <div onClick={(evt) => {if(evt.target.className === 'calendar-error-modal') {closeModal()}}} className='calendar-error-modal'>
            <div className="calendar-error-modal-content">
                <h2>{message}</h2>
            </div>
        </div>
    )
}

export default CalendarErrorModal
