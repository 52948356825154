import React, { useState, useRef } from 'react'
import './RescheduleSessionModal.css'
import '../../Calendar/InstructorModals/InstructorAvailableModal.css'

import { db } from '../../../firebase'

import LoadingOverlay from 'react-loading-overlay'

function RescheduleSessionModal({ sessionID, sessionDate, athleteName, closeModal, uid}) {

    const [confirming, setConfirming] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [completed, setCompleted] = useState(false)

    // Refs
    const startInputRef = useRef();
    const startTimeInputRef = useRef();
    const endTimeInputRef = useRef();

    function validateData() {
        console.log(startTimeInputRef.current.value)
        console.log(endTimeInputRef.current.value)
        console.log(startInputRef.current.value)

        const startHours = parseInt(startTimeInputRef.current.value.slice(0,2));
        const endHours = parseInt(endTimeInputRef.current.value.slice(0,2));

        if (endTimeInputRef.current.value === '') {
            setErrorMessage('Please select a date');
            return false
        }

        if (startTimeInputRef.current.value === '00:00' && endTimeInputRef === '00:00') {
            setErrorMessage('Please select a starting ending time');
            return false
        }

        if (startHours >= endHours) {
            setErrorMessage('Please ensure the starting time is before the ending time')
            return false
        }
    }


    async function confirmReschedule() {

        setErrorMessage('');

        if (validateData() === false) {
            return
        }
        setLoading(true)
        const startHours = parseInt(startTimeInputRef.current.value.slice(0,2));
        const endHours = parseInt(endTimeInputRef.current.value.slice(0,2));
        const sessionsMap = {}

        const selDate = new Date(startInputRef.current.value);
        // return console.log(startInputRef.current)
        selDate.setHours(0);
        selDate.setMinutes(0);
        selDate.setSeconds(0);
        selDate.setMilliseconds(0)
        // return console.log(selDate)
               // Check if calendar slot already exists for day
       const calDocs = await db.collection('users').doc(uid).collection('calendar')
       .where('date', '==', selDate)
       .get()
        // If it exists
        if (calDocs.docs.length > 0) {

            console.log('do exist')
            const calDoc = calDocs.docs[0].data();
            const sessionsMap = calDoc.Sessions;
            for (let i = startHours; i < endHours; i++) {
                sessionsMap[`${i}`] = 'Available'
            }
            console.log(sessionsMap)
            await db.collection('users').doc(uid).collection('calendar').doc(calDocs.docs[0].id).update({
                Sessions: sessionsMap,
            })


        } else {
            console.log('dont exist')
            for (let i = startHours; i < endHours; i++) {
                sessionsMap[`${i}`] = 'Available'
            }
            console.log(sessionsMap)
            await db.collection('users').doc(uid).collection('calendar').add({
                date: selDate,
                Sessions: sessionsMap,
            })
        }

        setErrorMessage('')

        setLoading(true)

        try {

            await db.collection('sessions').doc(sessionID).update({
                rescheduledByInstructor: true,
            });
            // closeModal()
            setCompleted(true)
        } catch (err) {
            setErrorMessage('Could not reschedule your session, please try again')
            setLoading(false)
        }
    }

    function formatTime(date) {
        const d = new Date(date);
        return (d.toLocaleTimeString('en-gb', {
            hour: '2-digit',
            minute: '2-digit'
        }))
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    return (
        <div onClick={(e) => {if (e.target.className === 'reschedule-session-modal') { closeModal() }}} className='reschedule-session-modal'>
            <LoadingOverlay
            spinner
            active={loading}
            >
            <div className="reschedule-session-modal-content instructor-available-modal-content">
                {!completed ? <>
                <h1>Reschedule Session</h1>
            {/* <div className="reschedule-dates"> */}
            <h4>{sessionDate.toLocaleDateString('en-us', { dateStyle: 'long' })}</h4>
            <p>Please open up a slot on your calendar for {athleteName} to book</p>
            <div className="rs-row instructor-available-modal-row date-row">
                    <p>Date:</p>
                    <div className="rs-input">
                    <input type='date' ref={startInputRef} min={formatDate(new Date())} />

                    </div>

                </div>
                <div className="instructor-available-modal-row date-row">
                    <p className='bold'>Time:</p>
                    {/* <TimePicker disableClock={true} /> */}
                    <input type='time' ref={startTimeInputRef} defaultValue={'00:00'} />
                    <h1>-</h1>
                    <input type='time' ref={endTimeInputRef}  defaultValue={'00:00'}/>
                    {/* <p className='middle-pee'>{endingDate.toLocaleDateString("en-US", options)}</p> */}
                    {/* <DateTimePicker minDate={minDate} className='available-dt-picker' onChange={setEndingDate} value={endingDate} defaultValue={end} /> */}
                </div>
            {/* </div> */}
            {errorMessage !== '' && <div className="error-message-row">{errorMessage}</div>}
            <div className="button-row">
                    <button onClick={confirmReschedule} id='confirm-button' className='confirm-button'>Confirm</button>
                    <button onClick={closeModal} className='cancel-button'>Close</button>

                </div>
                </> : <>
                <div className="success-msg">
                    <h1 className='mb-20'>Successfully added</h1>
                    <p>We will let {athleteName} know that you need to reschedule</p>
                </div>
                <div className="button-row">
                                    <button onClick={closeModal} className='cancel-button'>Close</button>

                </div>

                </>}
            </div>

            </LoadingOverlay>
        </div>
    )
}

export default RescheduleSessionModal
