import React from 'react'
import './SignUpConfirmationModal.css'

function SignUpConfirmationModal({proceed}) {
    return (
        <div className='signup-confirmation-modal'>
            <div className="signup-modal-content">
                <h1>Account Successfully Created</h1>
                <h2>Proceed to complete your account setup.</h2>
                <div className="proceed-button-row">
                <button className='picton-button' onClick={proceed}>Proceed</button>
                </div>
            </div>
        </div>
    )
}


export default SignUpConfirmationModal
