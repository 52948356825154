import React, { useState, useEffect, useRef } from 'react'

import { db } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext';

import NotificationSnack from './NotificationSnack';

function NotificationHandler() {

    const { currentUser } = useAuth();

    const notifications = [
        // {
        //     type: 'booking',
        //     title: 'New Booking',
        //     description: 'So and so made a new booking',
        //     id: 1,
        // },
        // {
        //     type: 'booking',
        //     title: 'New Booking',
        //     description: 'So and so and so made a new booking',
        //     id: 2,
        // },
        // {
        //     type: 'booking',
        //     title: 'New Booking',
        //     description: 'So and sooooo made a new booking',
        //     id: 3,
        // },
    ];

    const [showSnack, setShowSnack] = useState(false);
    const [currentUID, setCurrentUID] = useState('');
    const [notificationArray, setNotificationArray] = useState(notifications);

    const notificationRef = useRef();
    notificationRef.current = notificationArray;

    useEffect(() => {
        if (currentUser) {
            listenForNotifications(currentUser.uid)
        }
    }, [currentUser])

    async function removeItem(documentID) {
        const newArray = [];

        let currentArray = [...notificationRef.current];
        let index;

        for (var i = 0; i < currentArray.length; i++) {
            if (currentArray[i].documentID === documentID) {
                currentArray.splice(i, 1);
            }
        }

        setNotificationArray(currentArray);
    }

    async function listenForNotifications(uid) {
        try {
            // console.log('loading')

            const nots = await db.collection('users').doc(uid).collection('notifications').get();
            // console.log(nots.docs.length)
        } catch (err) {
            console.log(err)
        }


        // db.collection('users').doc(uid).collection('notifications').onSnapshot((notifications) => {
        //     const current = notifications.docs.length;
        //     notifications.docChanges().forEach((change) => {
        //         if (change.type === 'added') {
        //             const notification = change.doc.data();
        //             change.doc.ref.update({
        //                 read: true,
        //             });
        //         }
        //     });


        // });
    }

    return (
        <div className='notification-handler'>
        {/* <NotificationSnack description={'Name Namerson made a new booking on September 19th at 4pm'} title={'New Booking'} type={'booking'}/> */}
        {notificationArray.map((notification, index) => {
            // console.log(notification.title);
            return <NotificationSnack title={notification.title} type={notification.type} description={notification.description} documentID={notification.documentID} hideNotification={removeItem} />
        })}
        </div>
    )
}


export default NotificationHandler
