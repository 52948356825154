import React, { useState, useRef, useEffect } from 'react'
import './ForgotPasswordModal.css'

import { admin } from '../../../firebase'
import LoadingOverlay from 'react-loading-overlay'

function ForgotPasswordModal({ closeModal }) {

    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [completed, setCompleted] = useState(false)

    const [input, setInput] = useState('')

    const inputRef = useRef()

    const passwordResetEmail = admin.functions().httpsCallable('resetPasswordEmail');

    async function resetPasswordEmail() {
        // setLoading(true)
        setErrorMessage('')

        const userMail = inputRef.current.value;
        // return console.log(userMail)
        try {
            await passwordResetEmail({ userMail });
            setLoading(false)
            setCompleted(true);
        } catch (err) {
            setErrorMessage('Sorry, an error occurred trying to send your email - please try again');
            setLoading(false)
        }
    }

    return (
        <div onClick={(e) => {if (e.target.className === 'forgot-password-modal') { closeModal() }}} className='forgot-password-modal'>
            <LoadingOverlay
                active={loading}
                spinner
            >
            <div className="forgot-password-modal-content">
            {!completed ?
                <>
                 <h1>Reset password</h1>
                 <p>Enter your email address, click the link below, and we will send you an email to sort that out.</p>
                <input onKeyUp={(e) => {if (e.key === 'Enter') { resetPasswordEmail() }}} onChange={(e) => setInput(e.target.value)} ref={inputRef} type='text' placeholder='email address' />
                {errorMessage !== '' && <div className="error-message">
                    {errorMessage}
                </div>}
                <div className="button-row">
                    <button disabled={input === ''} onClick={resetPasswordEmail} className="general-button">Send</button>
                </div>
                </>
                 : <>
                <h2>Success! Please check your email inbox</h2>
                <div className="button-row">
                    <button onClick={closeModal} className="general-button">Close</button>
                </div>
                </>}
            </div>
            </LoadingOverlay>
        </div>
    )
}

export default ForgotPasswordModal
