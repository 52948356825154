import React, {useState, useEffect, useRef } from 'react'
import './CalendarPage.css'
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useLocation, useNavigate } from 'react-router';
// Components
import MenuItem from './MenuItem';
import DiscountModal from './DiscountModal';
import SetAvailableModal from './SetAvailableModal';
import CalendarErrorModal from './CalendarErrorModal';
import LoadingOverlay from 'react-loading-overlay';
import BookingDetailModal from './BookingDetailModal';
import InstructorBookingRequestModal from './InstructorBookingRequestModal';
import InstructorAvailableModal from './InstructorModals/InstructorAvailableModal'
import InstructorViewSessionModal from './InstructorModals/InstructorViewSessionModal'
import SelectSearch, {useSelect, fuzzySearch, } from 'react-select-search';
import RBCToolbar from './RBCToolbar'
import CreditsModal from '../../Components/CreditsModal/CreditsModal';
import InstructorAddSessionModal from './InstructorModals/InstructorAddSessionModal'
import AddClientModal from '../Clients/AddClientModal/AddClientModal'
import PayBookingModal from '../Bookings/PayBookingModal/PayBookingModal'
import CalendarInfo from './CalendarInfo/CalendarInfo'

import Checkbox from '@material-ui/core/Checkbox'
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@material-ui/core/Drawer';
import HelpIcon from '@mui/icons-material/Help';

// Calendar
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'

// Firebase
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';

const localizer = momentLocalizer(moment)

const myEventsList = [
    {
      id: 0,
      title: 'All Day Event very long title',
    //   allDay: true,
      start: new Date(2021, 7, 23),
      end: new Date(2021, 7, 25),
    },
    {
      id: 1,
      title: 'Long Event',
      start: new Date(2015, 3, 7),
      end: new Date(2015, 3, 10),
    },

    {
      id: 2,
      title: 'DTS STARTS',
      allDay: false,
      start: new Date(2021, 7, 13, 12, 30, 0),
      end: new Date(2021, 7, 13, 13, 30, 0),
      type: 'Booked',
    },
    {
      id: 12,
      title: 'Available',
      allDay: false,
      start: new Date(2021, 7, 13, 11, 0, 0),
      end: new Date(2021, 7, 13, 14, 0, 0),
      type: 'available',
    },

    {
      id: 3,
      title: 'DTS ENDS',
      start: new Date(2016, 10, 6, 0, 0, 0),
      end: new Date(2016, 10, 13, 0, 0, 0),
    },

    {
      id: 4,
      title: 'Some Event',
      start: new Date(2015, 3, 9, 0, 0, 0),
      end: new Date(2015, 3, 10, 0, 0, 0),
    },
    {
      id: 5,
      title: 'Conference',
      start: new Date(2015, 3, 11),
      end: new Date(2015, 3, 13),
      desc: 'Big conference for important people',
    },
    {
      id: 6,
      title: 'Meeting',
      start: new Date(2021, 5, 22, 10, 30, 0, 0),
      end: new Date(2021, 5, 22, 12, 30, 0, 0),
      desc: 'Pre-meeting meeting, to prepare for the meeting',
    },
    {
      id: 7,
      title: 'Lunch',
      start: new Date(2015, 3, 12, 12, 0, 0, 0),
      end: new Date(2015, 3, 12, 13, 0, 0, 0),
      desc: 'Power lunch',
    },
    {
      id: 8,
      title: 'Meeting',
      start: new Date(2015, 3, 12, 14, 0, 0, 0),
      end: new Date(2015, 3, 12, 15, 0, 0, 0),
    },
    {
      id: 9,
      title: 'Happy Hour',
      start: new Date(2015, 3, 12, 17, 0, 0, 0),
      end: new Date(2015, 3, 12, 17, 30, 0, 0),
      desc: 'Most important meal of the day',
    },];


function CalendarPage() {

    const { currentUser, userRole } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    // State items
    const [activeTab, setActiveTab] = useState('overview');
    const [storedEvents, setStoredEvents] = useState([]);
    const [discountModalOpen, setDiscountModalOpen] = useState(false);
    const [availableModalOpen, setAvailableModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [bookingModalOpen, setBookingModalOpen] = useState(false);
    const [currentBookingModal, setCurrentBookingModal] = useState();
    const [loading, setLoading] = useState(true);
    const [showDrawer, setShowDrawer] = useState(false)

    const [selectedView, setSelectedView] = useState('week');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [listingNames, setListingNames] = useState();
    const [currentModal, setCurrentModal] = useState();
    const [currentErrorModal, setCurrentErrorModal] = useState();
    const [currentFacility, setCurrentFacility] = useState();
    const [currentFacilityID, setCurrentFacilityID] = useState();
    const facIDRef = useRef();
    facIDRef.current = currentFacilityID;
    const [facilityCapacity, setFacilityCapacity] = useState()
    const [instructorCalendar, setInstructorCalendar] = useState(false);
    const [showCredits, setShowCredits] = useState(false)
    const [showAddClient, setShowAddClient] = useState(false)
    const [payModal, setPayModal] = useState(false);
    const [showPayModal,setShowPayModal] = useState()
    const [showCalendarInfo, setShowCalendarInfo] = useState(false)

    const [facilityArray, setFacilityArray] = useState([]);

    const [instructorAvailableModalOpen, setInstructorAvailableModalOpen] = useState(false)
    const [instructorAvailableModal, setInstructorAvailableModal] = useState()

    const [instructorViewSessionModal, setInstructorViewSessionModal] = useState();
    const [instructorViewSessionModalOpen, setInstructorViewSessionModalOpen] = useState(false)

    const [instructorAddSessionModalOpen, setInstructorAddSessionModalOpen] = useState(false)

    const [filter, setFilter] = useState('')

    const [noFacs, setNoFacs] = useState(false)


    // Menu Item Refs
    const overviewRef = useRef();
    const availableRef = useRef();
    const priceBlocksRef = useRef();
    const discountRef = useRef();

    const selectRef = useRef();

    const selectedViewRef = useRef();
    selectedViewRef.current = selectedView;


async function showBookingModal(event) {
  // return console.log(event)

  if (event.title.toLowerCase().includes('pending')) {

    const { bookingID, title, total } = event;
    const bookingDoc = await db.collection('bookings').doc(bookingID).get();
    const booking = bookingDoc.data();
    const { start, end, facilityID, instructorID } = booking;
    const startDate = new Date(start.toDate());
    const endDate = new Date(end.toDate());
    const facName = title.replace(' - Payment Pending', '');


    setPayModal(<PayBookingModal bookingID={bookingID} start={startDate} end={endDate} closeModal={() => setShowPayModal(false)}
         facilityID={facilityID} facilityName={facName} instructorID={instructorID} total={total}
    />)
    return setShowPayModal(true)
  }

  if (event.type === 'instructor-available') {
    setInstructorViewSessionModal(<InstructorViewSessionModal startHour={event.start.getHours()} uid={currentUser.uid} calendarID={event.calendarID} location={event.location} available={true} date={event.start.toLocaleDateString('en-us', {dateStyle: 'full'})} startTime={event.start.toLocaleTimeString('en-gb', {timeStyle: 'short'})} endTime={new Date(event.end).toLocaleTimeString('en-gb', {timeStyle: 'short'})} closeModal={() => setInstructorViewSessionModalOpen(false)} />)
    return setInstructorViewSessionModalOpen(true)
  }

  if (event.type === 'session') {
    console.log(event)
    setInstructorViewSessionModal(<InstructorViewSessionModal athleteName={event.title} sessionID={event.id} startHour={event.start.getHours()} uid={currentUser.uid} calendarID={event.calendarID} location={event.location} available={false} date={event.start.toLocaleDateString('en-us', {dateStyle: 'full'})} startTime={event.start.toLocaleTimeString('en-gb', {timeStyle: 'short'})} endTime={new Date(event.end).toLocaleTimeString('en-gb', {timeStyle: 'short'})} closeModal={() => setInstructorViewSessionModalOpen(false)} />)
    return setInstructorViewSessionModalOpen(true)
  }

  if (userRole === 'instructor' && (event.type === 'booked' || event.type === 'pending')) {
    // alert('hi')
    console.log(event)
    setCurrentBookingModal(<BookingDetailModal instructorID={event.instructorID} role={userRole} startObj={event.start} endObj={event.end} total={event.total} chatID={event.chatID} userID={currentUser.uid} reloadCalendar={() => {
      if (userRole === 'owner') {
        loadCalendarForFacility(currentFacilityID)
      } else {
        if (currentFacilityID === currentUser.uid) {
          loadCalendarForFacility(event.instructorID);
        } else {
          loadCalendarForFacility(event.facilityID, true)
        }
      }
    }
    } calendarID={event.id} bookingID={event.bookingID} facilityID={userRole === 'owner' ? currentFacilityID : event.facilityID} venueName={event.title} type={event.type} title={event.title} start={event.start.toLocaleString()} end={event.end.toLocaleString()} closeModal={() => setBookingModalOpen(false)} />)
    setBookingModalOpen(true);
    // setCurrentBookingModal()
  }

  // console.log(event.chatID);
  setCurrentBookingModal(<BookingDetailModal instructorID={event.instructorID} role={userRole} startObj={event.start} endObj={event.end} total={event.total} chatID={event.chatID} userID={currentUser.uid} reloadCalendar={() => {
    if (userRole === 'owner') {
      loadCalendarForFacility(currentFacilityID)
    } else {
      if (currentFacilityID === currentUser.uid) {
        loadCalendarForFacility(event.instructorID);
      } else {
        loadCalendarForFacility(event.facilityID, true)
      }
    }
  }
  } calendarID={event.id} bookingID={event.bookingID} facilityID={userRole === 'owner' ? currentFacilityID : event.facilityID} venueName={currentFacility} type={event.type} title={event.title} start={event.start.toLocaleString()} end={event.end.toLocaleString()} closeModal={() => setBookingModalOpen(false)} />)
  setBookingModalOpen(true);
}

function hideModal(event) {
    // console.log(event)
    if (event.target.className === 'discount-modal') {
            setDiscountModalOpen(false);
            setActiveTab('overview')
    }
}

function eventStyle(event) {
  let bcg = 'black';
  if (event.type === 'booked') {
    bcg = '#023e73';
  }
  if (event.type === 'pending' || event.type === 'pending-session') {
    bcg = '#D94A4A';
  }
  if (event.type === 'instructor-available' || event.type === 'available') {
    bcg = 'green'
  }
  if (event.type === 'payment-pending') {
    bcg = '#f2c53d'
  }

  var styleMap = {
      backgroundColor: bcg,
      // borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block'
  }


  return {style:styleMap};
}

// Load this user's facilities
async function loadFacilities() {
  const facDocs = await db.collection('facilities').where('ownerIDs', 'array-contains', currentUser.uid).get();
  const nameArray = [];
  const facArray = [];
  // facDocs.forEach((facility) => {
    for (var i = 0; i < facDocs.docs.length; i++) {
    const facility = facDocs.docs[i];
    const facName = facility.data().listingName;
    const capacity = facility.data().maxCapacity;
    const option = (<option value={facility.id} id={facName}>{facName}</option>)
    facArray.push({
      name: facName,
      value: facility.id,
      capacity,
    });
    nameArray.push(option);
    if (i === 0) {
      setFacilityCapacity(capacity);
      setCurrentFacilityID(facility.id);
      setCurrentFacility(facName);
      loadCalendarForFacility(facility.id);
    }
    }
    if (facDocs.docs.length === 0) {
      setNoFacs(true)
      setLoading(false)
    }
  // });
  setFacilityArray(facArray);
  setListingNames(nameArray);

}

// Load Facilities on start up
useEffect(() => {
  if (currentUser !== null && currentUser !== undefined && userRole !== null && userRole !== undefined) {

    if (userRole === 'instructor') {
      loadCalendarForFacility(currentUser.uid, false);

    }
    if (userRole === 'owner') {
      console.log(userRole)
      loadFacilities();
    }
  }
}, [currentUser, userRole])


// Load all calendar events for this facility
async function loadCalendarForFacility(facilityID, menu) {
  setLoading(true);
  // const calendarDocs = await db.collection('facilities').doc(facilityID).collection('calendarEvents').get();
  if (userRole === 'owner') {
    const obj = facilityArray.find(fac => fac.value === facilityID);
    // console.log(obj)
    if (obj !== undefined && obj !== null) {
      setFacilityCapacity(obj.capacity);
      setCurrentFacility(obj.name)
    }
    // setFacilityCapacity(obj.capacity);
  db.collection('facilities').doc(facilityID).collection('calendarEvents').onSnapshot(async (calendarDocs) => {
  const eventArray = [];
    for (let i = 0; i < calendarDocs.docs.length; i++) {
      const eventData = calendarDocs.docs[i];
    const doc = eventData.data();
    if (doc.cancelled === true) {
      continue;
    }
    const {bookingID, instructorID} = doc;
    const total = doc.total;
    let chatID = '';
    if (doc.type === 'booked') {
      const chatDocs = await db.collection('chat').where('ownerID', '==', currentUser.uid).where('instructorID', '==', doc.instructorID).get();
      if (chatDocs.docs.length > 0) {
        chatID = chatDocs.docs[0].id;
      }
    }
    const eventMap = {
      total,
      bookingID,
      chatID,
      id: eventData.id,
      title: doc.title,
      allDay: false,
      start: new Date(doc.start.toDate()),
      end: new Date(doc.end.toDate()),
      type: doc.type,
      instructorID,
    };

    eventArray.push(eventMap);
  }
  const bookedEvent =     {
    id: 2,
    title: 'Peter Peterson',
    allDay: false,
    start: new Date(2021, 7, 27, 14, 30, 0),
    end: new Date(2021, 7, 27, 15, 30, 0),
    type: 'booked',
  };
  const bookedEvent2 =     {
    id: 10,
    title: 'Peter Peterson',
    allDay: false,
    start: new Date(2021, 8, 27, 14, 30, 0),
    end: new Date(2021, 8, 27, 15, 30, 0),
    type: 'booked',
  };
  eventArray.push(bookedEvent)
  // eventArray.push(bookedEvent2)
  setStoredEvents([...eventArray]);
  setLoading(false);
});
  }
  if (userRole === 'instructor' && menu === true) {
    setInstructorCalendar(false);
    // console.log(facilityID);
    const facilityDoc = await db.collection('facilities').doc(facilityID).get();
    const facility = facilityDoc.data();
    const {listingName, ownerIDs} = facility;

    setCurrentFacility(listingName);
    db.collection('facilities').doc(facilityID).collection('calendarEvents').onSnapshot(async (calendarDocs) => {
      // console.log(calendarDocs.docs.length)
      const eventArray = [];
      for (let i = 0; i < calendarDocs.docs.length; i++) {
        const eventData = calendarDocs.docs[i];
        const doc = eventData.data();
        const bookingID = doc.bookingID;
        const instructorID = doc.instructorID;
        const type = doc.type;
        const capacity = doc.capacity;
        const bookingIDs = doc.bookingIDs ?? [];
        let title = '';
        if (type === 'available') {
          title = `${doc.title} - (${capacity - bookingIDs.length} left)`
        } else {
          title = doc.title;
        }
        if (doc.cancelled === true) {
          continue
        }
        if (type === 'booked' && instructorID !== currentUser.uid) {
          continue
        }
        const total = doc.total;
        let chatID = '';
        if (doc.type === 'booked') {
          const chatDocs = await db.collection('chat').where('ownerID', '==', ownerIDs[0]).where('instructorID', '==', doc.instructorID).get();
          if (chatDocs.docs.length > 0) {
            chatID = chatDocs.docs[0].id;
          }
        }
        // console.log(doc.type)
        const eventMap = {
          capacity,
          bookingIDs,
          instructorID: doc.instructorID,
          facilityID: doc.facilityID,
          total,
          bookingID,
          chatID,
          id: eventData.id,
          title,
          allDay: false,
          start: new Date(doc.start.toDate()),
          end: new Date(doc.end.toDate()),
          type: doc.type,
        };
        eventArray.push(eventMap);
      }

      setStoredEvents([...eventArray]);
      setLoading(false);

      });
  }

if (userRole === 'instructor' && menu !== true) {
  setInstructorCalendar(true);
  const userDoc = await db.collection('users').doc(facilityID).get();
  const user = userDoc.data();
  const userName = `${user.firstName} ${user.lastName}`;
  const favFacs = user.favoriteFacilities ?? [];
  loadInstructorFavorites(favFacs, userName)
  setCurrentFacility(userName)
  db.collection('users').doc(facilityID).collection('calendarEvents').onSnapshot(async (calendarDocs) => {

    const eventArray = [];
    for (let i = 0; i < calendarDocs.docs.length; i++) {
    const eventData = calendarDocs.docs[i];
    const doc = eventData.data();
    if (doc.cancelled === true) {
      continue;
    }
    const bookingID = doc.bookingID;
    const bookingFacID = doc.facilityID;
    const facilityDoc = await db.collection('facilities').doc(bookingFacID).get();
    if (!facilityDoc.exists) {
      continue;
    }
    const facility = facilityDoc.data();
    const ownerIDs = facility.ownerIDs;
    const userID = ownerIDs[0];
    const total = doc.total;
    let chatID = '';
    if (doc.type === 'booked') {
      const chatDocs = await db.collection('chat').where('instructorID', '==', currentUser.uid).where('ownerID', '==', userID).get();
      if (chatDocs.docs.length > 0) {
        chatID = chatDocs.docs[0].id;
      }
    }
    let type = doc.type;

    let title = doc.title;
    if (doc.paymentStatus === 'paymentPending') {
      title = `${title} - Payment Pending`;
      type = 'payment-pending'
    }

    const eventMap = {
      facilityID: doc.facilityID,
      instructorID: doc.instructorID,
      total,
      bookingID,
      chatID,
      id: eventData.id,
      title,
      allDay: false,
      start: new Date(doc.start.toDate()),
      end: new Date(doc.end.toDate()),
      type,
    };

    eventArray.push(eventMap);

  }
  setLoading(false)
  const instructorAvailableSessions = await db.collection('users').doc(currentUser.uid).collection('calendar').get();
  for (let i = 0; i < instructorAvailableSessions.docs.length; i++) {
    const calendarDoc = instructorAvailableSessions.docs[i];
    const calendarData = calendarDoc.data();
    const calendarID = calendarDoc.id;
    const {Sessions} = calendarData;
    const date = new Date(calendarData.date.toDate());

    if (Array.isArray(Sessions)) {
      for (let i = 0; i < Sessions.length; i++) {

        let title = '';
        let id = ''
        let type = '';
        let sessionLocation = '';

        const sessionDetails = Sessions[i];
        const {startTime, endTime, athleteID, sessionID, sessionName} = sessionDetails;
        const start = new Date(startTime.toDate())
        const end = new Date(endTime.toDate())
        if (sessionName === 'Available') {
          const eventMap = {
            id: sessionID,
            title: sessionName,
            allDay: false,
            start,
            end,
            type: 'instructor-available',
            location: sessionLocation,
            calendarID,
          }
   
          eventArray.push(eventMap);

          continue
        }
        const sessionDoc = await db.collection('sessions').doc(sessionID).get();
        if (!sessionDoc.exists) {
          continue;
        }
        const session = sessionDoc.data();
        const { isGroupSession, classID, requestStatus} = session;
        sessionLocation = session.location;
        if (!isGroupSession) {
         const athleteDoc = await db.collection('users').doc(athleteID).get();
         const athlete = athleteDoc.data();
         const athleteName = `${athlete.firstName} ${athlete.lastName}`;
         title = athleteName;
        } else {
         const classDoc = await db.collection('classes').doc(classID).get();
         const classData = classDoc.data();
         const {className} = classData;
         title = className;
        }

        type = 'session'
  
        if (requestStatus === 'requestedByAthlete') {
          type = 'pending-session'
        }

        const eventMap = {
          id: sessionID,
          title: sessionName,
          allDay: false,
          start,
          end,
          type,
          location: sessionLocation,
          calendarID,
        }
 
        eventArray.push(eventMap);
      }

    } else {
      for (let [key, value] of Object.entries(Sessions)) {
        // console.log(date);
         const start = new Date(date);
         start.setHours(key);
         const end = new Date(date)
         const increase = parseInt(key) + 1;
         end.setHours(increase);
         let title = '';
         let id = ''
         let type = '';
         let sessionLocation = '';
         if (value === 'Available') {
           title = value;
           type = 'instructor-available'
         } else {
          //  console.log(value)
           let sessionID = value;
           if (value.includes('class')) {
            //  console.log('hurrrr')
            sessionID = sessionID.replace('class:', '');
            // console.log(sessionID);
           }
           const sessionDoc = await db.collection('sessions').doc(sessionID).get();
           if (!sessionDoc.exists) {
             continue;
           }
           const session = sessionDoc.data();
           const {athleteID, isGroupSession, classID, requestStatus} = session;
           sessionLocation = session.location;
           if (!isGroupSession) {
            const athleteDoc = await db.collection('users').doc(athleteID).get();
            const athlete = athleteDoc.data();
            const athleteName = `${athlete.firstName} ${athlete.lastName}`;
            title = athleteName;
           } else {
            const classDoc = await db.collection('classes').doc(classID).get();
            const classData = classDoc.data();
            const {className} = classData;
            title = className;
           }
  
           id = sessionID;
           type = 'session'
  
           if (requestStatus === 'requestedByAthlete') {
             type = 'pending-session'
           }
         }
  
         const eventMap = {
           id,
           title,
           allDay: false,
           start,
           end,
           type,
           location: sessionLocation,
           calendarID,
         }
  
         eventArray.push(eventMap);
      }
    }




  }
  // console.log(eventArray)
  setStoredEvents([...eventArray]);
  // console.log(eventArray);
  if (location.state !== undefined && location.state !== null) {
    // console.log(`facility id is ${location.state.facilityID}`)
    console.log(location);
    if (Object.keys(location.state).length > 0) {
        loadCalendarForFacility(location.state.facilityID, true);
    setCurrentFacilityID(location.state.facilityID);
    location.state = {};
    // navigate.replace({ state: {} })
    }

  }
  setLoading(false);
  });


}

}

function handleSelect({ start, end }) {

    }


function testSelect(event) {
  // If click, go to where user clicked
  if (event.action === 'click') {
      setSelectedView('day')
      setSelectedDate(event.slots[0]);
  }
  if (event.action === 'select') {
    // Check if shorter than 30 minutes
    const startmoment = moment(event.start);
    const endmoment = moment(event.end);
    const minDiff = endmoment.diff(startmoment, 'minutes')
    // If shorter, notify user
    if (minDiff < 30) {
      setCurrentErrorModal(<CalendarErrorModal closeModal={() => setErrorModalOpen(false)} message={'Minimum 30 minutes required'} />)
      return setErrorModalOpen(true);
    }
    // If instructor on their own calendar, return
    if (userRole === 'instructor') {



      if (instructorCalendar) {

        // Check if timeslot already is available
        let freeEvt;
        let found = false;
        const { start, end} = event;
        const startMoment = moment(start)
        const endMoment = moment(end)
        storedEvents.forEach((evt) => {

          const evtStartMom = moment(evt.start)
          const evtEndMom = moment(evt.end)

          if (evt.type === 'instructor-available') {
            if ((start > evt.start && start < evt.end) || (end > evt.start && end <= evt.end)) {
              found = true;
              // freeEvt = evt;
            }
          
            if (evtStartMom.isBetween(startMoment, endMoment) || evtEndMom.isBetween(startMoment, endMoment)) {
              found = true;
            }
            
          }

        });

        if (found) {
          setCurrentErrorModal(<CalendarErrorModal closeModal={() => setErrorModalOpen(false)} message={'You already have a slot available at this time'} />)
          setErrorModalOpen(true);
          return
        }

        const date = new Date(event.start);
        setInstructorAvailableModal(<InstructorAvailableModal
            reloadCalendar={() => loadCalendarForFacility(currentUser.uid, false)}
            closeModal={() => setInstructorAvailableModalOpen(false)}
            start={event.start}
            end={event.end}
            selectedDate={event.start}
            uid={currentUser.uid}
          />)
        setInstructorAvailableModalOpen(true)
        return
      }

      // Check whether the selected slot has availability
      let open = false;
      let freeEvt;
      const { start, end} = event;
      storedEvents.forEach((evt) => {
        if (evt.type !== 'available') {
          return
        }
        if (start >= evt.start && end <= evt.end) {
          open = true;
          freeEvt = evt;
        }
      });

      if (open === true) {
        const bookingIDs = freeEvt.bookingIDs;
        const maxCapacity = freeEvt.capacity;
        let count = 0;
        let ownBooking = false;
        // Check if other bookings exist at the same time;
        bookingIDs.forEach((bookingID) => {
          let between = false;
          let booking = storedEvents.find(b => b.bookingID === bookingID);
          const bookingStart = moment(booking.start);
          const bookingEnd = moment(booking.end);
          const eventStart = moment(event.start);
          const eventEnd = moment(event.end);

          // Check if user already has booking at this time
          if (booking.instructorID === currentUser.uid) {
            ownBooking = true;
          }

          // Start
          if (eventStart.isBetween(bookingStart, bookingEnd)) {
            between = true;
          }
          // End
          if (eventEnd.isBetween(bookingStart, bookingEnd)) {
            between = true;
          }

          // If true increase count
          if (between) {
            count += 1;
          }
        });
        // If instructor already has booking
        if (ownBooking) {
          setCurrentErrorModal(<CalendarErrorModal closeModal={() => setErrorModalOpen(false)} message={'You already have a booking at this time'} />)
          setErrorModalOpen(true);
          return
        }
        // If slot is full
        if (count === maxCapacity) {
          setCurrentErrorModal(<CalendarErrorModal closeModal={() => setErrorModalOpen(false)} message={'Sorry - this slot is already at maximum capacity!'} />)
          setErrorModalOpen(true);
          return
        }
        // Instructor Booking Request
        setCurrentBookingModal(<InstructorBookingRequestModal reloadCalendar={() => loadCalendarForFacility(currentFacilityID, true)} availableID={freeEvt.id} instructorID={currentUser.uid} minDate={freeEvt.start} maxDate={freeEvt.end} rateType='hourly' facilityID={currentFacilityID} facilityName={currentFacility} start={start} end={end} closeModal={() => setBookingModalOpen(false)} />)
        setBookingModalOpen(true)
      }
      return
    }
  // If selected date is in the past
  const now = new Date();
  if (event.start < now) {
    setCurrentErrorModal(<CalendarErrorModal closeModal={() => setErrorModalOpen(false)} message={'Please ensure to pick a date in the future'} />)
    setErrorModalOpen(true);
    return
  }

    // Check if time already has available event
    let taken = false;
    storedEvents.forEach((booking) => {
      const bookingStart = moment(booking.start);
      const bookingEnd = moment(booking.end);
      const eventStart = moment(event.start);
      const eventEnd = moment(event.end);

      if (eventStart.isBetween(bookingStart, bookingEnd) || eventEnd.isBetween(bookingStart, bookingEnd)) {
        taken = true;
      }

      if (bookingStart.isBetween(eventStart, eventEnd) || bookingEnd.isBetween(eventStart, eventEnd)) {
        taken = true;
      }

    })
    if (taken) {
      setCurrentErrorModal(<CalendarErrorModal closeModal={() => setErrorModalOpen(false)} message={'You already have a slot available at this time'} />)
      setErrorModalOpen(true);
      return
    }
      setCurrentModal(<SetAvailableModal storedEvents={storedEvents} capacity={facilityCapacity} isLoading={setLoading} reloadCalendar={() => loadCalendarForFacility(currentFacilityID)} facilityName={currentFacility} facilityID={currentFacilityID} start={event.start} end={event.end} closeModal={() => {setAvailableModalOpen(false);setActiveTab('overview')}} />)
      setActiveTab('availability')
      setAvailableModalOpen(true);

  }


}

async function loadInstructorFavorites(facArray, userName) {

  const nameArray = [{
    name: userName,
    value: currentUser.uid,
  }];
  for (var i = 0; i < facArray.length; i++) {
    const facilityID = facArray[i];
    const facilityDoc = await db.collection('facilities').doc(facilityID).get();
    const facility = facilityDoc.data();
    const facilityName = facility.listingName;
    nameArray.push({
      name: facilityName,
      value: facilityID,
    })
  }
  // console.log(nameArray)
  setFacilityArray(nameArray);
}

function openAvailabilityModal() {
      const start = new Date();
      start.setDate(start.getDate() + 1);
      start.setMinutes(0);
      start.setSeconds(0);
      const end = new Date();
      end.setDate(end.getDate() + 1);
      end.setHours(end.getHours() + 1);
      end.setMinutes(0);
      end.setSeconds(0);

      setCurrentModal(<SetAvailableModal facilityName={currentFacility} facilityID={currentFacilityID} isLoading={setLoading} reloadCalendar={() => loadCalendarForFacility(currentFacilityID)} end={end} start={start} closeModal={() => {setAvailableModalOpen(false);setActiveTab('overview')}} />)
      setActiveTab('availability')
      setAvailableModalOpen(true);
}

    return (
      <LoadingOverlay
      active={loading}
      spinner
      text='Loading calendar events...'
      >
        <div className="calendar-page">
          {!noFacs ? <>
            <Drawer
                            onClick={() => setShowDrawer(false)}
                            className='calendar-menu-drawer'
                            anchor='left'
                            open={showDrawer}
                            onClose={() => setShowDrawer(false)}
            >
              <MenuItem text="Overview" ref={overviewRef} active={activeTab === 'overview' ? true : false} onClickEvent={() => { setActiveTab('overview') }} />
                {userRole === 'owner' && <MenuItem text="Availability" ref={availableRef} active={activeTab === 'availability' ? true : false} onClickEvent={() => {openAvailabilityModal()}} />}
                {(userRole === 'instructor' && instructorCalendar) && <MenuItem text="Availability" ref={availableRef} active={activeTab === 'availability' ? true : false} onClickEvent={() => {
                  setInstructorAvailableModal(<InstructorAvailableModal             reloadCalendar={() => loadCalendarForFacility(currentUser.uid, false)}
                  closeModal={() => setInstructorAvailableModalOpen(false)} uid={currentUser.uid} selectedDate={new Date()} />)
                  setInstructorAvailableModalOpen(true)

                }} />}
                {(userRole === 'instructor' && !instructorCalendar) && <MenuItem text="Credits" ref={availableRef} active={activeTab === 'credits' ? true : false} onClickEvent={() => {setShowCredits(true)}} />}
                {(userRole === 'instructor' && instructorCalendar) && <MenuItem text="Add Session" ref={availableRef} active={activeTab === 'sessions' ? true : false} onClickEvent={() => {setInstructorAddSessionModalOpen(true)}} />}
                {(userRole === 'instructor' && instructorCalendar) && <MenuItem text="Add Client" ref={availableRef} active={activeTab === 'clients' ? true : false} onClickEvent={() => {setShowAddClient(true)}} />}
                {/* {userRole === 'owner' && <MenuItem text="Price Blocks" active={activeTab === 'price-blocks' ? true : false} onClickEvent={() => {setActiveTab('price-blocks')}} />} */}
                {/* {userRole === 'owner' && <MenuItem text="Discounts" active={activeTab === 'discounts' ? true : false} onClickEvent={() => {setActiveTab('discounts'); setDiscountModalOpen(true);}} />} */}
              </Drawer>

              {/* Modals  */}
            {errorModalOpen && currentErrorModal}
            {discountModalOpen && <DiscountModal clickAction={hideModal} closeModal={() => {setDiscountModalOpen(false); setActiveTab('overview')}} />}
            {availableModalOpen && currentModal}
            {bookingModalOpen && currentBookingModal}
            {instructorAvailableModalOpen && instructorAvailableModal}
            {instructorViewSessionModalOpen && instructorViewSessionModal}
            {instructorAddSessionModalOpen && <InstructorAddSessionModal closeModal={() => setInstructorAddSessionModalOpen(false)} uid={currentUser.uid} />}
            {showAddClient && <AddClientModal closeModal={() => setShowAddClient(false)} uid={currentUser.uid} />}
            {showPayModal && payModal}
            {showCalendarInfo && <CalendarInfo closeModal={() => setShowCalendarInfo(false)} />}

            <div className="side-menu">
                <MenuItem text="Overview" ref={overviewRef} active={activeTab === 'overview' ? true : false} onClickEvent={() => { setActiveTab('overview') }} />
                {userRole === 'owner' && <MenuItem text="Availability" ref={availableRef} active={activeTab === 'availability' ? true : false} onClickEvent={() => {openAvailabilityModal()}} />}
                {(userRole === 'instructor' && instructorCalendar) && <MenuItem text="Availability" ref={availableRef} active={activeTab === 'availability' ? true : false} onClickEvent={() => {
                  setInstructorAvailableModal(<InstructorAvailableModal reloadCalendar={() => loadCalendarForFacility(currentUser.uid, false)}
                  closeModal={() => setInstructorAvailableModalOpen(false)} uid={currentUser.uid} selectedDate={new Date()} />)
                  setInstructorAvailableModalOpen(true)
                }} />}
                {(userRole === 'instructor' && instructorCalendar) && <MenuItem text="Add Session" ref={availableRef} active={activeTab === 'sessions' ? true : false} onClickEvent={() => {setInstructorAddSessionModalOpen(true)}} />}
                {(userRole === 'instructor' && !instructorCalendar) && <MenuItem text="Credits" ref={availableRef} active={activeTab === 'credits' ? true : false} onClickEvent={() => {setShowCredits(true)}} />}
                {(userRole === 'instructor' && instructorCalendar) && <MenuItem text="Add Client" ref={availableRef} active={activeTab === 'clients' ? true : false} onClickEvent={() => {setShowAddClient(true)}} />}
                {/* {userRole === 'owner' && <MenuItem text="Price Blocks" active={activeTab === 'price-blocks' ? true : false} onClickEvent={() => {setActiveTab('price-blocks')}} />} */}
                {/* {userRole === 'owner' && <MenuItem text="Discounts" active={activeTab === 'discounts' ? true : false} onClickEvent={() => {setActiveTab('discounts'); setDiscountModalOpen(true);}} />} */}
            </div>
            <div className="main-page">
              <div className="menu-icon-container">
              <div className="drop-container">
                  <SelectSearch
                  closeOnSelect={true}
                  search={false}
                  placeholder={currentFacility}
                  options={facilityArray}
                  onChange={(value) => {
                    // console.log(value);
                    if (value !== currentUser.uid) {
                      loadCalendarForFacility(value, true); setCurrentFacilityID(value);
                    } else {
                      loadCalendarForFacility(value); setCurrentFacilityID(value);

                    }
                    // setCurrentFacility(selectRef.current.options[selectRef.current.options.selectedIndex].id)
                  }}
                  />
                    {/* <select ref={selectRef} onChange={(value) => {loadCalendarForFacility(value.target.value); setCurrentFacilityID(value.target.value); setCurrentFacility(selectRef.current.options[selectRef.current.options.selectedIndex].id)}}>
                        {listingNames}
                    </select> */}
                </div>
              <MenuIcon className='cal-menu-icon' onClick={() => setShowDrawer(true)} />

              </div>
                    {showCredits && <CreditsModal instructorID={currentUser.uid} facilityID={facIDRef.current} closeModal={() => setShowCredits(false)} />}

                <div className="heading-container">
                    <h2>Calendar for: {currentFacility}</h2>
                </div>
                <div className="calendar-filter-row selected-client-menu mb-20">
                    <div style={{visibility: instructorCalendar ? 'visible' : 'hidden', flex: '0.4'}} className="calendar-filter-group">
                <button onClick={() => setFilter('')} className={filter === '' ? 'active-client-menu-button left-button' : 'left-button'}>All</button>
                        {userRole === 'instructor' && <button onClick={() => setFilter('instructor-available')} className={filter === 'instructor-available' && 'active-client-menu-button'} >Available</button>}
                        {userRole === 'owner' && <button onClick={() => setFilter('available')} className={filter === 'available' && 'active-client-menu-button'} >Available</button>}
                        {userRole === 'instructor' && <button onClick={() => setFilter('sessions')} className={filter === 'sessions' && 'active-client-menu-button'} >Sessions</button>}
                        <button onClick={() => setFilter('bookings')} className={filter === 'bookings' ? 'active-client-menu-button right-button' : 'right-button'}>Bookings</button>
                    </div>
                    <div className="time-div">
                      <p>Current Time: {new Date().toLocaleTimeString('en-gb', {timeStyle: 'short'})}</p>
                    </div>
                    {/* <div className="calendar-legend-group">
                      <div className="cal-leg-item">
                        <span className="blue"></span>
                        <p>Bookings</p>
                      </div>
                      <div className="cal-leg-item">
                        <span className="black"></span>
                        <p>Sessions</p>
                      </div>
                      <div className="cal-leg-item">
                        <span className="red"></span>
                        <p>Pending</p>
                      </div>
                      <div className="cal-leg-item">
                        <span className="green"></span>
                        <p>Available</p>
                      </div>
                    </div> */}
                    <div className="help-div">
                    <HelpIcon onClick={() => setShowCalendarInfo(true)} className='help-icon' />

                    </div>

                </div>
                <div className="cal-container">
                    <Calendar
                    min={new Date(0, 0, 0, 5, 0, 0)}
                    max={new Date(0, 0, 0, 22, 0, 0)}
                    // views={['month', 'day', 'agenda']}
                    views={['month', 'day', 'week']}
                    onView={(evt) => {setSelectedView(evt);}}
                    selectable
                    date={selectedDate}
                    view={selectedViewRef.current}
                    onNavigate={(a, b, c) => {setSelectedDate(a);}}
                    onSelectEvent={(event) => {showBookingModal(event)}}
                    onSelectSlot={testSelect}
                    localizer={localizer}
                    events={filter === '' ? storedEvents : storedEvents.map(event => {
                      if (filter === 'sessions') {
                        if (event.type === 'session' || event.type === 'pending-session') {
                          return event
                        }
                      } else if (filter === 'bookings') {
                        if (event.type === 'pending' || event.type === 'booked') {
                          return event
                        }
                      } else if (event.type === filter) {
                        return event
                      } else {
                        return null
                      }

                    })}
                    startAccessor="start"
                    endAccessor="end"
                    // style={{ height: 500, }}
                    step={60}
                    timeslots={1}
                    eventPropGetter={eventStyle}
                    components={{
                        month: {
                          dateHeader: ({ date, label }) => {
                            let highlightDate =
                              storedEvents.find(event =>
                                moment(date).isBetween(
                                  moment(event.start),
                                  moment(event.end),
                                  null,
                                  "[]"
                                )
                              ) !== undefined;
                            return (
                              <p style={highlightDate ? { color: "red" } : null}>{label}</p>
                            );
                          },

                        },
                        day: {
                        },
                        toolbar: RBCToolbar,
                      }}
                    />
                </div>
            </div>
            </> :
            <>
            <div className="no-facs-container">
              <h1>No facilities added yet</h1>
            </div>
            </>}
        </div>
        </LoadingOverlay>
    )
}

export default CalendarPage
