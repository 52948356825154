import React, { useState, useEffect, useRef } from 'react'
import './ReviewPage.css'

// Components
import InfoCard from '../../../Components/Cards/InfoCard'
import ReviewItem from './ReviewItem'
import ReportReviewModal from './ReportReviewModal'
import ReplyReviewModal from './ReplyReviewModal'
import LoadingOverlay from 'react-loading-overlay';

// Firebase
import { db } from '../../../firebase'

function ReviewPage({ venueName, facilityID, setLoading, userRole, uid }) {

    const [reportModalOpen, setReportModalOpen] = useState(false);
    const [reportModalID, setReportModalID] = useState('');
    const [replyModalOpen, setReplyModalOpen] = useState(false);
    const [replyModalID, setReplyModalID] = useState()
    const [loadedReviews, setLoadedReviews] = useState([]);
    const [totalReviews, setTotalReviews] = useState(0);
    const [totalReviewsToday, setTotalReviewsToday] = useState(0);
    const [totalReviewChange, setTotalReviewChange] = useState(0);
    const [reviewAverage, setReviewAverage] = useState(0);
    const [reviewAverageToday, setReviewAverageToday] = useState(0);
    const [averageChangeToday, setAverageChangeToday] = useState(0);
    const [fiveStarReviews, setFiveStarReviews] = useState(0);
    const [fiveStarsToday, setFiveStarsToday] = useState(0);
    const [fiveStarChange, setFiveStarChange] = useState(0);


    useEffect(() => {
        setLoading(true)
        // if (userRole === 'owner') {
        //     loadReviewsForFacility(facilityID)
        // }
        if (userRole === 'instructor') {
            loadReviewsForInstructor()
        }
    },[]);

    useEffect(() => {
        if (userRole === 'owner') {
            loadReviewsForFacility(facilityID)
        }
        }, [facilityID])

    function sameDay(first, second) {
        return first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();
    }

    function showReportModal(reviewID) {
        setReportModalID(reviewID)
        setReportModalOpen(true);
    }

    function showReplyModal(reviewID) {
        setReplyModalID(reviewID)
        setReplyModalOpen(true)
    }

    async function loadReviewsForInstructor() {
        console.log('loading for instructor')
        // Set up variables
        let reviewsToday = 0;
        let totalRatingToday = 0;
        let fiveStarsToday = 0;
        let fives = 0;

        // Instructor Data
        const instructorDoc = await db.collection('users').doc(uid).get();
        const instructor = instructorDoc.data();
        const {reviewAverage, totalReviews} = instructor;

        const test = await db.collection('reviews').doc('reviewCollections')
        .collection('public').where('instructorID', '==', uid)
        .orderBy('dateTimeStamp', 'desc').get().catch(err => console.log(err))
        console.log('made it past')
        // Open snapshot for reviews for this intructor
        db.collection('reviews').doc('reviewCollections')
            .collection('public').where('instructorID', '==', uid)
            .orderBy('dateTimeStamp', 'desc').onSnapshot(async reviews => {
                console.log('in the ting')
                const reviewArray = [];
                const now = new Date();
                for (var i = 0; i < reviews.docs.length; i++) {
                    const reviewDoc = reviews.docs[i];
                    const review = reviewDoc.data();
                    const reviewID = reviewDoc.id;
                    const {athleteID} = review;
                    const textContent = review.textContent;
                    const dateObject = new Date(review.dateTimeStamp.toDate());
                    const dateString = dateObject.toDateString();
                    const rating = review.rating;

                    if (rating === 5) {
                        fives += 1;
                    }

                    // If review happened today
                    if (sameDay(now, dateObject) === true) {
                        reviewsToday += 1;
                        totalRatingToday += rating;
                        if (rating === 5) {
                            fiveStarsToday += 1;
                        }
                    }

                    // Athlete Details
                    const athleteDoc = await db.collection('users').doc(athleteID).get();
                    const athlete = athleteDoc.data();
                    const athleteName = `${athlete.firstName} ${athlete.lastName}`;
                    const imageLink = athlete.imageLink;

                    // Add to aray
                    reviewArray.push({
                        reviewID,
                        instructorID: athleteID,
                        instructorName: athleteName,
                        textContent,
                        dateString,
                        imageLink,
                        rating,
                    });
                // });
                }

            // If there were reviews today calculate average, otherwise N/A
            let ratingToday = 0;
            if (totalReviewsToday > 0) {
                console.log('doing')
                ratingToday = (Math.round((totalRatingToday / reviewsToday) * 100)) / 100;
            }

            // Calculate the difference between ratings today and general
            const percentageAverageChange = (Math.round((ratingToday / reviewAverage) * 100));

            // Percentage change in reviews today
            const reviewsWithoutToday = totalReviews - reviewsToday;
            let percentageChange;
            if (reviewsWithoutToday === 0) {
                percentageChange = 100;
            } else {
                percentageChange = (Math.round(reviewsToday / reviewsWithoutToday) * 100);
            }

            // Five star calculations
            const fivesWithoutToday = fives - fiveStarsToday;
            let fivesPercentageChange = 0;
            if (fiveStarsToday > 0) {
                fivesPercentageChange = (Math.round(fiveStarsToday / fivesWithoutToday) * 100);
            }

            if (fivesWithoutToday === 0) {
                fivesPercentageChange = 100;
            }

            // Total Reviews Card
            setTotalReviews(totalReviews);
            setTotalReviewsToday(reviewsToday);
            setTotalReviewChange(percentageChange);

            // Review Average Card
            setReviewAverage(reviewAverage);
            setReviewAverageToday(ratingToday);
            setAverageChangeToday(percentageAverageChange);

            // Five Star Reviews Card
            setFiveStarReviews(fives);
            setFiveStarsToday(fiveStarsToday);
            setFiveStarChange(fivesPercentageChange)

            // Reviews
            setLoadedReviews(reviewArray);
            setLoading(false)

            })
    }

    async function loadReviewsForFacility(facilityID) {

        setLoading(true)

        // console.log(facilityID);

        if (facilityID === undefined || facilityID === null) {
            return setLoading(false)
        }

        // Set up variables
        let reviewsToday = 0;
        let totalReviews = 0;
        let totalRatingToday = 0;
        let fiveStarsToday = 0;

        // Load facility data
        const facilityDoc = await db.collection('facilities').doc(facilityID).get();
        const facility = facilityDoc.data();
        // const facReviewAverage = (Math.round(facility.reviewAverage * 100)) / 100;
        const facReviewAverage = facility.reviewAverage ?? 0

        // Open snapshot for reviews for this facility
        db.collection('facilityReviews').where('facilityID', '==', facilityID).orderBy('dateTimeStamp', 'desc').onSnapshot(async reviews => {
            const reviewArray = [];
            const now = new Date();
            totalReviews = reviews.docs.length;
            // Cycle through each document
            for (var i = 0; i < reviews.docs.length; i++) {
                const reviewDoc = reviews.docs[i];
                const review = reviewDoc.data();
                const reviewID = reviewDoc.id;
                const instructorID = review.instructorID;
                const textContent = review.textContent;
                const dateObject = new Date(review.dateTimeStamp.toDate());
                const dateString = dateObject.toDateString();
                const rating = review.rating;

                // If review happened today
                if (sameDay(now, dateObject) === true) {
                    reviewsToday += 1;
                    totalRatingToday += rating;
                    if (rating === 5) {
                        fiveStarsToday += 1;
                    }
                }

                // Instructor Details
                const instructorDoc = await db.collection('users').doc(instructorID).get();
                const instructor = instructorDoc.data();
                const instructorName = `${instructor.firstName} ${instructor.lastName}`;
                const imageLink = instructor.imageLink;

                // Add to aray
                reviewArray.push({
                    reviewID,
                    instructorID,
                    instructorName,
                    textContent,
                    dateString,
                    imageLink,
                    rating,
                });
            // });
            }


            // If there were reviews today calculate average, otherwise N/A
            let ratingToday = 0;
            if (totalReviewsToday > 0) {
                console.log('doing')
                ratingToday = (Math.round((totalRatingToday / reviewsToday) * 100)) / 100;
            }
            // Calculate the difference between ratings today and general
            let percentageAverageChange = (Math.round((ratingToday / reviewAverage) * 100));
            if (isNaN(percentageAverageChange)) {
                percentageAverageChange = 0
            }

            // Percentage change in reviews today
            const reviewsWithoutToday = totalReviews - reviewsToday;
            let percentageChange;
            if (reviewsWithoutToday === 0) {
                percentageChange = 100;
            } else {
                percentageChange = (Math.round(reviewsToday / reviewsWithoutToday) * 100);
            }

            // Five star calculations
            const fives = facility.fiveStarReviews ?? 0;
            const fivesWithoutToday = fives - fiveStarsToday;
            let fivesPercentageChange = 0;
            if (fiveStarsToday > 0) {
                fivesPercentageChange = (Math.round(fiveStarsToday / fivesWithoutToday) * 100);
            }

            if (fivesWithoutToday === 0) {
                fivesPercentageChange = 100;
            }

            // Total Reviews Card
            setTotalReviews(facility.totalReviews ?? 0);
            setTotalReviewsToday(reviewsToday);
            setTotalReviewChange(percentageChange);

            // Review Average Card
            setReviewAverage(facReviewAverage);
            setReviewAverageToday(ratingToday);
            setAverageChangeToday(percentageAverageChange);

            // Five Star Reviews Card
            setFiveStarReviews(fives);
            setFiveStarsToday(fiveStarsToday);
            setFiveStarChange(fivesPercentageChange)

            // Reviews
            setLoadedReviews(reviewArray);
            setLoading(false)
        });

    }

    return (
        <div className='review-page'>
            {reportModalOpen && <ReportReviewModal reviewID={reportModalID} closeModal={() => setReportModalOpen(false)} />}
            {replyModalOpen && <ReplyReviewModal reviewID={replyModalID} closeModal={() => setReplyModalOpen(false)} />}
            <div className="review-header-row">
                <h2>{venueName}</h2>
            </div>
            <div className="review-stats-row">
                <InfoCard value={totalReviews} info={'Total Reviews'} type='general' flex={0.3} today={totalReviewsToday} dir='up' change={totalReviewChange} icon='money' />
                <InfoCard value={reviewAverage} info={'Review Average'} type='red' flex={0.3} today={reviewAverageToday} dir='up' change={averageChangeToday} icon={'gym'}/>
                <InfoCard value={fiveStarReviews} info={'Five Stars'} type='blue' flex={0.3} today={fiveStarsToday} dir='up' change={fiveStarChange} icon={'person'} />
            </div>
            <div style={{marginTop:'40px'}} className="review-header-row">
                <h2 style={{textDecoration:'none'}}>Reviews</h2>
            </div>
            <div className="review-content-container">
                {loadedReviews.map((review, index) => {
                    return <ReviewItem
                        dateString={review.dateString}
                        showReportModal={showReportModal}
                        showReplyModal={showReplyModal}
                        instructorName={review.instructorName}
                        textContent={review.textContent}
                        reviewID={review.reviewID}
                        imageLink={review.imageLink}
                        rating={review.rating}
                        />
                })}
                {loadedReviews.length === 0 && <div className='no-reviews-container mt-20'>
                    <h3>No reviews to display</h3>
                    </div>}
                {/* <ReviewItem
                imageLink='https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg'
                dateString={'13th September, 2021'}
                showReportModal={() => setReportModalOpen(true)}
                showReplyModal={() => setReplyModalOpen(true)}
                instructorName='Peter Peterson'
                textContent='Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis aliquid molestiae esse officia ipsum quibusdam hic rem ut beatae, suscipit, excepturi repellendus dolor? Nesciunt, accusamus eveniet maxime molestias harum illum.' />
                <ReviewItem
                                imageLink='https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg'
                instructorName='Peter Peterson'
                textContent='Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis aliquid molestiae esse officia ipsum quibusdam hic rem ut beatae, suscipit, excepturi repellendus dolor? Nesciunt, accusamus eveniet maxime molestias harum illum.' />
                <ReviewItem
                                imageLink='https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg'

                instructorName='Peter Peterson'
                textContent='Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officiis aliquid molestiae esse officia ipsum quibusdam hic rem ut beatae, suscipit, excepturi repellendus dolor? Nesciunt, accusamus eveniet maxime molestias harum illum.' /> */}
            </div>
        </div>
    )
}

export default ReviewPage
