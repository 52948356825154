import React, { useState, useEffect, useRef } from 'react'
import './SetAvailableModal.css'

// Components
import DateTimePicker from 'react-datetime-picker';
import TimePicker from 'react-time-picker';
import moment from 'moment';



import { db } from '../../firebase';

function SetAvailableModal({ capacity, reloadCalendar, closeModal, start, end, facilityID, facilityName, isLoading, storedEvents }) {
    // console.log(formatDate(end))
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    // const [finished, setFinished] = useState(false);
    const [endingDate, setEndingDate] = useState(formatDate(end));
    const [startingDate, setStartingDate] = useState(formatDate(start))
    const [endingTime, setEndingTime] = useState(formatTime(end));
    const [startingTime, setStartingTime] = useState(formatTime(start))
    const [errorMessage, setErrorMessage] = useState('');
    const [update, setUpdate] = useState(0);

    const minDate = formatDate(new Date());

    const startRef = useRef();
    startRef.current = startingDate;
    const endRef = useRef();
    endRef.current = endingDate;
    const startTimeRef = useRef();
    startTimeRef.current = startingTime;
    const endTimeRef = useRef();
    endTimeRef.current = endingTime;

    const startInputRef = useRef();
    const endInputRef = useRef();
    const startTimeInputRef = useRef();
    const endTimeInputRef = useRef();

    const capacityRef = useRef();

    // const minDate = new Date();
    // minDate.setDate(minDate.getDate() + 1);
    // minDate.setHours(0);
    // minDate.setMinutes(0);

    useEffect(() => {
        // alert('HALALLAOAOAOOOOOO')
        setStartingDate(start);
        setEndingDate(end);
        console.log(facilityID)
    }, [])

    function validateDates() {
        const startingDate = new Date(startInputRef.current.value)
        const endingDate = new Date(endInputRef.current.value)
        const startHours = parseInt(startTimeInputRef.current.value.slice(0,2));
        const startMinutes = parseInt(startTimeInputRef.current.value.slice(3,5));
        startingDate.setHours(startHours);
        startingDate.setMinutes(startMinutes)
        const endHours = parseInt(endTimeInputRef.current.value.slice(0,2));
        const endMinutes = parseInt(endTimeInputRef.current.value.slice(3,5));
        endingDate.setHours(endHours);
        endingDate.setMinutes(endMinutes)
        if (startingDate > endingDate) {
            console.log('ello')
            setErrorMessage('Please ensure the start date is before the end date.');
            return false
        }
        if (startingDate < minDate) {
            setErrorMessage('Please ensure the start date is in the future')
            return false
        }

        let taken = false;

        storedEvents.forEach((booking) => {
            const bookingStart = moment(booking.start);
            const bookingEnd = moment(booking.end);
            const eventStart = moment(startingDate);
            const eventEnd = moment(endingDate);



            
            if (eventStart.isBetween(bookingStart, bookingEnd) || eventEnd.isBetween(bookingStart, bookingEnd)) {
                console.log('hey')
              taken = true;
            }
      
            if (bookingStart.isBetween(eventStart, eventEnd) || bookingEnd.isBetween(eventStart, eventEnd)) {
                console.log('ho')
                taken = true;
            }
      
          })

          console.log(taken)

          if (taken) {
              setErrorMessage('Please ensure that this slot does not overlap with others')
              return false
          }

    }

    async function saveCalendarSlot() {
        capacity = capacityRef.current.value;
        const startDate = new Date(startInputRef.current.value);
        const startHours = parseInt(startTimeInputRef.current.value.slice(0,2));
        const startMinutes = parseInt(startTimeInputRef.current.value.slice(3,5));
        startDate.setHours(startHours);
        startDate.setMinutes(startMinutes)
        const endDate = new Date(endInputRef.current.value);
        const endHours = parseInt(endTimeInputRef.current.value.slice(0,2));
        const endMinutes = parseInt(endTimeInputRef.current.value.slice(3,5));
        endDate.setHours(endHours);
        endDate.setMinutes(endMinutes)
        // return console.log(startDate, endDate)
        setErrorMessage('');
        isLoading(true);
        if (validateDates() === false) {
            return isLoading(false);
        }
        const type = 'available';
        const title = 'Available';
        if (startDate.getDate() === endDate.getDate()) {
        try {
            await db.collection('facilities').doc(facilityID).collection('calendarEvents').add({
                facilityID,
                start: startDate,
                end: endDate,
                type,
                title,
                bookingIDs: [],
                capacity,
            });
            console.log('success');
            reloadCalendar();
            isLoading(false);
            closeModal();
        }
        catch (err) {
            isLoading(false);
            setErrorMessage('Could not save, please try again')
        }
        } else {
            // Map out each day
            const dayDiff = endDate.getDate() - startDate.getDate();
            for (let i = 0; i < dayDiff + 1; i++) {
                const newStart = new Date(startDate);
                newStart.setDate(newStart.getDate() + i);
                const newEnd = new Date(newStart);
                newEnd.setHours(endHours);
                newEnd.setMinutes(endMinutes);
                console.log(newStart, newEnd);
                try {
                    await db.collection('facilities').doc(facilityID).collection('calendarEvents').add({
                        facilityID,
                        start: newStart,
                        end: newEnd,
                        type,
                        title,
                        bookingIDs: [],
                        capacity,
                    });
                    console.log('success');
                    reloadCalendar();
                    isLoading(false);
                    closeModal();
                }
                catch (err) {
                    isLoading(false);
                    setErrorMessage('Could not save, please try again')
                }
            }
        }
        isLoading(false);
    }

    function formatTime(date) {
        const d = new Date(date);
        return (d.toLocaleTimeString('en-gb', {
            hour: '2-digit',
            minute: '2-digit'
        }))
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }


    return (
        <div onClick={(evt) =>  {if (evt.target.className === 'available-modal') { closeModal()}}} className='available-modal'>
            <div className="available-modal-content scale-in-center">
                <h1>Set Availability - {facilityName}:</h1>
                <div className="available-modal-row date-row">
                    <p>Date:</p>
                    <input type='date' ref={startInputRef} onChange={(e) => {
                        const sd = new Date(e.target.value)
                        const ed = new Date(endInputRef.current.value);
                        // console.log(ed, sd, ed < sd)
                        if (ed < sd) {
                            endInputRef.current.value = e.target.value
                        }
                        }} min={minDate} defaultValue={startRef.current} />
                    <h1>-</h1>
                    <input type='date' ref={endInputRef} onChange={(e) => console.log(e)} min={minDate} defaultValue={endRef.current} />
                    {/* <p className='bold'>Start Date:</p>
                    <p className='middle-pee'>{startingDate.toLocaleDateString("en-US", options)}</p>
                    <DateTimePicker minDate={minDate} disableClock={true}  className='available-dt-picker' onChange={setStartingDate} defaultValue={start} value={startingDate} /> */}
                </div>
                <div className="available-modal-row date-row">
                    <p className='bold'>Time:</p>
                    {/* <TimePicker disableClock={true} /> */}
                    <input type='time' ref={startTimeInputRef} defaultValue={startTimeRef.current} step={300} onChange={(e) =>{
                        const startHours = parseInt(e.target.value.slice(0,2));
                        const endHours = parseInt(endTimeInputRef.current.value.slice(0,2));
                        // if (startHours > )
                        // console.log(endTimeInputRef.current.value)
                        // console.log(e)
                        }} />
                    <h1>-</h1>
                    <input type='time' ref={endTimeInputRef}  defaultValue={endTimeRef.current}/>
                    {/* <p className='middle-pee'>{endingDate.toLocaleDateString("en-US", options)}</p> */}
                    {/* <DateTimePicker minDate={minDate} className='available-dt-picker' onChange={setEndingDate} value={endingDate} defaultValue={end} /> */}
                </div>
                <div className="available-options-container">
                    <div className="available-options-header">
                        <div className="available-options-group">
                            <p>Capacity</p>
                            <input type='number' ref={capacityRef} min={1} defaultValue={capacity} step={1} max={capacity} />
                        </div>
                    </div>

                </div>
                <div className="availability-error-message-row">
                    <p>{errorMessage}</p>
                </div>
                <div className="button-row">
                    <button onClick={saveCalendarSlot} id='confirm-button' className='confirm-button'>Confirm</button>
                    <button onClick={closeModal} id="cancel-button" className='cancel-button'>Close</button>
                </div>
            </div>
        </div>
    )
}

export default SetAvailableModal
