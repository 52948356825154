import React from 'react'
import { useNavigate } from 'react-router';
import './HomeBookingItem.css'

function HomeBookingItem({ start, end, type, rate, total, venue, bookingID, facilityID }) {

    var dateOptions = { year: 'numeric', month: 'long', day: 'numeric'};
    var timeOptions = { hour: '2-digit', minute: '2-digit'}

    const navigate = useNavigate();

    function goToBooking() {
        console.log(bookingID)
        navigate(
            '/bookings',
            {
                state: {bookingID, facilityID, type: 'booking'},
        })
    }

    return (
        <div onClick={goToBooking} className='home-booking-item'>
            {type === 'hourly' &&
            <div className="hourly-item">
            <div className="hourly-item-left">
                <h1>{start.toLocaleDateString('en-us', dateOptions)}</h1>
                <p>{venue}</p>
                <p>{start.toLocaleTimeString('en-us', timeOptions)} - {end.toLocaleTimeString('en-us', timeOptions)}</p>
            </div>
            <div className="hourly-item-right">
                <p className='money-line'>R {total}</p>

            </div>
            </div>

            }
        </div>
    )
}

export default HomeBookingItem
