import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './NotificationItem.css'

// Material UI
import AddCommentIcon from '@material-ui/icons/AddComment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import GroupsIcon from '@mui/icons-material/Groups';
import PaidIcon from '@mui/icons-material/Paid';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';


import { db } from '../../firebase';

import PayBookingModal from '../../Pages/Bookings/PayBookingModal/PayBookingModal';


const useStyles = makeStyles(() => ({
    tooltip: {
      fontSize: '18px',
    },
  }));

function NotificationItem({ title, description, type, docID, hideDrop, read, dateCreated, facilityID, uid, userRole, loadPayModal }) {
    const [chosenImage, setChosenImage] = useState();
    const [showPayModal, setShowPayModal] = useState(false)
    const [payModal, setPayModal] = useState(false)

    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        switch (type) {
            case 'booking-paid': setChosenImage(<CreditScoreIcon />)
                break;
            case 'booking-payment': setChosenImage(<PaidIcon />)
                break;
            case 'group': setChosenImage(<GroupsIcon />)
                break;
            case 'booking': setChosenImage(<EventAvailableIcon />)
                break;
            case 'review': setChosenImage(<AddCommentIcon />)
                break;
            case 'chat': setChosenImage(<MailIcon />)
                break;
            case 'credits': setChosenImage(<AddShoppingCartIcon />)
                break;
            default: setChosenImage(<NotificationsIcon />)
                break;
        }
    }, []);

   async function handleClick() {

        // return console.log(type)

        // Mark as read
        db.collection('users').doc(uid).collection('notifications').doc(docID).update({
            read: true,
        })

        if (userRole === 'instructor') {
            if (type === 'booking-payment') {
                const bookingDoc = await db.collection('bookings').doc(docID).get();
                const booking = bookingDoc.data();
                const { start, end, instructorID, facilityID, total, paymentStatus } = booking;
                const startDate = new Date(start.toDate())
                const endDate = new Date(end.toDate());
                // return alert(paymentStatus)
                if (paymentStatus === 'paymentPending') {
                    return loadPayModal(docID)
                } else {
                    return navigate(
                        '/bookings',
                        {state: {
                            bookingID: docID,
                            type: 'booking',
                        }
                    })
                }

                // // Facility
                // const facilityDoc = await db.collection('facilities').doc(facilityID).get();
                // const facility = facilityDoc.data();
                // const { listingName } = facility;
                //  setPayModal(<PayBookingModal closeModal={() => setShowPayModal(false)} start={startDate} end={endDate} bookingID={docID} facilityName={listingName}
                //     facilityID={facilityID} instructorID={instructorID} total={total}
                // />)
                // return setShowPayModal(true)
            }

            if (type === 'group') {
               return navigate(
                    '/bookings',
                    {
                        state: {
                            bookingID: docID,
                            facilityID,
                            type,
                        }
                })
            }
            if (type === 'chat') {
                console.log(docID)
                return navigate(
                    '/inbox',
                    {
                        state: {
                            chatID: docID,
                    }
                })
            }
            if (type === 'session') {
                console.log(docID)
                return navigate(
                    '/bookings',
                    {
                        state: {
                            bookingID: docID,
                            type,
                    }
                })
            }
        }
        console.log(docID)

        if (type === 'booking') {
            navigate(
                '/bookings',
                {
                    state: {
                        bookingID: docID,
                        facilityID,
                        type,
                    }
            })
        }
        if (type === 'review') {
            navigate(
                '/analytics',
                {
                    state: {
                        reviewID: docID,
                        facilityID,
                        type,
                }
            })
        }
        if (type === 'welcome') {
            navigate('/listings')
        }
    }


    return (
        <div onClick={handleClick} className={!read ? 'notification-item unread ' : 'notification-item'}>
            {showPayModal && payModal}
            <div className="notification-image">
                {chosenImage}
            </div>
            <div className="notification-content">
                <h3>{title}</h3>
                <Tooltip classes={{tooltip: classes.tooltip}} title={description}>
                <p>{description}</p>
                </Tooltip>
                <div className="notification-date">
                    <p>{dateCreated}</p>
                </div>
            </div>
        </div>
    )
}

export default NotificationItem
